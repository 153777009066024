import React, { useContext } from "react";

import { CenterItem } from "./components";
import { AccessibilityContext, Side } from "context/AccessibilityContext";
import { Loader } from "components";
import useStores from "~/hooks/useStores";

const CenterPanel = () => {
  const [accessibilityState] = useContext(AccessibilityContext);
  const { activeTreeNode } = useStores();
  const isLoading =
    accessibilityState.loading.active === true && accessibilityState.loading.sides.includes(Side.Builder);

  return (
    <div>
      {isLoading === true && <Loader overlay text={accessibilityState.loading.text} />}
      {activeTreeNode && <CenterItem treeNode={activeTreeNode} />}
    </div>
  );
};

export default CenterPanel;
