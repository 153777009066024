import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import i18n from "i18next";
import { Button, Loader } from "components";
import Api from "~/util/Api";
import { saveCredentials } from "~/util/Credentials";
import { isInIframe } from "~/util/Utils";
import { DESTINATION } from "~/util/ProtectedRoute";
import Communication from "~/util/Communication";
import { NOTIFICATION_PLACEMENT } from "~/util/Constant";
import { useAppNotification } from "@ogury/design-system";

import style from "./Signin.module.scss";

let connecting = false;
let connected = false;

export default function Signin() {
  const notification = useAppNotification();
  const history = useHistory();
  const [sso, setSso] = useState(true);

  async function connect(persistAndRedirectUser) {
    if (connecting === false && connected === false) {
      connecting = true;
      const parentProxy = await Communication.initialize(true);
      console.debug("Asking the credentials to the iframe parent");
      try {
        const credentials = await parentProxy.call("onCredentials");
        // Invoking the "onCredentials()" method in the parent caller, which returns a credentials object
        persistAndRedirectUser(credentials);
        connected = true;
      } catch (error) {
        notification.error({
          message: error.message,
          description: i18n.t("signIn.notification.description"),
          placement: NOTIFICATION_PLACEMENT,
          duration: 0,
        });
      } finally {
        connecting = false;
        await parentProxy.destroy();
      }
    }
  }

  function persistAndRedirectUser(user) {
    saveCredentials(user);

    let destination = localStorage.getItem(DESTINATION);
    if (destination) {
      destination = decodeURIComponent(destination);
    } else {
      destination = "/";
    }

    console.debug(`Redirecting to '${destination}'…`);
    history.replace(destination);
  }

  function onMessage(event) {
    if (event.origin === Api.ROUTES.LOGIN_URL && event.data) {
      // Login form
      persistAndRedirectUser(event.data);
    } else {
      console.warn("PostMessage received but event might not be signin event. Ignored.", event.origin, event.data);
    }
  }

  useEffect(() => {
    const run = async () => {
      const shouldUseSso = isInIframe() === true;
      if (shouldUseSso === true) {
        // The form is embedded in an iframe
        await connect(persistAndRedirectUser);
      } else {
        window.addEventListener("message", onMessage);
        setSso(false);
      }
      return () => {
        if (shouldUseSso === false) {
          window.removeEventListener("message", onMessage);
        }
      };
    };
    // noinspection JSIgnoredPromiseFromCall
    run();
  }, []);

  function signin() {
    window.open(`${Api.ROUTES.LOGIN_URL}/?sendMessage=true#/login`, "_blank");
  }

  function renderSso() {
    if (sso === true) {
      return <Loader text={"Authenticating"} withLoader={true} />;
    }
  }

  function renderSignin() {
    if (sso === false) {
      return <Button onClick={signin}>{i18n.t("signIn.title")}</Button>;
    }
  }

  return (
    <div className={style.container}>
      {renderSso()}
      {renderSignin()}
    </div>
  );
}
