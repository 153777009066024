import React from "react";
import PropTypes from "prop-types";

const InputUiFactory = ({ input, level, ...rest }) => {
  if (input !== undefined) {
    // We bind the "dispatch" function to the instance
    const InputUi = input.renderComponent();
    return <InputUi input={input} level={level} {...rest} />;
  }
  return <React.Fragment />;
};

InputUiFactory.propTypes = {
  input: PropTypes.object.isRequired,
  level: PropTypes.number.isRequired,
};

export default InputUiFactory;
