import { getActiveTreeNodeId } from "~/context/UiStoreContext";
import References from "~/util/References";

let HISTORY_STORE = [];
let DIRTY = false;

let currentIndex = -1;
const MAXIMUM_HISTORY = 20;

export const HistoryType = {
  Value: "VALUE",
  Enabled: "ENABLED",
  Collection: "COLLECTION",
};

function updateIndex() {
  DIRTY = true;
  HISTORY_STORE.length = currentIndex + 1;
  if (HISTORY_STORE.length === MAXIMUM_HISTORY) {
    HISTORY_STORE.shift();
  } else {
    currentIndex++;
  }
}

function getMetaData() {
  return {
    centerScrollTop: document.getElementById(References.CENTER_PANEL_ID).scrollTop,
    activeInput: getActiveTreeNodeId(),
  };
}

export default {
  push: (id, type, previous, current) => {
    updateIndex();
    HISTORY_STORE.push({
      id,
      type,
      previous,
      current,
      ...getMetaData(),
    });
  },
  pushCollection: (previous, current) => {
    updateIndex();
    HISTORY_STORE.push({
      type: HistoryType.Collection,
      previous,
      current,
      ...getMetaData(),
    });
  },
  getCurrentIndex: () => currentIndex,
  get: inputId => HISTORY_STORE.find(data => data.id === inputId),
  undo: () => {
    const data = HISTORY_STORE[currentIndex];
    currentIndex--;
    return data;
  },
  redo: () => {
    const data = HISTORY_STORE[currentIndex + 1];
    currentIndex++;
    return data;
  },
  canUndo: () => HISTORY_STORE.length > 0 && currentIndex >= 0,
  canRedo: () => HISTORY_STORE.length > 0 && currentIndex < HISTORY_STORE.length - 1,
  getAll: () => HISTORY_STORE,
  reset: () => {
    HISTORY_STORE = [];
    currentIndex = -1;
  },
  resetDirty: () => (DIRTY = false),
  getDirty: () => DIRTY,
};
