import InputBinaryModel from "./InputBinaryModel";
import InputLottieUi from "../ui/InputLottieUi/InputLottieUi";
import { toStringWithBytes, toStringWithLessThan } from "~/util/Helpers";
import { Typography } from "@ogury/design-system";
import i18n from "i18next";

export default class InputLottieModel extends InputBinaryModel {
  constructor(...args) {
    super(...args);
  }

  async computeConstraintsErrors() {
    const maximumBytes = this.getMaximumBytesConstraints();
    if (maximumBytes !== undefined) {
      const data = await this.getData();
      if (window.atob(data).length > maximumBytes) {
        return [
          {
            message: i18n.t("error.constraint.lottieFile", { maximumBytes: toStringWithBytes(maximumBytes) }),
          },
        ];
      }
    }

    return [];
  }

  renderConstraints() {
    const maximumBytes = this.getMaximumBytesConstraints();

    return (
      <span>
        {maximumBytes !== undefined && (
          <span>
            <Typography.P2Regular>{i18n.t("inputs.label.weight")}</Typography.P2Regular>
            <Typography.P2Regular>{toStringWithLessThan(toStringWithBytes(maximumBytes))}</Typography.P2Regular>
          </span>
        )}
      </span>
    );
  }

  renderComponent() {
    return InputLottieUi;
  }
}
