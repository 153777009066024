import InputUiFactory from "../../../../../inputs/ui/InputUiFactory";
import React from "react";
import { InputHeader } from "../../../../../../components";
import References from "../../../../../../util/References";
import useStores from "../../../../../../hooks/useStores";

export default function CenterItem({ treeNode = {}, level = 0 }) {
  const { getDeepNodeInstanceById } = useStores();
  const instance = getDeepNodeInstanceById(treeNode.id);

  if (!instance || !treeNode.display) {
    return <></>;
  }
  const isRoot = treeNode.id === References.ROOT_INPUT_ID;
  const isEnabled = instance.isEnabled();

  function renderFinalOrHeader() {
    if (!isRoot) {
      if (treeNode.superType === References.INPUT_SUPER_TYPE.FINAL && isEnabled) {
        return <InputUiFactory key={instance.getId()} input={instance} level={level} />;
      } else {
        return <InputHeader input={instance} />;
      }
    }
  }

  function renderGroupAndCollection() {
    if (treeNode.inputs?.length >= 1 && isEnabled) {
      return treeNode.inputs.map(childNode => {
        return <CenterItem key={"centerItem-" + childNode.id} treeNode={childNode} level={isRoot ? 0 : level + 1} />;
      });
    }
  }

  return (
    <div style={{ marginLeft: level + "0" + "px" }}>
      {renderFinalOrHeader()}
      {renderGroupAndCollection()}
    </div>
  );
}
