import { useEffect, useRef } from "react";
import lottie from "lottie-web";
import { Image, Transformer } from "react-konva/es/ReactKonva";
import PropTypes from "prop-types";
import References from "~/util/References";
import { restrictCoordinates } from "components/AdUnitCanvas/helpers";

export default function KonvaElement({ url, type, onChange, ...props }) {
  const animContainer = useRef(null);
  const imageRef = useRef(null);
  const transformerRef = useRef(null);

  useEffect(() => {
    if (!props.width || !props.height || props.width < 1 || props.height < 1) {
      return;
    }

    if (type === References.KONVA_ELEMENT_TYPE_LOTTIE) {
      const canvas = document.createElement("canvas");
      canvas.width = props.width;
      canvas.height = props.height;
      const context = canvas.getContext("2d");

      const anim = lottie.loadAnimation({
        container: animContainer.current,
        renderer: "canvas",
        loop: true,
        autoplay: true,
        path: url,
        rendererSettings: {
          context,
          clearCanvas: true,
        },
      });

      const handleFrame = () => {
        if (imageRef.current) {
          imageRef.current.getLayer().draw();
        }
      };

      anim.addEventListener("enterFrame", handleFrame);

      if (imageRef.current) {
        imageRef.current.image(canvas);
      }
      return () => {
        anim.removeEventListener("enterFrame", handleFrame);

        anim.destroy();
      };
    } else if (type === References.KONVA_ELEMENT_TYPE_IMAGE) {
      const htmlImage = new window.Image();
      htmlImage.src = url;

      const handleImageLoad = () => {
        if (imageRef.current) {
          imageRef.current.image(htmlImage);
        }
        htmlImage.removeEventListener("load", handleImageLoad);
      };

      htmlImage.addEventListener("load", handleImageLoad);
    }
  }, [url, props.width, props.height, type]);

  useEffect(() => {
    transformerRef.current?.nodes([imageRef.current]);
  }, [imageRef, transformerRef]);

  const handleImageDragMove = event => {
    const canvasDimensions = props.canvasData?.canvasDimensions;
    if (!canvasDimensions) {
      return;
    }
    const node = event.target;
    if (event.evt.shiftKey) {
      const restrictedCoordinates = restrictCoordinates(
        { x: node.x(), y: node.y(), width: node.width(), height: node.height() },
        canvasDimensions
      );

      node.x(restrictedCoordinates.x);
      node.y(restrictedCoordinates.y);
    }
  };

  return (
    <>
      <Image
        ref={imageRef}
        {...props}
        draggable
        onDragMove={handleImageDragMove}
        onDragEnd={e => {
          onChange({
            ...props,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={() => {
          const node = imageRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();
          const rotation = node.rotation();

          node.scaleX(1);
          node.scaleY(1);
          onChange({
            x: node.x(),
            y: node.y(),
            rotation,
            width: Math.max(node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
          });
        }}
      />
      <Transformer
        rotateEnabled={props.withRotation}
        rotateAnchorCursor={"pointer"}
        ref={transformerRef}
        enabledAnchors={["top-left", "top-right", "bottom-left", "bottom-right"]}
        boundBoxFunc={(oldBox, newBox) => {
          // limit resize
          if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
            return oldBox;
          }
          return newBox;
        }}
      />
    </>
  );
}

KonvaElement.propTypes = {
  url: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
