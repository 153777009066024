import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Radio, Space } from "@ogury/design-system";

import styles from "./SettingsTab.module.scss";
import { UNITS, useCreativeSettingsContext } from "~/context/CreativeSettingsContext";

const SettingsTab = () => {
  const [t] = useTranslation();

  const { creativeSettings, setCreativeSettings } = useCreativeSettingsContext();

  const handleUnitsChange = value => {
    setCreativeSettings({ ...creativeSettings, units: value });
  };

  return (
    <div className={styles.container}>
      <Typography.P2Strong>{t("leftPanel.settingsTab.units")}</Typography.P2Strong>
      <Radio.Group
        value={creativeSettings.units}
        onChange={handleUnitsChange}
        className={styles.radioGroup}
        data-testid="input-units"
      >
        <Space direction="vertical">
          <Radio value={UNITS.PIXELS}>{t("leftPanel.settingsTab.pixels")}</Radio>
          <Radio value={UNITS.PERCENTAGE} data-testid="radio-percentage">
            {t("leftPanel.settingsTab.percentage")}
          </Radio>
        </Space>
      </Radio.Group>
    </div>
  );
};

export default SettingsTab;
