import { cloneElement } from "react";
import * as PropTypes from "prop-types";
import {
  ArrowRightOutlined,
  CloudUploadOutlined,
  CompressOutlined,
  DownloadOutlined,
  SwapOutlined,
  ExpandAltOutlined,
  BgColorsOutlined,
  UploadOutlined,
  MoreOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  RedoOutlined,
  ReloadOutlined,
  SaveOutlined,
  DownSquareOutlined,
  UpSquareOutlined,
  WarningOutlined,
  UndoOutlined,
  InboxOutlined,
  CloseCircleFilled,
  LoadingOutlined,
  ArrowLeftOutlined,
  FileOutlined,
} from "@ant-design/icons";
import IconCarretDown from "assets/img/icon-carret-down.svg";
import IconCarretRight from "assets/img/icon-carret-right.svg";
import IconUp from "assets/img/icon-up.svg";
import IconDown from "assets/img/icon-down.svg";
import IconDelete from "assets/img/icon-delete.svg";
import IconCopy from "assets/img/icon-copy.svg";
import AddImage from "assets/img/add_Image.svg";
import CropImage from "assets/img/crop_Image.svg";
import EyeDropperImage from "assets/img/sip-line.svg";
import FileExcel2LineIcon from "remixicon-react/FileExcel2LineIcon";
import Download2LineIcon from "remixicon-react/Download2LineIcon";
import DeleteBinLineIcon from "remixicon-react/DeleteBinLineIcon";
import PencilLineIcon from "remixicon-react/PencilLineIcon";
// TODO: Replace with remixicons

export const IconNames = {
  ArrowRightOutlined: { type: "ArrowRightOutlined", component: <ArrowRightOutlined /> },
  ArrowLeftOutlined: { type: "ArrowLeftOutlined", component: <ArrowLeftOutlined /> },
  CarretDownOutlined: { type: "CarretDownOutlined", component: <img alt="Carret down" src={IconCarretDown} /> },
  CarretRightOutlined: { type: "CarretRightOutlined", component: <img alt="Carret right" src={IconCarretRight} /> },
  CloudUploadOutlined: { type: "CloudUploadOutlined", component: <CloudUploadOutlined /> },
  CompressOutlined: { type: "CompressOutlined", component: <CompressOutlined /> },
  DownloadOutlined: { type: "DownloadOutlined", component: <DownloadOutlined /> },
  SwapOutlined: { type: "SwapOutlined", component: <SwapOutlined /> },
  ExpandAltOutlined: { type: "ExpandAltOutlined", component: <ExpandAltOutlined /> },
  BgColorsOutlined: { type: "BgColorsOutlined", component: <BgColorsOutlined /> },
  UploadOutlined: { type: "UploadOutlined", component: <UploadOutlined /> },
  MoreOutlined: { type: "MoreOutlined", component: <MoreOutlined /> },
  PlusOutlined: { type: "PlusOutlined", component: <PlusOutlined /> },
  QuestionCircleOutlined: { type: "QuestionCircleOutlined", component: <QuestionCircleOutlined /> },
  RedoOutlined: { type: "RedoOutlined", component: <RedoOutlined /> },
  ReloadOutlined: { type: "ReloadOutlined", component: <ReloadOutlined /> },
  SaveOutlined: { type: "SaveOutlined", component: <SaveOutlined /> },
  DownSquareOutlined: { type: "DownSquareOutlined", component: <DownSquareOutlined /> },
  UpSquareOutlined: { type: "UpSquareOutlined", component: <UpSquareOutlined /> },
  WarningOutlined: { type: "WarningOutlined", component: <WarningOutlined /> },
  UndoOutlined: { type: "UndoOutlined", component: <UndoOutlined /> },
  InboxOutlined: { type: "InboxOutlined", component: <InboxOutlined /> },
  LoadingOutlined: { type: "LoadingOutlined", component: <LoadingOutlined /> },
  DeleteOutlined: { type: "DeleteOutlined", component: <img alt="Delete" src={IconDelete} /> },
  CopyOutlined: { type: "CopyOutlined", component: <img alt="Copy" src={IconCopy} /> },
  UpOutlined: { type: "UpOutlined", component: <img alt="Up" src={IconUp} /> },
  DownOutlined: { type: "DownOutlined", component: <img alt="Down" src={IconDown} /> },
  AddOutlined: { type: "AddOutlined", component: <img alt="Add" src={AddImage} /> },
  CropOutlined: { type: "CropOutlined", component: <img alt="Crop" src={CropImage} /> },
  EyeDropper: { type: "EyeDropper", component: <img alt="Crop" src={EyeDropperImage} /> },

  CloseCircleFilled: {
    type: "CloseCircleFilled",
    component: <CloseCircleFilled />,
  },
  FileOutlined: {
    type: "FileOutlined",
    component: <FileOutlined />,
  },
  Excel: { type: "Excel", component: <FileExcel2LineIcon /> },
  Download: { type: "Download", component: <Download2LineIcon /> },
  Delete: { type: "Delete", component: <DeleteBinLineIcon /> },
  Pencil: { type: "Pencil", component: <PencilLineIcon /> },
};

function Icons({ name, ...props }) {
  const Component = name ? IconNames[name].component : <></>;

  return cloneElement(Component, props);
}

Icons.propTypes = {
  name: PropTypes.oneOf(Object.keys(IconNames)),
};

export default Icons;
