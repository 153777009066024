import React from "react";

import Model from "./Model";
import InputRichTextUi from "../ui/InputRichTextUi";

export default class InputRichTextModel extends Model {
  constructor(...args) {
    super(...args);
    if (this.getValue() === undefined) {
      this.setValue("", true, undefined, false);
    }
  }

  renderComponent() {
    return InputRichTextUi;
  }
}
