import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connectToChild } from "penpal";
import { Alert, Space, Spin } from "@ogury/design-system";
import localPreview from "./LocalPreview.html";
import useStores from "~/hooks/useStores";
import References from "~/util/References";
import Core from "~/services/Core";
import { debounce } from "~/util/Utils";
import style from "./LocalPreview.module.scss";
import { PreviewControls } from "./components";
import Debug from "~/util/Debug";
import { buildSdkUrl } from "~/util/Constant";
import unitService, { syntheticUnitId } from "~/services/UnitService";

const VALUES_CHANGED_DEBOUNCE_MILLISECONDS = 200;

export default function LocalPreview({ templateUrl, containerWidth }) {
  const iframeRef = useRef();
  const previewControlsRef = useRef();
  const [t] = useTranslation();

  const { uiStore } = useStores();
  const [inputsValues, setInputsValues] = useState();
  const [experienceDescription, setExperienceDescription] = useState();
  const [initialized, setInitialized] = useState(false);
  const [binaryErrorIdsArray, setBinaryErrorIdsArray] = useState([]);
  const [globalError, setGlobalError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [iframeConnection, setIframeConnection] = useState();

  const creativeRatio = useRef(undefined);
  const sdkUrl = buildSdkUrl();

  function getExportValues() {
    const rootInstance = Core.getDeepNodeById(References.ROOT_INPUT_ID, true);
    if (rootInstance) {
      setLoading(true);
      setBinaryErrorIdsArray([]);
      (async function () {
        const { exportValues, idsWithBinaryValues } = await rootInstance.generateExportValues({
          assetsBaseUrl: "",
          resolveAssets: false,
          showErrors: false,
        });

        if (idsWithBinaryValues.length >= 1) {
          setBinaryErrorIdsArray(idsWithBinaryValues);
          setLoading(false);
          return;
        }
        setInputsValues(exportValues);
      })();
    }
  }

  const onUiStoreChanged = useCallback(debounce(getExportValues, VALUES_CHANGED_DEBOUNCE_MILLISECONDS), []);

  useEffect(() => {
    onUiStoreChanged();
  }, [uiStore]);

  function onLoaded(experienceDescription) {
    setExperienceDescription(experienceDescription);
    setGlobalError(false);
    Debug.log("Calling 'onLoaded' function");
    setLoading(false);
  }

  function onError(message) {
    Debug.log("Preview : ", message);
    setGlobalError(true);
    setLoading(false);
  }

  function onDebug(message) {
    Debug.log("PREVIEW : ", message);
  }

  function initConnection() {
    if (!iframeRef.current) {
      return;
    }

    const connection = connectToChild({
      iframe: iframeRef.current,
      methods: {
        onLoaded,
        onError,
        onDebug,
      },
    });

    connection.promise
      .then(child => setIframeConnection(child))
      .catch(error => {
        console.warn("An error occurred while initializing the connection with the iframe", error);
        setGlobalError(true);
      });
  }

  useEffect(() => {
    try {
      initConnection();
    } catch (error) {
      console.warn("An error occurred while calling the initConnection() method", error);
      onError();
    }
  }, []);

  useEffect(() => {
    if (iframeConnection && inputsValues && !initialized) {
      try {
        iframeConnection
          .initialize({
            templateUrl,
            sdkUrl,
            inputsValues,
            // on first load we need to setup a height offset for the chapter controls before they are loaded
            containerWidth: getBestFit(containerWidth, inputsValues.ratio),
          })
          .then(() => setInitialized(true));
        toggleDangerZones(inputsValues.ratio);
        creativeRatio.current = inputsValues.ratio;
      } catch (error) {
        console.warn("An error occurred while initializing the iframe", error);
        onError();
      }
    }
  }, [iframeConnection, inputsValues]);

  useEffect(() => {
    try {
      iframeConnection?.initialize({
        sdkUrl,
        templateUrl,
        inputsValues,
        containerWidth: getBestFit(containerWidth, inputsValues?.ratio),
      });
    } catch (e) {
      console.warn("An error occurred while resizing the iframe", error);
    }
  }, [previewControlsRef?.current?.clientHeight]);

  async function toggleDangerZones(ratio) {
    const dangerZonesProperties = await unitService.getDangerZonesProperties(ratio);
    const unitInputInstance = Core.getDeepNodeById("root." + syntheticUnitId, true);
    let dangerIconSize = 0;
    if (unitInputInstance) {
      dangerIconSize = unitService.getDangerIconSizeByAdUnitName(unitInputInstance.value);
    }
    if (iframeConnection) {
      try {
        iframeConnection.toggleDangerZones(dangerZonesProperties, dangerIconSize);
      } catch (error) {
        console.warn("An error occurred while toggling danger zones through the iframe", error);
        if (error.code === "ConnectionDestroyed") {
          initConnection();
        } else {
          onError();
        }
      }
    }
  }

  useEffect(() => {
    if (iframeConnection && initialized) {
      try {
        iframeConnection.setValues(inputsValues);
        if (creativeRatio.current && creativeRatio.current !== inputsValues.ratio) {
          toggleDangerZones(inputsValues.ratio);
        }
        creativeRatio.current = inputsValues.ratio;
      } catch (error) {
        console.warn("An error occurred while updating the values though the iframe", error);
        if (error.code === "ConnectionDestroyed") {
          initConnection();
        } else {
          onError();
        }
      }
    }
  }, [inputsValues]);

  function getBestFit(width, ratio) {
    const availableHeight = iframeRef.current.clientHeight - 15; // Magic number to add security offset
    let computedWidth = width;
    let computedHeight = width / ratio;
    if (computedHeight > availableHeight) {
      computedWidth = availableHeight * ratio;
    }

    return computedWidth; // offset layout spaces
  }

  function handleOnSelectScoreAndTrack(scoreId, trackId) {
    if (iframeConnection) {
      try {
        iframeConnection.initialize({
          sdkUrl,
          templateUrl,
          inputsValues,
          containerWidth: getBestFit(containerWidth, inputsValues?.ratio),
          trackId,
          scoreId,
        });
      } catch (error) {
        console.warn("An error occurred while updating the track selection though the iframe", error);
        onError();
      }
    }
  }

  function handleOnPlayTrack() {
    if (iframeConnection) {
      try {
        iframeConnection.playTrack();
      } catch (error) {
        console.warn("An error occurred while trying to play the track", error);
        onError();
      }
    }
  }
  function handleOnPauseTrack() {
    if (iframeConnection) {
      try {
        iframeConnection.pauseTrack();
      } catch (error) {
        console.warn("An error occurred while trying to pause the track", error);
        onError();
      }
    }
  }

  function renderLoading() {
    return (
      <div className={style.overlayContainer}>
        <Space direction="vertical" align="center">
          <Alert
            type="info"
            closable={false}
            description={
              <Space direction="vertical" align="center">
                <Spin />
                <div>{t("rightPanel.generatingPreview")}</div>
              </Space>
            }
          />
        </Space>
      </div>
    );
  }

  function renderErrors() {
    if (globalError) {
      return (
        <div className={style.overlayContainer}>
          <Alert
            type="error"
            closable={false}
            showIcon
            message={t("rightPanel.errors.title")}
            description={t("rightPanel.errors.localPreviewHasGlobalError")}
          />
        </div>
      );
    }

    if (binaryErrorIdsArray.length >= 1) {
      return (
        <div className={style.overlayContainer}>
          <Alert
            type="error"
            closable={false}
            showIcon
            message={t("rightPanel.errors.title")}
            description={t("rightPanel.errors.localPreviewHaveBinaryAssets")}
          />
        </div>
      );
    }
  }

  function renderControls() {
    if (experienceDescription) {
      return (
        <div className={style.controlsContainer} ref={previewControlsRef}>
          <PreviewControls
            experienceDescription={experienceDescription}
            onSelectScoreAndTrack={handleOnSelectScoreAndTrack}
            onPlayTrack={handleOnPlayTrack}
            onPauseTrack={handleOnPauseTrack}
          />
        </div>
      );
    }
  }
  return (
    <>
      {loading && renderLoading()}
      {renderErrors()}
      <iframe
        data-testid="local-preview-iframe"
        ref={iframeRef}
        className={`${style.iframe} ${globalError}`}
        srcDoc={localPreview}
      />
      {renderControls()}
    </>
  );
}
