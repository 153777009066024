import React, { createContext, useContext, useEffect, useState } from "react";

const HotkeyContext = createContext();

export function useHotkey() {
  return useContext(HotkeyContext);
}

function useProvideHotkey() {
  const [hotkey, setHotkey] = useState();

  function onKeyDown(event) {
    setHotkey(event);
  }

  function isHotkeyPressed(keyExpression) {
    if (hotkey === undefined) {
      return false;
    }
    let keyArray = keyExpression.split(",");
    let valid = false;

    for (let i = 0; i < keyArray.length; i++) {
      let keyCombinationArray = keyArray[i].split("+");
      let conditions = [];
      keyCombinationArray.map(theKey => {
        if (theKey.startsWith("ctrl") || theKey.startsWith("shift") || theKey.startsWith("meta")) {
          conditions.push(hotkey[theKey + "Key"]);
        } else {
          conditions.push(hotkey.key?.toLowerCase() === theKey.toLowerCase());
        }
        if (keyCombinationArray.length === 2 && hotkey["shiftKey"]) {
          conditions.push(false);
        }
      });
      if (conditions.every(el => el === true)) {
        valid = true;
        break;
      }
    }
    return valid;
  }

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  }, []);

  return { hotkey, isHotkeyPressed };
}

export function HotkeyProvider({ children }) {
  const hotkey = useProvideHotkey();
  return <HotkeyContext.Provider value={hotkey}>{children}</HotkeyContext.Provider>;
}
