import Api from "~/util/Api";
import siteService from "~/services/SiteService";
import Core from "~/services/Core";
import unitService from "~/services/UnitService";
import { deepCopy } from "~/util/Helpers";

const EXPERIENCE_MODE_INSCROLL = "inscroll";
const EXPERIENCE_MODE_TIME = "time";
const HIDDEN_SCORE_SUFFIX = "-hiddenInBuilder";

function generateScoreAndTrackOptions(experienceDescription) {
  const sequences = experienceDescription?.process?.sequences || [];
  const scoresOptions = [];
  const tracksOptionsByScore = [];

  sequences.forEach(sequence => {
    (sequence.scores || []).forEach(score => {
      if (score.id?.endsWith(HIDDEN_SCORE_SUFFIX)) {
        return;
      }
      if (score.mode === EXPERIENCE_MODE_INSCROLL || score.mode === EXPERIENCE_MODE_TIME) {
        scoresOptions.push({
          value: score.id,
          label: score.id.toString(),
        });

        const options = (score.programs || []).map(program => ({
          value: program.trackId,
          label: program.trackId.toString(),
        }));

        tracksOptionsByScore.push({
          scoreValue: score.id,
          options,
        });
      }
    });
  });

  return {
    scoresOptions,
    tracksOptionsByScore,
  };
}

async function getBinaryWeight(experienceId, dimensions, userAgent) {
  return Api.getBinaryWeight({ experienceId, dimensions, userAgent });
}

async function duplicate(experienceId, name, comment) {
  return Api.duplicateExperience(experienceId, name, comment);
}

async function setUnits(experienceId, technicalIds) {
  return Api.setExperienceUnits(experienceId, technicalIds);
}

async function attachToSite(siteId, experienceId) {
  const site = await siteService.get(siteId);
  if (site) {
    const experienceIsAlreadyAttached = site.experienceIds?.some(id => id.toString() === experienceId.toString());
    if (!experienceIsAlreadyAttached) {
      return Api.attachExperienceToSite(siteId, experienceId);
    }
  }
}

function getCurrentRatio() {
  return Core.getDeepNodeById("root.ratio", true)?.getValue();
}

async function getAvailableUnitsForDuplication() {
  const currentUnitTechnicalId = unitService.getCurrentUnitTechnicalId();
  const siteId = Api.getSiteId();
  const site = await siteService.get(siteId);
  const currentSiteUnitsTechnicalIds = site.units.map(unit => unit.technicalId);
  const currentRatio = this.getCurrentRatio();
  const units = await unitService.getUnits();
  const referenceUnits = deepCopy(units);

  return units.filter(unit => {
    if (unit.technicalId === currentUnitTechnicalId) {
      return false;
    } else if (currentSiteUnitsTechnicalIds.includes(unit.technicalId)) {
      return false;
    } else if (
      !referenceUnits
        .find(refUnit => refUnit.technicalId === unit.technicalId)
        ?.properties?.some(property => property.ratio === currentRatio)
    ) {
      return false;
    }
    return true;
  });
}

export default {
  duplicate,
  setUnits,
  attachToSite,
  generateScoreAndTrackOptions,
  getBinaryWeight,
  getCurrentRatio,
  getAvailableUnitsForDuplication,
};
