import Model from "./Model";
import InputUrlUi from "../ui/InputUrlUi";

export default class InputUrlModel extends Model {
  constructor(...args) {
    super(...args);
  }

  async computeConstraintsErrors() {
    const errors = [];
    this.getValidator().isNotEmpty(this.getValue(), errors);
    this.getValidator().isUrl(this.getValue(), errors);
    return errors;
  }

  renderComponent() {
    return InputUrlUi;
  }
}
