import InputGroupOrCollectionModel from "./InputGroupOrCollectionModel";
import References from "../../../util/References";

export default class InputGroupModel extends InputGroupOrCollectionModel {
  constructor(...args) {
    super(...args);
  }

  getSuperType() {
    return References.INPUT_SUPER_TYPE.GROUP;
  }
}
