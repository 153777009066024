import React, { useEffect, useState } from "react";

import { InputWrapper } from "components";
import { Switch } from "@ogury/design-system";

const InputBooleanUi = ({ input }) => {
  const [value, setValue] = useState(input.getValue());

  const onChange = newValue => {
    setValue(newValue === true);
    input.setValue(newValue);
  };

  useEffect(() => {
    if (input.getValue() != null) {
      setValue(input.getValue());
    }
  }, [input]);

  return (
    <InputWrapper input={input}>
      <div>
        <Switch size="small" checked={value} onChange={onChange} />
      </div>
    </InputWrapper>
  );
};

export default InputBooleanUi;
