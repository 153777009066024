import { DesignSystemProvider } from "@ogury/design-system";
import { StyleSheetManager } from "styled-components";
import {
  ExperienceProvider,
  PsdStoreProvider,
  AccessibilityContextProvider,
  HotkeyProvider,
  UiStoreProvider,
} from "~/context";
import { CreativeSettingsStoreProvider } from "./CreativeSettingsContext";

function AppProvider({ children }) {
  return (
    <StyleSheetManager target={document.getElementById("design-system-placeholder")}>
      <DesignSystemProvider>
        <CreativeSettingsStoreProvider>
          <UiStoreProvider>
            <ExperienceProvider>
              <AccessibilityContextProvider>
                <HotkeyProvider>
                  <PsdStoreProvider>{children}</PsdStoreProvider>
                </HotkeyProvider>
              </AccessibilityContextProvider>
            </ExperienceProvider>
          </UiStoreProvider>
        </CreativeSettingsStoreProvider>
      </DesignSystemProvider>
    </StyleSheetManager>
  );
}

export default AppProvider;
