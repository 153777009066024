import { useEffect, useState } from "react";

import { FileUploadMeta, InputWrapper } from "components";
import FilePickerV2 from "../../../components/FilePickerV2/FilePickerV2";
import { useTranslation } from "react-i18next";
import Api from "~/util/Api";
import { Form } from "@ogury/design-system";

const ZIP_LIMIT_SIZE_MB = 5;

const InputZipUi = ({ input }) => {
  const [value, setValue] = useState(input.getValue());
  const [uploadedFile, setUploadedFile] = useState();
  const [t] = useTranslation();

  useEffect(() => {
    if (input.getValue() != null) {
      setValue(input.getValue());
    }
  }, [input]);

  async function validateFile(file) {
    const errors = [];
    if (file.size > ZIP_LIMIT_SIZE_MB * 1024 * 1024) {
      errors.push(t("error.constraint.zipWeight", { maximumMegaBytes: ZIP_LIMIT_SIZE_MB }));
    }
    return errors;
  }

  async function processFile(file) {
    input.resetErrors();

    const fileErrors = await validateFile(file);
    if (fileErrors.length > 0) {
      setUploadedFile({
        percent: 100,
        status: "error",
        message: fileErrors.join(", "),
      });
      return;
    }
    setUploadedFile({ percent: 0, status: "processing", message: "Uploading" });
    let interval;
    try {
      let percent = 1;
      interval = setInterval(() => {
        setUploadedFile({ percent, status: "processing", message: "Uploading" });
        percent++;
        if (percent > 75) {
          clearInterval(interval);
        }
      }, 150);

      const { url } = await Api.uploadTemporaryFile("application/zip", encodeURIComponent(file.name), file);
      clearInterval(interval);

      setUploadedFile({
        percent: 100,
        status: "success",
      });

      setTimeout(() => {
        input.setValue(url);
      }, 300);
    } catch (error) {
      clearInterval(interval);
      setUploadedFile({ percent: 0, status: "error", message: error.message });
    }
  }

  function handleFileRemoval() {
    setUploadedFile(null);
    input.clearValue();
  }

  async function getUploadFileMeta(inputValue) {
    if (!inputValue) {
      return null;
    }
    const fileName = inputValue.split("/").pop();
    const fileSize = await fetch(inputValue)
      .then(response => response.blob())
      .then(blob => blob.size);

    return {
      icon: "default",
      status: "done",
      name: fileName,
      size: fileSize,
      downloadName: fileName,
      downloadBlob: inputValue,
    };
  }

  useEffect(() => {
    (async () => {
      const data = await getUploadFileMeta(input.getValue());
      setUploadedFile(data);
    })();
  }, [input.getValue()]);

  return uploadedFile ? (
    <Form.Item validateStatus={uploadedFile.status} help={uploadedFile.message}>
      <FileUploadMeta fileMeta={uploadedFile} onRemove={handleFileRemoval} />
    </Form.Item>
  ) : (
    <InputWrapper input={input}>
      <FilePickerV2
        hint={t("components.filePicker.uploadHintZip")}
        beforeUpload={processFile}
        action={() => false}
        accept={".zip"}
        maxCount={1}
      />
    </InputWrapper>
  );
};

export default InputZipUi;
