import Core from "~/services/Core";

class MetaService {
  canUpgrade() {
    const template = Core.getCurrentTemplate();
    // noinspection JSUnresolvedVariable
    if (template !== undefined) {
      const currentVersion = template.version;
      return template.versions?.indexOf(currentVersion) !== template?.versions?.length - 1;
    }
    return false;
  }
}

export default new MetaService();
