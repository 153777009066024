let clipboard = {};

function pushInputValue(inputValue) {
  clipboard = inputValue;
}

function popInputValue() {
  return clipboard;
}

export default {
  pushInputValue,
  popInputValue,
};
