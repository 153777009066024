import { datadogRum } from "@datadog/browser-rum";
import {
  Empty,
  Input,
  Loader,
  Modal,
  ModalClosableFooter,
  Pagination,
  Space,
  Typography,
  useAppNotification,
  Button,
} from "@ogury/design-system";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import LottieService from "~/services/LottieService";
import Api from "~/util/Api";
import { NOTIFICATION_PLACEMENT } from "~/util/Constant";
import DatadogEvents from "~/util/DatadogEvents";
import { META_SUFFIX } from "~/util/InputsComputer";
import style from "./LottieFilesAPIModal.module.scss";

const SEARCH_DEBOUNCE_MS = 400;

export default function LottieFilesAPIModal({ open, onClose, onSuccess }) {
  const [t] = useTranslation();
  const notification = useAppNotification();
  const [searchTerm, setSearchTerm] = useState();
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [results, setResults] = useState();
  const [selected, setSelected] = useState();
  const [query, setQuery] = useState({
    searchTerm: undefined,
    pageNumber: 1,
    pageSize: 10,
    total: 0,
  });

  const debounceRef = useRef(null);

  function handleOnSearchChange(value) {
    setSearchTerm(value);
  }

  async function searchAction() {
    try {
      setLoading(true);
      const apiResponse = await LottieService.search(query.searchTerm, query.pageNumber, query.pageSize);
      if (!apiResponse.items?.length) {
        setResults();
      } else {
        setResults({
          total: apiResponse.total,
          data: apiResponse.items,
        });
      }
    } catch (error) {
      notification.error({
        message: error.message,
        placement: NOTIFICATION_PLACEMENT,
        duration: 0,
      });
      setResults();
    } finally {
      setLoading(false);
    }
  }

  async function handleOnSelect() {
    try {
      setDownloading(true);
      const lottieJSON = await LottieService.downloadJson(selected.uuid);
      const fileBlob = new Blob([JSON.stringify(lottieJSON)], { type: "application/json" });
      const { url } = await Api.uploadTemporaryFile("application/json", selected.name + ".json", fileBlob);
      datadogRum.addAction(DatadogEvents.LOTTIE_OVERLAY_API_MODAL_ANIMATION_DOWNLOADED);

      return onSuccess({
        source: url,
        rotation: 0,
        size: 0,
        x: 0,
        y: 0,
        ["source" + META_SUFFIX]: {
          fileName: selected.name,
          height: lottieJSON.h,
          width: lottieJSON.w,
          bytes: fileBlob.size,
        },
      });
    } catch (error) {
      notification.error({
        message: error.message,
        placement: NOTIFICATION_PLACEMENT,
        duration: 0,
      });
    } finally {
      setDownloading(false);
    }
  }

  function handleOnSelectItem(item) {
    setSelected(item);
  }

  useEffect(() => {
    if (open) {
      datadogRum.addAction(DatadogEvents.LOTTIE_OVERLAY_API_MODAL_OPENING);
    }
  }, [open]);

  useEffect(() => {
    if (!searchTerm) {
      setResults();
      return;
    }
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => {
      if (searchTerm) {
        setQuery({
          ...query,
          pageNumber: 1,
          searchTerm,
        });
      }
    }, SEARCH_DEBOUNCE_MS);

    return () => clearTimeout(debounceRef.current);
  }, [searchTerm]);

  useEffect(() => {
    if (query.searchTerm) {
      searchAction();
    }
  }, [query]);

  function renderSearch() {
    return (
      <>
        <Input.Search
          value={searchTerm}
          onChange={handleOnSearchChange}
          placeholder={t("components.lottieOverlay.apiModal.searchPlaceholder")}
        />
        {results && (
          <div className={style.paginationContainer}>
            <Pagination
              currentPage={query.pageNumber}
              onChange={handleOnPaginationChange}
              pageSize={query.pageSize}
              total={results?.total}
              showTotal
              showSizeChanger
            />
          </div>
        )}
      </>
    );
  }

  function renderLoading() {
    return (
      <div className={style.loaderContainer}>
        <Loader />
      </div>
    );
  }

  function renderEmpty() {
    return <Empty title={t("notification.noResultsTitle")} description={t("notification.noResultsDescription")} />;
  }

  function handleOnPaginationChange(pageNumber, pageSize) {
    setQuery({
      ...query,
      pageNumber,
      pageSize,
    });
  }

  function renderResults() {
    function isSelected(result) {
      return selected?.uuid === result.uuid;
    }
    function renderItems() {
      return results?.data?.map(result => {
        return (
          <div
            onClick={() => handleOnSelectItem(result)}
            className={`${style.resultItem} ${isSelected(result) ? style.selected : ""}`}
          >
            <video autoPlay loop src={result.urls?.thumb}></video>
            {isSelected(result) && (
              <Button size="small" className={style.chooseButton} onClick={handleOnSelect}>
                {t("components.lottieOverlay.download")}
              </Button>
            )}
          </div>
        );
      });
    }
    return (
      <>
        <div className={style.resultContainer}>{renderItems()}</div>{" "}
        {results && query.pageSize >= 20 && (
          <div className={style.paginationContainer}>
            <Pagination
              currentPage={query.pageNumber}
              onChange={handleOnPaginationChange}
              pageSize={query.pageSize}
              total={results?.total}
              showTotal
              showSizeChanger
            />
          </div>
        )}
      </>
    );
  }

  function renderDownloading() {
    return (
      <div className={style.loaderContainer}>
        <Typography.P2Regular>Downloading animation...</Typography.P2Regular>
        <Loader />
      </div>
    );
  }

  return (
    <Modal
      open={open}
      width="50%"
      onCancel={onClose}
      title={t("components.lottieOverlay.apiModal.title")}
      footer={<ModalClosableFooter buttonLabel={t("components.OkCancelButtons.cancel")} />}
    >
      <Space style={{ width: "100%" }} direction="vertical" size="m">
        {!downloading && renderSearch()}
        {loading && !downloading && renderLoading()}
        {!loading && !results && !downloading && renderEmpty()}
        {!loading && results && !downloading && renderResults()}
        {!loading && results && downloading && renderDownloading()}
      </Space>
    </Modal>
  );
}

LottieFilesAPIModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
