import Model from "./Model";

export default class InputGroupOrCollectionModel extends Model {
  getValue() {
    // If target is enabled, empty string is returned. Because the input is a group, its state must only be "defined" or "undefined".
    // The condition should not be a specific value. The empty string matches the defined state.
    if (this.enabled === true) {
      return "";
    } else {
      return undefined;
    }
  }
}
