import { datadogRum } from "@datadog/browser-rum";

export function initErrorMonitoring(config) {
  const { applicationId, clientToken, env, version } = config;

  if (!(applicationId && clientToken && env)) {
    return;
  }

  // disable console log in production
  if (env === "prod") {
    // eslint-disable-next-line no-console
    console.log = () => {};
  }

  datadogRum.init({
    applicationId,
    clientToken,
    site: "datadoghq.eu",
    service: "ocs-builder",
    env,
    version,
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    trackFrustrations: true,
    defaultPrivacyLevel: "allow",
    beforeSend: event => !shouldDiscardEvent(event),
  });

  datadogRum.startSessionReplayRecording();
}

export const shouldDiscardEvent = event => {
  if (!event.error) {
    return false;
  }

  const errorMessage = event.error.message;
  const exceptions = [
    "Request has been terminated Possible causes: the network is offline", // third party error
    "Cannot read property 'postMessage' of null", // third party error
    "The user does not have customer account",
    "Script error.",
    "Could not download properly the contents at URL",
    "Cannot read properties of null (reading 'postMessage')",
  ];

  if (exceptions.findIndex(exception => errorMessage.indexOf(exception) >= 0) >= 0) {
    return true;
  }

  return false;
};
