import PropTypes from "prop-types";
import style from "./VideoPlayer.module.scss";

const VideoPlayer = ({ src, mp4Src, webmSrc, size = "large" }) => {
  return (
    <div className={style.container}>
      <video
        playsInline
        autoPlay
        muted
        loop
        controls
        style={{
          height: "auto",
          maxWidth: "100%",
          maxHeight: size === "large" ? 400 : 200,
        }}
      >
        <source key="mp4" src={mp4Src !== undefined ? mp4Src : src} type="video/mp4" />
        <source key="webm" src={webmSrc !== undefined ? webmSrc : src} type="video/webm" />
      </video>
    </div>
  );
};

VideoPlayer.propTypes = {
  src: PropTypes.string,
  mp4Src: PropTypes.string,
  webmSrc: PropTypes.string,
  size: PropTypes.oneOf(["small", "large"]),
};

export default VideoPlayer;
