import InputGroupOrCollectionModel from "./InputGroupOrCollectionModel";
import References from "../../../util/References";
import { SCHEMA_INPUTS_FIELDS } from "~/util/ModelConstants";

export default class InputCollectionModel extends InputGroupOrCollectionModel {
  constructor(...args) {
    super(...args);
  }

  getSuperType() {
    return References.INPUT_SUPER_TYPE.COLLECTION;
  }

  getUiData() {
    return { ...super.getUiData(), count: this.getReferenceInput()[SCHEMA_INPUTS_FIELDS.Count] };
  }
}
