import unitService from "~/services/UnitService";
import Core from "~/services/Core";

async function afterFetchTemplate(template) {
  const unitSyntheticInputDefinition = await unitService.getUnitsTemplateDefinition(template.id);
  template.inputs.unshift(unitSyntheticInputDefinition);
  return template;
}

function isVpaidTemplate() {
  const template = Core.getCurrentTemplate();
  return template?.id?.toLowerCase().includes("vpaid");
}

export default {
  afterFetchTemplate,
  isVpaidTemplate,
};
