import { useState } from "react";
import { theme, Typography } from "@ogury/design-system";
import { Image } from "components";
import { toPsdTransfer } from "~/util/Psd";
import style from "./AssetItem.module.scss";

export default function AssetItem({ item }) {
  const [grabbing, setGrabbing] = useState(false);

  function handleOnDragStart(event) {
    setGrabbing(true);
    event.dataTransfer.effectAllowed = "copyMove";
    return event.dataTransfer.setData("text", toPsdTransfer(item.name, item.base64Data));
  }

  return (
    <div
      style={{ cursor: grabbing ? "grabbing" : "grab" }}
      className={style.container}
      draggable="true"
      onDragStart={handleOnDragStart}
      onDragEnd={() => setGrabbing(false)}
      onDragExit={() => setGrabbing(false)}
    >
      <Image className={style.image} src={item.base64Data} withCheckboardBackground />
      <div className={style.text}>
        <Typography.P2Regular>{item.name}</Typography.P2Regular>
      </div>
      <div className={style.size}>
        <Typography.P3Regular style={{ color: theme.colors.content.subdued }}>
          {item.width}x{item.height}px
        </Typography.P3Regular>
      </div>
    </div>
  );
}
