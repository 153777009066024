import { Typography } from "@ogury/design-system";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { FilePicker, UrlPicker } from "..";
import style from "./Picker.module.css";

const Picker = ({
  fileExtensions,
  mimeTypes,
  doNotSupportCors,
  placeholder,
  onUrlChanged,
  onFileChanged,
  hint,
  onError,
}) => {
  const [t] = useTranslation();
  return (
    <div className={style.pickerContainer}>
      <UrlPicker placeholder={placeholder} doNotSupportCors={doNotSupportCors} onUrlChanged={onUrlChanged} />
      <Typography.P2Regular className={style.text}>{t("components.picker.or")}</Typography.P2Regular>
      <FilePicker
        hint={hint}
        fileExtensions={fileExtensions}
        mimeTypes={mimeTypes}
        onFileChanged={onFileChanged}
        onError={onError}
      />
    </div>
  );
};

Picker.propTypes = {
  fileExtensions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  mimeTypes: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  doNotSupportCors: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  onUrlChanged: PropTypes.func.isRequired,
  hint: PropTypes.string,
  onFileChanged: PropTypes.func.isRequired,
  onError: PropTypes.func,
};

export default Picker;
