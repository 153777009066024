import InputGroupOrCollectionModel from "./InputGroupOrCollectionModel";
import References from "../../../util/References";

export default class InputCollectionChildModel extends InputGroupOrCollectionModel {
  constructor(id, name) {
    super(
      {
        ui: {
          name,
          label: name,
        },
      },
      id
    );
    this.enabled = true;
  }

  getSuperType() {
    return References.INPUT_SUPER_TYPE.COLLECTION_CHILD;
  }
}
