import { SCHEMA_INPUTS_FIELDS } from "../../../util/ModelConstants";
import InputCompositeModel from "./InputCompositeModel";
import InputCtaUi from "../ui/InputCtaUi";
import i18n from "i18next";

const typeAcceptableValues = [
  {
    value: "none",
    label: "None",
    comment: i18n.t("comment.CTA.typeAcceptableValues.none"),
  },
  {
    value: "surface",
    label: "Surface",
    comment: i18n.t("comment.CTA.typeAcceptableValues.surface"),
  },
  {
    value: "icon",
    label: "Icon",
    comment: i18n.t("comment.CTA.typeAcceptableValues.icon"),
  },
  {
    value: "surfaceicon",
    label: "Surface + Icon",
    comment: i18n.t("comment.CTA.typeAcceptableValues.surfaceicon"),
  },
  {
    value: "button",
    label: "Button",
    comment: i18n.t("comment.CTA.typeAcceptableValues.button"),
  },
  {
    value: "surfacebutton",
    label: "Surface + Button",
    comment: i18n.t("comment.CTA.typeAcceptableValues.surfacebutton"),
  },
];

export default class InputCtaModel extends InputCompositeModel {
  constructor(...args) {
    super(...args);
  }

  computeChildInputs() {
    const typeAcceptableValuesConstraints = this.getConstraintsProperty(
      SCHEMA_INPUTS_FIELDS.ConstraintsCtaTypeAcceptableValues,
      ["none", "surface", "icon", "surfaceicon", "button", "surfacebutton"]
    );

    const filteredTypeAcceptableValues = typeAcceptableValues.filter(acceptableValue => {
      return typeAcceptableValuesConstraints.indexOf(acceptableValue.value) !== -1;
    });

    return [
      {
        id: "type",
        type: "string",
        ui: {
          name: "Type",
          label: "Type",
          comment: i18n.t("comment.CTA.computeInputs.type"),
        },
        acceptableValues: filteredTypeAcceptableValues,
        conditions: {
          hidden: filteredTypeAcceptableValues.length <= 1,
        },
        style: {
          orientation: "horizontal",
        },
        default: "none",
      },
      {
        id: "actionUrl",
        type: "url",
        ui: {
          name: "URL",
          label: "URL",
          comment: i18n.t("comment.CTA.computeInputs.actionUrl"),
          icon: "/url-icon.svg",
        },
        conditions: {
          hidden: !this.getConstraintsProperty(SCHEMA_INPUTS_FIELDS.ConstraintsCtaWithActionUrl, true),
          optional: this.getConstraintsProperty(SCHEMA_INPUTS_FIELDS.ConstraintsCtaIsActionUrlOptional, false),
          requiredWhen: {
            inputPath: "./type",
            inputValue: "none",
            operator: "different",
          },
        },
        default: "https://www.motion.ly",
      },
      {
        id: "text",
        type: "text",
        ui: {
          name: "Text",
          label: "Text",
          comment: i18n.t("comment.CTA.computeInputs.text"),
          icon: "/text-icon.svg",
        },
        conditions: {
          hidden: !this.getConstraintsProperty(SCHEMA_INPUTS_FIELDS.ConstraintsCtaWithText, true),
          requiredWhen: {
            inputPath: "./type",
            inputValue: "button",
            operator: "contains",
          },
        },
        default: "Click",
      },
      {
        id: "mode",
        type: "string",
        ui: {
          name: "Mode",
          label: "Mode",
          comment: i18n.t("comment.CTA.computeInputs.mode"),
          icon: "/question-icon.svg",
        },
        conditions: {
          hidden: !this.getConstraintsProperty(SCHEMA_INPUTS_FIELDS.ConstraintsCtaWithMode, true),
          requiredWhen: {
            inputPath: "./type",
            inputValue: "none",
            operator: "different",
          },
        },
        acceptableValues: [
          {
            value: "new",
            label: "New Window",
          },
          {
            value: "same",
            label: "Same Window",
          },
        ],
        style: {
          layout: "combo",
        },
        default: "new",
      },
      {
        id: "color",
        type: "color",
        ui: {
          name: "Color",
          label: "Color",
          comment: i18n.t("comment.CTA.computeInputs.color"),
          icon: "/color-icon.svg",
        },
        conditions: {
          hidden: !this.getConstraintsProperty(SCHEMA_INPUTS_FIELDS.ConstraintsCtaWithColor, true),
          requiredWhen: {
            type: "or",
            conditions: [
              {
                inputPath: "./type",
                inputValue: "icon",
                operator: "contains",
              },
              {
                inputPath: "./type",
                inputValue: "button",
                operator: "contains",
              },
            ],
          },
        },
        default: "#000000",
        constraints: {
          withAlpha: false,
        },
      },
      {
        id: "backgroundColor",
        type: "color",
        ui: {
          name: "Background",
          label: "Background",
          comment: i18n.t("comment.CTA.computeInputs.backgroundColor"),
          icon: "/color-icon.svg",
        },
        conditions: {
          hidden: !this.getConstraintsProperty(SCHEMA_INPUTS_FIELDS.ConstraintsCtaWithBackgroundColor, true),
          requiredWhen: {
            type: "or",
            conditions: [
              {
                inputPath: "./type",
                inputValue: "icon",
                operator: "contains",
              },
              {
                inputPath: "./type",
                inputValue: "button",
                operator: "contains",
              },
            ],
          },
        },
        default: "#FFFFFF",
        constraints: {
          withAlpha: false,
        },
      },
      {
        id: "size",
        type: "string",
        ui: {
          name: "Size",
          label: "Size",
          comment: i18n.t("comment.CTA.computeInputs.size"),
          icon: "/size-icon.svg",
        },
        conditions: {
          hidden: !this.getConstraintsProperty(SCHEMA_INPUTS_FIELDS.ConstraintsCtaWithSize, true),
          requiredWhen: {
            type: "or",
            conditions: [
              {
                inputPath: "./type",
                inputValue: "icon",
                operator: "contains",
              },
              {
                inputPath: "./type",
                inputValue: "button",
                operator: "contains",
              },
            ],
          },
        },
        acceptableValues: [
          {
            value: "xsmall",
            label: "X-Small",
          },
          {
            value: "small",
            label: "Small",
          },
          {
            value: "medium",
            label: "Medium",
          },
          {
            value: "large",
            label: "Large",
          },
          {
            value: "xlarge",
            label: "X-Large",
          },
        ],
        style: {
          layout: "combo",
        },
        default: "medium",
      },
      {
        id: "theme",
        type: "string",
        ui: {
          name: "Theme",
          label: "Theme",
          comment: i18n.t("comment.CTA.computeInputs.theme"),
          icon: "/theme-icon.svg",
        },
        conditions: {
          hidden: !this.getConstraintsProperty(SCHEMA_INPUTS_FIELDS.ConstraintsCtaWithTheme, true),
          requiredWhen: {
            inputPath: "./type",
            inputValue: "icon",
            operator: "contains",
          },
        },
        acceptableValues: [
          {
            value: "light",
            label: "Light",
          },
          {
            value: "dark",
            label: "Dark",
          },
        ],
        style: {
          orientation: "horizontal",
        },
        default: "light",
      },
      {
        id: "icon",
        type: "string",
        ui: {
          name: "Icon",
          label: "Icon",
          comment: i18n.t("comment.CTA.computeInputs.icon"),
          icon: "/icon-icon.svg",
        },
        conditions: {
          hidden: !this.getConstraintsProperty(SCHEMA_INPUTS_FIELDS.ConstraintsCtaWithIcon, true),
          requiredWhen: {
            inputPath: "./type",
            inputValue: "icon",
            operator: "contains",
          },
        },
        acceptableValues: [
          {
            value: "plus",
            label: "Plus",
            icon: "/cta-icon-plus-icon.svg",
          },
          {
            value: "info",
            label: "Info",
            icon: "/cta-icon-info-icon.svg",
          },
          {
            value: "hand",
            label: "Hand",
            icon: "/cta-icon-hand-icon.svg",
          },
          {
            value: "eye",
            label: "Eye",
            icon: "/cta-icon-eye-icon.svg",
          },
          {
            value: "caddy",
            label: "Caddy",
            icon: "/cta-icon-caddy-icon.svg",
          },
          {
            value: "basket",
            label: "Basket",
            icon: "/cta-icon-basket-icon.svg",
          },
        ],
        style: {
          orientation: "horizontal",
        },
        default: "hand",
      },
      {
        id: "font",
        type: "string",
        ui: {
          name: "Font",
          label: "Font",
          comment: i18n.t("comment.CTA.computeInputs.font"),
          icon: "/font-icon.svg",
        },
        conditions: {
          hidden: !this.getConstraintsProperty(SCHEMA_INPUTS_FIELDS.ConstraintsCtaWithFont, true),
          requiredWhen: {
            inputPath: "./type",
            inputValue: "button",
            operator: "contains",
          },
        },
        acceptableValues: [
          {
            value: "roboto-regular",
            label: "Roboto regular",
            illustration: "/cta-font-roboto-bold-illustration.svg",
          },
          {
            value: "roboto-bold",
            label: "Roboto bold",
            illustration: "/cta-font-roboto-regular-illustration.svg",
          },
          {
            value: "robotocondensed-regular",
            label: "RobotoCondensed regular",
            illustration: "/cta-font-robotocondensed-regular-illustration.svg",
          },
          {
            value: "robotocondensed-bold",
            label: "RobotoCondensed bold",
          },
          {
            value: "robotothin",
            label: "RobotoThin",
            illustration: "/cta-font-robotothin-illustration.svg",
          },
          {
            value: "robotoblack",
            label: "RobotoBlack",
          },
          {
            value: "arial-regular",
            label: "Arial regular",
          },
          {
            value: "arial-bold",
            label: "Arial bold",
          },
        ],
        style: {
          layout: "combo",
        },
        default: "roboto-bold",
      },
      {
        id: "buttonStyle",
        type: "string",
        ui: {
          name: "Style",
          label: "Style",
          comment: i18n.t("comment.CTA.computeInputs.buttonStyle.ui"),
        },
        conditions: {
          hidden: !this.getConstraintsProperty(SCHEMA_INPUTS_FIELDS.ConstraintsCtaWithButtonStyle, true),
          requiredWhen: {
            inputPath: "./type",
            inputValue: "button",
            operator: "contains",
          },
        },
        acceptableValues: [
          {
            value: "rectangle",
            label: "Rectangle",
            comment: i18n.t("comment.CTA.computeInputs.buttonStyle.rectangle"),
            icon: "/cta-buttonStyle-rectangle-icon.svg",
          },
          {
            value: "pill",
            label: "Pill",
            comment: i18n.t("comment.CTA.computeInputs.buttonStyle.pill"),
            icon: "/cta-buttonStyle-pill-icon.svg",
          },
        ],
        style: {
          layout: "combo",
        },
        default: "rectangle",
      },
      {
        id: "stroke",
        type: "float",
        ui: {
          name: "Stroke",
          label: "Stroke",
          comment: i18n.t("comment.CTA.computeInputs.stroke"),
          icon: "/cta-stroke-icon.svg",
        },
        conditions: {
          hidden: !this.getConstraintsProperty(SCHEMA_INPUTS_FIELDS.ConstraintsCtaWithStroke, true),
          requiredWhen: {
            inputPath: "./type",
            inputValue: "button",
            operator: "contains",
          },
        },
        range: {
          minimum: 0,
          maximum: 100,
        },
        style: {
          showConstraints: false,
        },
        default: 20,
      },
      {
        id: "opacity",
        type: "integer",
        ui: {
          name: "Opacity",
          label: "Opacity",
          comment: i18n.t("comment.CTA.computeInputs.opacity"),
          icon: "/cta-opacity-icon.svg",
        },
        conditions: {
          hidden: !this.getConstraintsProperty(SCHEMA_INPUTS_FIELDS.ConstraintsCtaWithOpacity, true),
          requiredWhen: {
            inputPath: "./type",
            inputValue: "button",
            operator: "contains",
          },
        },
        range: {
          minimum: 0,
          maximum: 100,
        },
        style: {
          showConstraints: false,
        },
        default: 100,
      },
      {
        id: "padding",
        ui: {
          name: "Padding",
          label: "Padding",
          comment: i18n.t("comment.CTA.computeInputs.padding.ui"),
        },
        conditions: {
          hidden: !this.getConstraintsProperty(SCHEMA_INPUTS_FIELDS.ConstraintsCtaWithPadding, true),
          requiredWhen: {
            inputPath: "./type",
            inputValue: "button",
            operator: "contains",
          },
        },
        inputs: [
          {
            id: "horizontal",
            type: "float",
            ui: {
              name: "Horizontal",
              label: "Horizontal",
              comment: i18n.t("comment.CTA.computeInputs.padding.horizontal"),
              icon: "/horizontal-icon.svg",
            },
            range: {
              minimum: 0,
              maximum: 500,
            },
            style: {
              showConstraints: false,
            },
            default: 100,
          },
          {
            id: "vertical",
            type: "float",
            ui: {
              name: "Vertical",
              label: "Vertical",
              comment: i18n.t("comment.CTA.computeInputs.padding.vertical"),
              icon: "/vertical-icon.svg",
            },
            range: {
              minimum: 0,
              maximum: 500,
            },
            style: {
              showConstraints: false,
            },
            default: 50,
          },
        ],
      },
      {
        id: "position",
        type: "point",
        ui: {
          name: "Position",
          label: "Position",
          comment: i18n.t("comment.CTA.computeInputs.position"),
          icon: "/position-icon.svg",
        },
        conditions: {
          hidden: !this.getConstraintsProperty(SCHEMA_INPUTS_FIELDS.ConstraintsCtaWithPosition, true),
          requiredWhen: {
            type: "or",
            conditions: [
              {
                inputPath: "./type",
                inputValue: "icon",
                operator: "contains",
              },
              {
                inputPath: "./type",
                inputValue: "button",
                operator: "contains",
              },
            ],
          },
        },
        semantics: {
          referenceInputPath: "/ratio",
        },
        constraints: this.getConstraintsProperty(SCHEMA_INPUTS_FIELDS.ConstraintsCtaPosition, {
          frame: {
            topLeft: {
              x: 2,
              y: 2,
            },
            bottomRight: {
              x: 98,
              y: 98,
            },
          },
          onInputPath: "/ratio",
        }),
        default: {
          x: 50,
          y: 90,
        },
      },
    ];
  }

  renderComponent() {
    return InputCtaUi;
  }
}
