import { createContext, useContext, useState } from "react";

const ExperienceContext = createContext();

export function useExperience() {
  return useContext(ExperienceContext);
}

function useProvider() {
  const [experience, setExperience] = useState({});
  return { experience, setExperience };
}

export function ExperienceProvider({ children }) {
  const experienceProvider = useProvider();
  return <ExperienceContext.Provider value={experienceProvider}>{children}</ExperienceContext.Provider>;
}
