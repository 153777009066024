import i18n from "i18n/i18n";
import ISO_639_1_CODES from "~/util/ISO_639_1_codes";

const tabularIntegrityValidator = (content, constraints) => {
  if (!content) {
    return [new Error(i18n.t("components.tabularInput.errors.emptyFile"))];
  }

  if (!constraints || JSON.stringify(constraints) === "{}") {
    return [];
  }

  const columnErrors = validateColumns(content.headers, constraints);

  if (columnErrors.length > 0) {
    return columnErrors;
  }

  // map constraints in same order as header columns
  const mappedConstraints = content.headers.map(column =>
    constraints[column] ? { name: column, ...constraints[column] } : undefined
  );
  const valuesErrors = validateValues(content.headers, content.values, mappedConstraints);

  return valuesErrors;
};

const validateColumns = (headers, constraints) => {
  const missingColumns = [];
  const requiredColumns = Object.keys(constraints).filter(key => constraints[key].required);

  requiredColumns.forEach(column => {
    if (!headers.includes(column)) {
      missingColumns.push(column);
    }
  });

  if (missingColumns.length > 0) {
    return [new Error(i18n.t("components.tabularInput.errors.missingColumns", { columns: missingColumns.join(", ") }))];
  }

  return [];
};

const validateValues = (headers, values, constraints) => {
  if (!values || values.length === 0) {
    return [new Error(i18n.t("components.tabularInput.errors.emptyFileValues"))];
  }

  if (!constraints || JSON.stringify(constraints) === "{}") {
    return [];
  }

  const errors = [];
  values.forEach((row, rowIndex) => {
    if (row.length !== headers.length) {
      errors.push(new Error(i18n.t("components.tabularInput.errors.invalidColumns", { row: rowIndex + 2 })));
      return;
    }

    row.forEach((value, columnIndex) => {
      const columnConstraint = constraints[columnIndex];
      if (!columnConstraint) {
        return;
      }

      const valueMeta = { column: columnConstraint.name, row: rowIndex + 2 };

      const type = columnConstraint.type;
      const max = columnConstraint.maximum;
      const min = columnConstraint.minimum;

      if (columnConstraint.required && !value) {
        errors.push(new Error(i18n.t("components.tabularInput.errors.emptyValue", valueMeta)));
        return;
      }

      if (!value) {
        return;
      }

      // we only support string and language validation at the moment
      switch (type) {
        case "string":
          if (typeof value !== "string") {
            errors.push(new Error(i18n.t("components.tabularInput.errors.invalidType", { ...valueMeta, type })));
          }

          if (min && value.length < min) {
            errors.push(new Error(i18n.t("components.tabularInput.errors.invalidLength", { ...valueMeta, max, min })));
          } else if (max && value.length > max) {
            errors.push(new Error(i18n.t("components.tabularInput.errors.invalidLength", { ...valueMeta, max, min })));
          }
          break;

        case "languageIsoAlpha2":
          if (!ISO_639_1_CODES[value]) {
            errors.push(new Error(i18n.t("components.tabularInput.errors.invalidLanguageCode", { ...valueMeta })));
          }
          break;
        case "boolean": {
          const booleanValue = value.toLowerCase();
          if (booleanValue !== "true" && booleanValue !== "false") {
            errors.push(new Error(i18n.t("components.tabularInput.errors.invalidBooleanValue", { ...valueMeta })));
          }
          break;
        }
        case "integer": {
          const integerValue = +value;
          if (!Number.isInteger(integerValue)) {
            errors.push(new Error(i18n.t("components.tabularInput.errors.invalidType", { ...valueMeta, type })));
          }
          if (!isNaN(min) && integerValue < min) {
            errors.push(new Error(i18n.t("components.tabularInput.errors.invalidIntegerMin", { ...valueMeta, min })));
          } else if (!isNaN(max) && integerValue > max) {
            errors.push(new Error(i18n.t("components.tabularInput.errors.invalidIntegerMax", { ...valueMeta, max })));
          }
          break;
        }
      }
    });
  });

  return errors;
};

export default tabularIntegrityValidator;
