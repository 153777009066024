import { connectToParent } from "penpal";

import { isInIframe } from "./Utils";
import Debug from "./Debug";

const isPenPalDebug = Debug.isDebug();
let connection;
let parent;
let options;
let clientsCount = 0;

async function getParent(methods) {
  if (isInIframe() === true) {
    if (connection === undefined) {
      Debug.log("Initializing the communication with the iframe hooks");
      try {
        options = { debug: isPenPalDebug, methods };
        connection = connectToParent(options);
        parent = await connection.promise;
      } catch (error) {
        console.error("Could not connect to the parent iframe hook", error);
      }
    }
    clientsCount++;
  }
  return parent;
}

async function destroyParent() {
  if (connection !== undefined) {
    clientsCount--;
    if (clientsCount <= 0) {
      console.debug("Destroying the communication with the iframe hooks");
      await connection.destroy();
      connection = undefined;
      parent = undefined;
      options = undefined;
    }
  }
}

export default {
  initialize: async (throwError = false, methods = undefined) => {
    const parent = await getParent(methods);
    if (parent !== undefined) {
      return {
        // Caution: do not turn this into a anonymous function, otherwise the "arguments" handling will not work!
        call: async function (callbackName) {
          console.debug("Invoking the hook callback '" + callbackName + "'");
          try {
            const theFunction = parent[callbackName];
            if (theFunction === undefined) {
              console.warn("The hook callback '" + callbackName + "' does not exist");
              // We let the invocation flow, since the "parent" object seems to be proxy
            }
            const theArguments = Array.prototype.slice.call(arguments, 1);
            return await theFunction.apply(undefined, theArguments);
          } catch (error) {
            console.warn("The hook callback '" + callbackName + "' invocation failed", error);
            if (throwError === true) {
              throw error;
            }
          }
        },
        destroy: destroyParent,
      };
    }
    return undefined;
  },
};
