import InputBinaryModel from "./InputBinaryModel";
import InputFontUi from "../ui/InputFontUi/InputFontUi";
import Model from "./Model";
import { toStringWithBytes, toStringWithCollection, toStringWithLessThan } from "~/util/Helpers";
import { Typography } from "@ogury/design-system";
import i18n from "i18next";

export default class InputFontModel extends InputBinaryModel {
  constructor(...args) {
    super(...args);
  }

  getFormatsConstraints() {
    return Model.getFormatsConstraints(this);
  }

  async computeConstraintsErrors() {
    const formats = this.getFormatsConstraints();
    if (formats !== undefined) {
      const mimeType = await this.getMimeType();
      const format = Model.mimeTypeToFormat(mimeType);
      if (format === undefined) {
        return [{ message: i18n.t("error.unhandled.fontMIMEType", { mimeType: mimeType }) }];
      }
      if (formats.indexOf(format) === -1) {
        return [
          {
            message: i18n.t("error.notSupported.fontFormat", { format: format }) + toStringWithCollection(formats),
          },
        ];
      }
    }

    const maximumBytes = this.getMaximumBytesConstraints();
    if (maximumBytes !== undefined) {
      const data = await this.getData();
      if (window.atob(data).length > maximumBytes) {
        return [
          {
            message: i18n.t("error.constraint.fontWeight", { maximumBytes: toStringWithBytes(maximumBytes) }),
          },
        ];
      }
    }

    return [];
  }

  renderConstraints() {
    const formats = this.getFormatsConstraints();
    const maximumBytes = this.getMaximumBytesConstraints();
    if (formats === undefined && maximumBytes === undefined) {
      return null;
    }
    return (
      <span>
        {formats !== undefined && (
          <span>
            <Typography.P2Regular>{i18n.t("inputs.label.formats")}</Typography.P2Regular>
            <Typography.P2Regular>{toStringWithCollection(formats)}</Typography.P2Regular>
          </span>
        )}
        {maximumBytes !== undefined && (
          <span>
            <Typography.P2Regular>{i18n.t("inputs.label.weight")}</Typography.P2Regular>
            <Typography.P2Regular>{toStringWithLessThan(toStringWithBytes(maximumBytes))}</Typography.P2Regular>
          </span>
        )}
      </span>
    );
  }

  renderComponent() {
    return InputFontUi;
  }
}
