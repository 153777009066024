import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Alert, CopyPaste, IconNames, Icons, Loader, Picker } from "components";
import { isInternalAsset } from "~/util/Constant";
import {
  computeFileNameFromUrl,
  computeUiValueFromUrlOrBinaryString,
  fromBlobToDataUrl,
  fromDataUrlToBase64Data,
  fromDataUrlToBlob,
  fromDataUrlToMimeType,
  increaseFileNameVersion,
  sanitizeFileName,
  toBinaryObject,
} from "~/util/InputsComputer";
import { downloadBlob } from "~/util/Utils";
import style from "./BinaryUi.module.scss";
import i18n from "i18next";
import { useExperience } from "~/context";
import { Button, Typography } from "@ogury/design-system";
import Api from "~/util/Api";
import { useTranslation } from "react-i18next";

function fromInput(value) {
  if (!value) {
    return undefined;
  } else if (typeof value === "string") {
    return { url: value };
  } else if (value.bin !== undefined) {
    return toBinaryObject(value.bin);
  }
}

async function updateValueFromDataUrl(computeObject, fromMimeTypeToFileExtension, value, dataUrl) {
  delete value.url;
  const url = dataUrl;
  value.urlOrDataUrl = url;
  value.mimeType = fromDataUrlToMimeType(dataUrl);

  const extension = fromMimeTypeToFileExtension(value.mimeType);
  const dotIndex = value.name.lastIndexOf(".");
  const currentExtension =
    dotIndex === -1 || dotIndex === value.name.length - 1 ? "" : value.name.substring(dotIndex + 1);
  if (currentExtension !== extension) {
    // We change the file name extension
    const currentNameWithoutExtension = dotIndex === -1 ? value.name : value.name.substring(0, dotIndex);
    value.name = currentNameWithoutExtension + (extension === undefined ? "" : "." + extension);
  } else {
    // We change the file name, in order to address the cache
    value.name = increaseFileNameVersion(value.name);
  }

  const data = fromDataUrlToBase64Data(dataUrl);
  value.size = window.atob(data).length;
  value.data = data;
  value.object = await computeObject(url);
  return value;
}

const BinaryUi = ({
  input = {},
  inputValue,
  inputName = "",
  fileExtensions,
  mimeTypes,
  doNotSupportCors,
  placeholder,
  preview,
  properties,
  actions,
  computeObject,
  computeBlob,
  fromMimeTypeToFileExtension,
  hint,
  onValidate,
  onValueChanged = () => {},
  onDelete = () => {},
}) => {
  const [t] = useTranslation();
  const { experience } = useExperience();
  const [value, setValue] = useState(fromInput(inputValue));
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const shouldRenderCopyPasteActions = useMemo(() => {
    return input?.referenceInput?.type === "imageOverlay";
  }, [input]);

  const renderedProperties = useMemo(() => {
    if (value !== undefined && properties !== undefined) {
      return properties(value);
    }
    return undefined;
  }, [properties, value]);

  function handleOnPaste(value) {
    const updatedInput = {
      ...input.getValue(),
      ...value,
    };
    input.setValue(updatedInput);
  }

  async function setValueFromInput(computeObject, setValue, binaryStringValue) {
    try {
      const value = await computeUiValueFromUrlOrBinaryString(binaryStringValue);
      value.object = await computeObject(value.urlOrDataUrl);
      setValue(value);
    } catch (error) {
      console.debug(error);
      setError(error.message);
    }
  }
  useEffect(() => {
    const run = async () => {
      if (inputValue !== undefined) {
        await setValueFromInput(computeObject, setValue, inputValue);
      } else {
        setValue(undefined);
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    run();
  }, [inputValue, computeObject]);

  useEffect(() => {
    // reseting the errors if the value is OK
    if (value !== undefined) {
      setError(undefined);
    }
  }, [value]);

  const setDataUrl = useCallback(
    async dataUrl => {
      setLoading(true);
      const blob = fromDataUrlToBlob(dataUrl);
      const newValue = await updateValueFromDataUrl(computeObject, fromMimeTypeToFileExtension, value, dataUrl);
      const mimeType = fromDataUrlToMimeType(dataUrl);

      const file = new File([blob], newValue.name);

      try {
        const { url } = await Api.uploadTemporaryFile(mimeType, newValue.name, file);
        setValue({
          name: newValue.name,
          mimeType,
          size: blob.size,
          url,
          urlOrDataUrl: undefined,
          object: newValue.object,
        });
        onValueChanged(url);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    },
    [computeObject, fromMimeTypeToFileExtension, value, inputValue]
  );

  const computedActions = useMemo(() => {
    return actions === undefined || value === undefined ? undefined : actions(value, setDataUrl);
  }, [actions, value, setDataUrl]);

  function onConvertToUrlClick() {
    (async function () {
      const blob = fromDataUrlToBlob(value.urlOrDataUrl);
      const { name, mimeType } = value;
      const file = new File([blob], name);

      try {
        setLoading(true);
        const { url } = await Api.uploadTemporaryFile(mimeType, name, file);
        setValue({
          name,
          mimeType,
          size: blob.size,
          url,
          urlOrDataUrl: undefined,
          object: value.object,
        });
        onValueChanged(url);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    })();
  }

  const onUrlChanged = useCallback(
    async (url, blob, viaProxy) => {
      try {
        const dataUrl = viaProxy === true ? await fromBlobToDataUrl(blob) : undefined;
        // If an exception is thrown, the "UrlPicker" will handle it
        const options = await onValidate(blob, {
          url: viaProxy === true ? undefined : url,
          dataUrl: dataUrl,
        });

        const fileName = computeFileNameFromUrl(url, true);

        setValue({
          name: fileName,
          mimeType: options.mimeType,
          size: blob.size,
          url,
          urlOrDataUrl: dataUrl,
          object: options.object,
        });
        onValueChanged(url);
      } catch (error) {
        setError(t("error.compute.invalidBinaryInput"));
      } finally {
        setLoading(false);
      }
    },
    [onValidate, inputValue]
  );

  const onFileChanged = useCallback(
    async ({ blob, fileName, mimeType, dataUrl, base64Data, setInner }) => {
      setLoading(true);
      fileName = sanitizeFileName(fileName);

      if (fileName === inputName) {
        fileName = increaseFileNameVersion(fileName);
      }

      // If an exception is thrown, the "FilePicker" will handle it
      const options = await onValidate(blob, {
        mimeType: mimeType,
        dataUrl: dataUrl,
        base64Data: base64Data,
      });
      // We first update the "Picker" internal state…
      setInner(undefined);
      // …, second, we update the internal state…
      const file = new File([blob], fileName);

      const { url } = await Api.uploadTemporaryFile(mimeType, fileName, file);
      setValue({
        name: fileName,
        mimeType: mimeType,
        size: blob.size,
        url,
        urlOrDataUrl: undefined,
        object: options.object,
      });
      onValueChanged(url);
      setLoading(false);
    },
    [onValidate, inputValue]
  );

  const renderTop = useCallback(() => {
    return value !== undefined ? (
      <>
        <div className={style.infos}>
          {renderedProperties}
          {renderActionButtons()}
        </div>

        {value.url !== undefined &&
          experience !== undefined &&
          isInternalAsset(value.url, experience.assetsBaseUrl) === false && (
            <div style={{ overflow: "hidden", alignItems: "center" }}>
              <Typography.P2Regular className={style.urlPreview}>{value.url}</Typography.P2Regular>
            </div>
          )}
        {value.url === undefined && (
          <Button
            type="secondary"
            size="small"
            style={{ marginRight: 10, minWidth: "70px", marginBottom: 10 }}
            onClick={onConvertToUrlClick}
          >
            <>
              {i18n.t("inputs.label.toURL")}
              <Icons name={IconNames.SwapOutlined.type} style={{ marginLeft: 10 }} />
            </>
          </Button>
        )}
        {preview && value.urlOrDataUrl !== undefined && preview(value.urlOrDataUrl)}
      </>
    ) : (
      <>
        {renderActionButtons()}
        <Picker
          hint={hint}
          fileExtensions={fileExtensions}
          mimeTypes={mimeTypes}
          doNotSupportCors={doNotSupportCors}
          placeholder={placeholder}
          onUrlChanged={onUrlChanged}
          onFileChanged={onFileChanged}
          onError={() => setLoading(false)}
        />
      </>
    );
  }, [
    experience,
    value,
    onConvertToUrlClick,
    renderedProperties,
    onUrlChanged,
    onFileChanged,
    preview,
    inputValue,
    fileExtensions,
    mimeTypes,
    doNotSupportCors,
    placeholder,
  ]);

  const downloadBinary = async () => {
    const blob = await computeBlob(value);
    downloadBlob(blob, value.name);
  };

  const deleteBinary = () => {
    setValue(undefined);
    setError(undefined);
    onDelete();
  };

  const renderChangeAction = useCallback(() => {
    return (
      <div className={style.actions}>
        {value?.object && (
          <>
            {computedActions}
            <Button
              data-testid="btn-download-binary"
              type="tertiary"
              size="small"
              icon={<Icons name={IconNames.Download.type} />}
              iconPosition="iconOnly"
              onClick={downloadBinary}
            />
          </>
        )}
        {value && (
          <Button
            data-testid="btn-delete-binary"
            type="tertiary"
            size="small"
            icon={<Icons name={IconNames.Delete.type} />}
            iconPosition="iconOnly"
            onClick={deleteBinary}
          />
        )}
        {shouldRenderCopyPasteActions && <CopyPaste input={input} onPaste={handleOnPaste} />}
      </div>
    );
  }, [computedActions, inputValue]);

  const renderActionButtons = useCallback(() => {
    return renderChangeAction();
  }, [inputValue, computedActions, inputValue]);

  const renderError = useCallback(() => {
    if (error) {
      return (
        <div className={style.alertContainer}>
          <Alert showIcon type="error" message={error} />
        </div>
      );
    }
  }, [error]);

  return (
    <div className={style.container}>
      {loading && <Loader overlay text="Processing file" />}
      {renderError()}
      {renderTop()}
    </div>
  );
};

BinaryUi.propTypes = {
  inputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fileExtensions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  mimeTypes: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  doNotSupportCors: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  properties: PropTypes.func.isRequired,
  actions: PropTypes.func,
  computeObject: PropTypes.func.isRequired,
  computeBlob: PropTypes.func.isRequired,
  hint: PropTypes.string,
  fromMimeTypeToFileExtension: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
};
export default BinaryUi;
