import { useState } from "react";

import { TextInput } from "..";
import { isUrl, urlToBlob } from "../../util/Utils";
import PropTypes from "prop-types";
import { theme, Typography } from "@ogury/design-system";

const UrlPicker = ({ placeholder, doNotSupportCors, onUrlChanged }) => {
  const [error, setError] = useState();

  return (
    <>
      <TextInput
        placeholder={placeholder || "File URL"}
        onChange={url => {
          if (isUrl(url) === true) {
            urlToBlob(url, doNotSupportCors)
              .then(({ blob, viaProxy }) => {
                // The conversion to blob is OK
                onUrlChanged(url, blob, viaProxy);
              })
              .catch(error => {
                setError(error.message);
              });
          } else {
            setError("URL format is not valid");
          }
        }}
      />
      {error && (
        <Typography.P2Regular style={{ color: theme.colors.destructive.content }}>{error}</Typography.P2Regular>
      )}
    </>
  );
};

UrlPicker.propTypes = {
  placeholder: PropTypes.string,
  doNotSupportCors: PropTypes.bool.isRequired,
  onUrlChanged: PropTypes.func.isRequired,
};

export default UrlPicker;
