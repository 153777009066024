import React, { useContext, useState } from "react";

import { Loader } from "components";
import { AccessibilityContext, Side } from "context/AccessibilityContext";
import { Tabs, Anchor } from "@ogury/design-system";
import { useTranslation } from "react-i18next";

const TABS = {
  FILES: "files",
};

export default function LeftPanel() {
  const [t] = useTranslation();
  const [activeTab, setActiveTab] = useState(TABS.LAYERS);

  const [accessibilityState] = useContext(AccessibilityContext);

  const isLoading =
    accessibilityState.loading.active === true && accessibilityState.loading.sides.includes(Side.Builder);

  // TODO: Implement file tree
  function renderFileTree() {
    return (
      <Anchor
        getCurrentAnchor={() => {}}
        items={[
          {
            key: "description",
            title: "description.json",
          },
        ]}
      />
    );
  }

  function onClickOnSettingsTab(tabName) {
    setActiveTab(tabName);
  }

  return (
    <div>
      <Tabs
        data-testid="left-panel-tabs"
        tabBarStyle={{ paddingLeft: 16 }}
        onTabClick={onClickOnSettingsTab}
        items={[
          {
            key: TABS.FILES,
            label: t("leftPanel.files"),
            children: renderFileTree(),
          },
        ]}
      />
      {isLoading === true && <Loader overlay />}
    </div>
  );
}
