import Papa from "papaparse";

function parseCsv(csv, config = {}) {
  return Papa.parse(csv, {
    header: false,
    skipEmptyLines: true,
    error: err => {
      throw new Error(err);
    },
    ...config,
    complete: result => {
      if (result.errors.length > 0) {
        config.error(result.errors[0].message);
        return;
      }
      if (config.complete) config.complete(result.data);
    },
  });
}

export { parseCsv };
