import { useMemo } from "react";
import style from "./Details.module.scss";
import { Avatar, List, Popover, Skeleton, Typography } from "@ogury/design-system";
import { PopoverElement } from "components/CreativeWeight/components";
import CodeSSlashFillIcon from "remixicon-react/CodeSSlashFillIcon";
import { useTranslation } from "react-i18next";
import { Image } from "components";

export default function Details({ resources, totalWeight, assetsOnly, onGotoBuilder }) {
  const [t] = useTranslation();

  const orderedResources = useMemo(
    () => ({
      assets: resources?.filter(resource => resource.isAsset),
      others: resources?.filter(resource => !resource.isAsset),
    }),
    [resources]
  );

  function renderBytesAndPercentage(item) {
    const percentage = Math.round(((item.networkWeightInBytes * 100) / totalWeight) * 100) / 100 + " %";
    return (
      <div className={style.bytesAndPercentageContainer}>
        <div>
          <Typography.P2Strong>{item.networkWeightReadable}</Typography.P2Strong>
        </div>
        <div>{percentage}</div>
      </div>
    );
  }

  function renderAvatar(item) {
    if (item.isImage) {
      return (
        <Popover
          content={
            <PopoverElement large url={item.url} onGotoBuilder={onGotoBuilder} filename={item.title}>
              <Image src={item.url} withCheckboardBackground />
            </PopoverElement>
          }
          trigger="hover"
        >
          <Avatar src={item.url} />
        </Popover>
      );
    } else if (item.isVideo) {
      return (
        <Popover
          content={
            <PopoverElement large url={item.url} onGotoBuilder={onGotoBuilder} filename={item.title}>
              <video autoPlay loop src={item.url} />
            </PopoverElement>
          }
          trigger="hover"
        >
          <video autoPlay loop width={40} src={item.url} />
        </Popover>
      );
    }
    return <CodeSSlashFillIcon />;
  }

  function renderResourceItem(item) {
    const itemMetaData = {
      avatar: renderAvatar(item),
      title: <Typography.P2Regular>{item.title}</Typography.P2Regular>,
    };

    return (
      <List.Item actions={[renderBytesAndPercentage(item)]}>
        <Skeleton avatar title={false} loading={false}>
          <List.Item.Meta {...itemMetaData} />
        </Skeleton>
      </List.Item>
    );
  }
  return (
    <>
      {!assetsOnly && (
        <div className={style.detailsTitle}>
          <Typography.P2Strong>{t("components.creativeWeight.assets")}</Typography.P2Strong>
        </div>
      )}
      <List
        dataSource={orderedResources.assets}
        itemLayout="horizontal"
        renderItem={renderResourceItem}
        size="default"
      />
      {!assetsOnly && (
        <>
          <div className={style.detailsTitle}>
            <Typography.P2Strong>{t("components.creativeWeight.others")}</Typography.P2Strong>
          </div>
          <List
            dataSource={orderedResources.others}
            itemLayout="horizontal"
            renderItem={renderResourceItem}
            size="default"
          />
        </>
      )}
    </>
  );
}
