import { Button, Modal, ModalClosableFooter } from "@ogury/design-system";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const BinaryUiModalDialog = ({
  title,
  isOpen,
  setOpen,
  isChanged,
  onApplyUrlOrDataUrl,
  onRevertUrlOrDataUrl,
  width,
  buttons,
  children,
}) => {
  const [t] = useTranslation();

  return (
    <Modal
      open={isOpen}
      width={width}
      onCancel={() => {
        onRevertUrlOrDataUrl();
        setOpen(false);
      }}
      title={title}
      footer={
        <ModalClosableFooter
          buttonLabel={t("components.OkCancelButtons.cancel")}
          additionalActions={buttons}
          actions={
            <Button
              onClick={() => {
                onApplyUrlOrDataUrl();
                setOpen(false);
              }}
              disabled={isChanged === false}
            >
              {t("inputsUI.button.save")}
            </Button>
          }
        />
      }
    >
      {children}
    </Modal>
  );
};

BinaryUiModalDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  isChanged: PropTypes.bool.isRequired,
  onApplyUrlOrDataUrl: PropTypes.func.isRequired,
  onRevertUrlOrDataUrl: PropTypes.func.isRequired,
  title: PropTypes.string,
  width: PropTypes.string,
  buttons: PropTypes.node,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default BinaryUiModalDialog;
