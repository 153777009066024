import { isInIframe } from "./Utils";
import { CUSTOMER_ID, USE_API_KEY } from "~/util/Constant";

export const STORE = {};
export const CREDENTIALS = "credentials";

export function saveCredentials(credentials) {
  console.debug("Saving the credentials with the customer id '" + credentials.customerId + "'");
  if (isInIframe() === true) {
    STORE[CREDENTIALS] = credentials;
  } else {
    localStorage.setItem(CREDENTIALS, JSON.stringify(credentials));
  }
}

export function getCredentials() {
  let credentials;
  if (isInIframe() === true) {
    credentials = STORE[CREDENTIALS];
  } else {
    const rawCredentials = localStorage.getItem(CREDENTIALS);
    if (rawCredentials !== undefined) {
      credentials = JSON.parse(rawCredentials);
    }
  }
  return credentials === null ? undefined : credentials;
}

export function getCustomerId() {
  return CUSTOMER_ID;
}

export function isAuthenticated() {
  const credentials = getCredentials();
  return credentials !== undefined && (credentials.accessToken !== undefined || credentials.apiKey !== undefined);
}

export function getApiKey() {
  if (USE_API_KEY) {
    return process.env.REACT_APP_API_KEY;
  }
  const credentials = getCredentials();
  return credentials === undefined ? undefined : credentials.apiKey;
}

export function getAccessToken() {
  const credentials = getCredentials();
  return credentials === undefined ? undefined : credentials.accessToken;
}
