const ROOT_INPUT_ID = "root";

const INPUT_SUPER_TYPE = {
  FINAL: "final",
  COMPOSITE: "composite",
  GROUP: "group",
  COLLECTION: "collection",
  COLLECTION_CHILD: "collectionChild",
};

const KONVA_ELEMENT_TYPE_LOTTIE = "lottie";
const KONVA_ELEMENT_TYPE_IMAGE = "image";

const CENTER_PANEL_ID = "center-panel";

export default {
  ROOT_INPUT_ID,
  CENTER_PANEL_ID,
  INPUT_SUPER_TYPE,
  KONVA_ELEMENT_TYPE_LOTTIE,
  KONVA_ELEMENT_TYPE_IMAGE,
};
