import { createContext, useReducer } from "react";

/**
 * @type {React.Context<undefined>}
 */
const AccessibilityContext = createContext(undefined);

const Actions = {
  SetLoading: "SET_LOADING",
};

export const Side = {
  Builder: "BUILDER",
  Preview: "PREVIEW",
  Buttons: "BUTTONS",
};

export const LoadingOptions = {
  active: false,
  text: "Loading",
  sides: [], // Side to lock, if no side specified, the loading overlay is fullscreen
};

const actionsFunctions = dispatch => {
  return {
    setLoading: (loadingOptions = LoadingOptions) => {
      const newValue = {
        type: Actions.SetLoading,
        payload: Object.assign({}, LoadingOptions, loadingOptions),
      };
      return dispatch(newValue);
    },
  };
};

const AccessibilityReducer = (accessibilityState, action) => {
  switch (action.type) {
    case Actions.SetLoading:
      return {
        loading: action.payload,
      };
    default:
      break;
  }
};

const AccessibilityContextProvider = ({ children }) => {
  const initialState = {
    loading: LoadingOptions,
  };

  const [accessibilityState, dispatch] = useReducer(AccessibilityReducer, initialState, undefined);

  const accessibilityActions = actionsFunctions(dispatch);

  return (
    <AccessibilityContext.Provider value={[accessibilityState, accessibilityActions]}>
      {children}
    </AccessibilityContext.Provider>
  );
};

export { AccessibilityContext, AccessibilityContextProvider };
