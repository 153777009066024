import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import ResizeObserver from "resize-observer-polyfill";
import { Layer, Rect, Stage } from "react-konva/es/ReactKonva";
import { KonvaURLImage } from "~/components";
import { theme } from "@ogury/design-system";
import GridUi from "./GridUi";
import { InputTypes } from "~/app/inputs/model/ModelFactory";
import Model from "~/app/inputs/model/Model";
import { computeAdSize, fitCanvasToContainer, processImageInput } from "./helpers";

import styles from "./AdUnitCanvas.module.scss";
import unitService from "~/services/UnitService";

const AdUnitCanvas = ({
  children,
  height = 400,
  gridEnabled,
  onCanvasChange,
  constraintsFrame,
  constraintsReferenceInput,
  checkboardEnabled,
  visible = true,
}) => {
  const canvasWrapperRef = useRef(null);
  const CANVAS_WRAPPER_PADDING = theme.spacing.space_s;

  const [canvasDimensions, setCanvasDimensions] = useState();

  // use 1 instead of 0 to prevent division by 0
  const [adRatio, setAdRatio] = useState(1);
  const unitInstance = unitService.getCurrentUnitInputInstance();

  const originalAdSize = useMemo(
    () => computeAdSize(unitInstance?.getValue(), adRatio),
    [adRatio, unitInstance?.getValue()]
  );

  const canvasScale = useMemo(() => {
    if (!adRatio || !canvasDimensions || !originalAdSize) {
      return 1;
    }

    return canvasDimensions.width / originalAdSize.width;
  }, [canvasDimensions, originalAdSize, adRatio]);

  const ratioReferenceInput = useMemo(
    () =>
      constraintsReferenceInput?.getSemanticsNature() === Model.Natures.Ratio ? constraintsReferenceInput : undefined,
    [constraintsReferenceInput]
  );
  const assetReferenceInput = useMemo(
    () => (constraintsReferenceInput?.getType() === InputTypes.Image ? constraintsReferenceInput : undefined),
    [constraintsReferenceInput]
  );

  const constraintsBounds = useMemo(() => {
    if (!canvasDimensions || !constraintsFrame) {
      return;
    }
    return {
      x: (constraintsFrame.x * canvasDimensions.width) / 100,
      y: (constraintsFrame.y * canvasDimensions.height) / 100,
      width: (constraintsFrame.width * canvasDimensions.width) / 100,
      height: (constraintsFrame.height * canvasDimensions.height) / 100,
    };
  }, [JSON.stringify(constraintsFrame), canvasDimensions]);

  const assetReferenceImageUrl = useMemo(
    () => (assetReferenceInput ? processImageInput(assetReferenceInput.getValue()) : null),
    [assetReferenceInput?.getValue()]
  );

  // calculate ad ratio based on constraints
  const ratioReferenceInputValue = ratioReferenceInput?.getValue();
  const assetReferenceInputValue = assetReferenceInput?.getValue();

  useEffect(() => {
    if (assetReferenceInputValue) {
      assetReferenceInput.computeMetas().then(metas => metas && setAdRatio(metas.ratio));
    } else if (ratioReferenceInputValue) {
      setAdRatio(ratioReferenceInputValue);
    } else {
      setAdRatio(1);
    }
  }, [assetReferenceInputValue, ratioReferenceInputValue]);

  useEffect(() => {
    if (onCanvasChange) {
      onCanvasChange({
        canvasDimensions,
        canvasScale,
        originalAdSize,
        constraintsBounds,
      });
    }
  }, [canvasDimensions, canvasScale, constraintsBounds, originalAdSize]);

  /* Hooks */
  // setup resize canvas container resize observer
  useLayoutEffect(() => {
    if (!canvasWrapperRef.current || !originalAdSize) {
      return;
    }

    // reset listeners for canvas wrapper
    const myObserver = new ResizeObserver(entries => {
      // We wrap it in requestAnimationFrame to avoid this error - ResizeObserver loop limit exceeded
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }

        setCanvasDimensions(fitCanvasToContainer(entries[0].contentRect, adRatio));
      });
    });
    myObserver.observe(canvasWrapperRef.current);

    return () => {
      myObserver.disconnect();
    };
  }, [adRatio]);

  // resize canvas on adRatio change
  useEffect(() => {
    if (!canvasWrapperRef.current) {
      setCanvasDimensions(
        fitCanvasToContainer({ height: CANVAS_WRAPPER_HEIGHT - parseInt(CANVAS_WRAPPER_PADDING) * 2 }, adRatio)
      );
    } else {
      setCanvasDimensions(
        fitCanvasToContainer(
          {
            height: canvasWrapperRef.current.clientHeight - parseInt(CANVAS_WRAPPER_PADDING) * 2,
            width: canvasWrapperRef.current.clientWidth - parseInt(CANVAS_WRAPPER_PADDING) * 2,
          },
          adRatio
        )
      );
    }
  }, [adRatio]);

  return (
    <div
      ref={canvasWrapperRef}
      style={{ height, padding: CANVAS_WRAPPER_PADDING, display: visible ? "flex" : "none" }}
      className={`${styles.canvasWrapper}${checkboardEnabled ? " checkboard" : ""}`}
    >
      {canvasDimensions && (
        <Stage {...canvasDimensions}>
          <Layer>
            {assetReferenceImageUrl && <KonvaURLImage {...canvasDimensions} src={assetReferenceImageUrl} />}
            <Rect
              width={canvasDimensions.width - 1}
              height={canvasDimensions.height - 1}
              stroke={theme.colors.shape.neutral600}
              strokeWidth={1}
              fill="transparent"
              dash={[5, 5]}
            />
            {constraintsBounds && (
              <Rect {...constraintsBounds} stroke={theme.colors.shape.neutral600} strokeWidth={1} dash={[5, 5]} />
            )}
            {children}
            {gridEnabled && (
              <GridUi
                x={1}
                y={1}
                width={canvasDimensions.width - 2}
                height={canvasDimensions.height - 2}
                columns={10}
                rows={10}
              />
            )}
          </Layer>
        </Stage>
      )}
    </div>
  );
};

AdUnitCanvas.propTypes = {
  constraintsReferenceInput: PropTypes.object.isRequired,
  onCanvasChange: PropTypes.func.isRequired,
  children: PropTypes.node,
  height: PropTypes.number,
  gridEnabled: PropTypes.bool,
  constraintsFrame: PropTypes.object,
};

export default AdUnitCanvas;
