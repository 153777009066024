import React, { useEffect, useMemo, useState } from "react";
import { Circle } from "react-konva/es/ReactKonva";
import { InputWrapper } from "~/components";
import { theme } from "@ogury/design-system";
import { restrictCoordinates } from "components/AdUnitCanvas/helpers";
import { AdUnitCanvas, CoordinatesInputs } from "components";
import { UNITS, useCreativeSettingsContext } from "context/CreativeSettingsContext";

const InputPointUi = ({ input }) => {
  const CIRCLE_SIZE = 16;

  // use global context to detect pixel usage
  const { creativeSettings } = useCreativeSettingsContext();
  const absoluteValues = creativeSettings.units === UNITS.PIXELS;

  const [canvasDimensions, setCanvasDimensions] = useState();
  const [canvasScale, setCanvasScale] = useState(0.5);
  const [originalAdSize, setOriginalAdSize] = useState();
  const [constraintsBounds, setConstraintsBounds] = useState();

  const [pointCoordinatesInputs, setPointCoordinatesInputs] = useState();

  const inputValue = useMemo(() => input.getValue(), [input.getValue()]);
  const inputConstraintBounds = useMemo(() => {
    if (!constraintsBounds || !canvasDimensions || canvasDimensions.width === 0) {
      return;
    }

    if (!absoluteValues) {
      return {
        x: {
          min: (constraintsBounds.x / canvasDimensions.width) * 100,
          max: ((constraintsBounds.x + constraintsBounds.width) / canvasDimensions.width) * 100,
        },
        y: {
          min: (constraintsBounds.y / canvasDimensions.height) * 100,
          max: ((constraintsBounds.y + constraintsBounds.height) / canvasDimensions.height) * 100,
        },
      };
    }

    return {
      x: { min: constraintsBounds.x / canvasScale, max: (constraintsBounds.x + constraintsBounds.width) / canvasScale },
      y: {
        min: constraintsBounds.y / canvasScale,
        max: (constraintsBounds.y + constraintsBounds.height) / canvasScale,
      },
    };
  }, [constraintsBounds, absoluteValues]);

  /* Event handlers */
  const handleOnCanvasChange = data => {
    setCanvasDimensions(data.canvasDimensions);
    setCanvasScale(data.canvasScale);
    setOriginalAdSize(data.originalAdSize);
    setConstraintsBounds(data.constraintsBounds);
  };

  const handleCoordinatesChange = coordinates => {
    setPointCoordinatesInputs({ ...coordinates, isDirty: true });
  };

  const handleImageDragMove = event => {
    const coordinates = event.target;
    const restrictedCoordinates = restrictCoordinates(
      { x: coordinates.x(), y: coordinates.y(), width: 0, height: 0 },
      constraintsBounds
    );

    coordinates.x(restrictedCoordinates.x);
    coordinates.y(restrictedCoordinates.y);
  };

  const handleImageDragEnd = event => {
    if (!canvasDimensions) {
      return;
    }

    const { x, y } = event.target.attrs;
    const newCoordinates = absoluteValues
      ? { x: x / canvasScale, y: y / canvasScale }
      : { x: (x / canvasDimensions.width) * 100, y: (y / canvasDimensions.height) * 100 };

    setPointCoordinatesInputs(coordinates => ({
      ...coordinates,
      ...newCoordinates,
      isDirty: true,
    }));
  };

  /* UI computations */
  const pointCoordinates = useMemo(() => {
    if (!pointCoordinatesInputs || !canvasScale || !originalAdSize) {
      return null;
    }

    const { x, y } = pointCoordinatesInputs;
    const absoluteCoordinates = absoluteValues
      ? { ...pointCoordinatesInputs }
      : {
          x: (originalAdSize.width * x) / 100,
          y: (originalAdSize.height * y) / 100,
        };

    return {
      x: absoluteCoordinates.x * canvasScale,
      y: absoluteCoordinates.y * canvasScale,
      width: CIRCLE_SIZE,
      height: CIRCLE_SIZE,
    };
  }, [pointCoordinatesInputs, canvasScale]);

  /* Hooks */
  useEffect(() => {
    if (!pointCoordinatesInputs || !originalAdSize || !inputValue || !canvasDimensions) {
      return;
    }

    const { x, y } = pointCoordinatesInputs;
    const newX = !absoluteValues || x === 0 ? x : (x / originalAdSize.width) * 100;
    const newY = !absoluteValues || y === 0 ? y : (y / originalAdSize.height) * 100;

    if (pointCoordinatesInputs.isDirty) {
      input.setValue({
        ...inputValue,
        x: newX,
        y: newY,
      });
    }
  }, [pointCoordinatesInputs]);

  useEffect(() => {
    if (!inputValue || !originalAdSize) {
      return;
    }

    const { x, y } = inputValue;
    let newCoordinates = !absoluteValues
      ? {
          x,
          y,
        }
      : {
          x: (x * originalAdSize.width) / 100,
          y: (y * originalAdSize.height) / 100,
        };

    if (JSON.stringify(pointCoordinatesInputs) !== JSON.stringify(newCoordinates)) {
      setPointCoordinatesInputs({ ...newCoordinates, absoluteValues });
    }
  }, [originalAdSize, inputValue]);

  useEffect(() => {
    // refresh coordinates on unit change
    if (!originalAdSize || !pointCoordinatesInputs || absoluteValues === pointCoordinatesInputs.absoluteValues) {
      return;
    }

    const { x, y } = pointCoordinatesInputs;

    if (absoluteValues) {
      setPointCoordinatesInputs({
        x: (originalAdSize.width * x) / 100,
        y: (originalAdSize.height * y) / 100,
        absoluteValues,
        isDirty: true,
      });
    } else {
      setPointCoordinatesInputs({
        x: (x / originalAdSize.width) * 100,
        y: (y / originalAdSize.height) * 100,
        absoluteValues,
        isDirty: true,
      });
    }
  }, [absoluteValues]);

  return (
    <InputWrapper input={input}>
      <>
        <AdUnitCanvas
          constraintsReferenceInput={input.getConstraintsOnInputPathReferenceInstance()}
          constraintsFrame={input.getConstraintsFrame()}
          onCanvasChange={handleOnCanvasChange}
          checkboardEnabled
        >
          {pointCoordinates && (
            <Circle
              {...pointCoordinates}
              radius={CIRCLE_SIZE / 2}
              fill={theme.colors.shape.neutral300}
              draggable
              onDragMove={handleImageDragMove}
              onDragEnd={handleImageDragEnd}
            />
          )}
        </AdUnitCanvas>

        {pointCoordinatesInputs && (
          <CoordinatesInputs
            coordinates={pointCoordinatesInputs}
            constraints={inputConstraintBounds}
            canvasDimensions={constraintsBounds}
            canvasScale={canvasScale}
            onChange={handleCoordinatesChange}
            onLockRatio={value => setFrameLockRatio(value)}
            hideSize
          />
        )}
      </>
    </InputWrapper>
  );
};

export default InputPointUi;
