import { useEffect, useMemo, useState } from "react";
import experienceService from "~/services/ExperienceService";
import { Alert, Button, Checkbox, Loader, Select, Tabs, theme, Typography } from "@ogury/design-system";
import style from "./CreativeWeight.module.scss";
import { useTranslation } from "react-i18next";
import {
  contentTypeIsBuilderAsset,
  contentTypeIsImage,
  contentTypeIsVideo,
  formatBytes,
  removeFileProtocolPrefix,
} from "~/util/Utils";
import { Details, DonutChart } from "components/CreativeWeight/components";
import MagicFillIcon from "remixicon-react/MagicFillIcon";

const IPHONE14_USERAGENT =
  "Mozilla/5.0 (iPhone; CPU iPhone OS 16_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.4 Mobile/15E148 Safari/604.1";

const containersDimensions = [
  {
    value: 1,
    size: "1170x2532",
    label: "iPhone 14 - Interstitial (1170x2532px)",
    userAgent: IPHONE14_USERAGENT,
  },
  {
    value: 2,
    size: "1170x658",
    label: "iPhone 14 - Header (1170x658px)",
    userAgent: IPHONE14_USERAGENT,
  },
  {
    value: 3,
    size: "540x405",
    label: "iPhone 14 - Thumbnail (540x405px)",
    userAgent: IPHONE14_USERAGENT,
  },
  {
    value: 4,
    size: "1170x300",
    label: "iPhone 14 - Footer (1170x300px)",
    userAgent: IPHONE14_USERAGENT,
  },
  {
    value: 5,
    size: "1080x2400",
    label: "Google Pixel 6 - Interstitial (1080x2400px)",
  },
  {
    value: 6,
    size: "1080x608",
    label: "Google Pixel 6 - Header (1080x608px)",
  },
  {
    value: 7,
    size: "540x405",
    label: "Google Pixel 6 - Thumbnail (540x405px)",
  },
  {
    value: 8,
    size: "1080x277",
    label: "Google Pixel 6 - Footer (1080x277px)",
  },
];

export default function CreativeWeight({ experienceId, onGotoBuilder }) {
  const [loading, setLoading] = useState(true);
  const [advancedSettings, setAdvancedSettings] = useState(false);
  const [assetsOnly, setAssetsOnly] = useState(false);
  const [error, setError] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [t] = useTranslation();
  const [baseResources, setBaseResources] = useState();

  const [dimension, setDimension] = useState(containersDimensions[0]);

  const resources = useMemo(() => {
    if (baseResources) {
      return baseResources.filter(resource => (assetsOnly ? resource.isAsset === true : resource));
    }
  }, [baseResources, assetsOnly]);

  const totalWeight = useMemo(() => {
    let weight = 0;
    if (resources?.length) {
      resources.forEach(resource => (weight += resource.networkWeightInBytes));
    }
    return weight;
  }, [resources]);

  function resourcesAdapter(resources) {
    return resources
      .sort((a, b) => b.networkWeightInBytes - a.networkWeightInBytes)
      .map(resource => {
        return {
          isImage: contentTypeIsImage(resource.contentType),
          isVideo: contentTypeIsVideo(resource.contentType),
          isAsset: contentTypeIsBuilderAsset(resource.contentType),
          title: resource.file ? removeFileProtocolPrefix(resource.file) : resource.type,
          networkWeightInBytes: resource.networkWeightInBytes,
          networkWeightReadable: formatBytes(resource.networkWeightInBytes),
          url: resource.url,
        };
      });
  }

  async function load() {
    setLoading(true);
    setError(false);
    try {
      const binaryWeight = await experienceService.getBinaryWeight(experienceId, [dimension.size], dimension.userAgent);
      const firstDimensionResources = binaryWeight?.breakdowns?.[0].resources;
      if (firstDimensionResources) {
        setBaseResources(resourcesAdapter(firstDimensionResources));
      } else {
        setError(true);
      }
    } catch (error) {
      console.warn(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    load();
  }, [dimension]);

  const creativeTotalWeight = useMemo(() => {
    let bytes = 0;
    if (resources) {
      resources?.forEach(resource => (bytes += resource.networkWeightInBytes));
    }
    return formatBytes(bytes);
  }, [resources]);

  function renderLoading() {
    return (
      <div className={style.containerLoader}>
        <Loader />
      </div>
    );
  }

  function renderBody() {
    const items = [
      {
        key: "1",
        label: "Details",
        children: (
          <Details
            resources={resources}
            totalWeight={totalWeight}
            assetsOnly={assetsOnly}
            onGotoBuilder={onGotoBuilder}
          />
        ),
      },
      {
        key: "2",
        label: "Overview",
        children: (
          <div className={style.chartContainer}>
            <DonutChart resources={resources} totalWeight={totalWeight} onGotoBuilder={onGotoBuilder} />
          </div>
        ),
      },
    ];
    return (
      <div className={style.containerBody}>
        <div>
          <Alert
            description={
              <div className={style.alertContainer}>
                <div>
                  {t("components.creativeWeight.total")} <b>{creativeTotalWeight}</b>
                </div>
                <Button disabled icon={<MagicFillIcon />} iconPosition="left">
                  {t("components.creativeWeight.optimize")}
                </Button>
              </div>
            }
          />
        </div>
        <Tabs defaultActiveKey={activeTab} onTabClick={setActiveTab} items={items} />
      </div>
    );
  }

  function renderError() {
    return (
      <div className={style.containerLoader}>
        <Alert
          message={t("components.creativeWeight.errorTitle")}
          description={t("components.creativeWeight.errorDescription")}
          type="error"
        />
      </div>
    );
  }

  function renderDimensionSelector() {
    return (
      <div>
        <Typography.P2Strong
          style={{
            color: theme.colors.content.secondary,
          }}
        >
          {t("components.creativeWeight.device")}
        </Typography.P2Strong>
        <Select
          disabled={loading}
          className={style.dimensionSelector}
          options={containersDimensions}
          onChange={value => setDimension(containersDimensions.find(dimension => dimension.value === value))}
          value={dimension.value}
        />
        <div className={style.settings}>
          {!advancedSettings && (
            <Typography.P3Regular onClick={() => setAdvancedSettings(true)}>
              {t("components.creativeWeight.advancedSettings")}
            </Typography.P3Regular>
          )}
          {advancedSettings && (
            <Checkbox disabled={loading} checked={assetsOnly} onChange={setAssetsOnly}>
              {t("components.creativeWeight.showAssetsOnly")}
            </Checkbox>
          )}
        </div>
      </div>
    );
  }

  return (
    <div>
      {renderDimensionSelector()}
      {loading && !error && renderLoading()}
      {!loading && !error && resources && renderBody()}
      {!loading && error && renderError()}
    </div>
  );
}
