import { Loader as DSLoader, Typography } from "@ogury/design-system";
import PropTypes from "prop-types";
import style from "./Loader.module.scss";

const Loader = ({ text, overlay = false, withLoader = true, children, textStyle }) => {
  return (
    <div className={overlay ? style.loadingOverlay : ""}>
      <div style={textStyle} className={style.container}>
        {withLoader && <DSLoader />}
        {text !== undefined && (
          <div className={style.textContainer}>
            <Typography.P2Regular>{text}</Typography.P2Regular>
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

Loader.propTypes = {
  loader: PropTypes.string,
  text: PropTypes.string,
  withLoader: PropTypes.bool,
  overlay: PropTypes.bool,
  children: PropTypes.node,
  style: PropTypes.object,
};

export default Loader;
