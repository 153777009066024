import InputNumberModel from "./InputNumberModel";
import InputIntegerUi from "../ui/InputIntegerUi";

export default class InputIntegerModel extends InputNumberModel {
  constructor(...args) {
    super(...args);
  }

  renderComponent() {
    return InputIntegerUi;
  }
}
