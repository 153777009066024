import React from "react";

import { InputTypes } from "./ModelFactory";
import Model from "./Model";
import { RATIO_DIGITS_COUNT, SCHEMA_INPUTS_FIELDS } from "../../../util/ModelConstants";
import { toStringWithDigits } from "~/util/Helpers";
import { SliderWithNumber } from "components";
import { Typography } from "@ogury/design-system";
import i18n from "i18next";

export default class InputNumberModel extends Model {
  constructor(...args) {
    super(...args);
  }

  isFloat() {
    return this.getType() === InputTypes.Float;
  }

  getRangeBounds() {
    const range = this.getReferenceInput()[SCHEMA_INPUTS_FIELDS.Range];
    return range === undefined
      ? undefined
      : [range[SCHEMA_INPUTS_FIELDS.RangeMinimum], range[SCHEMA_INPUTS_FIELDS.RangeMaximum]];
  }

  computeRangeGranularity() {
    const rangeBounds = this.getRangeBounds();
    if (rangeBounds !== undefined) {
      const [rangeMinimum, rangeMaximum] = rangeBounds;
      if (rangeMinimum !== undefined && rangeMaximum !== undefined) {
        const minSplit = rangeMinimum.toString().split(".");
        const maxSplit = rangeMaximum.toString().split(".");

        let minValueLength,
          maxValueLength = 0;
        if (minSplit.length === 2) {
          minValueLength = minSplit[1];
        }
        if (maxSplit.length === 2) {
          maxValueLength = maxSplit[1];
        }
        if (minValueLength > maxValueLength) {
          maxValueLength = minValueLength;
        }
        let granularity = "0.";
        if (maxValueLength.length > 1) {
          for (let index = 1; index < maxValueLength.length; index++) {
            granularity += "0";
          }
        }
        granularity += "1";
        return parseFloat(granularity);
      }
    }
    return 0.1;
  }

  parseAndBoundRangeValue(value) {
    value = this.isFloat() === true ? parseFloat(value) : parseInt(value);

    if (isNaN(value) === true) {
      console.error("Could not parse the number with value '" + value + "'");
      value = this.getDefaultValue();
    }

    value = this.boundRangeValue(value);

    return value;
  }

  boundRangeValue(value) {
    const rangeBounds = this.getRangeBounds();

    if (rangeBounds !== undefined) {
      const [rangeMinimum, rangeMaximum] = rangeBounds;
      if (rangeMinimum !== undefined && value < rangeMinimum) {
        value = rangeMinimum;
      }
      if (rangeMaximum !== undefined && value > rangeMaximum) {
        value = rangeMaximum;
      }
    }
    return value;
  }

  async computeConstraintsErrors() {
    const rangeBounds = this.getRangeBounds();
    if (rangeBounds !== undefined) {
      const [minimum, maximum] = rangeBounds;
      const value = this.getValue();
      const isRatio = this.isFloat() === true && this.isRatio() === true;
      if (minimum !== undefined && value < minimum) {
        return [
          {
            message:
              i18n.t("error.constraint.numberMin") +
              toStringWithDigits(isRatio === true ? RATIO_DIGITS_COUNT : -1, minimum),
          },
        ];
      } else if (maximum !== undefined && value > maximum) {
        return [
          {
            message:
              i18n.t("error.constraint.numberMax") +
              toStringWithDigits(isRatio === true ? RATIO_DIGITS_COUNT : -1, maximum),
          },
        ];
      }
    }
    return [];
  }

  renderFreeOrRangeValue(granularity, value, setValue, onChange) {
    const rangeBounds = this.getRangeBounds();
    const [rangeMinimum, rangeMaximum] =
      rangeBounds === undefined ? [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER] : rangeBounds;
    return (
      <SliderWithNumber
        sliderValue={value.slider}
        inputValue={value.input}
        onChange={onChange}
        granularity={granularity}
        isFloat={this.isFloat()}
        onSlideChange={newValue => {
          setValue({
            slider: newValue,
            input: newValue,
          });
        }}
        rangeBounds={rangeBounds}
        rangeMaximum={rangeMaximum}
        rangeMinimum={rangeMinimum}
      />
    );
  }

  renderConstraints() {
    const rangeBounds = this.getRangeBounds();
    const minimum = rangeBounds === undefined ? undefined : rangeBounds[0];
    const maximum = rangeBounds === undefined ? undefined : rangeBounds[1];
    if (minimum === undefined && maximum === undefined) {
      return null;
    }
    const isRatio = this.isFloat() === true && this.isRatio() === true;
    return (
      <span>
        <Typography.P2Regular>{i18n.t("inputs.label.value")}</Typography.P2Regular>
        <Typography.P2Regular>
          [
          {minimum === undefined && isRatio === false ? (
            <>-&infin;</>
          ) : (
            toStringWithDigits(isRatio === true ? RATIO_DIGITS_COUNT : -1, minimum === undefined ? 0 : minimum)
          )}
          ,{" "}
          {maximum === undefined ? (
            <>&infin;</>
          ) : (
            toStringWithDigits(isRatio === true ? RATIO_DIGITS_COUNT : -1, maximum)
          )}
          ]
        </Typography.P2Regular>
      </span>
    );
  }
}
