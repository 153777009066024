import { createContext, useContext, useMemo, useState } from "react";

export const UNITS = {
  PIXELS: "pixels",
  PERCENTAGE: "percentage",
};

const CreativeSettingsStoreContext = createContext({
  creativeSettings: {},
  setCreativeSettings: () => {},
});

export function useCreativeSettingsContext() {
  return useContext(CreativeSettingsStoreContext);
}

export function CreativeSettingsStoreProvider({ children }) {
  const [settings, setSettings] = useState({ units: UNITS.PIXELS });
  const value = useMemo(
    () => ({
      creativeSettings: settings,
      setCreativeSettings: setSettings,
    }),
    [settings]
  );
  return <CreativeSettingsStoreContext.Provider value={value}>{children}</CreativeSettingsStoreContext.Provider>;
}
