import { useEffect, useState } from "react";

import { TextInput, InputIcon, InputWrapper } from "components";
import { SCHEMA_INPUTS_FIELDS } from "../../../util/ModelConstants";
import { theme, Typography } from "@ogury/design-system";

const InputStringUi = ({ input }) => {
  const [value, setValue] = useState(input.getValue());

  useEffect(() => {
    setValue(input.getValue());
  }, [input.getValue()]);

  const renderAcceptableValue = () => {
    const acceptableValue = input.getAcceptableValue(value);
    const illustration =
      acceptableValue === undefined ? undefined : acceptableValue[SCHEMA_INPUTS_FIELDS.AcceptableValuesIllustration];

    return (
      <div>
        {
          // TODO remove the UI logic from the model and bring it back in the UI. Already done with "InputFloatUi and component "AcceptableValues"
        }
        {input.renderAcceptableValues(value, newValue => {
          setValue(newValue);
          input.setValue(newValue);
        })}
        {illustration !== undefined && <InputIcon icon={illustration} square={false} />}
      </div>
    );
  };

  const renderEditValue = () => {
    const charactersConstraints = input.getCharactersConstraints();
    const charactersColor =
      charactersConstraints !== undefined &&
      (value.length < charactersConstraints.minimum || value.length > charactersConstraints.maximum)
        ? theme.colors.destructive.content
        : "";
    return (
      <>
        <TextInput
          value={value}
          onChange={setValue}
          onBlur={() => {
            input.setValue(value);
          }}
          minLength={charactersConstraints === undefined ? undefined : charactersConstraints.minimum}
          maxLength={charactersConstraints === undefined ? undefined : charactersConstraints.maximum}
        />
        {charactersConstraints !== undefined && (
          <div className="mt-h text-smaller">
            <Typography.P2Regular style={{ color: charactersColor }}>{value.length}</Typography.P2Regular> /{" "}
            {charactersConstraints.maximum}
          </div>
        )}
      </>
    );
  };

  return (
    <InputWrapper input={input}>
      {input.getAcceptableValues() !== undefined ? renderAcceptableValue() : renderEditValue()}
    </InputWrapper>
  );
};

export default InputStringUi;
