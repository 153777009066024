/*
 * Copyright (C) 2016-2020 KoppaSoft.
 *
 * The code hereby is the private full property of the KoppaSoft company, Paris, France.
 *
 * You have no right to re-use or modify it. There are no open-source, nor free license
 * attached to it!
 */
import i18n from "i18next";
import Api from "~/util/Api";
import { urlToBlob } from "./Utils";

const BINARY_SUFFIX = "-bin";
const META_SUFFIX = "-meta";

const FILE_NAME_MAXIMUM_LENGTH = 64;

function sanitizeFileName(fileName) {
  function removeAccents(string) {
    const accents = "ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž";
    const accentsOut = "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
    string = string.split("");
    const length = string.length;
    let character;
    for (let index = 0; index < length; index++) {
      if ((character = accents.indexOf(string[index])) !== -1) {
        string[index] = accentsOut[character];
      }
    }
    return string.join("");
  }

  function removeSpecialCharacters(string) {
    string = string.replace(/\s/g, "-");
    string = string.replace(/[`~!@#$^&*|+=?;:'",<>{}\[\]\\\/]/gi, "");
    // This removes the non-ASCII characters
    string = string.replace(/[\u{0080}-\u{FFFF}]/gu, "");
    const tokens = string.split(".");
    if (tokens.length > 2) {
      string = tokens.slice(0, tokens.length - 1).join("-") + "." + tokens[tokens.length - 1];
    }
    return string;
  }

  function truncate(string) {
    if (string.length > FILE_NAME_MAXIMUM_LENGTH) {
      const tokens = string.split(".");
      const extensionIncludingDot = tokens.length <= 1 ? "" : "." + tokens[1];
      string = string.substring(0, FILE_NAME_MAXIMUM_LENGTH - extensionIncludingDot.length) + extensionIncludingDot;
    }
    return string;
  }

  fileName = removeAccents(fileName);
  fileName = removeSpecialCharacters(fileName);
  fileName = truncate(fileName);
  return fileName;
}

function convertImageQuality(
  image,
  mimeType,
  color,
  quality,
  destinationWidth,
  destinationHeight,
  sourceX,
  sourceY,
  sourceWidth,
  sourceHeight
) {
  const canvas = document.createElement("canvas");
  canvas.width = destinationWidth;
  canvas.height = destinationHeight;
  const context = canvas.getContext("2d");
  if (color !== undefined) {
    context.fillStyle = color;
    context.fillRect(0, 0, destinationWidth, destinationHeight);
  }
  context.drawImage(image, sourceX, sourceY, sourceWidth, sourceHeight, 0, 0, canvas.width, canvas.height);
  return canvas.toDataURL(mimeType, quality / 100);
}

function increaseFileNameVersion(fileName) {
  // We do not need to increase the file name version, since the server side is in charge of this
  return fileName;

  // const tokens = fileName.split(".");
  // let filePrefix = tokens[0];
  // const extensionIncludingDot = tokens.length <= 1 ? "" : "." + tokens[1];
  // const match = filePrefix.match(/^(.*)-(\d+)$/);
  // let root;
  // let ordinal;
  // if (match !== null) {
  //   root = match[1];
  //   ordinal = parseInt(match[2]) + 1;
  // } else {
  //   root = filePrefix;
  //   ordinal = 0;
  // }
  // const ordinalSuffix = "-" + ordinal;
  // const idealFilePrefix = root + ordinalSuffix;
  // if (idealFilePrefix.length <= FILE_NAME_MAXIMUM_LENGTH - extensionIncludingDot.length) {
  //   return idealFilePrefix + extensionIncludingDot;
  // } else {
  //   root = root.substring(0, FILE_NAME_MAXIMUM_LENGTH - extensionIncludingDot.length - ordinalSuffix.length);
  //   return root + ordinalSuffix + extensionIncludingDot;
  // }
}

function computeFileNameFromUrl(url, withExtension, excludedValue) {
  if (!url) {
    return "";
  }
  function getName(string) {
    if (withExtension === true) {
      return string;
    }
    const index = string.lastIndexOf(".");
    if (index === -1) {
      return string;
    } else if (index >= string.length - 1) {
      return string.substring(0, string.length - 1);
    } else {
      return string.substring(0, index);
    }
  }

  // We turn the "\" into "/", just in case (especially if we are running on Windows)
  const pathSeparator = "/";
  url = url.replace(new RegExp("\\\\", "g"), pathSeparator);
  const tokens = url.split(pathSeparator);
  if (tokens.length < 2) {
    return sanitizeFileName(getName(url));
  }
  if (excludedValue !== undefined && tokens[tokens.length - 1] === excludedValue) {
    return tokens[tokens.length - 2];
  } else {
    const pathNameTokens = new URL(url).pathname.split("/");
    return sanitizeFileName(getName(pathNameTokens[pathNameTokens.length - 1]));
  }
}

function isDataUrl(string) {
  return string?.startsWith("data:");
}

/**
 * @param {Blob} blob the blob for which the MIME type must be computed
 * @param skipBlobType skips the blob.type resolving, forcing the computation with magic numbers
 * @returns {Promise<String>} the MIME type corresponding to the provided blob
 * @throws {Error} if the MIME type could not be properly computed
 */
function fromBlobToMimeType(blob, skipBlobType = false) {
  return new Promise((resolve, reject) => {
    if (blob.type !== "" && blob.type !== "application/octet-stream" && skipBlobType === false) {
      resolve(blob.type);
    } else {
      const fileReader = new FileReader();
      fileReader.onerror = () => {
        fileReader.onerror = null;
        fileReader.onloadend = null;
        reject(new Error(i18n.t("error.compute.blobType", { type: blob.type })));
      };
      fileReader.onloadend = event => {
        fileReader.onerror = null;
        fileReader.onloadend = null;
        // noinspection JSCheckFunctionSignatures
        const array = new Uint8Array(event.target.result);
        let header = "";
        for (let index = 0; index < array.length; index++) {
          const string = array[index].toString(16);
          header += (string.length < 2 ? "0" : "") + string;
        }
        header = header.toUpperCase();
        console.debug("The header of the blob with type '" + blob.type + "' is '" + header + "'");

        let mimeType;

        switch (header) {
          case "FFD8FFDB":
          case "FFD8FFE0":
          case "FFD8FFEE":
          case "FFD8FFE1":
          case "FFD8FFE2":
          case "FFD8FFED":
            mimeType = "image/jpeg";
            break;
          case "89504E47":
            mimeType = "image/png";
            break;
          case "52494646":
            mimeType = "image/webp";
            break;
          case "47494638":
            mimeType = "image/gif";
            break;
          case "4F54544F":
            // OTF
            mimeType = "font/otf";
            break;
          case "774F4646":
          case "1F8B0800":
            // WOFF
            mimeType = "font/woff";
            break;
          case "774F4632":
            // WOFF2
            mimeType = "font/woff2";
            break;
          case "00010000":
            // TTF
            mimeType = "font/ttf";
            break;
          case "74746366":
            // TTC
            mimeType = "font/collection";
            break;
          case "5C590000":
          case "0B0D0100":
          case "EB380000":
            // EOT
            mimeType = "application/vnd.ms-fontobject";
            break;
          case "00000018":
          case "00000020":
          case "0000001C":
            mimeType = "video/mp4";
            break;
          case "1A45DFA3":
            mimeType = "video/webm";
            break;
          default:
            // TODO do something to verify the integrity of the JSON somwhere.
            if (blob.type === "application/json") {
              mimeType = blob.type;
            }
        }
        if (mimeType === undefined) {
          return reject(new Error(i18n.t("error.compute.blobMIMEType", { type: blob.type, header: header })));
        }
        console.debug("The MIME type '" + mimeType + "' was computed for the blob with type '" + blob.type + "'");
        resolve(mimeType);
      };
      fileReader.readAsArrayBuffer(blob.slice(0, 4));
    }
  });
}

function fromBase64DataToDataUrl(mimeType, base64Data) {
  return "data:" + mimeType + ";base64," + base64Data;
}

function fromBinaryStringToMimeType(binaryStringValue) {
  const tokens = binaryStringValue.split(":");
  return tokens[1];
}

function fromBinaryStringToBlob(binaryStringValue) {
  const tokens = binaryStringValue.split(":");
  const mimeType = tokens[1];
  const base64 = tokens[2];
  return fromBase64DataToBlob(base64, mimeType);
}

function fromBinaryStringValueToDataUrl(binaryStringValue) {
  const tokens = binaryStringValue.split(":");
  const mimeType = tokens[1];
  const base64 = tokens[2];
  return fromBase64DataToDataUrl(mimeType, base64);
}

function fromBinaryStringValueToName(binaryStringValue) {
  const tokens = binaryStringValue.split(":");
  return decodeURIComponent(tokens[0]);
}

function fromDataUrlToBase64Data(dataUrl) {
  return dataUrl.split(";")[1].split(",")[1];
}

function fromDataUrlToMimeType(dataUrl) {
  return dataUrl.split(";")[0].substring(5);
}

function toBinaryObject(binaryStringValue) {
  const tokens = binaryStringValue.split(":");
  return { name: tokens[0], mimeType: tokens[1], data: tokens[2] };
}

function toBinaryString(fileName, mimeType, dataUrlOrBase64Data, isDataUrl) {
  const data = isDataUrl === true ? fromDataUrlToBase64Data(dataUrlOrBase64Data) : dataUrlOrBase64Data;
  return sanitizeFileName(fileName) + ":" + mimeType + ":" + data;
}

// noinspection JSUnusedGlobalSymbols
function fromNameAndDataUrlToBinaryString(fileName, dataUrl) {
  return fromDataUrlToBinaryString(fileName, fromDataUrlToMimeType(dataUrl), dataUrl);
}

function fromBase64DataToBinaryString(fileName, mimeType, base64Data) {
  return toBinaryString(fileName, mimeType, base64Data, false);
}

function fromDataUrlToBinaryString(fileName, mimeType, dataUrl) {
  return toBinaryString(fileName, mimeType, dataUrl, true);
}

async function fromUrlToBinaryObject(url, downloader, onBlob) {
  const blob = await downloader(url);
  if (onBlob !== undefined) {
    onBlob(blob);
  }
  // We compute the MIME type all the same, because the built-in "fetch" method on which the "urlToBlob()" function relies, is not always accurate and right on that regard
  let mimeType;
  try {
    mimeType = await fromBlobToMimeType(blob);
  } catch (error) {
    throw new Error(i18n.t("error.compute.urlMIMEType", { url: url, reason: error }));
  }
  try {
    const base64Data = await fromBlobToBase64Data(blob);
    const fileName = computeFileNameFromUrl(url, true);
    return {
      name: fileName,
      mimeType: mimeType,
      data: base64Data,
    };
  } catch (error) {
    throw "Could not extract the data from the asset at URL '" + url + "'";
  }
}

async function fromUrlToBinaryString(url, downloader) {
  const binaryObject = await fromUrlToBinaryObject(url, downloader);
  return fromBase64DataToBinaryString(binaryObject.name, binaryObject.mimeType, binaryObject.data);
}

function fromBase64DataToBlob(base64Data, mimeType) {
  // Taken from https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
  const sliceSize = 512;
  const byteCharacters = window.atob(base64Data);
  let byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let index = 0; index < slice.length; index++) {
      byteNumbers[index] = slice.charCodeAt(index);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: mimeType });
}

async function fromBlobToBase64Data(blob) {
  const dataUrl = await fromBlobToDataUrl(blob);
  return fromDataUrlToBase64Data(dataUrl);
}

async function fromBlobToDataUrl(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = () => {
      reject();
    };
    reader.onload = event => {
      resolve(event.target.result);
    };
    reader.readAsDataURL(blob);
  });
}

function fromDataUrlToBlob(dataUrl) {
  const tokens = dataUrl.split(";");
  return fromBase64DataToBlob(tokens[1].split(",")[1], tokens[0].substring(5));
}

function extractBinaryAttributes(key, value) {
  const tokens = value.split(":");
  if (tokens.length !== 3) {
    console.error("Cannot handle the input with binary key '" + key + "', because its form is invalid");
    return undefined;
  }
  return {
    key: key.substr(0, key.length - BINARY_SUFFIX.length),
    name: tokens[0],
    mimeType: tokens[1],
    blob: fromBase64DataToBlob(tokens[2], tokens[1]),
  };
}

async function fromFileToJSON(file) {
  return new Promise((resolve, reject) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = function (event) {
        const jsonObj = JSON.parse(event.target.result);
        resolve(jsonObj);
      };
      reader.onerror = function (error) {
        reject(error);
      };
      reader.readAsText(file);
    } else {
      reject(new Error(i18n.t("error.undefinedError")));
    }
  });
}

async function fromFileToDataUrl(file) {
  return new Promise((resolve, reject) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = function (event) {
        resolve(event.target.result);
      };
      reader.onerror = function (error) {
        reject(error);
      };
      reader.readAsDataURL(file);
    } else {
      reject(new Error(i18n.t("error.undefinedError")));
    }
  });
}

async function computeImage(urlOrDataUrl, getBlobViaCorsProxy) {
  return new Promise((resolve, reject) => {
    let errorsCount = 0;
    const image = new Image();
    image.onerror = () => {
      if (errorsCount >= 1 || isDataUrl(urlOrDataUrl) === true) {
        reject(new Error(i18n.t("error.compute.invalidImage")));
      } else {
        errorsCount++;
        // The issue may be linked to a CORS restriction
        getBlobViaCorsProxy(urlOrDataUrl)
          .then(blob => {
            const fileReader = new FileReader();
            fileReader.onerror = () => {
              fileReader.onerror = fileReader.onload = null;
              reject();
            };
            fileReader.onload = event => {
              fileReader.onerror = fileReader.onload = null;
              // noinspection JSValidateTypes
              image.src = event.target.result;
            };
            fileReader.readAsDataURL(blob);
          })
          .catch(error => {
            // We only nullify the listeners here, because in the case of a new attempt, because of CORS restriction, we still need them
            image.onerror = image.onload = null;
            reject(error);
          });
      }
    };
    image.onload = () => {
      image.onerror = image.onload = null;
      resolve(image);
    };
    image.crossOrigin = "anonymous";
    image.src = urlOrDataUrl;
  });
}

async function loadImage(urlOrDataUrl) {
  return computeImage(urlOrDataUrl, Api.useProxy);
}

function visitInputs(inputs, visitor) {
  function handleProperties(properties) {
    const keys = Object.keys(properties);
    for (const key of keys) {
      const value = properties[key];
      if (value instanceof Object) {
        handleProperties(value);
      } else if (typeof value === "string") {
        visitor(properties, key, value);
      }
    }
  }

  handleProperties(inputs);
}

async function computeBinaryObject(url, onBlob) {
  return fromUrlToBinaryObject(url, async _url => (await urlToBlob(_url)).blob, onBlob);
}

async function computeUiValueFromUrlOrBinaryString(urlOrBinaryString) {
  try {
    let value = {};
    if (typeof urlOrBinaryString === "string") {
      value.url = urlOrBinaryString;
      value.urlOrDataUrl = urlOrBinaryString;
      const { name, mimeType } = await computeBinaryObject(urlOrBinaryString, blob => {
        value.size = blob.size;
      });
      value.name = name;
      value.mimeType = mimeType;
    } else if (urlOrBinaryString?.bin) {
      value = toBinaryObject(urlOrBinaryString.bin);
      value.urlOrDataUrl = fromBase64DataToDataUrl(value.mimeType, value.data);
      value.size = window.atob(value.data).length;
    }
    return value;
  } catch (error) {
    console.debug("An error occured while decoding the URL / Binary data", error);
    throw new Error(error.message);
  }
}
function computeImageWeight(dataUrl) {
  return window.atob(fromDataUrlToBase64Data(dataUrl)).length;
}

export {
  BINARY_SUFFIX,
  META_SUFFIX,
  computeBinaryObject,
  computeFileNameFromUrl,
  computeImage,
  computeImageWeight,
  computeUiValueFromUrlOrBinaryString,
  convertImageQuality,
  extractBinaryAttributes,
  fromBase64DataToBinaryString,
  fromBase64DataToBlob,
  fromBase64DataToDataUrl,
  fromBinaryStringToBlob,
  fromBinaryStringToMimeType,
  fromBinaryStringValueToDataUrl,
  fromBinaryStringValueToName,
  fromBlobToBase64Data,
  fromBlobToDataUrl,
  fromBlobToMimeType,
  fromDataUrlToBase64Data,
  fromDataUrlToBinaryString,
  fromDataUrlToBlob,
  fromDataUrlToMimeType,
  fromFileToDataUrl,
  fromFileToJSON,
  fromNameAndDataUrlToBinaryString,
  fromUrlToBinaryObject,
  fromUrlToBinaryString,
  increaseFileNameVersion,
  isDataUrl,
  loadImage,
  sanitizeFileName,
  toBinaryObject,
  visitInputs,
};
