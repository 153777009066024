import React from "react";
import { Typography, Select } from "@ogury/design-system";
import {
  BINARY_SUFFIX,
  fromBinaryStringToBlob,
  fromBinaryStringToMimeType,
  fromBinaryStringValueToName,
  fromUrlToBinaryString,
  META_SUFFIX,
} from "~/util/InputsComputer";
import { isUrl, urlToBlob } from "~/util/Utils";
import { RadioGroup } from "components";
import i18n from "i18next";
import { InputTypes } from "./ModelFactory";
import { SCHEMA_INPUTS_FIELDS } from "~/util/ModelConstants";
import historyStore, { HistoryType } from "~/services/HistoryStore";
import Core from "~/services/Core";
import References from "~/util/References";
import Debug from "~/util/Debug";
import { isInternalAsset, LOG_INPUTS_CONDITIONS } from "~/util/Constant";
import { isObject } from "~/util/Helpers";
import Api from "~/util/Api";
import unitService, { syntheticUnitId } from "~/services/UnitService";

// The input path "dot" separator
const dotSeparator = ".";

/**
 * @param {String} string the value to asset
 * @return {boolean} <code>true</code> if and only if the provided value is a number
 */
function isNumber(string) {
  return parseInt(string, 10).toString() === string;
}

// eslint-disable-next-line no-unused-vars
function getCollectionChildren(inputInstance) {
  const children = {};
  // TODO implement conditional rendering for collections
  /*   InputStore.getAll().map((childInputInstance) => {
      const childId = childInputInstance.getId();
      if (childId.startsWith(id) === true) {
        const suffix = childId.substring(id.length);
        const tokens = suffix.split(dotSeparator);
        if (tokens.length >= 3 && tokens[0] === "" && isNumber(tokens[1]) === true) {
          const ordinal = tokens[1];
          let child = children[ordinal];
          if (child === undefined) {
            child = {};
            children[ordinal] = child;
          }
          let actualChildInstance = childInputInstance;
          if (childInputInstance.isGroupOrCollection() === true && childInputInstance.isCollection() === true) {
            actualChildInstance = getCollectionChildren(actualChildInstance);
          }
          child[suffix.substring(2 * dotSeparator.length + ordinal.length)] = actualChildInstance;
        }
      }
    }); */
  return Object.keys(children)
    .sort((key1, key2) => {
      const number1 = parseInt(key1, 10);
      const number2 = parseInt(key2, 10);
      if (number1 < number2) {
        return -1;
      }
      if (number1 > number2) {
        return 1;
      }
      return 0;
    })
    .map(key => children[key]);
}

/**
 * Resolves an input path from a given input, given a path relative to that input.
 *
 * @param {String} id the current input path, as a "pointed" expression, i.e. with descendent input paths split with the "pathSeparator" (which should be equal to "."). When a number is set between two "pathSeparator", this means that the input belongs to a collection, and it represents its ordinal position within the collection
 * @param {String} path the relative path to the input to resolve, as a "folderish" expression, i.e. with ancestors input path split with the "/" separator. If the path starts with a "/", it is an absolute path (expressed from the inputs virtual root). A "." means the currently resolved path, ".." means the direct ancestor of the currently resolved path
 * @param {boolean} [doNotEscapeCollections] whether the collection ordinals potentially present within the <code>id</code> parameter should be stripped, defaults implicitly to <code>false</code>
 * @returns {String} the resolved path, expressed in absolute, as a "pointed" expression
 */
function resolvePath(id, path, doNotEscapeCollections = false) {
  const pathSeparator = "/";

  function inner(idTokens, pathTokens, isFirst) {
    if (pathTokens.length > 0) {
      const firstPathToken = pathTokens[0];
      if (firstPathToken === "") {
        // The path is absolute
        idTokens.length = 0;
        idTokens.push(References.ROOT_INPUT_ID);
      } else if (firstPathToken === dotSeparator) {
        // The path first element of the path designates the current node (".")
        if (isFirst === true) {
          // We move to the upper node
          idTokens.pop();
        }
      } else if (firstPathToken === dotSeparator + dotSeparator) {
        // The path first element of the path designates the parent node ("..")
        if (isNumber(idTokens.pop()) === true) {
          // The id reaches an element within a collection ("N"), that we need to skip in that situation
          idTokens.pop();
        }
        if (isFirst === true) {
          if (isNumber(idTokens.pop()) === true) {
            // The id reaches an element within a collection ("N"), that we need to skip in that situation
            idTokens.pop();
          }
        }
      } else {
        idTokens.push(firstPathToken);
      }
      pathTokens.splice(0, 1);
      return inner(idTokens, pathTokens, false);
    }
    return idTokens.join(dotSeparator);
  }

  if (LOG_INPUTS_CONDITIONS === true) {
    console.debug(
      `Resolving the path with id '${id}' and path '${path}'${
        doNotEscapeCollections !== true ? " while not escaping the collections" : ""
      }`
    );
  }
  const idTokens = id.split(dotSeparator);
  const filteredIdTokens =
    doNotEscapeCollections === true
      ? idTokens
      : idTokens.filter(
          token =>
            // In the path, in case of an input belonging to a collection, a ".N." (N being a number) is inserted, which we strip, because it adds an extra path separator
            isNumber(token) === false
        );
  const inputPath = inner(filteredIdTokens, path.split(pathSeparator), true);
  if (LOG_INPUTS_CONDITIONS === true) {
    console.debug(`The resolved path is '${inputPath}'`);
  }
  return inputPath;
}

function checkWhenCondition(inputId, when) {
  const or = "or";
  const and = "and";
  const equals = "equals";
  const different = "different";
  const contains = "contains";
  const match = "match";

  function conditionMatch(inputPath, operator, inputValue) {
    const targetId = resolvePath(inputId, inputPath, true);

    let strippedTargetId;
    let isCountSuffix;
    const countSuffix = "[count]";
    if (targetId.endsWith(countSuffix) === true) {
      strippedTargetId = targetId.substring(0, targetId.length - countSuffix.length);
      isCountSuffix = true;
    } else {
      strippedTargetId = targetId;
      isCountSuffix = false;
    }

    const targetInstance = Core.getDeepNodeById(strippedTargetId, true);

    let result;
    if (targetInstance === undefined) {
      Debug.warn(
        `The input with id ${inputId} and path '${inputPath}' does not correspond to an existing input`,
        targetInstance
      );
      result = true;
    } else {
      const targetValue = targetInstance.getValue();
      let value;
      // TODO check the following lines with a condition in a collection
      if (isCountSuffix === true) {
        if (targetInstance.isCollection() === true) {
          value = getCollectionChildren(targetInstance).length;
        } else {
          throw new Error(i18n.t("error.condition.countSuffix", { countSuffix }));
        }
      } else {
        value = targetValue;
      }
      if (operator === equals) {
        result = inputValue === null ? value === undefined : inputValue === value;
      } else if (operator === different) {
        result = inputValue === null ? value !== undefined : inputValue !== value;
      } else if (operator === contains) {
        if (value === undefined) {
          result = false;
        } else if (typeof value === "string") {
          result = value.indexOf(inputValue) !== -1;
        } else {
          throw new Error(i18n.t("error.condition.contains", { contains }));
        }
      } else if (operator === match) {
        if (value === undefined) {
          result = false;
        } else if (typeof value === "string") {
          result = value.match(inputValue) !== null;
        } else {
          throw new Error(i18n.t("error.condition.match", { match }));
        }
      } else {
        throw new Error(i18n.t("error.condition.operator", { operator }));
      }
    }
    if (LOG_INPUTS_CONDITIONS === true) {
      console.debug(
        `The assessment over the input with id '${inputId}' and path '${inputPath}' with operator '${operator}', reference value '${inputValue}' returned the ${result} value`
      );
    }
    return result;
  }

  function assessResult(type, result) {
    if (result === false && (type === null || type === and)) {
      return false;
    }
    if (result === true && type === or) {
      return true;
    }
    return undefined;
  }

  function conditionsMatch(type, conditions) {
    for (let index = 0; index < conditions.length; index++) {
      const condition = conditions[index];
      const result = conditionMatch(
        condition[SCHEMA_INPUTS_FIELDS.ConditionsWhenInputPath],
        condition[SCHEMA_INPUTS_FIELDS.ConditionsWhenOperator],
        condition[SCHEMA_INPUTS_FIELDS.ConditionsWhenInputValue]
      );
      const assessedResult = assessResult(type, result);
      if (assessedResult !== undefined) {
        return assessedResult;
      }
    }
    return type === undefined || type === and;
  }

  function statementMatch(statement) {
    const type = statement[SCHEMA_INPUTS_FIELDS.ConditionsWhenType];
    const conditions = statement[SCHEMA_INPUTS_FIELDS.ConditionsWhenConditions];
    const statements = statement[SCHEMA_INPUTS_FIELDS.ConditionsWhenStatements];
    let result;
    if (conditions !== undefined) {
      result = conditionsMatch(type, conditions);
      if (statements === undefined) {
        return result;
      }
      if (result === true && type === or) {
        return result;
      }
    }
    if (statements !== undefined) {
      for (let index = 0; index < statements.length; index++) {
        const childStatement = statements[index];
        result = statementMatch(childStatement);
        const assessedResult = assessResult(type, result);
        if (assessedResult !== undefined) {
          return assessedResult;
        }
      }
    }
    return result;
  }

  const inputPath = when[SCHEMA_INPUTS_FIELDS.ConditionsWhenInputPath];
  try {
    if (inputPath !== undefined) {
      return conditionMatch(
        inputPath,
        when[SCHEMA_INPUTS_FIELDS.ConditionsWhenOperator],
        when[SCHEMA_INPUTS_FIELDS.ConditionsWhenInputValue]
      );
    }
    return statementMatch(when);
  } catch (error) {
    console.error(
      `Error while computing conditions, targeted value not available for '${inputId}' with path '${inputPath}'`,
      error
    );
    return true;
  }
}

export default class Model {
  static Natures = {
    Ratio: "ratio",
  };

  static Kind = {
    Synthetic: "synthetic",
  };

  static imageMimeTypes() {
    return [
      "image/jpeg",
      "image/png",
      "image/webp",
      "image/gif",
      "image/x-icon",
      "image/bmp",
      "image/svg",
      "image/tiff",
    ];
  }

  static videoMimeTypes() {
    return ["video/mp4", "video/webm"];
  }

  static mimeTypeToFormat(mimeType) {
    switch (mimeType) {
      case "image/jpeg":
        return "JPEG";
      case "image/png":
        return "PNG";
      case "image/webp":
        return "WEBP";
      case "image/gif":
        return "GIF";
      case "font/otf":
      case "application/x-font-opentype":
        return "OTF";
      case "font/woff":
        return "WOFF";
      case "font/woff2":
      case "application/font-woff2":
        return "WOFF2";
      case "font/ttf":
      case "application/x-font-truetype":
      case "application/font-sfnt":
        return "TTF";
      case "font/collection":
        return "TTC";
      case "application/vnd.ms-fontobject":
        return "EOT";
      case "video/x-msvideo":
        return "AVI";
      case "video/mp4":
        return "MP4";
      case "video/mkv":
        return "MKV";
      case "video/quicktime":
        return "MOV";
      case "video/ogg":
        return "OGG";
      case "video/webm":
        return "WEBM";
      default:
        return undefined;
    }
  }

  static formatsToMimeTypes(formats) {
    const mimeTypes = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const format of formats) {
      let mimeType;
      switch (format) {
        case "JPEG":
          mimeType = "image/jpeg";
          break;
        case "PNG":
          mimeType = "image/png";
          break;
        case "WEBP":
          mimeType = "image/webp";
          break;
        case "GIF":
          mimeType = "image/gif";
          break;
        case "OTF":
          mimeType = "font/otf";
          break;
        case "WOFF":
          mimeType = "font/woff";
          break;
        case "WOFF2":
          mimeType = "font/woff2";
          break;
        case "TTF":
          mimeType = "font/ttf";
          break;
        case "TTC":
          mimeType = "font/collection";
          break;
        case "EOT":
          mimeType = "application/vnd.ms-fontobject";
          break;
        case "AVI":
          mimeType = "video/x-msvideo";
          break;
        case "M4V":
          mimeType = "video/mp4";
          break;
        case "MKV":
          mimeType = "video/mkv";
          break;
        case "MOV":
          mimeType = "video/quicktime";
          break;
        case "MP4":
          mimeType = "video/mp4";
          break;
        case "OGG":
          mimeType = "video/ogg";
          break;
        case "WEBM":
          mimeType = "video/webm";
          break;
        default:
          mimeType = undefined;
      }
      mimeTypes.push(mimeType);
    }
    return mimeTypes;
  }

  static formatsToFileExtensions(formats) {
    let extensions = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const format of formats) {
      let formatExtensions;
      switch (format) {
        case "JPEG":
          formatExtensions = ["jpeg", "jpg"];
          break;
        case "PNG":
          formatExtensions = ["png"];
          break;
        case "WEBP":
          formatExtensions = ["webp"];
          break;
        case "GIF":
          formatExtensions = ["gif"];
          break;
        case "OTF":
          formatExtensions = ["otf"];
          break;
        case "WOFF":
          formatExtensions = ["woff"];
          break;
        case "WOFF2":
          formatExtensions = ["woff2"];
          break;
        case "TTF":
          formatExtensions = ["ttf"];
          break;
        case "TTC":
          formatExtensions = ["ttc"];
          break;
        case "EOT":
          formatExtensions = ["eot"];
          break;
        case "MP4":
          formatExtensions = ["mp4"];
          break;
        case "WEBM":
          formatExtensions = ["webm"];
          break;
        default:
          formatExtensions = undefined;
      }
      extensions = extensions.concat(formatExtensions);
    }
    return extensions;
  }

  static getMaximumBytesConstraints(model) {
    const constraints = model.getConstraints();
    return constraints === undefined ? undefined : constraints[SCHEMA_INPUTS_FIELDS.ConstraintsRatioMaximumBytes];
  }

  static getFormatsConstraints(model) {
    const constraints = model.getConstraints();
    return constraints === undefined ? undefined : constraints[SCHEMA_INPUTS_FIELDS.ConstraintsFormats];
  }

  static getConstraintsOnInputPathReferenceInstance(model, isImage) {
    const constraints = model.getConstraints();
    const inputPath = constraints === undefined ? undefined : constraints[SCHEMA_INPUTS_FIELDS.ConstraintsOnInputPath];
    const referenceInstance = model.resolveReferenceInstance(inputPath);
    if (referenceInstance !== undefined) {
      if (
        referenceInstance.getSemanticsNature() !== Model.Natures.Ratio &&
        (isImage === false || referenceInstance.getType() !== InputTypes.Image)
      ) {
        Debug.warn(
          `The constraints input path does not correspond to a ratio${isImage === false ? "" : " or an image"}`
        );
        return undefined;
      }
    }
    return referenceInstance;
  }

  static getRatioConstraints(model) {
    const constraints = model.getConstraints();
    const ratioConstraints = constraints === undefined ? undefined : constraints[SCHEMA_INPUTS_FIELDS.ConstraintsRatio];
    const ratioReferenceInstance = Model.getConstraintsOnInputPathReferenceInstance(model);
    const experienceRatio = ratioReferenceInstance === undefined ? 1 : ratioReferenceInstance.getValue();
    const minimum =
      ratioConstraints === undefined || ratioConstraints[SCHEMA_INPUTS_FIELDS.ConstraintsRangeMinimum] === undefined
        ? undefined
        : ratioConstraints[SCHEMA_INPUTS_FIELDS.ConstraintsRangeMinimum] * experienceRatio;
    const maximum =
      ratioConstraints === undefined || ratioConstraints[SCHEMA_INPUTS_FIELDS.ConstraintsRangeMaximum] === undefined
        ? undefined
        : ratioConstraints[SCHEMA_INPUTS_FIELDS.ConstraintsRangeMaximum] * experienceRatio;
    return { minimum, maximum };
  }

  constructor(referenceInput, id) {
    this.id = id;
    this.errors = [];
    this.showError = false;
    this.uiDisabled = false;
    this.processing = false;
    this.referenceInput = referenceInput;
    const defaultValue = this.getDefaultValue();
    if (defaultValue !== undefined) {
      this.value = defaultValue;
    }
    const conditions = this.getConditions();
    this.enabled = !(conditions !== undefined && conditions[SCHEMA_INPUTS_FIELDS.ConditionsOptional] === true);
    this.hidden = conditions !== undefined && conditions[SCHEMA_INPUTS_FIELDS.ConditionsHidden] === true;
  }

  getId() {
    return this.id;
  }

  getParentId() {
    const splittedId = this.getId().split(".");
    if (splittedId.length >= 2) {
      splittedId.pop();
      return splittedId.join(".");
    }
    return this.id;
  }

  getType() {
    return this.getReferenceInput().type;
  }

  getSuperType() {
    return References.INPUT_SUPER_TYPE.FINAL;
  }

  isComposite() {
    return this.getSuperType() === References.INPUT_SUPER_TYPE.COMPOSITE;
  }

  isGroup() {
    return this.getSuperType() === References.INPUT_SUPER_TYPE.GROUP;
  }

  isCollection() {
    return this.getSuperType() === References.INPUT_SUPER_TYPE.COLLECTION;
  }

  isCollectionChild() {
    return this.getSuperType() === References.INPUT_SUPER_TYPE.COLLECTION_CHILD;
  }

  isFinal() {
    return this.getSuperType() === References.INPUT_SUPER_TYPE.FINAL;
  }

  isUiDisabled() {
    return this.uiDisabled;
  }

  getValidator() {
    const undefinedError = {
      type: "undefined",
      message: i18n.t("error.undefinedError"),
    };

    const urlFormatError = {
      type: "wrong_format",
      message: i18n.t("error.invalid.URL"),
    };

    return {
      isDefined: (value, errors) => {
        if (value == null) {
          errors.push(undefinedError);
          return false;
        }
        return true;
      },
      isNotEmpty: (value, errors) => {
        if (value == null || value.trim() === "") {
          errors.push(undefinedError);
          return false;
        }
        return true;
      },
      isUrl: (value, errors) => {
        if (isUrl(value) === false) {
          errors.push(urlFormatError);
          return false;
        }
        return true;
      },
    };
  }

  getDefaultValue() {
    return this.getReferenceInput()[SCHEMA_INPUTS_FIELDS.Default];
  }

  isOptional() {
    const conditions = this.getConditions();
    return conditions !== undefined && conditions[SCHEMA_INPUTS_FIELDS.ConditionsOptional] === true;
  }

  setProcessing(processing) {
    this.processing = processing;
  }

  isEnabled() {
    return this.enabled;
  }

  setEnabled(enabled, onlyAssign, persistHistory = true) {
    if (persistHistory) {
      this.updateHistory(HistoryType.Enabled, enabled);
    }
    this.enabled = enabled;
    if (onlyAssign !== true) {
      this.regenerateUiStore();
    }
  }

  getReferenceInput() {
    return this.referenceInput;
  }

  getReferenceId() {
    return this.getReferenceInput().id;
  }

  getUi() {
    return this.getReferenceInput()[SCHEMA_INPUTS_FIELDS.Ui];
  }

  getConstraints() {
    return this.getReferenceInput()[SCHEMA_INPUTS_FIELDS.Constraints];
  }

  getConditions() {
    return this.getReferenceInput()[SCHEMA_INPUTS_FIELDS.Conditions];
  }

  getSemantics() {
    return this.getReferenceInput()[SCHEMA_INPUTS_FIELDS.Semantics];
  }

  getSemanticsKind() {
    const semantics = this.getSemantics();
    return semantics === undefined ? undefined : semantics[SCHEMA_INPUTS_FIELDS.SemanticsKind];
  }

  getSemanticsNature() {
    const semantics = this.getSemantics();
    return semantics === undefined ? undefined : semantics[SCHEMA_INPUTS_FIELDS.SemanticsNature];
  }

  getStyle() {
    return this.getReferenceInput()[SCHEMA_INPUTS_FIELDS.Style];
  }

  // noinspection JSUnusedGlobalSymbols
  getSemanticsReferenceInput() {
    const semantics = this.getSemantics();
    const inputPath = semantics === undefined ? undefined : semantics[SCHEMA_INPUTS_FIELDS.SemanticsReferenceInputPath];
    return this.resolveReferenceInstance(inputPath);
  }

  getAcceptableValues() {
    return this.getReferenceInput()[SCHEMA_INPUTS_FIELDS.AcceptableValues];
  }

  resolveReferenceInstance(path) {
    if (path === undefined) {
      return undefined;
    }
    const inputId = resolvePath(this.getId(), path, true);
    const referenceInstance = Core.getDeepNodeById(inputId, true);
    if (referenceInstance === undefined) {
      console.warn(
        `Cannot find the input instance reference with id '${inputId}' bound to the input with id '${this.getId()}'`
      );
    }
    return referenceInstance;
  }

  validateHiddenWhenConditions() {
    const conditions = this.getConditions();
    const hiddenWhen = conditions === undefined ? undefined : conditions[SCHEMA_INPUTS_FIELDS.ConditionsHiddenWhen];
    if (hiddenWhen !== undefined) {
      return checkWhenCondition(this.getId(), hiddenWhen);
    }
    return false;
  }

  validateRequiredWhenConditions() {
    const conditions = this.getConditions();
    const requiredWhen = conditions === undefined ? undefined : conditions[SCHEMA_INPUTS_FIELDS.ConditionsRequiredWhen];
    if (requiredWhen !== undefined) {
      return checkWhenCondition(this.getId(), requiredWhen);
    }
    return true;
  }

  resetErrors() {
    this.errors = [];
  }

  async validateConstraints() {
    this.getValidator().isDefined(this.getValue(), this.getErrors());
    if (this.getErrors()?.length > 0) {
      return false;
    }
    const additionalErrors = await this.computeConstraintsErrors();
    this.setErrors(this.getErrors().concat(additionalErrors));
    if (this.getErrors()?.length > 0) {
      Debug.warn(`A constraint on the input '${this.getId()}' is violated`);
    }
    return this.getErrors()?.length <= 0;
  }

  async computeConstraintsErrors() {
    return [];
  }

  shouldBeDisplayed() {
    return (
      this.validateRequiredWhenConditions() === true &&
      this.validateHiddenWhenConditions() === false &&
      this.hidden === false
    );
  }

  setRegenerateUiStore(regenerateUiStore) {
    this.regenerateUiStore = regenerateUiStore;
  }

  getAcceptableValue(value) {
    return this.getAcceptableValues()?.find(acceptableValue => acceptableValue.value === value);
  }

  iterateOverAcceptableValues(callback) {
    const acceptableValues = this.getAcceptableValues();
    if (acceptableValues !== undefined) {
      // The input has static values
      return acceptableValues.map(acceptableValueItem =>
        callback(
          acceptableValueItem[SCHEMA_INPUTS_FIELDS.AcceptableValuesLabel],
          acceptableValueItem[SCHEMA_INPUTS_FIELDS.AcceptableValuesValue],
          acceptableValueItem[SCHEMA_INPUTS_FIELDS.AcceptableValuesComment],
          acceptableValueItem[SCHEMA_INPUTS_FIELDS.AcceptableValuesIcon],
          acceptableValueItem[SCHEMA_INPUTS_FIELDS.AcceptableValuesIllustration]
        )
      );
    }
    return false;
  }

  async getAcceptableValuesOptions() {
    let options = [];
    this.iterateOverAcceptableValues(
      (acceptableLabel, acceptableValue, acceptableComment, acceptableIcon, acceptableIllustration) => {
        options.push({
          value: acceptableValue,
          label: acceptableLabel,
          comment: acceptableComment,
          icon: acceptableIcon,
          illustration: acceptableIllustration,
        });
      }
    );
    // Filter the ratios based on the unit selection
    if (this.getSemanticsNature() === Model.Natures.Ratio) {
      options = unitService.filterRatioOptions(options);
    }
    return options;
  }

  renderAcceptableValues(value, onChangeCallback) {
    const style = this.getStyle();
    const useSelect =
      style !== undefined && style[SCHEMA_INPUTS_FIELDS.StyleLayout] === SCHEMA_INPUTS_FIELDS.StyleLayoutCombo;
    let options = [];
    this.iterateOverAcceptableValues(
      (acceptableLabel, acceptableValue, acceptableComment, acceptableIcon, acceptableIllustration) => {
        options.push({
          value: acceptableValue,
          label: acceptableLabel,
          comment: acceptableComment,
          icon: acceptableIcon,
          illustration: acceptableIllustration,
        });
      }
    );

    if (useSelect === true) {
      return (
        <Select
          disabled={this.isUiDisabled()}
          name={this.getId()}
          options={options}
          value={value}
          onChange={onChangeCallback}
          width="40%"
        />
      );
    }
    return (
      <RadioGroup
        name={this.getId()}
        options={options}
        isHorizontal={
          style !== undefined &&
          style[SCHEMA_INPUTS_FIELDS.StyleOrientation] === SCHEMA_INPUTS_FIELDS.StyleOrientationHorizontal
        }
        value={value}
        onChange={onChangeCallback}
      />
    );
  }

  // eslint-disable-next-line no-unused-vars
  isValid(valueType, value, metas) {
    console.error(`Missing assessment in 'isValidValue()' for the input with type '${valueType}'`);
    return false;
  }

  getErrors() {
    return this.errors;
  }

  setErrors(errors) {
    this.errors = errors;
  }

  getValue() {
    // Targeted input is disabled, undefined is returned, as if no value exists.
    if (this.enabled === false) {
      return undefined;
    }
    return this.value;
  }

  getValueLabel() {
    return this.getAcceptableValue(this.value)?.label;
  }

  async updateHistory(type, current) {
    let previous = {};
    if (type === HistoryType.Value) {
      previous.value = this.getValue();
      previous.metas = await this.computeMetas();
    } else if (type === HistoryType.Enabled) {
      previous = this.isEnabled();
    }
    historyStore.push(this.getId(), type, previous, current);
  }

  async setValue(value, onlyAssign, metas, persistHistory = true) {
    Debug.log("Setting value : " + this.getValue() + "  of input with ID " + this.getId());
    this.setShowError(false);
    if (persistHistory) {
      await this.updateHistory(HistoryType.Value, { value, metas });
    }

    // This is strange, metas is passed but the method computeImportValue does not accept a second argument. But when removing it, the InputTabular breaks
    this.value = await this.computeImportValue(value, metas);

    if (onlyAssign !== true) {
      this.resetErrors();
      await this.validateConstraints();
      this.regenerateUiStore();
    }
  }

  async convertBinaryToTemporaryUrl(binaryValue) {
    const fileName = fromBinaryStringValueToName(binaryValue);
    const mimeType = fromBinaryStringToMimeType(binaryValue);
    const blob = fromBinaryStringToBlob(binaryValue);
    const file = new File([blob], fileName);
    const { url } = await Api.uploadTemporaryFile(mimeType, fileName, file);
    return { file, url };
  }

  async computeImportValue(value) {
    if (value?.bin) {
      try {
        value = (await this.convertBinaryToTemporaryUrl(value.bin)).url;
      } catch (error) {
        console.error("Can not convert the asset with ID " + this.getId() + " to URL. ", error);
        return value;
      }
    }
    return value;
  }

  async computeExportValue(value) {
    return value;
  }

  async import(value, metas) {
    if (value !== undefined) {
      await this.setValue(value, true, metas, false);
    }
    this.setEnabled(true, true, false);
  }

  async export(assetsBaseUrl = "", resolveAssets = false) {
    Debug.log("Start exporting input with id = '" + this.getId() + "'");
    let exportKey = this.getReferenceId();
    let exportObject = {};
    this.resetErrors();
    const binaryValue = await this.exportBinaryValue(assetsBaseUrl, resolveAssets, this.getValue());

    if (binaryValue !== undefined) {
      exportObject[exportKey + BINARY_SUFFIX] = binaryValue;
    } else {
      const value = this.getValue();
      if (value !== undefined) {
        const exportedValue = await this.computeExportValue(value, assetsBaseUrl, resolveAssets);
        if (exportedValue !== undefined) {
          exportObject[exportKey] = exportedValue;
        }
      }
    }
    const metas = await this.computeMetas();
    if (metas !== undefined) {
      exportObject[exportKey + META_SUFFIX] = metas;
    }
    return exportObject;
  }

  disableUi() {
    this.uiDisabled = true;
  }

  hasBinaryForm() {
    return false;
  }

  async exportBinaryValue(assetsBaseUrl, resolveAssets, value, forceExport = false) {
    Debug.log(
      "Exporting binary - With values assetsBaseUrl = '" +
        assetsBaseUrl +
        "' and resolveAssets = '" +
        resolveAssets +
        "' and value = '" +
        value
    );
    if (this.hasBinaryForm() === false) {
      return undefined;
    }
    if (value != null && value.bin != null) {
      return value.bin;
    }
    if (value == null) {
      return undefined;
    }
    Debug.log("Exporting binary - isInternalAsset = '" + isInternalAsset(value, assetsBaseUrl) + "'");
    if (!forceExport && (isInternalAsset(value, assetsBaseUrl) === false || resolveAssets === false)) {
      return undefined;
    }
    return fromUrlToBinaryString(value, async _url => (await urlToBlob(_url)).blob);
  }

  async computeMetas() {
    return;
  }

  renderConstraints() {
    return null;
  }

  renderComponent() {
    return () => {
      return <Typography.P2Regular>There's no input for the selected type.</Typography.P2Regular>;
    };
  }

  getFilename() {
    return undefined;
  }

  canBeDetached() {
    return false;
  }

  isShowError() {
    return this.showError;
  }

  setShowError(showError) {
    this.showError = showError;
  }

  shouldDisplayErrorBadge() {
    return this.getErrors().length >= 1 && this.isShowError();
  }

  clearValue() {
    this.setValue(undefined, false, undefined);
  }

  getUiData() {
    return {
      id: this.getId(),
      ui: this.getUi(),
      value: this.getValue(),
      valueLabel: this.getValueLabel(),
      showInLeftMenu: this.id === "root." + syntheticUnitId,
      optional: this.isOptional(),
      enabled: this.isEnabled(),
      display: this.shouldBeDisplayed(),
      superType: this.getSuperType(),
      errors: this.getErrors(),
      errorBadge: this.shouldDisplayErrorBadge(),
    };
  }

  generateUiTree() {
    const currentNode = Core.getDeepNodeById(this.getId());

    let tree = [{ ...this.getUiData(), inputs: [] }];

    function iterateThroughNodes(nodes, store) {
      nodes.map(node => {
        const instance = node.instance;
        const nodeUiData = instance.getUiData();
        if (node.inputs?.length >= 1) {
          nodeUiData.inputs = [];
          store.push(nodeUiData);
          return iterateThroughNodes(node.inputs, store.find(treeNode => treeNode.id === instance.getId()).inputs);
        } else {
          store.push(nodeUiData);
        }
      });
    }

    if (currentNode.inputs?.length >= 1) {
      iterateThroughNodes(currentNode.inputs, tree[0].inputs);
    }

    return tree;
  }

  async generateExportValues({ includeSelf = false, assetsBaseUrl, resolveAssets = false, showErrors = false }) {
    const currentNode = Core.getDeepNodeById(this.getId());
    const currentNodeReferenceId = this.getReferenceId();
    const currentNodeValue = this.getValue();

    let exportValues = {};
    let errors = [];
    const idsWithBinaryValues = [];

    exportValues[currentNodeReferenceId] = currentNodeValue;

    async function iterateThroughNodes(nodes, stack) {
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];
        const instance = node.instance;
        const nodeId = instance.getReferenceId();
        if (!instance.isEnabled()) {
          continue;
        }

        if (!instance.validateRequiredWhenConditions()) {
          continue;
        }

        if (instance.getSemanticsKind() === Model.Kind.Synthetic) {
          continue;
        }

        if (node.inputs?.length >= 1) {
          if (instance.isCollection()) {
            stack[nodeId] = [];
            for (let collection of node.inputs) {
              const collectionStack = {};
              await iterateThroughNodes(collection.inputs, collectionStack);
              stack[nodeId].push(collectionStack);
            }
            continue;
          }
          stack[nodeId] = {};
          await iterateThroughNodes(node.inputs, stack[nodeId]);
        } else {
          const exportObj = await instance.export(assetsBaseUrl, resolveAssets);
          await instance.validateConstraints();
          const instanceErrors = instance.getErrors();

          if (instanceErrors?.length >= 1) {
            Debug.error("Input with id '" + instance.getId() + "' generated an error while exporting ", instance);
          }
          if (instance.isEnabled() && instanceErrors?.length >= 1) {
            if (showErrors) {
              instance.setShowError(true);
            }
            errors = errors.concat(instanceErrors);
          }

          // This function is there to check if there is some "-bin" prefixes key in the exported values.
          // This is here to see if the preview can be computed or not, because the binary files can not be processed locally.
          function checkForBinaryPrefix(object) {
            return Object.keys(object).forEach(key => {
              if (key.includes(BINARY_SUFFIX)) {
                return idsWithBinaryValues.push(instance.getId());
              } else if (isObject(object[key])) {
                return checkForBinaryPrefix(object[key]);
              }
            });
          }

          checkForBinaryPrefix(exportObj);

          Object.keys(exportObj).forEach(key => {
            stack[key] = exportObj[key];
          });
        }
      }
    }

    if (currentNode.inputs) {
      exportValues[currentNodeReferenceId] = {};
      await iterateThroughNodes(currentNode.inputs, exportValues[currentNodeReferenceId]);
    }
    if (!includeSelf) {
      exportValues = exportValues[currentNodeReferenceId];
    }
    return { exportValues, errors, idsWithBinaryValues };
  }
}
