import React from "react";

const InputCompositeUi = ({ input, level, isRoot }) => {
  return <></>;
  /*  const rootReferenceInput = JSON.parse(JSON.stringify(input.getReferenceInput())); */
  /*  delete rootReferenceInput[SCHEMA_INPUTS_FIELDS.Type]; */
  /*  const referenceInput = Object.assign(rootReferenceInput, { [SCHEMA_INPUTS_FIELDS.Inputs]: input.referenceInputs }); */
  /*  return <CenterInput referenceInput={referenceInput} isRoot={isRoot} level={level} isHorizontal={false} />; */
};

export default InputCompositeUi;
