import { Button, Dropdown } from "@ogury/design-system";
import More2FillIcon from "remixicon-react/More2FillIcon";
import { useEffect, useRef, useState } from "react";

const defaultLanguage = "browserLanguage";
const Languages = [
  { label: "Default", value: defaultLanguage },
  { label: "French", value: "fr" },
  { label: "English", value: "en" },
  { label: "Spanish", value: "es" },
  { label: "Italian", value: "it" },
  { label: "German", value: "de" },
  { label: "Dutch", value: "nl" },
  { label: "Japanese", value: "ja" },
  { label: "Filipino", value: "fil" },
  { label: "Hindi", value: "hi" },
  { label: "Thai", value: "th" },
  { label: "Malay", value: "ms" },
  { label: "Chinese", value: "zh" },
  { label: "Indonesian", value: "id" },
  { label: "Danish", value: "da" },
  { label: "Finnish", value: "fi" },
  { label: "Norwegian", value: "no" },
  { label: "Portuguese", value: "pt" },
  { label: "Slovene", value: "sl" },
  { label: "Swedish", value: "sv" },
  { label: "Tamil", value: "ta" },
];

export default function PreviewOptions({ isLocal, onChange = () => {} }) {
  const [orientation, setOrientation] = useState("portrait");
  const [language, setLanguage] = useState(defaultLanguage);
  const initialized = useRef(false);

  useEffect(() => {
    if (language === defaultLanguage) {
      window.builderLanguage = undefined;
    } else {
      window.builderLanguage = language;
    }
  }, [language]);

  useEffect(() => {
    window.previewOrientation = orientation;
  }, [orientation]);

  useEffect(() => {
    if (initialized.current === true) {
      onChange();
    }
    initialized.current = true;
  }, [language, orientation]);

  const menuItems = [
    {
      key: "language",
      label: "Language",
      children: Languages.map(lang => {
        return {
          key: lang.value,
          label: lang.label,
          onClick: () => setLanguage(lang.value),
        };
      }),
    },
    ...(!isLocal
      ? [
          {
            key: "orientation",
            label: "Orientation",
            children: [
              {
                key: "portrait",
                label: "Portrait",
                onClick: () => setOrientation("portrait"),
              },
              {
                key: "landscape",
                label: "Landscape",
                onClick: () => setOrientation("landscape"),
              },
            ],
          },
        ]
      : []),
  ];
  return (
    <Dropdown trigger="click" menu={{ items: menuItems, selectable: true, selectedKeys: [language, orientation] }}>
      <Button iconPosition="iconOnly" icon={<More2FillIcon />} type="tertiary" />
    </Dropdown>
  );
}
