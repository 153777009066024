import InputColorUi from "../ui/InputColorUi/InputColorUi";
import Model from "./Model";
import { SCHEMA_INPUTS_FIELDS } from "../../../util/ModelConstants";

export default class InputColorModel extends Model {
  constructor(...args) {
    super(...args);
  }

  getAlphaConstraints() {
    const constraints = this.getConstraints();
    return constraints !== undefined && constraints[SCHEMA_INPUTS_FIELDS.ConstraintsWithAlpha] === true;
  }

  renderComponent() {
    return InputColorUi;
  }
}
