import { useState } from "react";
import lottie from "lottie-web";
import { useTranslation } from "react-i18next";
import { Controls, Player } from "@lottiefiles/react-lottie-player";
import { fromBase64DataToBlob, fromBlobToMimeType } from "~/util/InputsComputer";
import BinaryUi from "components/BinaryUi/BinaryUi";
import { MAX_HEIGHT } from "../UiConstants";
import { toStringWithBytes, toStringWithDimensions, toStringWithRatio } from "~/util/Helpers";
import style from "./InputLottieUi.module.scss";
import i18n from "i18n/i18n";
import { Typography } from "@ogury/design-system";

const lottieFileExtension = ".json";
const lottieMimeType = "application/json";

function loadAnimation(url) {
  return new Promise((resolve, reject) => {
    const div = document.createElement("div");
    const params = {
      container: div,
      renderer: "svg",
      path: url,
      autoplay: true,
    };
    try {
      const animationItem = lottie.loadAnimation(params);
      let responded = false;
      animationItem.addEventListener("data_ready", () => {
        responded = true;
        resolve(animationItem);
      });
      animationItem.addEventListener("data_failed", () => {
        responded = true;
        reject(new Error(i18n.t("error.invalid.lottieFile")));
      });
      // This is the only way to check whether the Lottie animation is well-formed
      window.setTimeout(() => {
        if (responded === false) {
          reject(new Error(i18n.t("error.invalid.lottieFile")));
        }
      }, 500);
    } catch (error) {
      reject(error);
    }
  });
}

const InputLottieUi = ({ input }) => {
  const [lottieFile, setLottieFile] = useState(null);
  const [t] = useTranslation();
  const maxHeight = MAX_HEIGHT;
  return (
    <BinaryUi
      inputValue={input.getValue()}
      inputName={input.getFilename()}
      onValueChanged={value => input.setValue(value)}
      fileExtensions={lottieFileExtension}
      mimeTypes={lottieMimeType}
      doNotSupportCors={true}
      placeholder="Lottie File URL"
      preview={urlOrDataUrl => {
        return (
          <Player
            autoplay
            loop
            src={urlOrDataUrl}
            lottieRef={animationItem => {
              setLottieFile(animationItem);
            }}
            style={{
              width:
                lottieFile === null
                  ? undefined
                  : lottieFile.animationData.h > maxHeight
                  ? maxHeight * (lottieFile.animationData.w / lottieFile.animationData.h)
                  : lottieFile.animationData.w,
              height: "auto",
              maxWidth: "100%",
              maxHeight: maxHeight,
            }}
          >
            <Controls visible={true} buttons={["play", "repeat", "frame"]} />
          </Player>
        );
      }}
      properties={value => {
        return (
          <div className={style.propertiesContainer}>
            <Typography.P2Regular className={style.name}>{value.name}</Typography.P2Regular>
            <div>
              {value.size !== undefined && <Typography.P2Regular>{toStringWithBytes(value.size)}</Typography.P2Regular>}
              {value.object !== undefined && (
                <Typography.P2Regular>
                  , {toStringWithDimensions(value.object.animationData.w, value.object.animationData.h)}
                </Typography.P2Regular>
              )}
              {value.object !== undefined && (
                <Typography.P2Regular>
                  , (ratio {toStringWithRatio(value.object.animationData.w / value.object.animationData.h)})
                </Typography.P2Regular>
              )}
            </div>
          </div>
        );
      }}
      computeObject={async url => {
        return await loadAnimation(url);
      }}
      computeBlob={async value => {
        // Taken from https://stackoverflow.com/questions/246801/how-can-you-encode-a-string-to-base64-in-javascript
        const Base64 = {
          _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
          encode: function (input) {
            let output = "";
            let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
            let i = 0;

            input = Base64._utf8_encode(input);

            while (i < input.length) {
              chr1 = input.charCodeAt(i++);
              chr2 = input.charCodeAt(i++);
              chr3 = input.charCodeAt(i++);

              enc1 = chr1 >> 2;
              enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
              enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
              enc4 = chr3 & 63;

              if (isNaN(chr2)) {
                enc3 = enc4 = 64;
              } else if (isNaN(chr3)) {
                enc4 = 64;
              }

              output =
                output +
                this._keyStr.charAt(enc1) +
                this._keyStr.charAt(enc2) +
                this._keyStr.charAt(enc3) +
                this._keyStr.charAt(enc4);
            }

            return output;
          },
          _utf8_encode: function (string) {
            string = string.replace(/\r\n/g, "\n");
            let utftext = "";

            for (let n = 0; n < string.length; n++) {
              const c = string.charCodeAt(n);

              if (c < 128) {
                utftext += String.fromCharCode(c);
              } else if (c > 127 && c < 2048) {
                utftext += String.fromCharCode((c >> 6) | 192);
                utftext += String.fromCharCode((c & 63) | 128);
              } else {
                utftext += String.fromCharCode((c >> 12) | 224);
                utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                utftext += String.fromCharCode((c & 63) | 128);
              }
            }

            return utftext;
          },
        };
        const jsonObject = value.object.animationData;
        const string = JSON.stringify(jsonObject);
        // We need to encode into base64 a special way, because the string may contain non-ASCII characters
        const base64Data = Base64.encode(string);
        return fromBase64DataToBlob(base64Data, value.mimeType);
      }}
      fromMimeTypeToFileExtension={() => {
        return "json";
      }}
      onValidate={async (blob, options) => {
        const mimeType = options.mimeType || (await fromBlobToMimeType(blob));
        if (mimeType !== lottieMimeType) {
          throw new Error(t("error.invalid.lottie", { mimeType: mimeType }));
        }
        options.mimeType = mimeType;
        options.object = await loadAnimation(options.url || options.dataUrl);
        return options;
      }}
    />
  );
};

export default InputLottieUi;
