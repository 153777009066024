import React, { useEffect, useMemo, useState } from "react";
import { InputWrapper } from "~/components";
import { Button, Row } from "@ogury/design-system";
import GridLineIcon from "remixicon-react/GridLineIcon";
import { AdUnitCanvas, CoordinatesInputs } from "components";
import TransformerWrapper from "./TransformerWrapper";
import { UNITS, useCreativeSettingsContext } from "context/CreativeSettingsContext";

const InputFrameUi = ({ input }) => {
  // use global context to detect pixel usage
  const { creativeSettings } = useCreativeSettingsContext();
  const absoluteValues = creativeSettings.units === UNITS.PIXELS;

  const [canvasDimensions, setCanvasDimensions] = useState();
  const [canvasScale, setCanvasScale] = useState(0.5);
  const [originalAdSize, setOriginalAdSize] = useState();

  const [frameCoordinatesInputs, setFrameCoordinatesInputs] = useState();
  const [gridEnabled, setGridEnabled] = useState(true);
  const [frameLockRatio, setFrameLockRatio] = useState(false);

  const inputValue = useMemo(() => input.getValue(), [input.getValue()]);
  const inputConstraintBounds = useMemo(() => {
    // calculate input constraints based on canvas size and image offset
    if (!absoluteValues) {
      return {
        x: {
          min: 0,
          max: 100,
        },
        y: {
          min: 0,
          max: 100,
        },
        width: { min: 1, max: 100 },
        height: { min: 1, max: 100 },
      };
    }

    if (!originalAdSize) {
      return;
    }

    return {
      x: { min: 0, max: originalAdSize.width },
      y: { min: 0, max: originalAdSize.height },
      width: { min: 10 / canvasScale, max: originalAdSize.width },
      height: { min: 10 / canvasScale, max: originalAdSize.height },
    };
  }, [originalAdSize, absoluteValues, canvasScale]);

  /* Event handlers */
  const handleOnCanvasChange = data => {
    const { canvasDimensions, canvasScale, originalAdSize } = data;
    setCanvasDimensions(canvasDimensions);
    setCanvasScale(canvasScale);
    setOriginalAdSize(originalAdSize);
  };

  const handleGridEnableClick = () => {
    setGridEnabled(!gridEnabled);
  };

  const handleCoordinatesChange = coordinates => {
    setFrameCoordinatesInputs({ ...coordinates, isDirty: true });
  };

  const handleTransformerChange = coordinates => {
    if (!originalAdSize || !inputValue || !canvasDimensions) {
      return;
    }

    const { x, y, height, width } = coordinates;

    const newCoordinates = absoluteValues
      ? { x: x / canvasScale, y: y / canvasScale, width: width / canvasScale, height: height / canvasScale }
      : {
          x: x === 0 ? 0 : (x / canvasDimensions.width) * 100,
          y: y === 0 ? 0 : (y / canvasDimensions.height) * 100,
          width: (width / canvasDimensions.width) * 100,
          height: (height / canvasDimensions.height) * 100,
        };

    setFrameCoordinatesInputs(coordinates => ({
      ...coordinates,
      ...newCoordinates,
      isDirty: true,
    }));
  };

  /* UI computations */
  const frameCoordinates = useMemo(() => {
    if (!frameCoordinatesInputs || !canvasScale || !originalAdSize) {
      return null;
    }

    const { x, y, width, height } = frameCoordinatesInputs;
    const absoluteCoordinates = absoluteValues
      ? { ...frameCoordinatesInputs }
      : {
          x: (originalAdSize.width * x) / 100,
          y: (originalAdSize.height * y) / 100,
          width: (originalAdSize.width * width) / 100,
          height: (originalAdSize.height * height) / 100,
        };

    return {
      x: absoluteCoordinates.x * canvasScale,
      y: absoluteCoordinates.y * canvasScale,
      width: absoluteCoordinates.width * canvasScale,
      height: absoluteCoordinates.height * canvasScale,
    };
  }, [frameCoordinatesInputs, canvasScale]);

  /* Hooks */
  useEffect(() => {
    if (!originalAdSize || !frameCoordinatesInputs) {
      return;
    }

    const { x, y, height, width } = frameCoordinatesInputs;

    const newWidth = !absoluteValues ? width : (width / originalAdSize.width) * 100;
    const newHeight = !absoluteValues ? height : (height / originalAdSize.height) * 100;
    const newX = !absoluteValues || x === 0 ? x : (x / originalAdSize.width) * 100;
    const newY = !absoluteValues || y === 0 ? y : (y / originalAdSize.height) * 100;

    // do not setValue before form change
    if (frameCoordinatesInputs.isDirty) {
      input.setValue({
        ...inputValue,
        topLeft: {
          x: newX,
          y: newY,
        },
        bottomRight: {
          x: newX + newWidth,
          y: newY + newHeight,
        },
      });
    }
  }, [frameCoordinatesInputs]);

  useEffect(() => {
    if (!inputValue || !originalAdSize) {
      return;
    }

    const { topLeft, bottomRight } = inputValue;
    let newCoordinates = !absoluteValues
      ? {
          x: topLeft.x,
          y: topLeft.y,
          width: bottomRight.x - topLeft.x,
          height: bottomRight.y - topLeft.y,
        }
      : {
          x: (topLeft.x * originalAdSize.width) / 100,
          y: (topLeft.y * originalAdSize.height) / 100,
          width: ((bottomRight.x - topLeft.x) * originalAdSize.width) / 100,
          height: ((bottomRight.y - topLeft.y) * originalAdSize.height) / 100,
        };

    setFrameCoordinatesInputs({ ...newCoordinates, absoluteValues });
  }, [originalAdSize]);

  useEffect(() => {
    // refresh coordinates on unit change
    if (!originalAdSize || !frameCoordinatesInputs || absoluteValues === frameCoordinatesInputs.absoluteValues) {
      return;
    }

    const { x, y, width, height } = frameCoordinatesInputs;

    if (absoluteValues) {
      setFrameCoordinatesInputs({
        x: (originalAdSize.width * x) / 100,
        y: (originalAdSize.height * y) / 100,
        width: (originalAdSize.width * width) / 100,
        height: (originalAdSize.height * height) / 100,
        absoluteValues,
        isDirty: true,
      });
    } else {
      setFrameCoordinatesInputs({
        x: (x / originalAdSize.width) * 100,
        y: (y / originalAdSize.height) * 100,
        width: (width / originalAdSize.width) * 100,
        height: (height / originalAdSize.height) * 100,
        absoluteValues,
        isDirty: true,
      });
    }
  }, [absoluteValues]);

  return (
    <InputWrapper input={input}>
      <>
        <AdUnitCanvas
          gridEnabled={gridEnabled}
          constraintsReferenceInput={input.getConstraintsOnInputPathReferenceInstance()}
          onCanvasChange={handleOnCanvasChange}
        >
          {canvasDimensions && frameCoordinates && (
            <TransformerWrapper
              {...frameCoordinates}
              canvasDimensions={canvasDimensions}
              lockRatio={frameLockRatio}
              onChange={handleTransformerChange}
            />
          )}
        </AdUnitCanvas>
        <Row align="end">
          <Button
            type={gridEnabled ? "secondary" : "tertiary"}
            onClick={handleGridEnableClick}
            iconPosition="iconOnly"
            size="small"
            icon={<GridLineIcon />}
          />
        </Row>

        {frameCoordinatesInputs && (
          <CoordinatesInputs
            coordinates={frameCoordinatesInputs}
            constraints={inputConstraintBounds}
            canvasDimensions={canvasDimensions}
            canvasScale={canvasScale}
            onChange={handleCoordinatesChange}
            onLockRatio={value => setFrameLockRatio(value)}
          />
        )}
      </>
    </InputWrapper>
  );
};

export default InputFrameUi;
