import { useEffect, useState } from "react";

import { InputWrapper, TextInput } from "components";

const InputUrlUi = ({ input }) => {
  const [value, setValue] = useState(input.getValue());

  useEffect(() => {
    if (input.getValue() != null) {
      setValue(input.getValue());
    }
  }, [input]);

  return (
    <InputWrapper input={input}>
      <TextInput placeholder="URL" onChange={setValue} onBlur={() => input.setValue(value)} value={value || ""} />
    </InputWrapper>
  );
};

export default InputUrlUi;
