import { useEffect, useState } from "react";
import { TextInput, InputWrapper, VideoPlayer } from "components";

const InputVideoUi = ({ input }) => {
  const [value, setValue] = useState(input.getValue());

  useEffect(() => {
    const inputValue = input.getValue();
    if (inputValue != null) {
      setValue(inputValue);
    }
  }, [input]);

  return (
    <InputWrapper input={input}>
      <TextInput placeholder="URL" onChange={setValue} onBlur={() => input.setValue(value)} value={value || ""} />
      <VideoPlayer src={value} />
    </InputWrapper>
  );
};

export default InputVideoUi;
