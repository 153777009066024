import InputLottieOverlayUi from "app/inputs/ui/InputLottieOverlayUi/InputLottieOverlayUi";
import Model from "app/inputs/model/Model";
import { BINARY_SUFFIX, fromBinaryStringValueToName, fromFileToJSON, META_SUFFIX } from "~/util/InputsComputer";

const SOURCE_PATH = "source";

const COORDINATES_COMPUTATION = {
  TOP_LEFT: "topLeft",
  GRAVITY: "gravity",
};

const SIZE_COMPUTATION = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
};

const defaultState = {
  x: 50,
  y: 50,
  size: 100,
  coordinatesComputation: COORDINATES_COMPUTATION.TOP_LEFT,
  sizeComputation: SIZE_COMPUTATION.HORIZONTAL,
};

export default class InputLottieOverlayModel extends Model {
  constructor(...args) {
    super(...args);
  }

  renderComponent() {
    return InputLottieOverlayUi;
  }

  hasBinaryForm() {
    return true;
  }

  getDefaultValue() {
    let defaultValue = super.getDefaultValue() || {};
    // If all the default values have not been set in the template, then we replace the missing one with standard values.
    Object.keys(defaultState).forEach(key => {
      if (!defaultValue.hasOwnProperty(key)) {
        defaultValue[key] = defaultState[key];
      }
    });
    return super.getDefaultValue();
  }

  computeValue(value, source) {
    return {
      x: value.x,
      y: value.y,
      size: value.size,
      rotation: value.rotation,
      sizeComputation: value.sizeComputation,
      coordinatesComputation: value.coordinatesComputation,
      ...source,
    };
  }

  async computeMetas() {
    return this.getValue()?.["source" + META_SUFFIX];
  }
  getConstraintsOnInputPathReferenceInstance() {
    return Model.getConstraintsOnInputPathReferenceInstance(this, false);
  }

  async computeImportValue(value) {
    if (value != null && value[SOURCE_PATH + BINARY_SUFFIX] != null) {
      const binaryValue = value[SOURCE_PATH + BINARY_SUFFIX];
      try {
        const { url, file } = await this.convertBinaryToTemporaryUrl(binaryValue);
        const fileName = fromBinaryStringValueToName(binaryValue);
        const lottieJSON = await fromFileToJSON(file);
        const source = url;
        return this.computeValue(value, {
          source,
          ["source" + META_SUFFIX]: {
            fileName,
            height: lottieJSON.h,
            width: lottieJSON.w,
          },
        });
      } catch (error) {
        console.error("Can not convert the asset with ID " + this.getId() + " to URL. ", error);
        return this.computeValue(value, { source: { bin: binaryValue } });
      }
    }
    return value;
  }

  async export(assetsBaseUrl = "", resolveAssets = false) {
    let exportKey = this.getReferenceId();
    let exportObj = {};
    const value = this.getValue();

    const source = value?.[SOURCE_PATH];
    if (source) {
      const binaryValue = await this.exportBinaryValue(assetsBaseUrl, resolveAssets, source);
      const isBinary = binaryValue != null;
      const valueKey = isBinary ? SOURCE_PATH + BINARY_SUFFIX : SOURCE_PATH;
      const valueData = isBinary ? binaryValue : source;

      const metas = await this.computeMetas();

      exportObj[exportKey] = this.computeValue(value, {
        [valueKey]: valueData,
        [SOURCE_PATH + META_SUFFIX]: metas,
      });
    }
    return exportObj;
  }
}
