import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./en-US.js";

export const resources = {
  en: {
    translation: translationEN,
  },
};

i18n.use(initReactI18next).init({
  preload: ["en"],
  fallbackLng: "en",
  resources,
  interpolation: {
    escapeValue: false,
  },
  react: {
    bindI18n: "languageChanged",
    bindI18nStore: "",
    transEmptyNodeValue: "",
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
    useSuspense: true,
  },
});

export default i18n;
