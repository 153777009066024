import { useEffect, useState } from "react";

import { SCHEMA_INPUTS_FIELDS } from "../../../util/ModelConstants";
import { InputIcon, InputWrapper } from "components";

const InputIntegerUi = ({ input }) => {
  const [value, setValue] = useState({
    slider: input.getValue(),
    input: input.getValue(),
  });

  const onChange = newValue => {
    newValue = input.parseAndBoundRangeValue(newValue);
    setValue({
      slider: newValue,
      input: newValue,
    });
    input.setValue(newValue);
  };

  useEffect(() => {
    if (input.getValue() != null) {
      setValue({
        slider: input.getValue(),
        input: input.getValue(),
      });
    }
  }, [input]);

  const renderAcceptableValue = () => {
    const acceptableValue = input.getAcceptableValue(value);
    const illustration =
      acceptableValue === undefined ? undefined : acceptableValue[SCHEMA_INPUTS_FIELDS.AcceptableValuesIllustration];

    return (
      <>
        {
          // TODO remove the UI logic from the model and bring it back in the UI. Already done with "InputFloatUi and component "AcceptableValues"
        }
        {input.renderAcceptableValues(value.input, onChange)}
        {illustration !== undefined && <InputIcon icon={illustration} square={false} />}
      </>
    );
  };

  const renderEditValue = () => {
    return input.renderFreeOrRangeValue(1, value, setValue, onChange);
  };

  return (
    <InputWrapper input={input}>
      {input.getAcceptableValues() !== undefined ? renderAcceptableValue() : renderEditValue()}
    </InputWrapper>
  );
};

export default InputIntegerUi;
