import { Preview } from "./components";
import useStores from "~/hooks/useStores";
import Core from "~/services/Core";
import AppService from "~/services/AppService";

export default function RightPanel({ afterSaveSeed, onReloadAndSave }) {
  const { activeTreeNode } = useStores();
  const template = Core.getCurrentTemplate();
  const isExpertMode = AppService.getIsExpertMode();

  return (
    (activeTreeNode || isExpertMode) && (
      <Preview afterSaveSeed={afterSaveSeed} templateUrl={template?.templateUrl} onReloadAndSave={onReloadAndSave} />
    )
  );
}
