import PropTypes from "prop-types";
import { Upload } from "@ogury/design-system";
import styles from "./FilePickerV2.module.scss";

const FilePickerV2 = props => {
  return (
    <div className={styles.filePickerWrapper}>
      <Upload.Dragger {...props} />
    </div>
  );
};

FilePickerV2.propTypes = {
  accept: PropTypes.string,
  maxCount: PropTypes.number,
  beforeUpload: PropTypes.func,
};

export default FilePickerV2;
