import React from "react";
import Model from "./Model";
import i18n from "i18next";
import { theme, Typography } from "@ogury/design-system";
export default class InputInvalidModel extends Model {
  constructor(...args) {
    super(...args);
  }

  renderComponent() {
    return () => (
      <Typography.P2Regular style={{ color: theme.colors.destructive.content }} type="danger">
        {i18n.t("inputs.error.internalError")}
      </Typography.P2Regular>
    );
  }
}
