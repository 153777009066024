import i18n from "i18next";
import { ExternalLink, IconNames, Icons } from "components";
import { HeaderButtons, UndoRedo } from "./components";
import style from "./Header.module.scss";
import { Badge, theme, Typography } from "@ogury/design-system";
import Core from "../../../../services/Core";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import metaService from "~/services/MetaService";

export default function Header({
  experienceData,
  parentProxy,
  showExperienceButton,
  onExperienceNameChange,
  onAfterSave,
  saveSeed,
  isStudio,
}) {
  const [nameInputState, setNameInputState] = useState({ dirty: false });
  const [initialExperienceName, setInitialExperienceName] = useState(experienceData.name);
  const [t] = useTranslation();
  const titleRef = useRef();

  function handleOnExperienceNameChange(event) {
    const value = event.target.value;
    onExperienceNameChange(value);

    const newInputState = { ...nameInputState };

    newInputState.dirty = value !== initialExperienceName;

    if (!value && newInputState.dirty) {
      newInputState.error = t("error.saveExperience.nameRequired");
    } else if (value.length > 64) {
      newInputState.error = t("error.saveExperience.invalidLength");
    } else {
      newInputState.error = undefined;
    }

    setNameInputState(newInputState);
  }

  function handleOnClose() {
    if (parentProxy !== undefined) {
      parentProxy.call("onClose");
    }
  }

  function handleOnCloseToUpgrade() {
    if (parentProxy !== undefined) {
      parentProxy.call("onUpgradeTemplate");
    }
  }

  function handleOnSave(experience) {
    setNameInputState({ ...nameInputState, dirty: false });
    setInitialExperienceName(experience.name);
  }

  const nameInputWidth = () => {
    if (!experienceData.name || !titleRef?.current) {
      return 122;
    }

    return titleRef.current.clientWidth + 15;
  };

  function renderTemplateName() {
    return (
      <div className={style.templateNameContainer}>
        <Typography.P3Regular style={{ color: theme.colors.content.subdued }}>
          {Core.getCurrentTemplate()?.name}
        </Typography.P3Regular>
        {metaService.canUpgrade(experienceData) && (
          <ExternalLink onClick={handleOnCloseToUpgrade}>
            <Typography.P3Regular>Update template </Typography.P3Regular>
          </ExternalLink>
        )}
      </div>
    );
  }

  return (
    <header className={style.container}>
      {/*
       TODO re-integration of debug tools
       <DebugTools loadTemplate={loadTemplate} loadExperience={loadExperience} />
      */}

      <div className={style.leftContainer}>
        {isStudio === false && (
          <div data-testid="button-back" className={style.closeButton} onClick={handleOnClose}>
            <Icons name={IconNames.ArrowLeftOutlined.type} />
          </div>
        )}
        <div className={style.nameAndTemplate}>
          <div className={style.nameAndState} data-testid="experience-title">
            {/* use to compute perfect input size based on experience name */}
            <span ref={titleRef} className={style.nameSizePlaceholder}>
              {experienceData.name}
            </span>
            <input
              data-testid="experience-name-input"
              id="experienceNameId"
              placeholder={i18n.t("header.placeholders.experienceName")}
              value={experienceData.name}
              onChange={handleOnExperienceNameChange}
              style={{ width: nameInputWidth() }}
            />
            {experienceData.state === "Published" && <Badge status="success" text={i18n.t("header.state.published")} />}
          </div>
          {nameInputState.error && (
            <Typography.P3Regular color={theme.colors.destructive.content}>{nameInputState.error}</Typography.P3Regular>
          )}
          {!nameInputState.error && renderTemplateName()}
        </div>
      </div>

      <UndoRedo />
      {/*
      {isStudio === false && <Image src={MotionlyLogo} alt="Motionly logo" style={{ height: 50 }} />}
*/}
      <HeaderButtons
        parentProxy={parentProxy}
        showExperienceButton={showExperienceButton}
        experienceData={{ ...experienceData, dirty: nameInputState.dirty }}
        onAfterSave={onAfterSave}
        saveSeed={saveSeed}
        onSave={handleOnSave}
      />
    </header>
  );
}
