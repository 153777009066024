import { useEffect, useState } from "react";

import { InputWrapper } from "components";
import { convertTextBreakline, convertHTMLBreakLine } from "~/util/Helpers";
import { Input } from "@ogury/design-system";

const InputRichTextUi = ({ input }) => {
  const [value, setValue] = useState(convertTextBreakline(input.getValue()));

  useEffect(() => {
    if (input.getValue()) {
      setValue(convertTextBreakline(input.getValue()));
    }
  }, [input]);

  return (
    <InputWrapper input={input}>
      <Input.TextArea
        placeholder={"Text"}
        value={value}
        onChange={value => {
          setValue(value);
        }}
        onBlur={() => {
          input.setValue(convertHTMLBreakLine(value));
        }}
        className="form-dark w-100"
      />
    </InputWrapper>
  );
};

export default InputRichTextUi;
