import { useUiStore } from "../context/UiStoreContext";
import Core from "../services/Core";
import { useMemo } from "react";

export default function useStores() {
  const { uiStore, regenerateUiStore, activeTreeNodeId, setActiveTreeNodeId } = useUiStore();

  function getTreeNodeById(nodeId) {
    let node = undefined;
    if (uiStore.length >= 1) {
      function iterateThroughStore(nodes) {
        for (let i = 0; i < nodes.length; i++) {
          if (nodes[i].id === nodeId) {
            node = nodes[i];
            break;
          }
          if (nodes[i].inputs) {
            iterateThroughStore(nodes[i].inputs);
          }
        }
      }

      iterateThroughStore(uiStore);
      return node;
    }
  }

  const activeTreeNode = useMemo(() => {
    return getTreeNodeById(activeTreeNodeId);
  }, [uiStore, activeTreeNodeId]);

  function getDeepNodeInstanceById(id) {
    const instance = Core.getDeepNodeById(id, true);
    if (instance) {
      instance.setRegenerateUiStore(regenerateUiStore);
      return instance;
    }
    return undefined;
  }

  function addCollection(collectionId, shouldRegenerateUiStore = true) {
    const childCollectionNode = Core.addCollection(collectionId);
    if (shouldRegenerateUiStore) {
      regenerateUiStore();
    }
    return childCollectionNode;
  }

  function deleteCollection(collectionId, shouldRegenerateUiStore = true) {
    Core.deleteCollection(collectionId);
    if (shouldRegenerateUiStore) {
      regenerateUiStore();
    }
  }

  function duplicateCollection(collectionId, shouldRegenerateUiStore = true) {
    const duplicatedNode = Core.duplicateCollection(collectionId);
    if (shouldRegenerateUiStore) {
      regenerateUiStore();
    }
    return duplicatedNode;
  }

  function moveCollection(collectionId, moveDown, shouldRegenerateUiStore = true) {
    const movedInstance = Core.moveCollection(collectionId, moveDown);
    if (shouldRegenerateUiStore) {
      regenerateUiStore();
    }
    return movedInstance;
  }

  /**
   * Initializes the deep store (background store with inputs instances) and the tree store (UI store for react, with strict minimum data)
   *
   */
  function initializeBuilder(shouldRegenerateUiStore = true) {
    Core.initializeDeepStore();
    if (shouldRegenerateUiStore) {
      regenerateUiStore();
    }
  }

  return {
    uiStore,
    regenerateUiStore,
    addCollection,
    moveCollection,
    duplicateCollection,
    deleteCollection,
    initializeBuilder,
    getDeepNodeInstanceById,
    getTreeNodeById,
    activeTreeNode,
    activeTreeNodeId,
    setActiveTreeNodeId,
  };
}
