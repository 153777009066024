import "react-image-crop/dist/ReactCrop.css";
import { Image, InputImage, InputWrapper } from "components";
import { loadImage } from "~/util/InputsComputer";
import { MAX_HEIGHT } from "../UiConstants";
import style from "./InputSpriteUi.module.scss";

const InputSpriteUi = ({ input }) => {
  return (
    <InputWrapper input={input}>
      <InputImage
        inputValue={input.getValue()}
        inputName={input.getFilename()}
        onValueChanged={value => input.setValue(value)}
        onDelete={() => input.deleteBinary()}
        formats={input.getFormatsConstraints()}
        ratioConstraints={input.getRatioConstraints()}
        preview={urlOrDataUrl => {
          return (
            <div className={style.container}>
              <Image
                style={{
                  height: "auto",
                  maxWidth: "100%",
                  maxHeight: MAX_HEIGHT,
                }}
                src={urlOrDataUrl}
                crossOrigin="anonymous"
                alt="Image"
                onError={async event => {
                  // This happens in case of CORS limitations
                  // This call is required, because we do not want this event to be shared, because of the "event.target" hack
                  event.persist();
                  event.target.onerror = null;
                  let image;
                  try {
                    image = await loadImage(urlOrDataUrl);
                  } catch (error) {
                    console.error(
                      "Could not render the preview image with CORS restriction and URL '" + urlOrDataUrl + "'",
                      error
                    );
                    return;
                  }
                  event.target.src = image.src;
                }}
              />
            </div>
          );
        }}
      />
    </InputWrapper>
  );
};

export default InputSpriteUi;
