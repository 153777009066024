import Model from "./Model";
import InputMultipleImagesUi from "app/inputs/ui/InputMultipleImagesUi";

export default class InputMultipleImagesModel extends Model {
  constructor(...args) {
    super(...args);
  }

  renderComponent() {
    return InputMultipleImagesUi;
  }
}
