import React from "react";

// TODO keep for further implementation
const InputIcon = () => {
  return <></>;
};

InputIcon.propTypes = {};

export default InputIcon;
