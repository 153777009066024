import Model from "./Model";
import InputBooleanUi from "../ui/InputBooleanUi";

export default class InputBooleanModel extends Model {
  constructor(...args) {
    super(...args);
  }

  renderComponent() {
    return InputBooleanUi;
  }
}
