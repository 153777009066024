import PropTypes from "prop-types";
import { Tooltip, InputIcon } from "..";
import { Radio, Space } from "@ogury/design-system";
import styles from "./RadioGroup.module.scss";

const RadioGroup = ({ name, options, value, onChange, isHorizontal }) => {
  return (
    <div>
      <Radio.Group onChange={onChange} value={value}>
        <Space direction={isHorizontal === true ? "horizontal" : "vertical"}>
          {options.map(({ label, value: acceptableValue, comment, icon }) => {
            return (
              <Radio key={"input-" + name + "-" + acceptableValue} value={acceptableValue}>
                <div className={styles.radioContent}>
                  {icon !== undefined && <InputIcon icon={icon} square={false} />}
                  {label}
                  {comment !== undefined && (
                    <div className={styles.comment}>
                      <Tooltip content={comment} />
                    </div>
                  )}
                </div>
              </Radio>
            );
          })}
        </Space>
      </Radio.Group>
    </div>
  );
};

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
      comment: PropTypes.string,
      icon: PropTypes.string,
    })
  ).isRequired,
  isHorizontal: PropTypes.bool.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
};

export default RadioGroup;
