import { useEffect, useState } from "react";

import { toStringWithDigits } from "../../util/Helpers";

import localStyle from "./NumberEdit.module.scss";
import { InputNumber, Typography } from "@ogury/design-system";

const NumberEdit = ({
  label,
  suffix,
  style = {},
  digits = -1,
  minimum = Number.MIN_SAFE_INTEGER,
  maximum = Number.MAX_SAFE_INTEGER,
  step = 1,
  disabled = false,
  value,
  onChange,
  ...rest
}) => {
  const [internal, setInternal] = useState(toStringWithDigits(digits, value, true));
  const digitsPower = digits === -1 ? undefined : Math.pow(10, digits);

  useEffect(() => {
    setInternal(toStringWithDigits(digits, value, true));
  }, [value]);

  function handleChange(target) {
    const floatValue = parseFloat(target.value);
    const newValue = onChange(
      typeof floatValue === "number" && Number.isNaN(floatValue) === false
        ? Math.min(maximum, Math.max(minimum, floatValue))
        : value
    );
    setInternal(toStringWithDigits(digits, newValue === undefined ? floatValue : newValue, true));
  }

  return (
    <div className={localStyle.container}>
      {label !== undefined && <Typography.P2Regular style={{ marginRight: 10 }}>{label}</Typography.P2Regular>}
      <InputNumber
        style={style}
        min={minimum}
        max={maximum}
        step={step}
        disabled={disabled}
        value={internal}
        onChange={value => {
          const floatValue = parseFloat(value);
          if (typeof floatValue === "number" && Number.isNaN(floatValue) === false) {
            const withDigitsValue =
              digitsPower === undefined ? floatValue : Math.round(floatValue * digitsPower) / digitsPower;
            setInternal(withDigitsValue);
          }
        }}
        onKeyDown={event => {
          if (event.key === "Enter") {
            handleChange(event.target);
          }
        }}
        onBlur={({ target }) => {
          handleChange(target);
        }}
        {...rest}
      />
      {suffix && <Typography.P2Regular style={{ marginLeft: 10 }}>{suffix}</Typography.P2Regular>}
    </div>
  );
};

export default NumberEdit;
