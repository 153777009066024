import PropTypes from "prop-types";
import React, { useContext, useEffect, useMemo, useState } from "react";

import { Button, Modal, ModalClosableFooter, Typography, useAppNotification } from "@ogury/design-system";
import { Loader } from "components";
import { AccessibilityContext } from "context/AccessibilityContext";
import { useCreativeSettingsContext } from "context/CreativeSettingsContext";
import { useTranslation } from "react-i18next";
import Split from "react-split";
import { useExperience } from "~/context";
import unitService from "~/services/UnitService";
import Api from "~/util/Api";
import Communication from "~/util/Communication";
import { NOTIFICATION_PLACEMENT } from "~/util/Constant";
import { isEmpty, random } from "~/util/Utils";
import historyStore from "../../services/HistoryStore";
import Debug from "../../util/Debug";
import References from "../../util/References";
import { Header } from "./components";
import FileManager from "./components/FileManager/FileManager";
import style from "./Layout.module.scss";
import CodeEditor from "./components/CodeEditor/CodeEditor";
import RightPanel from "./components/RightPanel/RightPanel";
import AppService from "~/services/AppService";

const useBeforeunload = callback => {
  const savedCallback = React.useRef();

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    const theCallback = event => {
      savedCallback.current(event);
    };
    window.addEventListener("beforeunload", theCallback);
    return () => {
      theCallback();
      window.removeEventListener("beforeunload", theCallback);
    };
  }, []);
};

const LayoutExpert = props => {
  const [loaded, setLoaded] = useState(false);
  const [t] = useTranslation();

  useEffect(() => {
    (async () => {
      await unitService.initialize();
      AppService.setIsExpertMode(true);
      setLoaded(true);
    })();
  }, []);
  return loaded === false ? <Loader overlay text={t("accessibility.initializing")} /> : <Layout {...props} />;
};

const Layout = ({ history, location, match }) => {
  const notification = useAppNotification();

  const [accessibilityState, accessibilityActions] = useContext(AccessibilityContext);
  const { experience, setExperience } = useExperience();
  const { creativeSettings, setCreativeSettings } = useCreativeSettingsContext();

  const [t] = useTranslation();
  // noinspection JSCheckFunctionSignatures
  const [experienceData, setExperienceData] = useState();
  const [fromTemplate, setFromTemplate] = useState(true);
  const [display, setDisplay] = useState({
    sizes: [21, 52, 27],
    studio: false,
  });
  const [afterSaveSeed, setAfterSaveSeed] = useState();
  const [saveSeed, setSaveSeed] = useState(); // TODO refacto this by puttin the experience save method in its own service layer.
  const [parentProxy, setParentProxy] = React.useState();
  const methods = useMemo(() => {
    return {
      shouldClose() {
        return new Promise.resolve(true);
      },
    };
  }, []);

  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [shouldCloseResolve, setShouldCloseResolve] = React.useState();

  // TODO when doing the future refactorization of the Parent Proxy in a hook, remove this to call the hook.
  const [canSaveExperience, setCanSaveExperience] = useState(true);
  const dirty = historyStore.getDirty();

  useEffect(() => {
    methods.shouldClose = () => {
      if (dirty === true && canSaveExperience) {
        setConfirmDialog(true);
        return new Promise(resolve => {
          // Caution: we need to passe a no-argument function, and not directory the function implementation, because React considers the state setter lazy instantiation
          setShouldCloseResolve(() => {
            return shouldClose => {
              resolve(shouldClose);
              setConfirmDialog(false);
              setShouldCloseResolve(undefined);
            };
          });
        });
      } else {
        return Promise.resolve(true);
      }
    };
  }, [dirty]);

  async function loadExperience(experienceId) {
    accessibilityActions.setLoading({
      active: true,
      text: t("accessibility.loadingExperience"),
    });

    try {
      let experience = await Api.getExperience(experienceId);

      setExperienceData({
        name: experience.name,
        state: experience.state,
        builderSettings: experience.data.builderSettings,
      });

      accessibilityActions.setLoading({
        active: true,
        text: t("accessibility.processingData"),
      });
      setExperience(experience);
      history.replace("/expert/" + experience.id + location.search);
      notification.success({
        message: "Experience successfully loaded",
        description: "Loading completed",
        placement: NOTIFICATION_PLACEMENT,
      });
    } catch (error) {
      console.warn(error);
      notification.error({
        message: error.message,
        description: "Loading failed",
        placement: NOTIFICATION_PLACEMENT,
        duration: 0,
      });
    } finally {
      accessibilityActions.setLoading({ active: false });
    }
  }

  useEffect(() => {
    let theParentProxy;
    const run = async () => {
      if (Api.isInStudioFlavor() === true) {
        setDisplay({
          sizes: [25, 75],
          studio: true,
        });
      }

      const params = match.params;
      // initialize experience data to show header
      setExperienceData({});

      Debug.log("URL parameters :", params);
      if (isEmpty(params) === false) {
        // TODO extract all proxy logic in dedicated service file or hook
        theParentProxy = await Communication.initialize(false, methods);
        setParentProxy(theParentProxy);
        const experienceId = params["experienceId"];

        if (experienceId !== undefined) {
          setFromTemplate(false);
          Debug.log("Loading experience with ID : " + experienceId);
          await loadExperience(experienceId);
        }

        const _canSaveExperience = await theParentProxy?.call("canSaveExperience");
        if (_canSaveExperience !== undefined && (_canSaveExperience === true || _canSaveExperience === false)) {
          setCanSaveExperience(_canSaveExperience);
        }
      } else {
        notification.error({
          message: "Failed to load the template: the URL schema is unexpected",
          description: "Loading failed",
          placement: NOTIFICATION_PLACEMENT,
          duration: 0,
        });
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    run();
    return () => {
      if (theParentProxy !== undefined) {
        theParentProxy.destroy();
      }
    };
  }, [methods]);

  useBeforeunload(event => {
    if (parentProxy === undefined && dirty === true) {
      const message = "";
      if (event !== undefined) {
        event.preventDefault();
        event.returnValue = message;
      }
      return message;
    }
  });

  function shouldDisplayShowExperienceButton() {
    return experience.id !== undefined;
  }

  const isLoading = accessibilityState.loading.active === true && accessibilityState.loading.sides.length === 0;

  useEffect(() => {
    const experienceNameFromManager = Api.getExperienceNameFromManager();
    if (experienceNameFromManager !== undefined) {
      setExperienceData({ name: experienceNameFromManager });
    }
  }, []);

  useEffect(() => {
    if (!experience?.data?.builderSettings) {
      return;
    }

    setCreativeSettings({ ...creativeSettings, units: experience.data.builderSettings.units });
  }, [experience]);

  return (
    <section className={style.container}>
      {isLoading === true && <Loader overlay text={accessibilityState.loading.text} />}
      {experienceData && (
        <Header
          experienceData={experienceData}
          isStudio={display.studio}
          loadExperience={loadExperience}
          parentProxy={parentProxy}
          showExperienceButton={shouldDisplayShowExperienceButton()}
          onAfterSave={() => {
            setAfterSaveSeed(random());
          }}
          saveSeed={saveSeed}
          onExperienceNameChange={name => setExperienceData({ ...experienceData, name })}
        />
      )}
      <div className={style.panelsContainer}>
        <Split
          sizes={display.sizes}
          minSize={[200, 380, 350]}
          className={style.split}
          gutterSize={6}
          gutterAlign="center"
          snapOffset={30}
          dragInterval={1}
          cursor="col-resize"
        >
          <div className={style.leftPanel} data-testid="left-panel">
            <FileManager />
          </div>
          <div data-testid="center-panel" id={References.CENTER_PANEL_ID} className={style.centerPanel}>
            <CodeEditor />
          </div>
          {display.studio === true ? null : (
            <div className={style.rightPanel} data-testid="preview-panel">
              {experience.id && (
                <RightPanel afterSaveSeed={afterSaveSeed} onReloadAndSave={() => setSaveSeed(random())} />
              )}
            </div>
          )}
        </Split>
        <Modal
          open={confirmDialog === true}
          width="40%"
          onCancel={() => shouldCloseResolve(false)}
          title={t("notification.reminder.notSavedTitle")}
          footer={
            <ModalClosableFooter
              buttonLabel={t("components.OkCancelButtons.cancel")}
              actions={<Button onClick={() => shouldCloseResolve(true)}>{t("notification.reminder.discard")}</Button>}
            />
          }
        >
          <Typography.P2Regular>{t("notification.reminder.notSaved")}</Typography.P2Regular>
        </Modal>
      </div>
    </section>
  );
};

LayoutExpert.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default LayoutExpert;
