export function validateLottieFormat(json) {
  const requiredFields = ["v", "fr", "ip", "op", "w", "h", "layers"];
  const isValidType = (value, type) => typeof value === type;
  for (const field of requiredFields) {
    if (!(field in json)) {
      return false;
    }
  }

  return !(
    !isValidType(json.v, "string") ||
    !isValidType(json.fr, "number") ||
    !isValidType(json.ip, "number") ||
    !isValidType(json.op, "number") ||
    !isValidType(json.w, "number") ||
    !isValidType(json.h, "number") ||
    !Array.isArray(json.layers)
  );
}
