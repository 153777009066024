export const SCHEMA_INPUTS_FIELDS = {
  Id: "id",
  Type: "type",
  Semantics: "semantics",
  SemanticsReferenceInputPath: "referenceInputPath",
  SemanticsNature: "nature",
  SemanticsKind: "kind",
  Ui: "ui",
  UiName: "name",
  UiLabel: "label",
  UiComment: "comment",
  UiInstructions: "instructions",
  UiIcon: "icon",
  UiIllustration: "illustration",
  Conditions: "conditions",
  ConditionsOptional: "optional",
  ConditionsHidden: "hidden",
  ConditionsHiddenWhen: "hiddenWhen",
  ConditionsRequiredWhen: "requiredWhen",
  ConditionsWhenType: "type",
  ConditionsWhenConditions: "conditions",
  ConditionsWhenStatements: "statements",
  ConditionsWhenOperator: "operator",
  ConditionsWhenInputPath: "inputPath",
  ConditionsWhenInputValue: "inputValue",
  Constraints: "constraints",
  ConstraintsRangeMinimum: "minimum",
  ConstraintsRangeMaximum: "maximum",
  ConstraintsOnInputPath: "onInputPath",
  ConstraintsFormats: "formats",
  ConstraintsRatio: "ratio",
  ConstraintsRatioMaximumBytes: "maximumBytes",
  ConstraintsRatioMinimumPixels: "minimumPixels",
  ConstraintsWithAlpha: "withAlpha",
  ConstraintsFrame: "frame",
  ConstraintsFrameTopLeft: "topLeft",
  ConstraintsFrameBottomRight: "bottomRight",
  ConstraintsFrameX: "x",
  ConstraintsFrameY: "y",
  ConstraintsPointWidth: "width",
  ConstraintsPointHeight: "height",
  ConstraintsPointRatio: "ratio",
  ConstraintsCharacters: "characters",
  ConstraintsVideoUrlsWidth: "width",
  ConstraintsVideoUrlsDurationInMilliseconds: "durationInMilliseconds",
  ConstraintsCtaPosition: "position",
  ConstraintsCtaTypeAcceptableValues: "typeAcceptableValues",
  ConstraintsCtaIsActionUrlOptional: "isActionUrlOptional",
  ConstraintsCtaWithColor: "withColor",
  ConstraintsCtaWithBackgroundColor: "withBackgroundColor",
  ConstraintsCtaWithSize: "withSize",
  ConstraintsCtaWithTheme: "withTheme",
  ConstraintsCtaWithIcon: "withIcon",
  ConstraintsCtaWithFont: "withFont",
  ConstraintsCtaWithButtonStyle: "withButtonStyle",
  ConstraintsCtaWithStroke: "withStroke",
  ConstraintsCtaWithOpacity: "withOpacity",
  ConstraintsCtaWithPadding: "withPadding",
  ConstraintsCtaWithPosition: "withPosition",
  ConstraintsCtaWithText: "withText",
  ConstraintsCtaWithActionUrl: "withActionUrl",
  ConstraintsCtaWithMode: "withMode",
  ConstraintsStripesWithVisibility: "withVisibility",
  ConstraintsStripesWithOnlyColors: "withOnlyColors",
  AcceptableValues: "acceptableValues",
  AcceptableValuesValue: "value",
  AcceptableValuesLabel: "label",
  AcceptableValuesComment: "comment",
  AcceptableValuesIcon: "icon",
  AcceptableValuesIllustration: "illustration",
  Style: "style",
  StyleLayout: "layout",
  StyleLayoutRadio: "radio",
  StyleLayoutCombo: "combo",
  StyleOrientation: "orientation",
  StyleOrientationHorizontal: "horizontal",
  StyleOrientationVertical: "vertical",
  StyleShowConstraints: "showConstraints",
  Default: "default",
  Count: "count",
  CountMinimum: "minimum",
  CountMaximum: "maximum",
  Range: "range",
  RangeMinimum: "minimum",
  RangeMaximum: "maximum",
  Inputs: "inputs",
};

export const RATIO_DIGITS_COUNT = 4;
