import { useState, useMemo, useEffect } from "react";
import Papa from "papaparse";

import { FilePickerV2, InputWrapper, ExternalLink, FileUploadMeta, InputUrlUpload, Divider } from "components";
import Api from "~/util/Api";
import { parseCsv } from "~/util/csv";
import { Typography, Form, Space } from "@ogury/design-system";
import { useTranslation, Trans } from "react-i18next";
import styles from "./InputTabularUi.module.scss";
import tabularIntegrityValidator from "./tabularIntegrityValidator";
import i18n from "~/i18n/i18n";
import { SHARED_GOOGLE_DRIVE_FOLDER_LINKS_PER_ENV } from "~/util/Constant";
import { isUrl } from "~/util/Utils";

const googleSpreadSheetUrlRule = {
  pattern: /^https:\/\/docs.google.com\/spreadsheets\/d\/(\w|-){1,}/,
  message: i18n.t("error.invalid.URL"),
};

const InputTabularUi = ({ input }) => {
  const [t] = useTranslation();

  const inputContraints = input.getConstraints();
  const inputUIName = input.getReferenceInput().ui.name;

  const TABULAR_FILE_ICON = "Excel";
  const MAX_FILE_SIZE = inputContraints ? Math.round(inputContraints.maximumBytes / 1024) : 100; // kbytes
  const CSV_TEMPLATE = inputContraints ? encodeURIComponent(`${Object.keys(inputContraints.columns).join(",")}\n`) : "";

  const validateFile = async file => {
    const errors = [];
    if (file.size / 1024 > MAX_FILE_SIZE) {
      errors.push(new Error(t("components.tabularInput.errors.invalidFileSize", { size: MAX_FILE_SIZE })));
    }

    if (file.size === 0) {
      errors.push(new Error(t("components.tabularInput.errors.emptyFile")));
    }

    return errors;
  };

  const getTabularValueMeta = tabularValue => {
    if (!tabularValue) {
      return null;
    }

    const { name, size, lastModified, headers, values } = tabularValue;

    const downloadBlob = `data:text/csv;charset=UTF-8,${encodeURIComponent(Papa.unparse([headers, ...values]))}`;
    return {
      name: name || `${inputUIName}.csv`,
      size,
      lastModified,
      icon: TABULAR_FILE_ICON,
      status: "done",
      downloadName: isUrl(name) ? `${inputUIName}.csv` : name,
      downloadBlob,
    };
  };

  const [uploadedFile, setUploadedFile] = useState(getTabularValueMeta(input.getValue()));

  useEffect(() => {
    setUploadedFile(getTabularValueMeta(input.getValue()));
  }, [input.getValue()]);

  const processFile = async file => {
    input.resetErrors();

    const defaultFileDescription = { icon: TABULAR_FILE_ICON, name: file.name };

    const fileErrors = await validateFile(file);

    if (fileErrors.length > 0) {
      setUploadedFile({
        ...defaultFileDescription,
        percent: 100,
        status: "error",
        errors: fileErrors,
      });
      return;
    }

    // starting file processing
    setUploadedFile({ ...defaultFileDescription, percent: 0, status: "processing" });

    parseCsv(file, {
      complete: data => {
        const [headers, ...values] = data;

        const contentErrors = inputContraints
          ? tabularIntegrityValidator({ headers, values }, inputContraints.columns)
          : [];

        if (contentErrors.length > 0) {
          setUploadedFile({
            ...defaultFileDescription,
            percent: 100,
            status: "error",
            errors: contentErrors,
          });

          return;
        }

        const downloadBlob = `data:text/csv;charset=UTF-8,${encodeURIComponent(Papa.unparse(data))}`;

        setUploadedFile({
          ...defaultFileDescription,
          percent: 100,
          status: "success",
          downloadBlob,
          downloadName: isUrl(defaultFileDescription.name) ? `${inputUIName}.csv` : defaultFileDescription.name,
        });

        // allow swiftly display of progress bar before updating context value
        setTimeout(() => {
          input.setValue(
            {
              headers,
              values,
            },
            false,
            { name: file.name, size: file.size, lastModified: new Date().getTime() }
          );
        }, 500);
      },
      error: () => {
        setUploadedFile({
          ...defaultFileDescription,
          percent: 100,
          status: "error",
          errors: [new Error(t("components.tabularInput.errors.invalidFile"))],
        });
      },
    });
  };

  const handleFileRemoval = () => {
    setUploadedFile(null);
    input.clearValue();
  };

  const processGoogleSpreadSheetUrl = async url => {
    const googleSpreadSheetKey = new URL(url).pathname.split("/")[3];
    try {
      const csvContent = await Api.toCsv(googleSpreadSheetKey);
      const file = new File([csvContent], url, {
        type: "text/csv",
      });

      processFile(file);
    } catch (err) {
      setUploadedFile({
        icon: TABULAR_FILE_ICON,
        name: url,
        percent: 100,
        status: "error",
        errors: [new Error(err.message)],
      });
    }
  };

  const handleRefreshFileUrl = fileUrl => {
    setUploadedFile({ icon: TABULAR_FILE_ICON, name: fileUrl, percent: 0, status: "processing" });
    processGoogleSpreadSheetUrl(fileUrl);
  };

  const parsedErrors = useMemo(() => {
    if (!uploadedFile) {
      return;
    }

    const { errors } = uploadedFile;
    if (!errors || errors.length === 0) {
      return;
    }

    return (
      <div>
        {t("components.tabularInput.errors.uploadFailed")}
        <ul>
          {errors.map((error, index) => (
            <li key={index}>{error.message}</li>
          ))}
        </ul>
        {t("components.tabularInput.errors.tryAgain")}
      </div>
    );
  }, [uploadedFile]);

  return (
    <InputWrapper className={styles.inputTabularWrapper} input={input}>
      <Typography.P2Strong className={styles.header}>{t("components.tabularInput.database")}</Typography.P2Strong>
      <Space direction="vertical">
        <Typography.P3Regular className={styles.description}>
          <a href={`data:text/csv,${CSV_TEMPLATE}`} download="template.csv">
            {t("components.tabularInput.downloadTemplate")}
          </a>
        </Typography.P3Regular>
        {!uploadedFile ? (
          <div>
            <FilePickerV2
              hint={t("components.filePicker.uploadHintCsv", { size: MAX_FILE_SIZE })}
              data-testid="input-tabular-ui"
              beforeUpload={processFile}
              action={() => false}
              accept=".csv"
              maxCount={1}
            />
            <Divider>{t("components.filePickerV2.or")}</Divider>
            <InputUrlUpload
              onProcessUrl={processGoogleSpreadSheetUrl}
              rules={[googleSpreadSheetUrlRule]}
              placeholder={t("components.tabularInput.googleSpreadSheet.placeholder")}
              help={
                <Typography.P3Regular className={styles.googleSpreadsheetHelp}>
                  <Trans t={t} i18nKey="components.tabularInput.googleSpreadSheet.help">
                    <ExternalLink
                      className={styles.sharedGoogleDriveFolderLink}
                      href={
                        SHARED_GOOGLE_DRIVE_FOLDER_LINKS_PER_ENV[process.env.REACT_APP_ENV] ??
                        SHARED_GOOGLE_DRIVE_FOLDER_LINKS_PER_ENV["development"]
                      }
                    >
                      shared Google Drive folder
                    </ExternalLink>
                  </Trans>
                </Typography.P3Regular>
              }
            />
          </div>
        ) : (
          <Form.Item validateStatus={uploadedFile.status} help={parsedErrors}>
            <FileUploadMeta fileMeta={uploadedFile} onRemove={handleFileRemoval} onRefresh={handleRefreshFileUrl} />
          </Form.Item>
        )}
      </Space>
    </InputWrapper>
  );
};

export default InputTabularUi;
