export const fitCanvasToContainer = (containerDimensions, adRatio) => {
  if (!containerDimensions) {
    return;
  }
  const { height } = containerDimensions;
  return computeBestFit({ width: height * adRatio, height }, containerDimensions);
};

export const processImageInput = value => {
  if (!value) {
    return null;
  }
  // if is bin image
  if (value.bin) {
    const imageMeta = value.bin.split(":");
    return `data:${imageMeta[1]};base64,${imageMeta[2]}`;
  }

  return value;
};

export const restrictCoordinates = (coordinates, bounds) => {
  if (!coordinates || !bounds) {
    return;
  }

  const newCoordinates = { ...coordinates };
  const newBounds = { ...bounds };
  if (!newBounds.x) {
    newBounds.x = 0;
  }

  if (!newBounds.y) {
    newBounds.y = 0;
  }

  if (newBounds.width === 0) {
    newCoordinates.x = newBounds.x;
  } else if (coordinates.x > newBounds.x + newBounds.width - coordinates.width) {
    newCoordinates.x = newBounds.x + newBounds.width - coordinates.width;
  } else if (coordinates.x <= newBounds.x) {
    newCoordinates.x = newBounds.x;
  }

  if (newBounds.height === 0) {
    newCoordinates.y = newBounds.y;
  } else if (coordinates.y > newBounds.y + newBounds.height - coordinates.height) {
    newCoordinates.y = newBounds.y + newBounds.height - coordinates.height;
  } else if (coordinates.y <= newBounds.y) {
    newCoordinates.y = newBounds.y;
  }

  return newCoordinates;
};

export const computeBestFit = (boxDimensions, containerDimensions) => {
  // calculate the best fit of the image inside current ad size
  const { width: boxWidth, height: boxHeight } = boxDimensions;
  const { width: containerWidth, height: containerHeight } = containerDimensions;
  const boxRatio = boxWidth / boxHeight;
  let computedWidth = boxWidth;
  let computedHeight = boxHeight;

  if (boxWidth > containerWidth) {
    computedWidth = containerWidth;
    computedHeight = computedWidth / boxRatio;

    if (computedHeight > containerHeight) {
      computedHeight = containerHeight;
      computedWidth = computedHeight * boxRatio;
    }
  } else if (boxHeight > containerHeight) {
    computedHeight = containerHeight;
    computedWidth = computedHeight * boxRatio;
    if (computedWidth > containerWidth) {
      computedWidth = containerWidth;
      computedHeight = computedWidth / boxRatio;
    }
  }

  return { width: computedWidth, height: computedHeight };
};

export const computeAdSize = (adUnitTechnicalId, adRatio) => {
  switch (parseFloat(adRatio)) {
    case 0.6: // Interstitial or In-Article Reveal
      return { width: 1040, height: 1734 };
    case 0.8: // Interstitial
      return { width: 1388, height: 1734 };
    case 0.95: // In Article Reveal
      return { width: 950, height: 1000 };
    case 0.66: // In-Article Reveal or IAB
      return { width: 640, height: 960 };
    case 0.5: // In-Article Reveal or inarticle reveal
      return { width: 600, height: 1200 };
    case 1.2: // In-Article MPU
      return { width: 600, height: 500 };
    case 1.7777: // Header or Footer
      return { width: 1280, height: 720 };
    case 4.4: // Header or Footer
      return adUnitTechnicalId === "header_ad" ? { width: 2640, height: 600 } : { width: 1760, height: 400 };
    case 1.3333: // Thumbnail
      return { width: 720, height: 540 };
    case 3.2: // Footer
      return { width: 1280, height: 400 };
    case 1.78: // In-Article Landscape
      return { width: 1280, height: 720 };

    // custom ratios, might not support pixel positioning correctly
    default:
      return { width: 1024 * adRatio, height: 1024 };
  }
};
export const restrictTransform = (container, canvasDimensions) => {
  const MIN_HEIGHT = 10;
  const MIN_WIDTH = 10;

  const computedBox = {
    ...container,
    x: Math.max(container.x, 0),
    y: Math.max(container.y, 0),
    width: Math.max(MIN_WIDTH, container.width),
    height: Math.max(MIN_HEIGHT, container.height),
  };

  if (computedBox.x + computedBox.width > canvasDimensions.width) {
    computedBox.width = Math.max(MIN_WIDTH, canvasDimensions.width - computedBox.x);
    computedBox.x = canvasDimensions.width - computedBox.width;
  }

  if (computedBox.y + computedBox.height > canvasDimensions.height) {
    computedBox.height = Math.max(MIN_WIDTH, canvasDimensions.height - computedBox.y);
    computedBox.y = canvasDimensions.height - computedBox.height;
  }

  return computedBox;
};
