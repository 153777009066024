import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Divider } from "~/components";
import { Button, Col, Form, InputNumber, Row, Space, Typography } from "@ogury/design-system";
import LinkMIcon from "remixicon-react/LinkMIcon";
import PropTypes from "prop-types";

import style from "./CoordinatesInputs.module.scss";
import LayoutLeft2LineIcon from "remixicon-react/LayoutLeft2LineIcon";
import LayoutRowLineIcon from "remixicon-react/LayoutRowLineIcon";
import LayoutRight2LineIcon from "remixicon-react/LayoutRight2LineIcon";
import LayoutColumnLineIcon from "remixicon-react/LayoutColumnLineIcon";
import LayoutTop2LineIcon from "remixicon-react/LayoutTop2LineIcon";
import LayoutBottom2LineIcon from "remixicon-react/LayoutBottom2LineIcon";
import { useTranslation } from "react-i18next";

const CoordinatesInputs = ({
  coordinates,
  onChange,
  canvasDimensions,
  canvasScale,
  onLockRatio,
  alwaysLockRatio,
  hideSize,
  hidePositioning,
  constraints = {
    x: { min: 0, max: !coordinates?.absoluteValues ? 100 : undefined },
    y: { min: 0, max: !coordinates?.absoluteValues ? 100 : undefined },
    width: { min: 1, max: !coordinates?.absoluteValues ? 100 : undefined },
    height: { min: 1, max: !coordinates?.absoluteValues ? 100 : undefined },
  },
}) => {
  const [lockRatio, setLockRatio] = useState(alwaysLockRatio);
  const [inputValues, setInputValues] = useState(coordinates);
  const [t] = useTranslation();
  const sizeRatio = useMemo(() => coordinates.height / coordinates.width, [coordinates]);

  useEffect(() => {
    if (onChange && JSON.stringify(inputValues) !== JSON.stringify(coordinates)) {
      onChange(inputValues);
    }
  }, [inputValues]);

  const handleLockRatioClick = () => {
    setLockRatio(!lockRatio);
    if (onLockRatio) {
      onLockRatio(!lockRatio);
    }
  };

  const handleWidthChange = value => {
    if (!value) {
      return;
    }

    if (lockRatio) {
      setInputValues({ ...coordinates, width: value, height: value * sizeRatio });
    } else {
      setInputValues({ ...coordinates, width: value });
    }
  };

  const handleHeightChange = value => {
    if (!value) {
      return;
    }

    if (lockRatio) {
      setInputValues({ ...coordinates, height: value, width: value / sizeRatio });
    } else {
      setInputValues({ ...coordinates, height: value });
    }
  };

  const handleOnInputClick = event => event.target.select();

  const formatter = useCallback(
    (value, info) => {
      if (value === undefined || info?.userTyping) {
        return value;
      }
      return parseFloat(value).toFixed(2);
    },
    [coordinates.absoluteValues]
  );

  const imageWidth = coordinates?.width || 1;
  const imageHeight = coordinates?.height || 1;
  const canvasWidth = canvasDimensions?.width / (canvasScale || 1);
  const canvasHeight = canvasDimensions?.height / (canvasScale || 1);
  function handleLeftHorizontally() {
    setInputValues({ ...coordinates, x: 0 });
  }
  function handleCenterHorizontally() {
    const x = coordinates.absoluteValues ? canvasWidth / 2 - imageWidth / 2 : 50 - imageWidth / 2;
    setInputValues({
      ...coordinates,
      x,
    });
  }
  function handleRightHorizontally() {
    const x = coordinates.absoluteValues ? canvasWidth - imageWidth : 100 - imageWidth;
    setInputValues({
      ...coordinates,
      x,
    });
  }
  function handleTopVertically() {
    setInputValues({ ...coordinates, y: 0 });
  }
  function handleCenterVertically() {
    const y = coordinates.absoluteValues ? canvasHeight / 2 - imageHeight / 2 : 50 - imageHeight / 2;
    setInputValues({
      ...coordinates,
      y,
    });
  }
  function handleBottomVertically() {
    const y = coordinates.absoluteValues ? canvasHeight - imageHeight : 100 - imageHeight;
    setInputValues({
      ...coordinates,
      y,
    });
  }

  function renderInputLabel(value) {
    return (
      <>
        <Typography.P2Strong>{value}&nbsp;</Typography.P2Strong>
        <Typography.P2Regular>{coordinates.absoluteValues ? " (pixels)" : " (%)"}</Typography.P2Regular>
      </>
    );
  }

  return (
    <Space size="xs" direction="vertical">
      {!hidePositioning && (
        <div>
          <Button type="tertiary" size="small" onClick={handleLeftHorizontally}>
            <LayoutLeft2LineIcon />
          </Button>
          <Button type="tertiary" size="small" onClick={handleCenterHorizontally}>
            <LayoutColumnLineIcon />
          </Button>
          <Button type="tertiary" size="small" onClick={handleRightHorizontally}>
            <LayoutRight2LineIcon />
          </Button>
          <Button type="tertiary" size="small" onClick={handleTopVertically}>
            <LayoutTop2LineIcon />
          </Button>
          <Button type="tertiary" size="small" onClick={handleCenterVertically}>
            <LayoutRowLineIcon />
          </Button>
          <Button type="tertiary" size="small" onClick={handleBottomVertically}>
            <LayoutBottom2LineIcon />
          </Button>
        </div>
      )}
      <Row gutter={[8, 8]} align="middle" justify="start" wrap={true}>
        <Col>
          <Form.Item label={renderInputLabel("X")}>
            <InputNumber
              min={constraints.x.min}
              max={constraints.x.max}
              width={160}
              value={coordinates?.x}
              formatter={formatter}
              onChange={value => setInputValues({ ...coordinates, x: value || constraints.x.min })}
              onClick={handleOnInputClick}
              disabled={constraints.x.min === constraints.x.max}
              data-testid="input-x"
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label={renderInputLabel("Y")}>
            <InputNumber
              min={constraints.y.min}
              max={constraints.y.max}
              width={160}
              value={coordinates?.y}
              formatter={formatter}
              onChange={value => setInputValues({ ...coordinates, y: value || constraints.x.min })}
              onClick={handleOnInputClick}
              disabled={constraints.y.min === constraints.y.max}
              data-testid="input-y"
            />
          </Form.Item>
        </Col>
        {!hideSize && (
          <>
            <Col>
              <Divider className={style.verticalDivider} type="vertical" />
            </Col>
            <Col>
              <Form.Item label={renderInputLabel(t("inputs.label.width"))}>
                <InputNumber
                  min={constraints.width.min}
                  max={constraints.width.max}
                  width={160}
                  value={coordinates?.width}
                  formatter={formatter}
                  onChange={handleWidthChange}
                  onClick={handleOnInputClick}
                  data-testid="input-width"
                />
              </Form.Item>
            </Col>
            <Col>
              <Button
                type={lockRatio ? "secondary" : "tertiary"}
                iconPosition="iconOnly"
                size="small"
                disabled={alwaysLockRatio}
                onClick={handleLockRatioClick}
                icon={<LinkMIcon />}
                data-testid="button-lock-ratio"
              />
            </Col>
            <Col>
              <Form.Item label={renderInputLabel(t("inputs.label.height"))}>
                <InputNumber
                  min={constraints.height.min}
                  max={constraints.height.max}
                  width={160}
                  value={coordinates?.height}
                  formatter={formatter}
                  onChange={handleHeightChange}
                  onClick={handleOnInputClick}
                  data-testid="input-height"
                />
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
    </Space>
  );
};

CoordinatesInputs.propTypes = {
  coordinates: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onLockRatio: PropTypes.func,
  alwaysLockRatio: PropTypes.bool,
  hideSize: PropTypes.bool,
  hidePositioning: PropTypes.bool,
  disabledInputs: PropTypes.object,
};

export default CoordinatesInputs;
