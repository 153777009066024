import React, { useContext, useState } from "react";

import { Loader } from "components";
import { AccessibilityContext, Side } from "context/AccessibilityContext";
import { LeftItem, PsdExplorer, SettingsTab } from "./components";
import useStores from "~/hooks/useStores";
import References from "~/util/References";
import { Tabs } from "@ogury/design-system";
import { useTranslation } from "react-i18next";

const TABS = {
  LAYERS: "layers",
  ASSETS: "assets",
  SETTING: "settings",
};

export default function LeftPanel() {
  const [t] = useTranslation();
  const [activeTab, setActiveTab] = useState(TABS.LAYERS);
  const { uiStore, setActiveTreeNodeId } = useStores();

  const [accessibilityState] = useContext(AccessibilityContext);

  const isLoading =
    accessibilityState.loading.active === true && accessibilityState.loading.sides.includes(Side.Builder);

  function renderTreeTab() {
    if (uiStore?.length >= 1) {
      return (
        isLoading === false &&
        uiStore[0].inputs.map(treeNode => {
          if (treeNode.display) {
            return <LeftItem key={"treeNode-" + treeNode.id} treeNode={treeNode} />;
          }
        })
      );
    }
  }

  function onClickOnSettingsTab(tabName) {
    if (activeTab === TABS.LAYERS && tabName === TABS.LAYERS) {
      setActiveTreeNodeId(References.ROOT_INPUT_ID);
    }
    setActiveTab(tabName);
  }

  return (
    <div>
      <Tabs
        data-testid="left-panel-tabs"
        tabBarStyle={{ paddingLeft: 16 }}
        onTabClick={onClickOnSettingsTab}
        items={[
          {
            key: TABS.LAYERS,
            label: t("leftPanel.layers"),
            children: renderTreeTab(),
          },
          {
            key: TABS.ASSETS,
            label: t("leftPanel.assets"),
            children: <PsdExplorer />,
          },
          {
            key: TABS.SETTING,
            label: t("leftPanel.settings"),
            children: <SettingsTab />,
          },
        ]}
      />
      {isLoading === true && <Loader overlay />}
    </div>
  );
}
