import React from "react";
import Model from "./Model";
import InputFrameUi from "../ui/InputFrameUi/InputFrameUi";
import { SCHEMA_INPUTS_FIELDS } from "../../../util/ModelConstants";

function isDistanceNull(value1, value2) {
  return Math.abs(value1 - value2) <= 1e-6;
}

export default class InputFrameModel extends Model {
  constructor(...args) {
    super(...args);
  }

  isValid(valueType, value, metas) {
    return (
      valueType === "object" &&
      value.topLeft !== undefined &&
      typeof value.topLeft === "object" &&
      value.topLeft.x !== undefined &&
      typeof value.topLeft.x === "number" &&
      value.topLeft.y !== undefined &&
      typeof value.topLeft.y === "number" &&
      typeof value.bottomRight === "object" &&
      value.bottomRight !== undefined &&
      value.bottomRight.x !== undefined &&
      typeof value.bottomRight.x === "number" &&
      value.bottomRight.y !== undefined &&
      typeof value.bottomRight.y === "number"
    );
  }

  getConstraintsLimits() {
    const constraints = this.getConstraints();
    const frameConstraints = constraints === undefined ? undefined : constraints[SCHEMA_INPUTS_FIELDS.ConstraintsFrame];
    const topLeft =
      frameConstraints === undefined ? undefined : frameConstraints[SCHEMA_INPUTS_FIELDS.ConstraintsFrameTopLeft];
    const bottomRight =
      frameConstraints === undefined ? undefined : frameConstraints[SCHEMA_INPUTS_FIELDS.ConstraintsFrameBottomRight];
    const x = topLeft === undefined ? 0 : topLeft[SCHEMA_INPUTS_FIELDS.ConstraintsFrameX];
    const y = topLeft === undefined ? 0 : topLeft[SCHEMA_INPUTS_FIELDS.ConstraintsFrameY];
    return {
      frame:
        frameConstraints === undefined
          ? undefined
          : {
              x: x,
              y: y,
              width: bottomRight === undefined ? 100 : bottomRight[SCHEMA_INPUTS_FIELDS.ConstraintsFrameX] - x,
              height: bottomRight === undefined ? 100 : bottomRight[SCHEMA_INPUTS_FIELDS.ConstraintsFrameY] - y,
            },
      // frame: undefined,
      ratio: constraints === undefined ? undefined : constraints[SCHEMA_INPUTS_FIELDS.ConstraintsPointRatio],
      // ratio: undefined,
      // ratio: {minimum: 0.75, maximum: 1.25},
      width: constraints === undefined ? undefined : constraints[SCHEMA_INPUTS_FIELDS.ConstraintsPointWidth],
      // width: undefined,
      // width: {minimum: 20, maximum: 75},
      height: constraints === undefined ? undefined : constraints[SCHEMA_INPUTS_FIELDS.ConstraintsPointHeight],
      // height: undefined
      // height: {minimum: 33, maximum: 66}
    };
  }

  getConstraintsOnInputPathReferenceInstance() {
    return Model.getConstraintsOnInputPathReferenceInstance(this, true);
  }

  renderComponent() {
    return InputFrameUi;
  }

  boundCoordinates(value, width, height, previousValue) {
    const constraintsLimits = this.getConstraintsLimits();
    const applyConstraints = (constraintProperty, valueProperty, topLeftValue, bottomRightValue, dimensionValue) => {
      const constraint = constraintsLimits[constraintProperty];
      if (constraint !== undefined) {
        if (
          constraint[SCHEMA_INPUTS_FIELDS.ConstraintsRangeMinimum] !== undefined &&
          (bottomRightValue - topLeftValue) / dimensionValue <
            constraint[SCHEMA_INPUTS_FIELDS.ConstraintsRangeMinimum] / 100
        ) {
          if (previousValue !== undefined) {
            if (isDistanceNull(value.topLeft[valueProperty], previousValue.topLeft[valueProperty]) === true) {
              topLeftValue = previousValue.topLeft[valueProperty];
              bottomRightValue =
                topLeftValue + (constraint[SCHEMA_INPUTS_FIELDS.ConstraintsRangeMinimum] * dimensionValue) / 100;
            } else if (
              isDistanceNull(value.bottomRight[valueProperty], previousValue.bottomRight[valueProperty]) === true
            ) {
              bottomRightValue = previousValue.bottomRight[valueProperty];
              topLeftValue =
                bottomRightValue - (constraint[SCHEMA_INPUTS_FIELDS.ConstraintsRangeMinimum] * dimensionValue) / 100;
            }
          } else {
            bottomRightValue =
              topLeftValue + (constraint[SCHEMA_INPUTS_FIELDS.ConstraintsRangeMinimum] * dimensionValue) / 100;
          }
        } else if (
          constraint[SCHEMA_INPUTS_FIELDS.ConstraintsRangeMaximum] !== undefined &&
          (bottomRightValue - topLeftValue) / dimensionValue >
            constraint[SCHEMA_INPUTS_FIELDS.ConstraintsRangeMaximum] / 100
        ) {
          if (previousValue !== undefined) {
            if (isDistanceNull(value.topLeft[valueProperty], previousValue.topLeft[valueProperty]) === true) {
              topLeftValue = previousValue.topLeft[valueProperty];
              bottomRightValue =
                topLeftValue + (constraint[SCHEMA_INPUTS_FIELDS.ConstraintsRangeMaximum] * dimensionValue) / 100;
            } else if (
              isDistanceNull(value.bottomRight[valueProperty], previousValue.bottomRight[valueProperty]) === true
            ) {
              bottomRightValue = previousValue.bottomRight[valueProperty];
              topLeftValue =
                bottomRightValue - (constraint[SCHEMA_INPUTS_FIELDS.ConstraintsRangeMaximum] * dimensionValue) / 100;
            }
          } else {
            bottomRightValue =
              topLeftValue + (constraint[SCHEMA_INPUTS_FIELDS.ConstraintsRangeMaximum] * dimensionValue) / 100;
          }
        }
      }
      return { left: topLeftValue, right: bottomRightValue };
    };
    let x1 = value.topLeft.x;
    let y1 = value.topLeft.y;
    let x2 = value.bottomRight.x;
    let y2 = value.bottomRight.y;

    const constraintsFrame = constraintsLimits.frame;
    if (constraintsFrame !== undefined) {
      if (x1 < (constraintsFrame.x * width) / 100) {
        x1 = (constraintsFrame.x * width) / 100;
      } else if (x2 > ((constraintsFrame.x + constraintsFrame.width) * width) / 100) {
        const previous = x2;
        x2 = ((constraintsFrame.x + constraintsFrame.width) * width) / 100;
        x1 += x2 - previous;
      }
      if (y1 < (constraintsFrame.y * height) / 100) {
        y1 = (constraintsFrame.y * height) / 100;
      } else if (y2 > ((constraintsFrame.y + constraintsFrame.height) * height) / 100) {
        const previous = y2;
        y2 = ((constraintsFrame.y + constraintsFrame.height) * height) / 100;
        y1 += y2 - previous;
      }
    }

    const xResult = applyConstraints("width", "x", x1, x2, width);
    x1 = xResult.left;
    x2 = xResult.right;
    const yResult = applyConstraints("height", "y", y1, y2, height);
    y1 = yResult.left;
    y2 = yResult.right;
    if (constraintsLimits.ratio !== undefined) {
      if (
        constraintsLimits.ratio[SCHEMA_INPUTS_FIELDS.ConstraintsRangeMinimum] !== undefined &&
        (x2 - x1) / (y2 - y1) < constraintsLimits.ratio[SCHEMA_INPUTS_FIELDS.ConstraintsRangeMinimum]
      ) {
        y2 = y1 + (x2 - x1) / constraintsLimits.ratio[SCHEMA_INPUTS_FIELDS.ConstraintsRangeMinimum];
      } else if (
        constraintsLimits.ratio[SCHEMA_INPUTS_FIELDS.ConstraintsRangeMaximum] !== undefined &&
        (x2 - x1) / (y2 - y1) > constraintsLimits.ratio[SCHEMA_INPUTS_FIELDS.ConstraintsRangeMaximum]
      ) {
        y2 = y1 + (x2 - x1) / constraintsLimits.ratio[SCHEMA_INPUTS_FIELDS.ConstraintsRangeMaximum];
      }
    }

    return {
      topLeft: {
        x: x1,
        y: y1,
      },
      bottomRight: {
        x: x2,
        y: y2,
      },
    };
  }
}
