import { NumberEdit } from "..";
import { Slider, Typography } from "@ogury/design-system";
import style from "./SliderWithNumber.module.scss";

export default function SliderWithNumber({
  sliderValue,
  inputValue,
  isFloat,
  rangeBounds,
  rangeMinimum,
  rangeMaximum,
  granularity,
  onSlideChange,
  onChange,
}) {
  return (
    <div style={{ display: "flex" }}>
      {rangeBounds !== undefined && rangeBounds[0] !== undefined && rangeBounds[1] !== undefined && (
        <div className={style.sliderContainer}>
          <Typography.P2Regular className={style.minRange}>{rangeMinimum}</Typography.P2Regular>
          <Slider
            style={{ width: "100%" }}
            min={rangeMinimum}
            max={rangeMaximum}
            step={granularity}
            value={sliderValue}
            onChange={onSlideChange}
            onAfterChange={onChange}
          />
          <Typography.P2Regular className={style.maxRange}>{rangeMaximum}</Typography.P2Regular>
        </div>
      )}
      <div>
        <NumberEdit
          digits={isFloat === true ? -1 : 0}
          minimum={rangeMinimum}
          maximum={rangeMaximum}
          step={granularity}
          value={inputValue}
          onChange={newValue => {
            onChange(newValue);
            return newValue;
          }}
        />
      </div>
    </div>
  );
}
