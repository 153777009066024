import React, { useState, useEffect } from "react";
import style from "./CodeEditor.module.scss";
import { useExperience } from "~/context";
import Editor from "@monaco-editor/react";

import schema from "./schema.json";

export default function CodeEditor() {
  const { experience, setExperience } = useExperience();

  const [code, setCode] = useState("");

  function formatJSON(val = {}) {
    try {
      return JSON.stringify(val, null, 2);
    } catch {
      const errorJson = {
        error: `Error formatting the code${val}`,
      };
      return JSON.stringify(errorJson, null, 2);
    }
  }

  useEffect(() => {
    if (code === "" && experience.description) {
      setCode(formatJSON(experience.description));
    }
  }, [experience]);

  function onValidate(markers) {
    if (markers.length > 0) {
      setExperience({ ...experience, dirty: false });
    }
  }

  function onCodeChange(value) {
    setCode(value);
    try {
      const newJSON = JSON.parse(value);
      setExperience({ ...experience, description: newJSON, dirty: true });
    } catch (error) {
      console.warn("Not valid JSON", error);
    }
  }

  function renderCode() {
    return (
      <Editor
        height="90vh"
        defaultLanguage="json"
        value={code}
        onChange={onCodeChange}
        onValidate={onValidate}
        onMount={(editor, monaco) => {
          editor.updateOptions({
            automaticLayout: true,
            contextmenu: true,
            lineNumbers: "on",
            scrollBeyondLastLine: false,
            autoIndent: true,
            showFoldingControls: "always",
            foldingHighlight: false,
          });
          monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
            validate: true,
            allowComments: true,
            schemas: [
              {
                fileMatch: ["*"],
                schema: schema,
              },
            ],
          });
        }}
      />
    );
  }

  return <div className={style.codeEditorContainer}>{renderCode()}</div>;
}
