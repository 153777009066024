import Model from "./Model";
import InputZipUi from "app/inputs/ui/InputZipUi";

export default class InputZipModel extends Model {
  constructor(...args) {
    super(...args);
  }

  renderComponent() {
    return InputZipUi;
  }
}
