import Api from "./Api";
import i18n from "i18next";
import { FILE_PROTOCOL } from "~/util/Constant";

export function isEmpty(object) {
  if (object instanceof Object) {
    for (const property in object) {
      if (object.hasOwnProperty(property) === true) {
        return false;
      }
    }
  } else if (object == null) {
    return true;
  } else {
    return object.length === 0;
  }
  return true;
}

export function isUrl(url) {
  try {
    new URL(url);
  } catch (error) {
    return false;
  }
  return true;
}

export function random(size = 8) {
  return Array(size)
    .fill("0123456789")
    .map(x => {
      return x[Math.floor(Math.random() * x.length)];
    })
    .join("");
}

export async function urlToBlob(url, doNotSupportCors) {
  let attemptWithProxy = false;
  try {
    // We first try with no CORS support
    const response = await fetch(url, { cache: "default", redirect: "follow", mode: "no-cors" });
    if (response.ok === true) {
      return { blob: await response.blob(), viaProxy: false };
    } else if (response.url === "") {
      // Then, we try with the CORS support
      attemptWithProxy = true;
      const secondResponse = await fetch(url, { cache: "default", mode: "cors" });
      if (secondResponse.ok === true) {
        return { blob: await secondResponse.blob(), viaProxy: false };
      } else {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error(i18n.t("error.download.CORSRestriction", { url: url }));
      }
    } else {
      // noinspection ExceptionCaughtLocallyJS
      throw new Error(i18n.t("error.download.unknownReason", { url: url }));
    }
  } catch (error) {
    const downloadViaProxy = async () => {
      try {
        const blob = await Api.useProxy(url);

        return { blob: blob, viaProxy: true };
      } catch (innerError) {
        throw new Error(
          i18n.t("error.download.viaProxy", {
            url: url,
            reason: innerError.message || innerError.error,
          })
        );
      }
    };
    if (attemptWithProxy === true) {
      // The issue very likely to be a CORS restriction issue, hence we use the proxy server
      if (doNotSupportCors === true) {
        throw new Error(i18n.t("error.download.noSupportCORS", { url: url }));
      } else {
        return await downloadViaProxy();
      }
    } else {
      if (error.message === "Failed to fetch") {
        // This happens in case of Internet connection issue or in case of a CORS issue when the server sets the "Cross-Origin-Resource-Policy: same-origin" header response
        if (doNotSupportCors !== true) {
          // We re-attempt, even in case of an Internet connexion issue, because this might by a CORS issue
          return await downloadViaProxy();
        }

        throw new Error(i18n.t("error.download.internetIssue", { url: url }));
      } else {
        throw error;
      }
    }
  }
}

export function isInIframe() {
  return window.location !== window.parent.location;
}

// Taken from https://codesandbox.io/s/blue-bush-bh006?file=/src/App.js:89-461
export function debounce(func, wait, immediate) {
  let timeout;

  return (...args) => {
    let context = this;
    let later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);

    if (callNow) {
      func.apply(context, args);
    }
  };
}

export function downloadBlob(blob, fileName) {
  // noinspection JSUnresolvedVariable
  const isIE = !!document.documentMode;
  if (isIE === true) {
    window.navigator.msSaveBlob(blob, fileName);
  } else {
    const url = window.URL || window.webkitURL;
    const link = url.createObjectURL(blob);
    const downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", link);
    downloadAnchorNode.setAttribute("download", fileName);
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }
}

export function getImageDimensionsFromUrl(imageUrl) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const dimensions = { width: img.width, height: img.height };
      resolve(dimensions);
    };
    img.onerror = () => {
      reject(new Error("Failed to load image from URL"));
    };
    img.src = imageUrl;
  });
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function removeFileProtocolPrefix(string) {
  if (string.startsWith(FILE_PROTOCOL)) {
    return string.substring(FILE_PROTOCOL.length);
  }
  return string;
}

export function contentTypeIsImage(contentType) {
  return contentType.includes("image");
}
export function contentTypeIsVideo(contentType) {
  return contentType.includes("video");
}
export function contentTypeIsBuilderAsset(contentType) {
  return /font|image|video/.test(contentType);
}

export function generateColors(numColors, seed = 42) {
  const baseColors = [
    "rgba(255, 99, 132, 0.5)", // Light Red
    "rgba(54, 162, 235, 0.5)", // Light Blue
    "rgba(255, 206, 86, 0.5)", // Light Yellow
    "rgba(75, 192, 192, 0.5)", // Light Teal
    "rgba(153, 102, 255, 0.5)", // Light Purple
    "rgba(255, 159, 64, 0.5)", // Light Orange
    "rgba(233, 30, 99, 0.5)", // Pink
    "rgba(33, 150, 243, 0.5)", // Blue
    "rgba(76, 175, 80, 0.5)", // Green
    "rgba(255, 87, 34, 0.5)", // Deep Orange
  ];

  const colors = new Set();
  const rng = seededRandom(seed);

  while (colors.size < numColors) {
    const baseColor = baseColors[colors.size % baseColors.length];
    const color = adjustColor(baseColor, rng);
    colors.add(color);
  }

  return Array.from(colors);
}

function adjustColor(rgba, rng) {
  const color = rgba.match(/[\d.]+/g).map(Number);
  for (let i = 0; i < 3; i++) {
    color[i] += rng.nextInt(30) - 15;
    color[i] = Math.min(255, Math.max(0, color[i]));
  }
  return `rgba(${color.join(", ")})`;
}

function seededRandom(seed) {
  function random() {
    const x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
  }

  return {
    nextInt: function (max) {
      return Math.floor(random() * max);
    },
  };
}

export function addSuffixOnDupplicates(stringArray) {
  let result = [];

  function strArr(s, a) {
    for (let j = 0; j < a.length; j++) {
      if (a[j] === s) {
        return j;
      }
    }
    return -1;
  }

  for (let i = 0; i < stringArray.length; i++) {
    let word = stringArray[i];
    let suffix = 1;

    while (strArr(word, result) >= 0) {
      word = stringArray[i] + "-" + suffix++;
    }
    result.push(word);
  }
  return result;
}

export function getOS() {
  let userAgent = window.navigator.userAgent.toLowerCase(),
    macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i,
    windowsPlatforms = /(win32|win64|windows|wince)/i,
    iosPlatforms = /(iphone|ipad|ipod)/i,
    os = null;

  if (macosPlatforms.test(userAgent)) {
    os = "macos";
  } else if (iosPlatforms.test(userAgent)) {
    os = "ios";
  } else if (windowsPlatforms.test(userAgent)) {
    os = "windows";
  } else if (/android/.test(userAgent)) {
    os = "android";
  } else if (/linux/.test(userAgent)) {
    os = "linux";
  }

  return os;
}
