import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Api from "~/util/Api";
import { BinaryUiModalDialog, IconNames, Icons, Select } from "components";
import Model from "../../model/Model";
import {
  fromBase64DataToBlob,
  fromBlobToDataUrl,
  fromBlobToMimeType,
  fromDataUrlToBlob,
  isDataUrl,
} from "~/util/InputsComputer";
import BinaryUi from "components/BinaryUi/BinaryUi";
import { toStringWithBytes } from "~/util/Helpers";
import { urlToBlob } from "~/util/Utils";
import style from "./InputFontUi.module.scss";
import { theme, Button, Typography } from "@ogury/design-system";

const InputFontEdit = ({ urlOrDataUrl, mimeType, authorizedMimeTypes, isOpen, setOpen, setDataUrl }) => {
  const computeMimeTypes = (mimeTypes, excludedMimeType) => {
    return mimeTypes.filter(mimeType => {
      return mimeType !== excludedMimeType;
    });
  };
  const [internalUrlOrDataUrl, setInternalUrlOrDataUrl] = useState(urlOrDataUrl);
  const [internalMimeType, setInternalMimeType] = useState(mimeType);
  const [isChanged, setChanged] = useState(false);
  const [selectableMimeTypes, setSelectableMimeTypes] = useState(computeMimeTypes(authorizedMimeTypes, mimeType));
  const [selectedMimeType, setSelectedMimeType] = useState(selectableMimeTypes[0]);
  const [isWorking, setWorking] = useState(false);
  const [message, setMessage] = useState(undefined);
  const [t] = useTranslation();

  useEffect(() => {
    setInternalUrlOrDataUrl(urlOrDataUrl);
  }, [urlOrDataUrl]);

  useEffect(() => {
    setInternalMimeType(mimeType);
  }, [mimeType]);

  useEffect(() => {
    setSelectableMimeTypes(computeMimeTypes(authorizedMimeTypes, internalMimeType));
  }, [authorizedMimeTypes, internalMimeType]);

  useEffect(() => {
    setSelectedMimeType(selectableMimeTypes[0]);
  }, [selectableMimeTypes]);

  const handleOnConvertClick = async () => {
    setWorking(true);
    setMessage(undefined);
    try {
      let inputBlob;
      if (isDataUrl(internalUrlOrDataUrl) === true) {
        inputBlob = fromDataUrlToBlob(internalUrlOrDataUrl);
      } else {
        inputBlob = (await urlToBlob(internalUrlOrDataUrl)).blob;
      }
      const format = Model.mimeTypeToFormat(selectedMimeType);
      const resultBlob = await Api.convertFont(format, inputBlob);
      const newDataUrl = await fromBlobToDataUrl(resultBlob);
      setInternalUrlOrDataUrl(newDataUrl);
      setChanged(true);
      setMessage({
        isError: false,
        text: "The font has been converted",
      });
    } catch (error) {
      setMessage({ isError: true, text: error.message });
    } finally {
      setWorking(false);
    }
  };

  return (
    <>
      <Button
        data-testid="btn-edit-font"
        type="tertiary"
        size="small"
        onClick={() => {
          setMessage(undefined);
          setOpen(true);
        }}
        icon={<Icons name={IconNames.Pencil.type} />}
        iconPosition="iconOnly"
      />
      {isOpen === true && (
        <BinaryUiModalDialog
          isOpen={isOpen}
          setOpen={setOpen}
          width="50%"
          title={t("notification.editFont.title")}
          isChanged={isChanged}
          onApplyUrlOrDataUrl={async () => {
            await setDataUrl(internalUrlOrDataUrl);
          }}
          onRevertUrlOrDataUrl={() => {
            setInternalUrlOrDataUrl(urlOrDataUrl);
            setInternalMimeType(mimeType);
            setChanged(false);
          }}
          buttons={<></>}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography.P2Regular>{Model.mimeTypeToFormat(internalMimeType)}</Typography.P2Regular>
            <Typography.P2Regular style={{ marginLeft: 16, marginRight: 16 }}>=&gt;</Typography.P2Regular>
            <Select
              width="150px"
              value={selectedMimeType}
              onChange={setSelectedMimeType}
              options={selectableMimeTypes.map(mimeType => ({
                label: mimeType,
                value: mimeType,
              }))}
            />
            <Button
              style={{
                marginLeft: 16,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
              }}
              disabled={isWorking}
              onClick={handleOnConvertClick}
              loading={isWorking}
            >
              {t("inputsUI.button.font.convert")}
            </Button>
          </div>
          <div>
            {message && (
              <Typography.P2Regular
                style={{
                  minHeight: "2em",
                  color: message.isError === true ? theme.colors.destructive.content : "",
                }}
              >
                {message.text}
              </Typography.P2Regular>
            )}
          </div>
        </BinaryUiModalDialog>
      )}
    </>
  );
};

const InputFontUi = ({ input }) => {
  const formats = input.getFormatsConstraints();
  const mimeTypes =
    formats === undefined
      ? ["font/ttf", "font/otf", "font/woff", "font/woff2", "font/sfnt"]
      : Model.formatsToMimeTypes(formats);
  const idSuffix = input.getId().replace(/[.]/g, "-");
  const fontFamily = "preview-" + idSuffix;
  const [isOpen, setOpen] = useState(false);

  const [t] = useTranslation();

  function writeFontStyleInDOM(fontUrl) {
    const styleId = "style-font-" + idSuffix;
    const previousStyle = document.getElementById(styleId);
    if (previousStyle !== null) {
      document.head.removeChild(previousStyle);
    }
    const style = document.createElement("style");
    style.id = styleId;
    document.head.appendChild(style);
    style.innerHTML = `
            @font-face
            {
                font-family: "${fontFamily}";
                src: url(${fontUrl});
            }
        `;
  }

  return (
    <BinaryUi
      inputValue={input.getValue()}
      inputName={input.getFilename()}
      onValueChanged={value => input.setValue(value)}
      fileExtensions={
        formats === undefined
          ? [".ttf", ".otf", ".woff", ".woff2", ".eot"]
          : Model.formatsToFileExtensions(formats).map(extension => {
              return "." + extension;
            })
      }
      mimeTypes={mimeTypes}
      doNotSupportCors={true}
      placeholder="Font URL"
      preview={urlOrDataUrl => {
        writeFontStyleInDOM(urlOrDataUrl);
        return (
          <div>
            <Typography.P2Regular
              style={{
                fontFamily: fontFamily,
                fontSize: "1.2rem",
              }}
            >
              {t("inputs.sampleText.text")}
            </Typography.P2Regular>
          </div>
        );
      }}
      actions={(value, setDataUrl) => {
        return [
          <InputFontEdit
            key="edit"
            urlOrDataUrl={value.urlOrDataUrl}
            mimeType={value.mimeType}
            authorizedMimeTypes={mimeTypes}
            isOpen={isOpen}
            setOpen={setOpen}
            setDataUrl={setDataUrl}
          />,
        ];
      }}
      properties={value => {
        return (
          <div className={style.propertiesContainer}>
            <Typography.P2Regular className={style.name}>{value.name}</Typography.P2Regular>
            <div>
              {value.size !== undefined && <Typography.P2Regular>{toStringWithBytes(value.size)}</Typography.P2Regular>}
              {value.mimeType !== undefined && (
                <Typography.P2Regular> (type {Model.mimeTypeToFormat(value.mimeType)})</Typography.P2Regular>
              )}
            </div>
          </div>
        );
      }}
      computeObject={async () => {
        return undefined;
      }}
      computeBlob={async value => {
        if (value.data !== undefined) {
          return fromBase64DataToBlob(value.data, value.mimeType);
        } else {
          return (await urlToBlob(value.url, false)).blob;
        }
      }}
      fromMimeTypeToFileExtension={mimeType => {
        switch (mimeType) {
          default:
            return undefined;
          case "font/otf":
          case "application/x-font-opentype":
            return "otf";
          case "font/woff":
            return "woff";
          case "font/woff2":
          case "application/font-woff2":
            return "woff2";
          case "font/ttf":
          case "application/x-font-truetype":
          case "application/font-sfnt":
            return "ttf";
          case "font/collection":
            return "ttc";
          case "application/vnd.ms-fontobject":
            return "eot";
        }
      }}
      onValidate={async (blob, options) => {
        const mimeType = options.mimeType || (await fromBlobToMimeType(blob));
        if (
          mimeType.indexOf("font/") !== 0 &&
          (mimeType.indexOf("application/") !== 0 || mimeType.indexOf("font") === -1)
        ) {
          throw new Error(t("error.invalid.font", { mimeType: mimeType }));
        }
        options.mimeType = mimeType;
        // TODO: use the Font API to check the validity of the font, and add the Font object to the "options", once loaded
        return options;
      }}
    />
  );
};

export default InputFontUi;
