import React, { useEffect, useState } from "react";

import { NumberEdit, InputWrapper } from "components";
import { Select } from "@ogury/design-system";

const customKey = "Custom";
export const builtInRatios = {
  [customKey]: { name: undefined, label: "Custom", value: undefined },
  IAB120x600: {
    name: "120x600",
    label: 'IAB "SKYSCRAPER" 120x600',
    value: 0.2,
  },
  IAB160x600: {
    name: "160x600",
    label: 'IAB "MEGASKYSCRAPER" 160x600',
    value: 0.2666,
  },
  IAB300x600: {
    name: "300x600",
    label: 'IAB "GRAND ANGLE" 300x600',
    value: 0.5,
  },
  Instagram: { name: "Instagram", label: "Instagram 1080x1920", value: 0.5625 },
  IAB320x480: {
    name: "320x480",
    label: "IAB 320x480 and 640x960",
    value: 0.6666,
  },
  Square: { name: "Square", label: "Square", value: 1 },
  IAB300x250: { name: "300x250", label: 'IAB "PAVE" 300x250', value: 1.2 },
  Facebook1200x628: {
    name: "1200x628",
    label: "Facebook 1200x628",
    value: 1.9108,
  },
  IAB970x250: {
    name: "970x250",
    label: 'IAB "BILLBOARD" 970x250',
    value: 3.88,
  },
  IAB994x250: {
    name: "994x250",
    label: 'IAB "WIDEBOARD" 994x250',
    value: 3.976,
  },
  IAB300x50: {
    name: "300x50",
    label: 'IAB "Smartphone Banner" 300x50',
    value: 6,
  },
  IAB320x50: {
    name: "320x50",
    label: 'IAB "Smartphone Banner" 320x50',
    value: 6.4,
  },
  IAB728x90: {
    name: "728x90",
    label: 'IAB "Leaderboard" 728x90',
    value: 8.0888,
  },
  IAB970x90: {
    name: "970x90",
    label: 'IAB "Super Leaderboard" 970x90',
    value: 10.7777,
  },
};

const InputRatioUi = ({ input }) => {
  const [value, setValue] = useState(input.getValue());
  const rangeBounds = input.getRangeBounds();
  const minimum = rangeBounds === undefined ? undefined : rangeBounds[0];
  const maximum = rangeBounds === undefined ? undefined : rangeBounds[1];
  const filteredItems = rangeBounds === undefined ? builtInRatios : {};

  useEffect(() => {
    setValue(input.getValue());
  }, [input.getValue()]);

  if (rangeBounds !== undefined) {
    Object.keys(builtInRatios).forEach(key => {
      const item = builtInRatios[key];
      let keep = true;
      if (item.value !== undefined) {
        if (minimum !== undefined && item.value < minimum) {
          keep = false;
        }
        if (maximum !== undefined && item.value > maximum) {
          keep = false;
        }
      }
      if (keep === true) {
        filteredItems[key] = item;
      }
    });
  }

  const options = Object.keys(filteredItems).map(item => ({
    label: filteredItems[item].label,
    value: filteredItems[item].value,
  }));

  return (
    <InputWrapper input={input}>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Select
          disabled={input.isUiDisabled()}
          name={input.getId()}
          value={value}
          width={150}
          options={options}
          dropdownWidth={280}
          onChange={optionValue => {
            if (optionValue !== undefined) {
              setValue(optionValue);
              input.setValue(optionValue);
            }
          }}
        />
        <div style={{ marginLeft: 10 }}>
          <NumberEdit
            disabled={input.isUiDisabled()}
            label="Ratio"
            digits={4}
            style={{ width: 100 }}
            minimum={minimum}
            maximum={maximum}
            step={0.05}
            value={value}
            onChange={newValue => {
              setValue(newValue);
              input.setValue(newValue);
              return newValue;
            }}
          />
        </div>
      </div>
    </InputWrapper>
  );
};

export default InputRatioUi;
