import { forwardRef } from "react";
import * as PropTypes from "prop-types";
import localStyle from "./Image.module.scss";

const Image = forwardRef(
  (
    {
      src,
      alt = "",
      onError = () => {},
      onLoad = () => {},
      style = {},
      crossOrigin = "",
      className = "",
      withCheckboardBackground = false,
    },
    ref
  ) => {
    return (
      <img
        className={`${localStyle.container} ${withCheckboardBackground && localStyle.withBackground} ${className}`}
        src={src}
        alt={alt}
        ref={ref}
        crossOrigin={crossOrigin}
        onError={onError}
        onLoad={onLoad}
        style={style}
      />
    );
  }
);

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  ref: PropTypes.func,
  onError: PropTypes.func,
  onLoad: PropTypes.func,
  style: PropTypes.object,
  crossOrigin: PropTypes.string,
  className: PropTypes.string,
  withCheckboardBackground: PropTypes.bool,
};
export default Image;
