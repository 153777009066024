import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Rect, Transformer } from "react-konva/es/ReactKonva";

import Style from "~/util/Style";
import { restrictCoordinates, restrictTransform } from "components/AdUnitCanvas/helpers";

const violet = Style.variables["violet"];
const violetRgba = Style.variables["violetRgba"];

const TransformerWrapper = ({ x, y, width, height, onChange, canvasDimensions, lockRatio }) => {
  const shapeRef = useRef();
  const transformerRef = useRef();

  const coordinates = {
    leftTouch: Math.trunc(x) <= 0,
    rightTouch: Math.ceil(x + width) >= canvasDimensions?.width,
    topTouch: Math.trunc(y) <= 0,
    bottomTouch: Math.ceil(y + height) >= canvasDimensions?.height,
    transformerBoundingRect: {
      x,
      y,
      width,
      height,
    },
  };

  const [isTransforming, setIsTransforming] = useState(false);

  useEffect(() => {
    // we need to attach transformer manually
    transformerRef.current.nodes([shapeRef.current]);
    transformerRef.current.getLayer().batchDraw();
  }, []);

  const handleDragMove = event => {
    const coordinates = event.target;
    const restrictedCoordinates = restrictCoordinates(
      { x: coordinates.x(), y: coordinates.y(), width: coordinates.width(), height: coordinates.height() },
      canvasDimensions
    );

    coordinates.x(restrictedCoordinates.x);
    coordinates.y(restrictedCoordinates.y);
  };

  const handleDragEnd = event => {
    onChange({
      width,
      height,
      x: event.target.x(),
      y: event.target.y(),
    });

    setIsTransforming(false);
  };

  const handleTransformEnd = e => {
    // transformer is changing scale of the node
    // and NOT its width or height
    // but in the store we have only width and height
    // to match the data better we will reset scale on transform end
    const node = shapeRef.current;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    // we will reset it back
    node.scaleX(1);
    node.scaleY(1);
    onChange({
      x: node.x(),
      y: node.y(),
      // set minimal value
      width: node.width() * scaleX,
      height: node.height() * scaleY,
    });

    setIsTransforming(false);
  };

  const { leftTouch, rightTouch, topTouch, bottomTouch, transformerBoundingRect } = coordinates;
  const enabledAnchors = lockRatio ? ["top-left", "top-right", "bottom-left", "bottom-right"] : undefined;
  return (
    <>
      <Rect
        ref={shapeRef}
        x={x}
        y={y}
        width={width}
        height={height}
        fill={violetRgba}
        draggable
        onDragStart={() => setIsTransforming(true)}
        onDragMove={handleDragMove}
        onDragEnd={handleDragEnd}
        onTransformStart={() => setIsTransforming(true)}
        onTransformEnd={handleTransformEnd}
        keepRatio={lockRatio}
      />
      {!isTransforming && (
        <>
          {leftTouch && (
            <Rect
              data-testid="left-touch-guide"
              width={2}
              height={transformerBoundingRect.height}
              x={transformerBoundingRect.x}
              y={transformerBoundingRect.y}
              fill="#FF00C7"
            />
          )}
          {rightTouch && (
            <Rect
              data-testid="right-touch-guide"
              width={2}
              height={transformerBoundingRect.height}
              x={Math.trunc(transformerBoundingRect.x + transformerBoundingRect.width) - 2}
              y={transformerBoundingRect.y}
              fill="#FF00C7"
            />
          )}
          {topTouch && (
            <Rect
              data-testid="top-touch-guide"
              width={transformerBoundingRect.width}
              height={2}
              x={transformerBoundingRect.x}
              y={transformerBoundingRect.y}
              fill="#FF00C7"
            />
          )}
          {bottomTouch && (
            <Rect
              data-testid="bottom-touch-guide"
              width={transformerBoundingRect.width}
              height={2}
              x={transformerBoundingRect.x}
              y={Math.trunc(transformerBoundingRect.y + transformerBoundingRect.height) - 2}
              fill="#FF00C7"
            />
          )}
        </>
      )}
      <Transformer
        ref={transformerRef}
        rotateEnabled={false}
        keepRatio={lockRatio}
        enabledAnchors={enabledAnchors}
        centeredScaling={false}
        anchorStroke={violetRgba}
        anchorFill={violet}
        anchorSize={10}
        borderEnabled={false}
        boundBoxFunc={(_, newBox) => restrictTransform(newBox, canvasDimensions)}
      />
    </>
  );
};

TransformerWrapper.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  canvasDimensions: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  lockRatio: PropTypes.bool,
};

export default TransformerWrapper;
