import React from "react";

import { computeUiValueFromUrlOrBinaryString, fromBinaryStringValueToDataUrl } from "~/util/InputsComputer";
import {
  toStringWithBytes,
  toStringWithCollection,
  toStringWithGreaterThan,
  toStringWithInteger,
  toStringWithLessThan,
  toStringWithRange,
  toStringWithRatio,
} from "~/util/Helpers";
import InputBinaryModel from "./InputBinaryModel";
import InputSpriteUi from "../ui/InputSpriteUi/InputSpriteUi";
import Model from "./Model";
import { SCHEMA_INPUTS_FIELDS } from "~/util/ModelConstants";
import { loadImage } from "~/util/Image";
import { Typography } from "@ogury/design-system";
import i18n from "i18next";

export default class InputSpriteModel extends InputBinaryModel {
  constructor(...args) {
    super(...args);
  }

  async getDimensions() {
    const image = await loadImage(this.getValue());
    if (image === undefined) {
      return undefined;
    }
    return { width: image.naturalWidth, height: image.naturalHeight };
  }

  async computeMetas() {
    const image = await loadImage(this.getValue());
    if (image === undefined) {
      return undefined;
    }
    const { mimeType } = await computeUiValueFromUrlOrBinaryString(this.getValue());
    return {
      ratio: image.naturalWidth / image.naturalHeight,
      width: image.naturalWidth,
      height: image.naturalHeight,
      mimeType,
    };
  }

  computeSrc() {
    const value = this.getValue();
    return value === undefined
      ? undefined
      : value.bin !== undefined
      ? fromBinaryStringValueToDataUrl(value.bin)
      : value;
  }

  getConstraintsOnInputPathReferenceInstance() {
    return Model.getConstraintsOnInputPathReferenceInstance(this, false);
  }

  getFormatsConstraints() {
    return Model.getFormatsConstraints(this);
  }

  getRatioConstraints() {
    return Model.getRatioConstraints(this);
  }

  getMinimumPixelsConstraints() {
    const constraints = this.getConstraints();
    return constraints === undefined ? undefined : constraints[SCHEMA_INPUTS_FIELDS.ConstraintsRatioMinimumPixels];
  }

  async computeConstraintsErrors() {
    // Checking that the file value is not corrupted (wrong URL or wrong binary data)
    try {
      await computeUiValueFromUrlOrBinaryString(this.getValue());
    } catch (error) {
      return [
        {
          message: i18n.t("error.invalid.corruptedBinary"),
        },
      ];
    }

    const formats = this.getFormatsConstraints();
    if (formats !== undefined) {
      const mimeType = await this.getMimeType();
      const format = Model.mimeTypeToFormat(mimeType);
      if (format === undefined) {
        return [{ message: i18n.t("error.unhandled.imageMIMEType", { mimeType: mimeType }) }];
      }
      if (formats.indexOf(format) === -1) {
        return [
          {
            message: i18n.t("error.notSupported.imageFormat", { format: format }) + toStringWithCollection(formats),
          },
        ];
      }
    }

    const { minimum: minimumRatio, maximum: maximumRatio } = this.getRatioConstraints();

    const imageMetas = await this.computeMetas();
    if (imageMetas && (minimumRatio !== undefined || maximumRatio !== undefined)) {
      const { ratio, width, height } = imageMetas;

      if (minimumRatio === maximumRatio && ratio !== minimumRatio) {
        const isBelowConstraint = ratio < minimumRatio; // true if current ratio is lower than expected

        // Adds +1 px and -1 px to width and computes a new ratio, if the new ratio overlaps the previous constraint violation, then it's OK.
        // We look for the limit, lets say the expected ratio is 0,6 and the current uploaded image ratio is 0,59994.
        // If width plus 1px or width minus 1px new ratio is 0,60023 then it's OK because 1px is the minimum variation user can do while resizing its image, so this dimension is the closest one to the expected strict 0,6 ratio.
        if (
          !(
            (width + 1) / height < minimumRatio !== isBelowConstraint ||
            (width - 1) / height < minimumRatio !== isBelowConstraint ||
            width / (height + 1) < minimumRatio !== isBelowConstraint ||
            width / (height + 1) < minimumRatio !== isBelowConstraint
          )
        ) {
          return [
            {
              message: i18n.t("error.constraint.imageRatio") + toStringWithRatio(minimumRatio),
            },
          ];
        }
      } else if (minimumRatio !== undefined && ratio < minimumRatio) {
        return [
          {
            message: i18n.t("error.constraint.imageRatioMin") + toStringWithRatio(minimumRatio),
          },
        ];
      } else if (maximumRatio !== undefined && ratio > maximumRatio) {
        return [
          {
            message: i18n.t("error.constraint.imageRatioMax") + toStringWithRatio(maximumRatio),
          },
        ];
      }
    }

    const maximumBytes = this.getMaximumBytesConstraints();

    if (maximumBytes !== undefined) {
      const data = await this.getData();
      try {
        if (window.atob(data).length > maximumBytes) {
          return [
            {
              message: i18n.t("error.constraint.imageWeight", { maximumBytes: toStringWithBytes(maximumBytes) }),
            },
          ];
        }
      } catch (e) {
        return [
          {
            message: i18n.t("error.notSupported.invalidData"),
          },
        ];
      }
    }

    const minimumPixels = this.getMinimumPixelsConstraints();
    if (minimumPixels !== undefined) {
      const dimensions = await this.getDimensions();
      if (dimensions.width * dimensions.height < minimumPixels) {
        return [
          {
            message: i18n.t("error.constraint.imagePixel", { minimumPixels: toStringWithBytes(minimumPixels) }),
          },
        ];
      }
    }

    return [];
  }

  renderConstraints() {
    const formats = this.getFormatsConstraints();
    const { minimum: minimumRatio, maximum: maximumRatio } = this.getRatioConstraints();
    const maximumBytes = this.getMaximumBytesConstraints();
    const minimumPixels = this.getMinimumPixelsConstraints();
    if (
      formats === undefined &&
      minimumRatio === undefined &&
      maximumRatio === undefined &&
      maximumBytes === undefined &&
      minimumPixels === undefined
    ) {
      return null;
    }
    return (
      <span>
        {formats !== undefined && (
          <span>
            <Typography.P2Regular>{i18n.t("inputs.label.formats")}</Typography.P2Regular>
            <Typography.P2Regular>{toStringWithCollection(formats)}</Typography.P2Regular>
          </span>
        )}
        {(minimumRatio !== undefined || maximumRatio !== undefined) && (
          <span>
            <Typography.P2Regular>{i18n.t("inputs.label.ratio")}</Typography.P2Regular>
            <Typography.P2Regular>
              {toStringWithRange(toStringWithRatio(minimumRatio), toStringWithRatio(maximumRatio))}
            </Typography.P2Regular>
          </span>
        )}
        {maximumBytes !== undefined && (
          <span>
            <Typography.P2Regular>{i18n.t("inputs.label.weight")}</Typography.P2Regular>
            <Typography.P2Regular>{toStringWithLessThan(toStringWithBytes(maximumBytes))}</Typography.P2Regular>
          </span>
        )}
        {minimumPixels !== undefined && (
          <span>
            <Typography.P2Regular>{i18n.t("inputs.label.pixels")}</Typography.P2Regular>
            <Typography.P2Regular>{toStringWithGreaterThan(toStringWithInteger(minimumPixels))}</Typography.P2Regular>
          </span>
        )}
      </span>
    );
  }

  renderComponent() {
    return InputSpriteUi;
  }
}
