import { theme } from "@ogury/design-system";
import { Rect } from "react-konva/es/ReactKonva";
import PropTypes from "prop-types";

const GridUi = ({ rows, columns, ...props }) => {
  const cellWidth = props.width / columns;
  const cellHeight = props.height / rows;
  return (
    <>
      <Rect width={1} height={props.height} fill={theme.colors.media.cyan.cyan5} x={props.width / 2 - 0.5} y={0} />
      <Rect width={props.width} height={1} fill={theme.colors.media.cyan.cyan5} x={0} y={props.height / 2 - 0.5} />
      {Array.from({ length: rows + 1 }).map((_, rowIndex) => {
        return (
          <Rect
            data-testid="row-grid-line"
            key={rowIndex}
            width={props.width}
            fill={theme.colors.media.cyan.cyan5}
            height={0.5}
            x={props.x}
            y={rowIndex * cellHeight + props.y - (rowIndex > 0 ? 0.5 : 0)}
          />
        );
      })}
      {Array.from({ length: columns + 1 }).map((_, columnIndex) => {
        return (
          <Rect
            data-testid="column-grid-line"
            key={columnIndex}
            width={0.5}
            fill={theme.colors.media.cyan.cyan5}
            height={props.height}
            x={columnIndex * cellWidth + props.x - (columnIndex > 0 ? 0.5 : 0)}
            y={props.y}
          />
        );
      })}
    </>
  );
};

GridUi.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  rows: PropTypes.number.isRequired,
  columns: PropTypes.number.isRequired,
};

export default GridUi;
