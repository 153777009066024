import React from "react";

import InputNumberModel from "./InputNumberModel";
import InputFloatUi from "../ui/InputFloatUi";
import InputRatioUi from "../ui/InputRatioUi";
import Model from "app/inputs/model/Model";
import Api from "~/util/Api";

const ratioFromManager = Api.getRatioFromManager();

export default class InputFloatModel extends InputNumberModel {
  constructor(...args) {
    super(...args);

    if (this.isRatio() && ratioFromManager) {
      Debug.warn("Ratio is forced from URL with value : " + ratioFromManager);
      this.setValue(ratioFromManager, true, undefined, false);
      this.disableUi();
    }
  }

  async setValue(value, onlyAssign, metas, persistHistory = true) {
    // Template has a constrained ratio (thumbnail / interstitial / header) and is openend from sites.
    // This ensure the integrity of the ratio value (user won't be able to change it from import file for example)
    if (this.isRatio() && ratioFromManager) {
      if (value !== ratioFromManager) {
        return;
      }
    }
    await super.setValue(value, onlyAssign, metas, persistHistory);
  }

  isRatio() {
    return this.getSemanticsNature() === Model.Natures.Ratio && this.getAcceptableValues() === undefined;
  }

  renderComponent() {
    return this.isRatio() === true ? InputRatioUi : InputFloatUi;
  }
}
