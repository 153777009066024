import React from "react";
import InputCompositeModel from "./InputCompositeModel";
import InputCompositeUi from "../ui/InputCompositeUi";
import { SCHEMA_INPUTS_FIELDS } from "../../../util/ModelConstants";
import i18n from "i18next";

export default class InputStripesModel extends InputCompositeModel {
  constructor(...args) {
    super(...args);
  }

  computeChildInputs() {
    const withOnlyColors = this.getConstraintsProperty(SCHEMA_INPUTS_FIELDS.ConstraintsStripesWithOnlyColors, false);
    return [
      {
        id: "visible",
        type: "string",
        ui: {
          name: "Visible",
          label: "Visible",
          comment: i18n.t("comment.stripe.computeInputs.visible"),
        },
        conditions: {
          hidden: !this.getConstraintsProperty(SCHEMA_INPUTS_FIELDS.ConstraintsStripesWithVisibility, true),
        },
        acceptableValues: [
          {
            value: "true",
            label: "Yes",
            comment: i18n.t("comment.stripe.computeInputs.true"),
          },
          {
            value: "false",
            label: "No",
            comment: i18n.t("comment.stripe.computeInputs.false"),
          },
        ],
        style: {
          orientation: "horizontal",
        },
        default: "true",
      },
      {
        id: "topOffsetPercentage",
        type: "float",
        ui: {
          name: "Position",
          label: "Position",
          comment: i18n.t("comment.stripe.computeInputs.topOffsetPercentage"),
          icon: "/position-icon.svg",
        },
        default: 3,
        range: {
          minimum: 0,
          maximum: 100,
        },
        conditions: {
          hidden: withOnlyColors,
          requiredWhen: {
            inputPath: "./visible",
            inputValue: "true",
            operator: "equals",
          },
        },
      },
      {
        id: "widthPercentage",
        type: "float",
        ui: {
          name: "Width",
          label: "Width",
          comment: i18n.t("comment.stripe.computeInputs.widthPercentage"),
          icon: "/width-icon.svg",
        },
        default: 88,
        range: {
          minimum: 4,
          maximum: 96,
        },
        conditions: {
          hidden: withOnlyColors,
          requiredWhen: {
            inputPath: "./visible",
            inputValue: "true",
            operator: "equals",
          },
        },
      },
      {
        id: "thicknessPercentage",
        type: "float",
        ui: {
          name: "Thickness",
          label: "Thickness",
          comment: i18n.t("comment.stripe.computeInputs.thicknessPercentage"),
          icon: "/height-icon.svg",
        },
        default: 0.5,
        range: {
          minimum: 0,
          maximum: 5,
        },
        conditions: {
          hidden: withOnlyColors,
          requiredWhen: {
            inputPath: "./visible",
            inputValue: "true",
            operator: "equals",
          },
        },
      },
      {
        id: "backgroundColor",
        type: "color",
        ui: {
          name: "Background",
          label: "Background",
          comment: i18n.t("comment.stripe.computeInputs.backgroundColor"),
          icon: "/color-icon.svg",
        },
        default: "#E2C49790",
        constraints: {
          withAlpha: true,
        },
        conditions: {
          requiredWhen: {
            inputPath: "./visible",
            inputValue: "true",
            operator: "equals",
          },
        },
      },
      {
        id: "foregroundColor",
        type: "color",
        ui: {
          name: "Foreground",
          label: "Foreground",
          comment: i18n.t("comment.stripe.computeInputs.foregroundColor"),
          icon: "/color-icon.svg",
        },
        default: "#FFFFFFFF",
        constraints: {
          withAlpha: true,
        },
        conditions: {
          requiredWhen: {
            inputPath: "./visible",
            inputValue: "true",
            operator: "equals",
          },
        },
      },
    ];
  }

  renderComponent() {
    return InputCompositeUi;
  }
}
