import { Button, Checkbox, Divider, Dropdown, useMessage } from "@ogury/design-system";
import FileCopyLineIcon from "remixicon-react/FileCopyLineIcon";
import style from "components/BinaryUi/BinaryUi.module.scss";
import More2LineIcon from "remixicon-react/More2LineIcon";
import React, { useState } from "react";
import virtualClipboardService from "~/services/VirtualClipboardService";
import { useTranslation } from "react-i18next";
import { isEmpty } from "~/util/Utils";
import { datadogRum } from "@datadog/browser-rum";
import DatadogEvents from "~/util/DatadogEvents";

const PasteModes = {
  PASTE_ALL: "pasteAll",
  PASTE_IMAGE: "pasteImage",
  PASTE_COORDINATES: "pasteCoordinates",
  PASTE_WIDTH_HEIGHT: "pasteWidthHeight",
};

export default function CopyPaste({ input, onPaste }) {
  const [t] = useTranslation();
  const { notifySuccess } = useMessage();
  const [selectedPasteModes, setSelectedPasteModes] = useState([]);

  function handleOnCopy() {
    virtualClipboardService.pushInputValue(input?.getValue());
    notifySuccess(t("inputsUI.copyPaste.success"));
  }

  function handleOnChangeSelectedPasteMode(mode) {
    setSelectedPasteModes(prevModes => {
      if (prevModes.includes(mode)) {
        return prevModes.filter(item => item !== mode);
      } else {
        return [...prevModes, mode];
      }
    });
  }

  function handleOnPaste() {
    const clipboardValue = virtualClipboardService.popInputValue();
    let pastedData = {};

    if (selectedPasteModes.includes(PasteModes.PASTE_ALL)) {
      pastedData = clipboardValue; // Paste everything
      datadogRum.addAction(DatadogEvents.PASTE_ALL);
    } else {
      if (selectedPasteModes.includes(PasteModes.PASTE_IMAGE)) {
        pastedData.image = clipboardValue.image;
        datadogRum.addAction(DatadogEvents.PASTE_IMAGE);
      }
      if (selectedPasteModes.includes(PasteModes.PASTE_COORDINATES)) {
        pastedData.x = clipboardValue.x;
        pastedData.y = clipboardValue.y;
        datadogRum.addAction(DatadogEvents.PASTE_COORDINATES);
      }
      if (selectedPasteModes.includes(PasteModes.PASTE_WIDTH_HEIGHT)) {
        pastedData.size = clipboardValue.size;
        datadogRum.addAction(DatadogEvents.PASTE_WIDTH_HEIGHT);
      }
    }

    onPaste(pastedData);
  }

  const valueToPasteExists = !isEmpty(virtualClipboardService.popInputValue());
  const cantPartialPaste = input.getValue()?.image === undefined;

  const pasteItems = [
    {
      key: PasteModes.PASTE_ALL,
      label: (
        <Checkbox
          checked={selectedPasteModes.includes(PasteModes.PASTE_ALL)}
          onChange={() => handleOnChangeSelectedPasteMode(PasteModes.PASTE_ALL)}
        >
          {t("inputsUI.copyPaste.pasteAll")}
        </Checkbox>
      ),
    },
    {
      key: PasteModes.PASTE_IMAGE,
      label: (
        <Checkbox
          checked={selectedPasteModes.includes(PasteModes.PASTE_IMAGE)}
          disabled={cantPartialPaste || selectedPasteModes.includes(PasteModes.PASTE_ALL)}
          onChange={() => handleOnChangeSelectedPasteMode(PasteModes.PASTE_IMAGE)}
        >
          {t("inputsUI.copyPaste.pasteImage")}
        </Checkbox>
      ),
    },

    {
      key: PasteModes.PASTE_COORDINATES,
      label: (
        <Checkbox
          checked={selectedPasteModes.includes(PasteModes.PASTE_COORDINATES)}
          disabled={cantPartialPaste || selectedPasteModes.includes(PasteModes.PASTE_ALL)}
          onChange={() => handleOnChangeSelectedPasteMode(PasteModes.PASTE_COORDINATES)}
        >
          {t("inputsUI.copyPaste.pasteCoordinates")}
        </Checkbox>
      ),
    },
    {
      key: PasteModes.PASTE_WIDTH_HEIGHT,
      label: (
        <Checkbox
          checked={selectedPasteModes.includes(PasteModes.PASTE_WIDTH_HEIGHT)}
          disabled={cantPartialPaste || selectedPasteModes.includes(PasteModes.PASTE_ALL)}
          onChange={() => handleOnChangeSelectedPasteMode(PasteModes.PASTE_WIDTH_HEIGHT)}
        >
          {t("inputsUI.copyPaste.pasteWidthHeight")}
        </Checkbox>
      ),
    },
  ];

  return input ? (
    <>
      {input?.getValue()?.image && (
        <Button
          data-testid="btn-copy"
          type="tertiary"
          size="small"
          icon={<FileCopyLineIcon />}
          iconPosition="iconOnly"
          onClick={handleOnCopy}
        />
      )}

      {valueToPasteExists && (
        <Dropdown
          dropdownRender={() => {
            return (
              <div className={style.pasteDropdownContainer}>
                <div className={style.pasteDropdownBody}>
                  {pasteItems.map(item => {
                    return <div className={style.pasteDropdownItem}>{item.label}</div>;
                  })}
                </div>
                <Divider style={{ margin: 0 }} />
                <div className={style.pasteDropdownFooter}>
                  <Button type="tertiary">{t("inputsUI.button.paste.cancel")}</Button>
                  <Button disabled={!selectedPasteModes?.length} onClick={handleOnPaste} type="primary">
                    {t("inputsUI.button.paste.paste")}
                  </Button>
                </div>
              </div>
            );
          }}
        >
          <Button
            data-testid="btn-paste"
            type="tertiary"
            size="small"
            icon={<More2LineIcon />}
            iconPosition="iconOnly"
            onClick={event => event.preventDefault()}
          />
        </Dropdown>
      )}
    </>
  ) : (
    <></>
  );
}
