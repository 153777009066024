import { Preview } from "./components";
import useStores from "~/hooks/useStores";
import Core from "~/services/Core";

export default function RightPanel({ afterSaveSeed, onReloadAndSave }) {
  const { activeTreeNode } = useStores();
  const template = Core.getCurrentTemplate();

  return (
    activeTreeNode && (
      <Preview afterSaveSeed={afterSaveSeed} templateUrl={template?.templateUrl} onReloadAndSave={onReloadAndSave} />
    )
  );
}
