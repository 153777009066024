import { createContext, useContext, useEffect, useState } from "react";
import Core from "../services/Core";
import References from "../util/References";

let ACTIVE_UI_NODE_ID = References.ROOT_INPUT_ID;

const UiStoreContext = createContext();

export function useUiStore() {
  return useContext(UiStoreContext);
}

function useProvider() {
  const [uiStore, setUiStore] = useState([]);
  const [activeTreeNodeId, setActiveTreeNodeId] = useState(References.ROOT_INPUT_ID);

  useEffect(() => {
    ACTIVE_UI_NODE_ID = activeTreeNodeId;
  }, [activeTreeNodeId]);

  function regenerateUiStore() {
    const rootDeepNode = Core.getDeepNodeById(References.ROOT_INPUT_ID);
    if (rootDeepNode) {
      const rootTree = rootDeepNode.instance.generateUiTree();
      setUiStore(rootTree);
      if (!activeTreeNodeId) {
        setActiveTreeNodeId(rootDeepNode.instance.getId());
      }
    }
  }

  return { uiStore, setUiStore, regenerateUiStore, activeTreeNodeId, setActiveTreeNodeId };
}

export function UiStoreProvider({ children }) {
  const uiStoreProvider = useProvider();
  return <UiStoreContext.Provider value={uiStoreProvider}>{children}</UiStoreContext.Provider>;
}

export function getActiveTreeNodeId() {
  return ACTIVE_UI_NODE_ID;
}
