import Model from "app/inputs/model/Model";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BinaryUi, ImageEdit } from "~/components";
import { convertImageQuality, fromBlobToMimeType, fromDataUrlToBlob, loadImage } from "~/util/InputsComputer";
import { ImageProperties } from "./components";

const DEFAULT_IMAGE_QUALITY = 90;

export default function InputImage({
  input,
  inputValue,
  inputName,
  formats,
  onValueChanged,
  onDelete,
  ratioConstraints = {
    minimum: undefined,
    maximum: undefined,
  },
  preview,
}) {
  const mimeTypes = formats === undefined ? Model.imageMimeTypes() : Model.formatsToMimeTypes(formats);
  const [isOpen, setOpen] = useState(false);
  const [t] = useTranslation();
  const { minimum: minimumRatio, maximum: maximumRatio } = ratioConstraints;
  return (
    <BinaryUi
      input={input}
      inputValue={inputValue}
      inputName={inputName}
      onValueChanged={onValueChanged}
      onDelete={onDelete}
      fileExtensions={
        formats === undefined
          ? [".jpg", ".jpeg", ".png", ".gif", ".webp"]
          : Model.formatsToFileExtensions(formats).map(extension => {
              return "." + extension;
            })
      }
      hint={t("components.filePicker.uploadHintImages")}
      mimeTypes={mimeTypes}
      doNotSupportCors={false}
      placeholder={t("components.inputImage.urlPlaceholder")}
      preview={preview}
      actions={(value, setDataUrl) => {
        return [
          <ImageEdit
            key="edit"
            urlOrDataUrl={value.urlOrDataUrl}
            mimeType={value.mimeType}
            authorizedMimeTypes={mimeTypes}
            minimumRatio={minimumRatio}
            maximumRatio={maximumRatio}
            isOpen={isOpen}
            setOpen={setOpen}
            setDataUrl={setDataUrl}
          />,
        ];
      }}
      properties={value => <ImageProperties value={value} />}
      computeObject={loadImage}
      computeBlob={async value => {
        const image = value.object;
        if (image) {
          const dataUrl = convertImageQuality(
            image,
            value.mimeType,
            undefined,
            DEFAULT_IMAGE_QUALITY,
            image.naturalWidth,
            image.naturalHeight,
            0,
            0,
            image.naturalWidth,
            image.naturalHeight
          );

          return fromDataUrlToBlob(dataUrl);
        }
      }}
      fromMimeTypeToFileExtension={mimeType => {
        switch (mimeType) {
          case "image/jpeg":
            return "jpg";
          case "image/png":
            return "png";
          case "image/webp":
            return "webp";
          case "image/gif":
            return "gif";
          default:
            return undefined;
        }
      }}
      onValidate={async (blob, options) => {
        const mimeType = await fromBlobToMimeType(blob, true);
        if (!mimeTypes.includes(mimeType)) {
          throw new Error(t("error.invalid.image", { mimeType: mimeType }));
        }
        options.mimeType = mimeType;
        options.object = await loadImage(options.url || options.dataUrl);
        return options;
      }}
    />
  );
}
