import { useCallback } from "react";
import InputColorUi from "./InputColorUi/InputColorUi";
import InputStringUi from "./InputStringUi";
import InputFloatUi from "./InputFloatUi";
import InputPointUi from "./InputPointUi/InputPointUi";
import InputUrlUi from "./InputUrlUi";
import { InputHeader } from "components";

const InputCtaUi = ({ input }) => {
  const renderUi = useCallback(
    (UiComponent, id) => {
      return <></>;
      /*  const model = input.models[id];
       return <UiComponent input={model} />; */
    },
    [input]
  );

  return (
    <>
      <InputHeader input={input} />
      <div className="inputs-group">
        {renderUi(InputStringUi, "type")}
        <div className="inputs-row">
          {renderUi(InputUrlUi, "actionUrl")}
          {renderUi(InputStringUi, "text")}
          {renderUi(InputStringUi, "mode")}
        </div>
        <div className="inputs-row">
          {renderUi(InputColorUi, "color")}
          {renderUi(InputColorUi, "backgroundColor")}
        </div>
        <div className="inputs-row">
          {renderUi(InputStringUi, "size")}
          {renderUi(InputStringUi, "font")}
          {renderUi(InputStringUi, "buttonStyle")}
        </div>
        <div className="inputs-row">
          {renderUi(InputFloatUi, "stroke")}
          {renderUi(InputFloatUi, "opacity")}
        </div>
        {/*
        <CenterInput referenceInput={input.models["padding"].referenceInput} isRoot={false} level={0} isHorizontal />
*/}
        {renderUi(InputPointUi, "position")}
      </div>
    </>
  );
};

export default InputCtaUi;
