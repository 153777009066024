import { Redirect, Route } from "react-router-dom";

import { isAuthenticated } from "./Credentials";
import { USE_API_KEY, USE_STUBS } from "./Constant";

export const DESTINATION = "destination";

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const authenticated = isAuthenticated() || USE_STUBS;
  const destination = encodeURIComponent(window.location.hash);
  localStorage.setItem(DESTINATION, destination.replace("%23", ""));

  if (USE_API_KEY) {
    return <Route {...rest} render={props => <Component {...rest} {...props} />} />;
  }

  return (
    <Route
      {...rest}
      render={props => {
        return authenticated ? <Component {...rest} {...props} /> : <Redirect to="/signin" />;
      }}
    />
  );
};
