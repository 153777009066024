import React from "react";
import style from "./PopoverElement.module.scss";
import { Button } from "@ogury/design-system";
import Core from "~/services/Core";
import { useTranslation } from "react-i18next";

const ASSETS_TYPES = ["image", "font", "imageOverlay", "lottie", "video", "videoUrls"];

export default function PopoverElement({ large = false, url, filename, onGotoBuilder, children }) {
  const [t] = useTranslation();

  async function handleOnGotoBuilder() {
    const store = Core.getDeepStore();
    let instanceId;

    function iterate(nodes) {
      for (const node of nodes) {
        const instance = node.instance;
        const type = instance.getReferenceInput().type;
        const instanceFilename = instance.getFilename();

        if (ASSETS_TYPES.includes(type) && instanceFilename) {
          if (instanceFilename === filename) {
            instanceId = instance.getId();
            return;
          }
        }

        if (node.inputs) {
          iterate(node.inputs);
        }
      }
    }

    iterate(store);

    if (instanceId) {
      onGotoBuilder(instanceId);
    }
  }

  const enhancedChildren = React.cloneElement(children, {
    className: `${children.props.className} ${style.popoverImage} ${large && style.large}`,
  });

  return (
    <div className={style.container}>
      {enhancedChildren}
      <div className={style.buttonsContainer}>
        <Button type="secondary" onClick={handleOnGotoBuilder}>
          {t("components.creativeWeight.seeInBuilder")}
        </Button>
        <Button type="secondary" onClick={() => window.open(url, "_blank")}>
          {t("components.creativeWeight.openUrl")}
        </Button>
      </div>
    </div>
  );
}
