import React, { useEffect, useState } from "react";
import i18n from "i18next";
import PropTypes from "prop-types";
import { useHotkey } from "context";
import { Tooltip } from "components";
import { NOTIFICATION_PLACEMENT } from "~/util/Constant";
import { getOS } from "~/util/Utils";
import { Button, useAppNotification } from "@ogury/design-system";

// Taken from https://www.30secondsofcode.org/react/s/use-interval
const useInterval = (callback, milliseconds) => {
  const savedCallback = React.useRef();

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    const theCallback = () => {
      savedCallback.current();
    };
    if (milliseconds !== null) {
      const interval = setInterval(theCallback, milliseconds);
      return () => clearInterval(interval);
    }
  }, [milliseconds]);
};

export default function SaveButton({ onClick, disabled }) {
  const notification = useAppNotification();

  const [timestamp, setTimestamp] = useState(undefined);
  const [countdown, setCountdown] = useState(undefined);
  const { hotkey, isHotkeyPressed } = useHotkey();
  const timeoutInMilliseconds = 0;

  useInterval(
    () => {
      if (timeoutInMilliseconds > 0) {
        if (timestamp !== undefined) {
          if (Date.now() >= timestamp) {
            setTimestamp(undefined);
            setCountdown(undefined);
          } else {
            setCountdown(Math.ceil((timestamp - Date.now()) / 1000) + " s");
          }
        }
      }
    },
    timeoutInMilliseconds > 0 ? 500 : 5000
  );

  const runOnClick = async () => {
    if (!disabled) {
      try {
        await onClick();
      } catch (error) {
        notification.error({
          message: error.message,
          description: "Saving failed",
          placement: NOTIFICATION_PLACEMENT,
          duration: 0,
        });
        return;
      }
      setTimestamp(Date.now() + timeoutInMilliseconds);
      setTimeout(() => {
        setTimestamp(undefined);
        setCountdown(undefined);
      }, timeoutInMilliseconds);
    }
  };

  useEffect(() => {
    if (countdown === undefined) {
      if (isHotkeyPressed("meta+s,ctrl+s")) {
        runOnClick();
      }
    }
  }, [hotkey]);

  useEffect(() => {
    function preventDefaultSaveHotkey(event) {
      if (event.code === "KeyS" && (event.metaKey || event.ctrlKey)) {
        event.preventDefault();
      }
    }

    document.addEventListener("keydown", preventDefaultSaveHotkey);
    return () => document.removeEventListener("keydown", preventDefaultSaveHotkey);
  }, []);

  function getSaveTooltipHotkeyLabel() {
    let tooltip = i18n.t("header.button.saveTooltip");
    if (getOS() === "macos") {
      tooltip = i18n.t("header.button.saveTooltipMAC");
    }
    return tooltip;
  }

  return (
    <Tooltip content={getSaveTooltipHotkeyLabel()}>
      <Button
        style={{ position: "relative" }}
        onClick={runOnClick}
        disabled={disabled === true || timestamp !== undefined}
        data-testid="button-save"
      >
        {countdown !== undefined && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {countdown}
          </div>
        )}
        <div style={{ visibility: countdown === undefined ? "visible" : "hidden" }}>{i18n.t("header.button.save")}</div>
      </Button>
    </Tooltip>
  );
}

SaveButton.propTypes = {
  parentProxy: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};
