import React from "react";
import Model from "./Model";
import InputPointUi from "../ui/InputPointUi/InputPointUi";
import { SCHEMA_INPUTS_FIELDS } from "../../../util/ModelConstants";

export default class InputPointModel extends Model {
  constructor(...args) {
    super(...args);
  }

  isValid(valueType, value, metas) {
    return (
      valueType === "object" &&
      value.x !== undefined &&
      typeof value.x === "number" &&
      value.y !== undefined &&
      typeof value.y === "number"
    );
  }

  getConstraintsFrame() {
    const constraints = this.getConstraints();
    const frameConstraints = constraints === undefined ? undefined : constraints[SCHEMA_INPUTS_FIELDS.ConstraintsFrame];
    const topLeft =
      frameConstraints === undefined ? undefined : frameConstraints[SCHEMA_INPUTS_FIELDS.ConstraintsFrameTopLeft];
    const bottomRight =
      frameConstraints === undefined ? undefined : frameConstraints[SCHEMA_INPUTS_FIELDS.ConstraintsFrameBottomRight];
    const x = topLeft === undefined ? 0 : topLeft[SCHEMA_INPUTS_FIELDS.ConstraintsFrameX];
    const y = topLeft === undefined ? 0 : topLeft[SCHEMA_INPUTS_FIELDS.ConstraintsFrameY];
    return {
      x: x,
      y: y,
      width: bottomRight === undefined ? 100 : bottomRight[SCHEMA_INPUTS_FIELDS.ConstraintsFrameX] - x,
      height: bottomRight === undefined ? 100 : bottomRight[SCHEMA_INPUTS_FIELDS.ConstraintsFrameY] - y,
    };
  }

  getConstraintsOnInputPathReferenceInstance() {
    return Model.getConstraintsOnInputPathReferenceInstance(this, true);
  }

  renderComponent() {
    return InputPointUi;
  }

  boundCoordinates(value, width, height) {
    const constraintsFrame = this.getConstraintsFrame();
    return {
      x: Math.min(
        ((constraintsFrame.x + constraintsFrame.width) * width) / 100,
        Math.max((constraintsFrame.x * width) / 100, value.x)
      ),
      y: Math.min(
        ((constraintsFrame.y + constraintsFrame.height) * height) / 100,
        Math.max((constraintsFrame.y * height) / 100, value.y)
      ),
    };
  }
}
