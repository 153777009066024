import { DEBUG_KEY, IS_DEBUG, USE_STUBS } from "./Constant";

let DEBUG = IS_DEBUG;

function write(color, ...text) {
  if (DEBUG) {
    console.groupCollapsed(
      "%c OCS Builder ",
      `font-size : 10px; background: ${color} ; color: #FFF; border-radius : 5px; padding : 2px 0;`,
      ...text
    );
    console.trace("StackTrace");
    console.groupEnd();
  }
}

function error(...args) {
  write("red", ...args);
}

function warn(...args) {
  write("orange", ...args);
}

function log(...args) {
  write("#7156F3", ...args);
}

function success(...args) {
  write("#007c10", ...args);
}

function logAppConstants() {
  if (DEBUG) {
    warn("---------------- DEBUG ENABLED -----------------");
    warn("USE_STUBS", USE_STUBS);
    warn("------------------------------------------------");
  }
}

function toggleDebug() {
  DEBUG = !DEBUG;
  localStorage.setItem(DEBUG_KEY, DEBUG);
}

export default {
  log,
  logAppConstants,
  error,
  warn,
  success,
  isDebug: () => DEBUG === true,
  toggleDebug,
};
