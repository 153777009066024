import { useTranslation } from "react-i18next";
import { toStringWithBytes, toStringWithDimensions, toStringWithRatio } from "~/util/Helpers";
import { Typography } from "@ogury/design-system";
import style from "./ImageProperties.module.scss";

export default function ImageProperties({ value }) {
  const [t] = useTranslation();
  const hasExtension = value.name?.split(".").length > 1;

  return (
    <div>
      <Typography.P2Strong>{hasExtension ? value.name : `${value.name}.png`}</Typography.P2Strong>
      <div className={style.container}>
        {value.size && <Typography.P2Regular>{toStringWithBytes(value.size)}</Typography.P2Regular>}
        {value.object && (
          <Typography.P2Regular>
            , {toStringWithDimensions(value.object.naturalWidth, value.object.naturalHeight)}
            {t("inputs.properties.px")}
          </Typography.P2Regular>
        )}
        {value.object && (
          <Typography.P2Regular>
            , ({t("inputs.properties.ratio")}{" "}
            {toStringWithRatio(value.object.naturalWidth / value.object.naturalHeight)})
          </Typography.P2Regular>
        )}
      </div>
    </div>
  );
}
