export default {
  LOTTIE_OVERLAY_API_MODAL_OPENING: "lottieOverlayAPIModalOpening",
  LOTTIE_OVERLAY_API_MODAL_ANIMATION_DOWNLOADED: "lottieOverlayAPIModalAnimationDownloaded",
  LOTTIE_OVERLAY_FILE_MANUALLY_UPLOADED: "lottieOverlayFileManuallyUploaded",
  DUPLICATION_AFTER_SAVE: "duplicationAfterSave", // The user has chosen a unit to duplicate the current creative, before saving the creative.
  CLICK_MANUAL_SAVE_AND_DUPLICATE: "manualSaveAndDuplicate", // The user has manually opened the save and duplicate modal
  PASTE: "paste", // The user has pasted something
  PASTE_ALL: "pasteAll", // COMPLEMENT TO "PASTE" : The user has pasted all values
  PASTE_IMAGE: "pasteImage", // COMPLEMENT TO "PASTE" : The user has pasted only image value
  PASTE_COORDINATES: "pasteCoordinates", // COMPLEMENT TO "PASTE" : The user has pasted only coordinates values
  PASTE_WIDTH_HEIGHT: "pasteWidthHeight", // COMPLEMENT TO "PASTE" : The user has pasted only width and height values
};
