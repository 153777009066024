import React, { useEffect, useState } from "react";
import { UNITS, useCreativeSettingsContext } from "context/CreativeSettingsContext";
import { Button, Col, Form, InputNumber, Row, Space, Typography } from "@ogury/design-system";
import PropTypes from "prop-types";
import LayoutLeft2LineIcon from "remixicon-react/LayoutLeft2LineIcon";
import LayoutColumnLineIcon from "remixicon-react/LayoutColumnLineIcon";
import LayoutRight2LineIcon from "remixicon-react/LayoutRight2LineIcon";
import LayoutTop2LineIcon from "remixicon-react/LayoutTop2LineIcon";
import LayoutRowLineIcon from "remixicon-react/LayoutRowLineIcon";
import LayoutBottom2LineIcon from "remixicon-react/LayoutBottom2LineIcon";
import { Divider } from "~/components";
import style from "./AdUnitCanvasControls.module.scss";
import { useTranslation } from "react-i18next";

function twoDecimals(number) {
  return Math.round(number * 100) / 100;
}

export default function AdUnitCanvasControls({
  canvasData,
  withRotation,
  konvaElementCoordinatesAndSize,
  konvaElementRatio,
  onChange,
}) {
  const [coordinatesAndSize, setCoordinatesAndSize] = useState(konvaElementCoordinatesAndSize);
  const { creativeSettings } = useCreativeSettingsContext();
  const [t] = useTranslation();
  const absoluteValues = creativeSettings.units === UNITS.PIXELS;
  const { canvasDimensions, originalAdSize } = canvasData;

  function fromCanvasToAdDimensions(canvasCoordinatesAndSize) {
    let x = (canvasCoordinatesAndSize.x * originalAdSize.width) / canvasDimensions.width;
    let y = (canvasCoordinatesAndSize.y * originalAdSize.height) / canvasDimensions.height;
    let width = (canvasCoordinatesAndSize.width * originalAdSize.width) / canvasDimensions.width;
    let height = (canvasCoordinatesAndSize.height * originalAdSize.height) / canvasDimensions.height;

    if (!absoluteValues) {
      x = (canvasCoordinatesAndSize.x * 100) / canvasDimensions.width;
      y = (canvasCoordinatesAndSize.y * 100) / canvasDimensions.height;
      width = (canvasCoordinatesAndSize.width * 100) / canvasDimensions.width;
      height = (canvasCoordinatesAndSize.height * 100) / canvasDimensions.height;
    }

    return {
      ...canvasCoordinatesAndSize,
      x,
      y,
      width,
      height,
    };
  }

  function fromAdToCanvasDimensions(adCoordinatesAndSize) {
    let x = (adCoordinatesAndSize.x * canvasDimensions.width) / originalAdSize.width;
    let y = (adCoordinatesAndSize.y * canvasDimensions.height) / originalAdSize.height;
    let width = (adCoordinatesAndSize.width * canvasDimensions.width) / originalAdSize.width;
    let height = (adCoordinatesAndSize.height * canvasDimensions.height) / originalAdSize.height;

    if (!absoluteValues) {
      x = (adCoordinatesAndSize.x * canvasDimensions.width) / 100;
      y = (adCoordinatesAndSize.y * canvasDimensions.height) / 100;
      width = (adCoordinatesAndSize.width * canvasDimensions.width) / 100;
      height = (adCoordinatesAndSize.height * canvasDimensions.height) / 100;
    }

    return {
      ...adCoordinatesAndSize,
      x,
      y,
      width,
      height,
    };
  }

  function handleOnInputChange(property, value) {
    if (property === "width") {
      setCoordinatesAndSize({
        ...coordinatesAndSize,
        width: value,
        height: value / konvaElementRatio,
      });
    } else if (property === "height") {
      setCoordinatesAndSize({
        ...coordinatesAndSize,
        height: value,
        width: value * konvaElementRatio,
      });
    } else {
      setCoordinatesAndSize({
        ...coordinatesAndSize,
        [property]: value,
      });
    }
  }

  function renderInputLabel(value) {
    return (
      <>
        <Typography.P2Strong>{value}&nbsp;</Typography.P2Strong>
        <Typography.P2Regular>{absoluteValues ? " (pixels)" : " (%)"}</Typography.P2Regular>
      </>
    );
  }

  function handleOnInputBlur() {
    onChange(fromAdToCanvasDimensions(coordinatesAndSize));
  }
  function handleLeftHorizontally() {
    onChange(fromAdToCanvasDimensions({ ...coordinatesAndSize, x: 0 }));
  }
  function handleCenterHorizontally() {
    const x = absoluteValues
      ? (originalAdSize.width - coordinatesAndSize.width) / 2
      : 50 - coordinatesAndSize.width / 2;
    onChange(fromAdToCanvasDimensions({ ...coordinatesAndSize, x }));
  }
  function handleRightHorizontally() {
    const x = absoluteValues ? originalAdSize.width - coordinatesAndSize.width : 100 - coordinatesAndSize.width;
    onChange(fromAdToCanvasDimensions({ ...coordinatesAndSize, x }));
  }
  function handleTopVertically() {
    onChange(fromAdToCanvasDimensions({ ...coordinatesAndSize, y: 0 }));
  }
  function handleCenterVertically() {
    const y = absoluteValues
      ? (originalAdSize.height - coordinatesAndSize.height) / 2
      : 50 - coordinatesAndSize.height / 2;
    onChange(fromAdToCanvasDimensions({ ...coordinatesAndSize, y }));
  }
  function handleBottomVertically() {
    const y = absoluteValues ? originalAdSize.height - coordinatesAndSize.height : 100 - coordinatesAndSize.height;
    onChange(fromAdToCanvasDimensions({ ...coordinatesAndSize, y }));
  }

  function formatInput(value) {
    return absoluteValues ? Math.round(value) : twoDecimals(value);
  }

  useEffect(() => {
    setCoordinatesAndSize(fromCanvasToAdDimensions(konvaElementCoordinatesAndSize));
  }, [konvaElementCoordinatesAndSize, absoluteValues]);

  const inputNumberCommonProps = {
    width: 140,
    onKeyPress: ({ key }) => key === "Enter" && handleOnInputBlur(),
    onBlur: handleOnInputBlur,
  };

  return (
    <Space size="xs" direction="vertical">
      <div>
        <Button type="tertiary" size="small" onClick={handleLeftHorizontally}>
          <LayoutLeft2LineIcon />
        </Button>
        <Button type="tertiary" size="small" onClick={handleCenterHorizontally}>
          <LayoutColumnLineIcon />
        </Button>
        <Button type="tertiary" size="small" onClick={handleRightHorizontally}>
          <LayoutRight2LineIcon />
        </Button>
        <Button type="tertiary" size="small" onClick={handleTopVertically}>
          <LayoutTop2LineIcon />
        </Button>
        <Button type="tertiary" size="small" onClick={handleCenterVertically}>
          <LayoutRowLineIcon />
        </Button>
        <Button type="tertiary" size="small" onClick={handleBottomVertically}>
          <LayoutBottom2LineIcon />
        </Button>
      </div>
      <Row gutter={[8, 8]} align="middle" justify="start" wrap={true}>
        <Col>
          <Form.Item label={renderInputLabel("X")}>
            <InputNumber
              {...inputNumberCommonProps}
              onChange={value => handleOnInputChange("x", value)}
              value={formatInput(coordinatesAndSize?.x)}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label={renderInputLabel("Y")}>
            <InputNumber
              {...inputNumberCommonProps}
              onChange={value => handleOnInputChange("y", value)}
              value={formatInput(coordinatesAndSize?.y)}
            />
          </Form.Item>
        </Col>
        <Col>
          <Divider className={style.verticalDivider} type="vertical" />
        </Col>
        {absoluteValues ? (
          <>
            <Col>
              <Form.Item label={renderInputLabel(t("inputs.label.width"))}>
                <InputNumber
                  {...inputNumberCommonProps}
                  onChange={value => handleOnInputChange("width", value)}
                  value={formatInput(coordinatesAndSize?.width)}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label={renderInputLabel(t("inputs.label.height"))}>
                <InputNumber
                  {...inputNumberCommonProps}
                  onChange={value => handleOnInputChange("height", value)}
                  value={formatInput(coordinatesAndSize?.height)}
                />
              </Form.Item>
            </Col>
          </>
        ) : (
          <Col>
            <Form.Item label={renderInputLabel(t("inputs.label.size"))}>
              <InputNumber
                {...inputNumberCommonProps}
                onChange={value => handleOnInputChange("width", value)}
                value={Math.round(coordinatesAndSize?.width)}
              />
            </Form.Item>
          </Col>
        )}
        {withRotation && (
          <>
            <Col>
              <Divider className={style.verticalDivider} type="vertical" />
            </Col>
            <Col>
              <Form.Item label={t("inputs.label.rotation")}>
                <InputNumber
                  {...inputNumberCommonProps}
                  onChange={value => handleOnInputChange("rotation", value)}
                  value={twoDecimals(coordinatesAndSize?.rotation)}
                />
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
    </Space>
  );
}

AdUnitCanvasControls.propTypes = {
  onChange: PropTypes.func.isRequired,
  withRotation: PropTypes.bool,
  konvaElementCoordinatesAndSize: PropTypes.object.isRequired,
  canvasData: PropTypes.object.isRequired,
  konvaElementRatio: PropTypes.number.isRequired,
};
