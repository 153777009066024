import { useEffect, useMemo, useState } from "react";

import { SCHEMA_INPUTS_FIELDS } from "~/util/ModelConstants";
import { InputIcon, InputWrapper } from "components";
import AcceptableValues from "components/AcceptableValues/AcceptableValues";
import unitService from "~/services/UnitService";

const InputFloatUi = ({ input }) => {
  const [value, setValue] = useState({
    slider: input.getValue(),
    input: input.getValue(),
  });

  const currentUnitInputInstance = useMemo(() => unitService.getCurrentUnitInputInstance(), []);

  useEffect(() => {
    feedAcceptableValues();
  }, [currentUnitInputInstance?.getValue()]);

  const [acceptableValuesOptions, setAcceptableValuesOptions] = useState([]);

  const granularity = input.computeRangeGranularity();

  const onChange = newValue => {
    newValue = input.parseAndBoundRangeValue(newValue);

    setValue({
      slider: newValue,
      input: newValue,
    });
    input.setValue(newValue);
  };

  async function feedAcceptableValues() {
    const options = await input.getAcceptableValuesOptions();
    if (options?.length) {
      const currentInputValue = input.getValue();
      if (!options.find(option => option.value === currentInputValue)) {
        onChange(options[0].value);
      }
      setAcceptableValuesOptions(options);
    }
  }

  useEffect(() => {
    if (input.getValue() != null) {
      setValue({
        slider: input.getValue(),
        input: input.getValue(),
      });
    }
    feedAcceptableValues();
  }, [input.getValue()]);

  const renderAcceptableValue = () => {
    const acceptableValue = input.getAcceptableValue(value);
    const illustration =
      acceptableValue === undefined ? undefined : acceptableValue[SCHEMA_INPUTS_FIELDS.AcceptableValuesIllustration];

    return (
      <>
        <AcceptableValues
          value={input.getValue()}
          name={input.getId()}
          disabled={input.isUiDisabled()}
          inputStyle={input.getStyle()}
          onChangeCallback={onChange}
          options={acceptableValuesOptions}
        />
        {illustration !== undefined && <InputIcon icon={illustration} square={false} />}
      </>
    );
  };

  const renderEditValue = () => {
    return input.renderFreeOrRangeValue(granularity, value, setValue, onChange);
  };

  return (
    <InputWrapper input={input}>
      {input.getAcceptableValues() !== undefined ? renderAcceptableValue() : renderEditValue()}
    </InputWrapper>
  );
};

export default InputFloatUi;
