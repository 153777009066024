import React from "react";
import Model from "./Model";
import InputStringUi from "../ui/InputStringUi";
import { toStringWithInteger, toStringWithRange } from "~/util/Helpers";
import { SCHEMA_INPUTS_FIELDS } from "~/util/ModelConstants";
import { Typography } from "@ogury/design-system";
import i18n from "i18next";
import { syntheticUnitId } from "~/services/UnitService";
import Debug from "~/util/Debug";
import Api from "~/util/Api";

const adUnitTechnicalIdFromManager = Api.getAdUnitTechnicalIdFromManager();

export default class InputStringModel extends Model {
  constructor(...args) {
    super(...args);

    if (this.isAdUnit() && adUnitTechnicalIdFromManager) {
      Debug.warn("Ad unit is forced from URL with value : " + adUnitTechnicalIdFromManager);
      this.setValue(adUnitTechnicalIdFromManager, true, undefined, false);
      this.disableUi();
    } else if (this.getValue() === undefined) {
      const defaultValue = this.getReferenceInput()?.default || "";
      this.setValue(defaultValue, true);
    }
  }

  isAdUnit() {
    return this.getSemanticsKind() === Model.Kind.Synthetic && this.getId() === "root." + syntheticUnitId;
  }

  async setValue(value, onlyAssign, metas, persistHistory = true) {
    // Template has a constrained ratio (thumbnail / interstitial / header) and is openend from sites.
    // This ensure the integrity of the ratio value (user won't be able to change it from import file for example)
    if (this.isAdUnit() && adUnitTechnicalIdFromManager) {
      if (value !== adUnitTechnicalIdFromManager) {
        return;
      }
    }
    await super.setValue(value, onlyAssign, metas, persistHistory);
  }

  getCharactersConstraints() {
    const constraints = this.getConstraints();
    if (constraints === undefined) {
      return undefined;
    }
    const charactersConstraints = constraints[SCHEMA_INPUTS_FIELDS.ConstraintsCharacters];
    if (charactersConstraints === undefined) {
      return undefined;
    }
    return {
      minimum: charactersConstraints[SCHEMA_INPUTS_FIELDS.ConstraintsRangeMinimum],
      maximum: charactersConstraints[SCHEMA_INPUTS_FIELDS.ConstraintsRangeMaximum],
    };
  }

  async computeConstraintsErrors() {
    const charactersConstraints = this.getCharactersConstraints();
    if (charactersConstraints !== undefined) {
      const value = await this.getValue();
      if (charactersConstraints.minimum !== undefined && value.length < charactersConstraints.minimum) {
        return [
          {
            message: i18n.t("error.constraint.charactersMin", {
              minimum: toStringWithInteger(charactersConstraints.minimum),
            }),
          },
        ];
      } else if (charactersConstraints.maximum !== undefined && value.length > charactersConstraints.maximum) {
        return [
          {
            message: i18n.t("error.constraint.charactersMax", {
              maximum: toStringWithInteger(charactersConstraints.maximum),
            }),
          },
        ];
      }
    }

    return [];
  }

  renderConstraints() {
    const charactersConstraints = this.getCharactersConstraints();
    if (charactersConstraints === undefined) {
      return null;
    }
    return (
      <span>
        <span>
          <Typography.P2Regular>{i18n.t("inputs.label.characters")}</Typography.P2Regular>
          <Typography.P2Regular>
            {toStringWithRange(
              toStringWithInteger(charactersConstraints.minimum === undefined ? 0 : charactersConstraints.minimum),
              toStringWithInteger(charactersConstraints.maximum)
            )}
          </Typography.P2Regular>
        </span>
      </span>
    );
  }

  renderComponent() {
    return InputStringUi;
  }
}
