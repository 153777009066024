import ReactDOM from "react-dom/client";

import App from "App";

import { initErrorMonitoring } from "~/util/ErrorMonitoring";

import "assets/style/style.scss";

// variables should be declared at build time based on env and releaseid
// CRA requires REACT_APP_ prefix on env variables
const { REACT_APP_DATA_DOG_APP_ID, REACT_APP_DATA_DOG_CLIENT_TOKEN, REACT_APP_ENV, REACT_APP_RELEASE_ID } = process.env;

if (REACT_APP_ENV && !["localhost", "cypress"].includes(REACT_APP_ENV)) {
  initErrorMonitoring({
    applicationId: REACT_APP_DATA_DOG_APP_ID,
    clientToken: REACT_APP_DATA_DOG_CLIENT_TOKEN,
    env: REACT_APP_ENV,
    version: REACT_APP_RELEASE_ID,
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
