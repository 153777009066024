export default function getPSDJSInstance(callback) {
  if (!window.PSD) {
    const psdScript = document.createElement("script");
    psdScript.src = "https://rawgit.com/meltingice/psd.js/master/dist/psd.min.js";
    document.body.appendChild(psdScript);
    psdScript.addEventListener("load", () => {
      const initPsdScript = document.createElement("script");
      initPsdScript.innerHTML = "window.PSD = require('psd')";
      document.body.appendChild(initPsdScript);
      callback(window.PSD);
    });
  }
  callback(window.PSD);
}

export function toPsdTransfer(fileName, base64Data) {
  return fileName + ";" + base64Data;
}

export function fromPsdTransfer(string) {
  let arr = string.split(";");
  if (arr.length === 3) {
    return {
      fileName: arr[0],
      base64Data: arr[1] + ";" + arr[2],
    };
  }
  throw "PSD File transfer payload is incorrect";
}
