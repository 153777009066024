import Model from "./Model";
import InputTabularUi from "../ui/InputTabularUi/InputTabularUi";

export default class InputTabularModel extends Model {
  constructor(...args) {
    super(...args);
  }

  computeImportValue(value, metas) {
    if (!metas) {
      return undefined;
    }

    return { ...value, ...metas };
  }

  async computeExportValue(value) {
    if (!value) {
      return undefined;
    }

    const { headers, values } = value;
    return { headers, values };
  }

  async computeMetas() {
    const value = this.getValue();
    if (!value) {
      return undefined;
    }

    const { name, size, lastModified } = value;
    return {
      name,
      size,
      lastModified,
    };
  }

  renderComponent() {
    return InputTabularUi;
  }
}
