import Api from "~/util/Api";

async function get(siteId) {
  if (!siteId) {
    return;
  }
  return Api.getSite(siteId);
}

export default {
  get,
};
