import {
  computeBinaryObject,
  computeFileNameFromUrl,
  fromBinaryStringValueToName,
  toBinaryObject,
} from "~/util/InputsComputer";
import Model from "./Model";

export default class InputBinaryModel extends Model {
  hasBinaryForm() {
    return true;
  }

  deleteBinary() {
    this.setValue(undefined, true, undefined, true);
    this.resetErrors();
  }

  getMaximumBytesConstraints() {
    return Model.getMaximumBytesConstraints(this);
  }

  async getBinaryObject() {
    const value = this.getValue();
    if (value === undefined) {
      return undefined;
    }
    if (value.bin !== undefined) {
      return toBinaryObject(value.bin);
    }
    return computeBinaryObject(value);
  }

  getFilename() {
    const value = this.getValue();
    if (value === undefined) {
      return undefined;
    }
    if (value.bin !== undefined) {
      return fromBinaryStringValueToName(value.bin);
    }
    return computeFileNameFromUrl(value, true);
  }

  async getMimeType() {
    const binaryObject = await this.getBinaryObject();
    if (binaryObject) {
      return binaryObject.mimeType;
    }
  }

  async getData() {
    const binaryObject = await this.getBinaryObject();
    if (binaryObject) {
      return binaryObject.data;
    }
  }
}
