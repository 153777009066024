import { useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Col, Button, Progress, Row, theme, Typography, TextOverflow, Space } from "@ogury/design-system";
import Download2LineIcon from "remixicon-react/Download2LineIcon";
import DeleteBinLineIcon from "remixicon-react/DeleteBinLineIcon";
import { isUrl } from "~/util/Utils";
import styles from "./FileUploadMeta.module.scss";
import Icons, { IconNames } from "components/Icons/Icons";
import RefreshLineIcon from "remixicon-react/RefreshLineIcon";
import { ExternalLink } from "components";

const FileUploadMeta = ({ fileMeta, onRemove, onRefresh }) => {
  const [t] = useTranslation();

  const { name, status, icon, percent, downloadBlob, downloadName, size, width, height, lastModified } = fileMeta;
  const isUrlUpload = isUrl(name);

  const progressColor = useMemo(() => {
    let value = theme.colors.secondary.default;
    if (status === "error") {
      value = theme.colors.destructive.background;
    } else if (status === "success") {
      value = theme.colors.success.background;
    }

    return value;
  }, [status]);

  const handleRefresh = () => {
    if (!onRefresh) return;
    onRefresh(name);
  };

  const lastModifiedFormatted = useMemo(() => {
    if (!lastModified) {
      return "";
    }

    return new Date(lastModified).toLocaleString([Intl.DateTimeFormat().resolvedOptions().locale], {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  }, [lastModified]);

  return (
    <div className={styles.fileUploadMetaWrapper} data-testid={`file-meta-${status}`}>
      <Row wrap={false} gutter={[parseInt(theme.spacing.space_xs)]}>
        {IconNames[icon]?.type && (
          <Col className={styles.iconCell}>
            <div className={styles.fileIconWrapper} data-testid="icon-container">
              <Icons className={styles.fileIcon} name={IconNames[icon]?.type} />
            </div>
          </Col>
        )}

        <Col flex="1">
          {isUrlUpload ? (
            <Typography.P2Regular className={styles.fileName}>
              <ExternalLink href={name}>
                <TextOverflow maxWidth="620px" value={name} />
              </ExternalLink>
            </Typography.P2Regular>
          ) : (
            <Typography.P2Regular className={styles.fileName}>
              <TextOverflow maxWidth="620px" value={name} />
            </Typography.P2Regular>
          )}

          {status === "done" ? (
            <div className={styles.fileDescription}>
              <Space>
                {size && (
                  <Typography.P3Regular>
                    {size < 1024 ? `${size} bytes` : `${Math.round(size / 1024)} Kb`}.
                  </Typography.P3Regular>
                )}
                {lastModifiedFormatted && (
                  <Typography.P3Regular>
                    &nbsp;
                    {t("components.tabularInput.lastModified")}&nbsp;
                    {lastModifiedFormatted}
                  </Typography.P3Regular>
                )}
                {width && height && (
                  <Typography.P3Regular>
                    {width}x{height}px
                  </Typography.P3Regular>
                )}
              </Space>
            </div>
          ) : (
            <div className={styles.progressBarWrapper} data-testid="progress-bar">
              <Progress
                status={status}
                strokeWidth="4px"
                showInfo={status === "done"}
                strokeColor={progressColor}
                percent={percent}
              />
            </div>
          )}
        </Col>

        {(status === "done" || status === "error") && isUrlUpload && (
          <Col className={styles.actionCell}>
            <Button
              data-testid="btn-refresh-url-file"
              icon={<RefreshLineIcon />}
              onClick={handleRefresh}
              type="tertiary"
              iconPosition="iconOnly"
              size="small"
            />
          </Col>
        )}
        {status === "done" && (
          <>
            <Col className={styles.actionCell}>
              <a className={styles.actionCell} href={downloadBlob} download={downloadName} target={"_blank"}>
                <Button
                  type="tertiary"
                  iconPosition="iconOnly"
                  icon={<Download2LineIcon />}
                  size="small"
                  data-testid="btn-download-file"
                />
              </a>
            </Col>
          </>
        )}
        <Col className={styles.actionCell}>
          <Button
            type="tertiary"
            iconPosition="iconOnly"
            icon={<DeleteBinLineIcon />}
            onClick={onRemove}
            size="small"
            data-testid="btn-remove-file"
          />
        </Col>
      </Row>
    </div>
  );
};

FileUploadMeta.propTypes = {
  fileMeta: PropTypes.object,
  onRemove: PropTypes.func,
  onRefresh: PropTypes.func,
};

export default FileUploadMeta;
