import React from "react";
import Model from "./Model";
import InputVideoUrlsUi from "../ui/InputVideoUrlsUi/InputVideoUrlsUi";
import i18n from "i18next";
import {
  toStringWithBytes,
  toStringWithCollection,
  toStringWithDuration,
  toStringWithInteger,
  toStringWithLessThan,
  toStringWithRange,
  toStringWithRatio,
} from "~/util/Helpers";
import { Typography } from "@ogury/design-system";
import { SCHEMA_INPUTS_FIELDS } from "~/util/ModelConstants";
import {
  BINARY_SUFFIX,
  computeFileNameFromUrl,
  fromBinaryStringToBlob,
  fromBinaryStringValueToName,
  META_SUFFIX,
} from "~/util/InputsComputer";
import Debug from "~/util/Debug";
import Api from "~/util/Api";
import { urlToBlob } from "~/util/Utils";

export default class InputVideoUrlsModel extends Model {
  constructor(...args) {
    super(...args);
  }

  getFilename() {
    const value = this.getValue();
    if (!value) {
      return undefined;
    }
    if (value.bin !== undefined) {
      return fromBinaryStringValueToName(value.bin);
    }
    const metaKey = `source${META_SUFFIX}`;
    if (value[metaKey] !== undefined) {
      return value[metaKey].fileName;
    }
    return undefined;
  }

  async transcodeVideo(blob, fileName, durationInMilliseconds, width, height) {
    try {
      const result = await Api.transcodeVideo(blob);
      const urls = [];
      result?.widths?.forEach(item => {
        let data = {
          url: item.url,
          ["url" + META_SUFFIX]: {
            mimeType: "video/mp4",
            durationInMilliseconds: result.duration * 1000,
            width: item.width,
            height: item.height || item.width / result.ratio,
          },
        };
        urls.push(data);
      });
      return {
        source: result.source,
        ["source" + META_SUFFIX]: {
          fileName,
          durationInMilliseconds,
          width,
          height,
        },
        urls,
      };
    } catch (error) {
      Debug.error("Cant transcode videoUrl", error);
      return undefined;
    }
  }

  async computeImportValue(value, metas) {
    if (value?.bin) {
      try {
        const blob = fromBinaryStringToBlob(value.bin);
        return await this.transcodeVideo(blob, metas.fileName, metas.durationInMilliseconds, metas.width, metas.height);
      } catch (error) {
        Debug.error(
          "Can't transcode video url input from import value. Please check that the import value is a binary string or an URL"
        );
        return undefined;
      }
    } else if (value?.urls && !value.source) {
      // Retrocompatibility for older creative versions that don't have "source" properties
      const mp4url = value.urls.find(url => url.format?.toUpperCase() === "MP4")?.url;

      if (!mp4url) {
        Debug.error("Can't transcode video url input from older import value. Please check the experience format.");
        return undefined;
      }
      try {
        const video = document.createElement("video");
        video.src = mp4url;
        const loadMetadata = new Promise((resolve, reject) => {
          video.addEventListener("loadedmetadata", () => {
            resolve({
              durationInMilliseconds: video.duration * 1000,
              width: video.videoWidth,
              height: video.videoHeight,
            });
          });
          video.addEventListener("error", () => {
            reject(new Error("Error loading video metadata."));
          });
        });

        const { durationInMilliseconds, width, height } = await loadMetadata;
        video.remove();
        const fileName = await computeFileNameFromUrl(mp4url, true);
        const blob = await urlToBlob(mp4url);

        return await this.transcodeVideo(blob.blob, fileName, durationInMilliseconds, width, height);
      } catch (error) {
        Debug.error(
          "Can't transcode video url input from import value. Please check that the import value is a binary string or an URL",
          error
        );
        return undefined;
      }
    }
    return value;
  }

  hasBinaryForm() {
    return true;
  }

  async export(assetsBaseUrl, resolveAssets) {
    let exportKey = this.getReferenceId();
    let exportObject = {};
    let binaryValue;
    if (resolveAssets) {
      binaryValue = await this.exportBinaryValue(assetsBaseUrl, resolveAssets, this.getValue()?.source, true);
    }

    if (binaryValue !== undefined) {
      exportObject[exportKey + BINARY_SUFFIX] = binaryValue;
      const metas = await this.computeMetas();
      if (metas !== undefined) {
        exportObject[exportKey + META_SUFFIX] = metas;
      }
    } else {
      exportObject[exportKey] = this.getValue();
    }

    return exportObject;
  }

  async computeExportValue(value) {
    return value.source;
  }

  async computeMetas() {
    return this.getValue()?.["source" + META_SUFFIX];
  }

  getFormatsConstraints() {
    return Model.getFormatsConstraints(this);
  }

  getMaximumBytesConstraints() {
    return Model.getMaximumBytesConstraints(this);
  }

  getRatioConstraints() {
    return Model.getRatioConstraints(this);
  }

  getWidthConstraints() {
    const constraints = this.getConstraints();
    const widthConstraints =
      constraints === undefined ? undefined : constraints[SCHEMA_INPUTS_FIELDS.ConstraintsVideoUrlsWidth];
    return {
      minimum:
        widthConstraints === undefined || widthConstraints[SCHEMA_INPUTS_FIELDS.ConstraintsRangeMinimum] === undefined
          ? undefined
          : widthConstraints[SCHEMA_INPUTS_FIELDS.ConstraintsRangeMinimum],
      maximum:
        widthConstraints === undefined || widthConstraints[SCHEMA_INPUTS_FIELDS.ConstraintsRangeMaximum] === undefined
          ? undefined
          : widthConstraints[SCHEMA_INPUTS_FIELDS.ConstraintsRangeMaximum],
    };
  }

  getDurationInMillisecondsConstraints() {
    const constraints = this.getConstraints();
    const durationInMillisecondsConstraints =
      constraints === undefined
        ? undefined
        : constraints[SCHEMA_INPUTS_FIELDS.ConstraintsVideoUrlsDurationInMilliseconds];
    return {
      minimum:
        durationInMillisecondsConstraints === undefined ||
        durationInMillisecondsConstraints[SCHEMA_INPUTS_FIELDS.ConstraintsRangeMinimum] === undefined
          ? undefined
          : durationInMillisecondsConstraints[SCHEMA_INPUTS_FIELDS.ConstraintsRangeMinimum],
      maximum:
        durationInMillisecondsConstraints === undefined ||
        durationInMillisecondsConstraints[SCHEMA_INPUTS_FIELDS.ConstraintsRangeMaximum] === undefined
          ? undefined
          : durationInMillisecondsConstraints[SCHEMA_INPUTS_FIELDS.ConstraintsRangeMaximum],
    };
  }

  async computeConstraintsErrors() {
    return [];
  }

  renderConstraints() {
    const formats = this.getFormatsConstraints();
    const { minimum: minimumRatio, maximum: maximumRatio } = this.getRatioConstraints();
    const { minimum: minimumWidth, maximum: maximumWidth } = this.getWidthConstraints();
    const { minimum: minimumDurationInMilliseconds, maximum: maximumDurationInMilliseconds } =
      this.getDurationInMillisecondsConstraints();
    const maximumBytes = this.getMaximumBytesConstraints();
    if (
      formats === undefined &&
      minimumRatio === undefined &&
      maximumRatio === undefined &&
      minimumDurationInMilliseconds === undefined &&
      maximumDurationInMilliseconds === undefined &&
      maximumBytes === undefined
    ) {
      return null;
    }
    return (
      <span>
        {formats !== undefined && (
          <span>
            <Typography.P2Regular>{i18n.t("inputs.label.formats")}</Typography.P2Regular>
            <Typography.P2Regular>{toStringWithCollection(formats)}</Typography.P2Regular>
          </span>
        )}
        {(minimumRatio !== undefined || maximumRatio !== undefined) && (
          <span>
            <Typography.P2Regular>{i18n.t("inputs.label.ratio")}</Typography.P2Regular>
            <Typography.P2Regular>
              {toStringWithRange(toStringWithRatio(minimumRatio), toStringWithRatio(maximumRatio))}
            </Typography.P2Regular>
          </span>
        )}
        {(minimumWidth !== undefined || maximumWidth !== undefined) && (
          <span>
            <Typography.P2Regular>{i18n.t("inputs.label.width")}</Typography.P2Regular>
            <Typography.P2Regular>
              {toStringWithRange(toStringWithInteger(minimumWidth), toStringWithInteger(maximumWidth))}
            </Typography.P2Regular>
          </span>
        )}
        {(minimumDurationInMilliseconds !== undefined || maximumDurationInMilliseconds !== undefined) && (
          <span>
            <Typography.P2Regular>{i18n.t("inputs.label.duration")}</Typography.P2Regular>
            <Typography.P2Regular>
              {toStringWithRange(
                toStringWithDuration(minimumDurationInMilliseconds),
                toStringWithDuration(maximumDurationInMilliseconds)
              )}
            </Typography.P2Regular>
          </span>
        )}
        {maximumBytes !== undefined && (
          <span>
            <Typography.P2Regular>{i18n.t("inputs.label.weight")}</Typography.P2Regular>
            <Typography.P2Regular>{toStringWithLessThan(toStringWithBytes(maximumBytes))}</Typography.P2Regular>
          </span>
        )}
      </span>
    );
  }

  renderComponent() {
    return InputVideoUrlsUi;
  }
}
