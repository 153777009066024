import Model from "./Model";
import i18n from "i18next";
import References from "../../../util/References";

export default class InputCompositeModel extends Model {
  constructor(...args) {
    super(...args);
    this.childInputs = this.computeChildInputs();
  }

  computeChildInputs() {
    throw new Error(i18n.t("error.technical.computeInputs"));
  }

  getSuperType() {
    return References.INPUT_SUPER_TYPE.COMPOSITE;
  }

  getConstraintsProperty(property, defaultValue) {
    const constraints = this.getConstraints();
    return constraints === undefined || constraints[property] === undefined ? defaultValue : constraints[property];
  }
}
