import { Select, Space, theme, Typography } from "@ogury/design-system";
import ExperienceService from "~/services/ExperienceService";
import React, { useEffect, useMemo, useState } from "react";
import style from "./PreviewControls.module.scss";
import { Button } from "components";
import PlayLineIcon from "remixicon-react/PlayLineIcon";
import PauseLineIcon from "remixicon-react/PauseLineIcon";
import { useTranslation } from "react-i18next";

// TODO implement the multi-score when a template is available.
export default function PreviewControls({ experienceDescription, onSelectScoreAndTrack, onPlayTrack, onPauseTrack }) {
  const { scoresOptions, tracksOptionsByScore } = useMemo(() => {
    return ExperienceService.generateScoreAndTrackOptions(experienceDescription);
  }, [experienceDescription]);

  const { t } = useTranslation();

  const [playing, setPlaying] = useState(true);
  const [dropdownScores, setDropdownScores] = useState([]);
  const [dropdownTracks, setDropdownTracks] = useState([]);
  const [selectedScore, setSelectedScore] = useState(-1);
  const [selectedTrack, setSelectedTrack] = useState(-1);
  const [scoreAndTrackToPlay, setScoreAndTrackToPlay] = useState({
    scoreId: -1,
    trackId: -1,
  });

  function handleOnPlayPause() {
    if (playing) {
      setPlaying(false);
      onPauseTrack();
    } else {
      setPlaying(true);
      onPlayTrack();
    }
  }
  useEffect(() => {
    if (scoresOptions.length && tracksOptionsByScore.length) {
      // Resetting everything if there is only one track and one score
      if (scoresOptions.length === 1 && tracksOptionsByScore[0].options.length === 1) {
        setSelectedTrack(-1);
        setSelectedScore(-1);
        setDropdownTracks();
        setDropdownScores();
        return;
      }

      if (scoresOptions.length > 1) {
        scoresOptions.unshift({ value: -1, label: t("rightPanel.allScores") });
        setDropdownScores(scoresOptions);
        setDropdownTracks();
      } else {
        setSelectedScore(scoresOptions[0].value);
        if (tracksOptionsByScore[0].options?.length > 1) {
          const options = tracksOptionsByScore[0].options;
          options.unshift({ value: -1, label: t("rightPanel.allTracks") });
          setDropdownTracks(options);
        } else {
          setDropdownTracks();
        }
      }
    }

    // If the track previously selected does not exists anymore (== removal of a collection)
    if (selectedTrack !== -1) {
      const trackExists = tracksOptionsByScore.some(trackOptionByScore =>
        trackOptionByScore.options.some(option => option.value === selectedTrack)
      );
      if (!trackExists) {
        setSelectedScore(-1);
      }
    }
  }, [scoresOptions, tracksOptionsByScore]);

  useEffect(() => {
    if (selectedScore === -1) {
      setSelectedTrack(-1);
      return;
    }
    const options =
      tracksOptionsByScore?.find(trackOptionByScore => trackOptionByScore.scoreValue === selectedScore)?.options ||
      tracksOptionsByScore[0]?.options;
    if (options?.length === 1) {
      setSelectedTrack(options[0].value);
    } else if (options?.length > 1) {
      if (options[0].value !== -1) {
        options.unshift({ value: -1, label: t("rightPanel.allTracks") });
      }
      setDropdownTracks(options);
      setSelectedTrack(options[0].value);
    }
  }, [selectedScore]);

  useEffect(() => {
    if (scoreAndTrackToPlay.scoreId && scoreAndTrackToPlay.trackId) {
      onSelectScoreAndTrack(scoreAndTrackToPlay.scoreId, scoreAndTrackToPlay.trackId);
    }
    setPlaying(true);
  }, [scoreAndTrackToPlay]);

  useEffect(() => {
    const playingTrack = scoreAndTrackToPlay.trackId;
    const playingScore = scoreAndTrackToPlay.scoreId;
    if (playingTrack !== selectedTrack || playingScore !== selectedScore) {
      setScoreAndTrackToPlay({
        scoreId: selectedScore,
        trackId: selectedTrack,
      });
    }
  }, [selectedTrack]);

  function renderDropdownTracks() {
    if (dropdownTracks?.length) {
      return (
        <Space size="xxs" direction="vertical" style={{ width: "100%" }}>
          <Typography.P2Strong
            style={{
              color: theme.colors.content.secondary,
              textAlign: "left",
            }}
          >
            {t("rightPanel.track")}
          </Typography.P2Strong>
          <Select
            data-testid="dropdown-tracks"
            options={dropdownTracks}
            width={"100%"}
            onChange={setSelectedTrack}
            value={selectedTrack}
          />
        </Space>
      );
    }
  }

  function renderDropdownScores() {
    if (dropdownScores?.length) {
      return (
        <Space size="xxs" direction="vertical" style={{ width: "100%" }}>
          <Typography.P2Strong
            style={{
              color: theme.colors.content.secondary,
              textAlign: "left",
            }}
          >
            {t("rightPanel.score")}
          </Typography.P2Strong>
          <Select width={"100%"} value={selectedScore} options={dropdownScores} onChange={setSelectedScore} />
        </Space>
      );
    }
  }

  function renderPlayPauseButton() {
    if (selectedTrack && selectedTrack !== -1) {
      return (
        <Button onClick={handleOnPlayPause} type="secondary" iconPosition="iconOnly" size="default">
          {playing ? <PauseLineIcon /> : <PlayLineIcon />}
        </Button>
      );
    }
  }

  return (
    <div className={style.container}>
      <Space size="s" direction="vertical" style={{ width: "100%" }}>
        {renderDropdownScores()}
        {renderDropdownTracks()}
      </Space>
      {renderPlayPauseButton()}
    </div>
  );
}
