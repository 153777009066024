import { useMemo } from "react";
import PropTypes from "prop-types";
import { Tooltip as DsTooltip } from "@ogury/design-system";
import { random } from "../../util/Utils";
import QuestionLineIcon from "remixicon-react/QuestionLineIcon";

const Tooltip = ({ content, isRight = false, children, iconSize = 16 }) => {
  if (content === "") {
    return null;
  }
  const id = useMemo(() => {
    return random();
  }, []);

  return (
    <DsTooltip id={id} placement={isRight === false ? "bottom" : "right"} title={content}>
      {children ? children : <QuestionLineIcon size={iconSize} />}
    </DsTooltip>
  );
};

Tooltip.propTypes = {
  content: PropTypes.any.isRequired,
  isRight: PropTypes.bool,
};

export default Tooltip;
