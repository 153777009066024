import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import FileLineIcon from "remixicon-react/FileLineIcon";
import { theme, Typography } from "@ogury/design-system";
import getPSDJSInstance from "~/util/Psd";
import { AssetItem } from "./components";
import { Divider, FileInput, Loader } from "components";
import { usePsdStore } from "context/PsdStoreContext";
import { DBNAME, NUMBER_OF_DAYS_TO_KEEP_PSD_FILES, STORENAME, TRANSACTIONMODE } from "~/util/Constant";
import { addSuffixOnDupplicates } from "~/util/Utils";
import style from "./PsdExplorer.module.scss";
import { useExperience } from "~/context";
import Debug from "~/util/Debug";

export default function PsdExplorer() {
  const { setPsdStore } = usePsdStore();
  const { experience } = useExperience();
  const [loading, setLoading] = useState(false);
  const [allPSDFiles, setAllPSDFiles] = useState([]);
  const [selectedPSDFiles, setSelectedPSDFiles] = useState([]);
  const [currentFileName, setCurrentFileName] = useState();
  const [t] = useTranslation();

  const setStorePSDFiles = allPSDFiles => {
    setPsdStore(allPSDFiles);
  };

  function formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return hours + ":" + minutes + ampm;
  }

  function isYesterday(saveTime) {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const [date, month, year] = saveTime.split("-");
    const saveDate = new Date(year, month, date);

    return yesterday.toDateString() === saveDate.toDateString();
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  useEffect(() => {
    if (experience?.id !== undefined) {
      const request = window.indexedDB.open(DBNAME, 1);
      request.onsuccess = function (event) {
        const db = event.target.result;
        const transaction = db.transaction([STORENAME], TRANSACTIONMODE.RW);
        const store = transaction.objectStore(STORENAME);

        try {
          //Get all PSD files
          const requestAllDatas = store.getAll();
          requestAllDatas.onsuccess = event => {
            const relativeFiles = event.target.result.filter(f => f.experienceId === experience.id);
            const deletedFilesKeys = [];
            //Delete PSD Files more than 14 days
            for (const file of relativeFiles) {
              if (Math.floor((Date.now() - file.timestamp) / 1000 / 60 / 60 / 24) > NUMBER_OF_DAYS_TO_KEEP_PSD_FILES) {
                deletedFilesKeys.push(file.PSD);
                transaction.objectStore(STORENAME).delete(file.PSD);
              }
            }
            const newRelativeFiles = relativeFiles.filter(f => !deletedFilesKeys.includes(f.PSD));

            //Set PSD files items
            const PSDFiles = newRelativeFiles.length > 0 ? JSON.parse(newRelativeFiles[0].value) : undefined;
            if (PSDFiles) {
              setSelectedPSDFiles(PSDFiles);
              setCurrentFileName(newRelativeFiles[0].fileName);
            }
            setAllPSDFiles(newRelativeFiles);
            setStorePSDFiles(newRelativeFiles);
          };
        } catch (error) {
          Debug.error("An error occured while loading the PSD files from indexedDB", error);
        }
        db.close();
      };

      request.onupgradeneeded = function (event) {
        const db = event.target.result;
        db.createObjectStore(STORENAME, {
          keyPath: "PSD",
          autoIncrement: true,
        });
      };
    }
  }, []);

  function handleUpload(event) {
    try {
      const fileName = event.fileName;
      let fileToDelete = "";
      const allPSDFile = allPSDFiles.length > 0 ? allPSDFiles.map(file => file.fileName) : [];
      if (allPSDFile.length > 2) {
        fileToDelete = allPSDFile[0];
      }

      setCurrentFileName(fileName);
      let updatedAllPSDFiles = allPSDFiles;

      getPSDJSInstance(PSD => {
        const file = new Uint8Array(event.fileBytesArray);
        const psd = new PSD(file);
        const filesArray = [];

        psd.parse();
        const tree = psd.tree();
        tree.descendants().forEach(searchForAssets);

        function searchForAssets(node) {
          try {
            const { image } = node.layer;

            // if width and height equals 0, then this is a group and should not be parsed
            if (image.width() === 0 && image.height() === 0) {
              return;
            }

            filesArray.push({
              name: node.name,
              width: image.width(),
              height: image.height(),
              base64Data: image.toBase64(),
            });
          } catch (error) {
            console.error("Can't base64 image", error);
          }
        }

        if (fileToDelete !== "") {
          updatedAllPSDFiles = allPSDFiles.filter(file => file.fileName != fileToDelete);
        }

        const dateTime = new Date(Date.now());
        const [year, month, date] = dateTime.toISOString().replace("T", "-").split("-");
        const isoStr = `${padTo2Digits(date)}-${padTo2Digits(month)}-${year}, ${formatAMPM(dateTime)} `;

        const curatedFilesname = addSuffixOnDupplicates(filesArray.map(file => file.name));
        const newFilesArray = filesArray.map((file, index) => {
          file.name = curatedFilesname[index];
          return file;
        });

        const newPSDFile = {
          experienceId: undefined,
          fileName: fileName,
          time: isoStr,
          timestamp: Date.now(),
          value: JSON.stringify(newFilesArray),
        };

        updatedAllPSDFiles = updatedAllPSDFiles.concat(newPSDFile);

        setAllPSDFiles(updatedAllPSDFiles);

        setSelectedPSDFiles(newFilesArray);

        setStorePSDFiles(updatedAllPSDFiles);
      });
    } catch (error) {
      Debug.error();
    } finally {
      setLoading(false);
    }
  }

  function handleOnChange(e, name) {
    const selectedFile = allPSDFiles.find(file => file.fileName === name);
    setSelectedPSDFiles(JSON.parse(selectedFile.value));
    setCurrentFileName(selectedFile.fileName);
  }

  function handleOnError() {}

  function handleOnProcessing() {
    setLoading(true);
  }

  function renderLoading() {
    if (loading) {
      return (
        <div className={style.loaderContainer} data-testid="loading">
          <Loader text={t("leftPanel.PSDExplorer.loading")} />
        </div>
      );
    }
  }

  const FileName = ({ fileName, children }) => {
    return (
      <div className={style.fileNameContainer} data-testid="file-name">
        <div>
          <FileLineIcon size={17} color={theme.colors.media.geekBlue.geekblue6} />
        </div>
        <span>
          <Typography.P2Regular className={style.fileName}>{fileName}</Typography.P2Regular>
          {children}
        </span>
      </div>
    );
  };
  FileName.propTypes = {
    fileName: PropTypes.string,
    children: PropTypes.node,
  };

  function renderFileNames() {
    if (!loading) {
      return (
        <div className={style.contentContainer}>
          {allPSDFiles.length > 1 && (
            <Typography.P2Strong as="h3" className={style.title}>
              {t("leftPanel.PSDExplorer.recentlyUploaded")}
            </Typography.P2Strong>
          )}
          {allPSDFiles.length > 1 &&
            allPSDFiles?.map((file, index) => {
              const fileTime = file.time ? file.time.split(",") : [];
              return (
                <div
                  className={style.recentFileContainer}
                  key={"psd-asset-" + index}
                  onClick={e => handleOnChange(e, file.fileName)}
                >
                  <FileName fileName={file.fileName}>
                    {fileTime.length === 2 && (
                      <div>
                        <Typography.P3Regular style={{ color: theme.colors.content.subdued }}>
                          {isYesterday(fileTime[0])
                            ? t("leftPanel.PSDExplorer.yesterday") + ", " + fileTime[1]
                            : file.time}
                        </Typography.P3Regular>
                      </div>
                    )}
                  </FileName>
                </div>
              );
            })}
        </div>
      );
    }
  }

  function renderFiles() {
    if (!loading) {
      return (
        <div>
          {currentFileName && <Divider style={{ marginBottom: 15, marginTop: 15 }} />}
          {currentFileName && (
            <Typography.P2Strong as="h3" className={style.title}>
              {t("leftPanel.PSDExplorer.currentFile")}
            </Typography.P2Strong>
          )}
          {currentFileName && <FileName fileName={currentFileName} />}
          <div className={style.assetsContainer}>
            {selectedPSDFiles && selectedPSDFiles?.map((file, index) => <AssetItem key={index} item={file} />)}
          </div>
        </div>
      );
    }
  }

  function renderFileInput() {
    if (!loading) {
      return (
        <FileInput
          hint={t("components.filePicker.uploadHintPsd")}
          disabled={loading}
          fileExtensions={[".psd"]}
          onChanged={handleUpload}
          readArrayBuffer
          onError={handleOnError}
          onProcessing={handleOnProcessing}
          data-testid="input-file"
        >
          <Typography.P2Regular>{t("leftPanel.PSDExplorer.uploadText")}</Typography.P2Regular>
        </FileInput>
      );
    }
  }

  return (
    <div className={style.container}>
      {renderFileInput()}
      {renderLoading()}
      {renderFileNames()}
      {renderFiles()}
    </div>
  );
}
