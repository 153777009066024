import { useState } from "react";
import { Row, Col, Button, Input, theme, Form } from "@ogury/design-system";
import styles from "./InputUrlUpload.module.scss";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const InputUrlUpload = ({ onProcessUrl, rules, help, placeholder }) => {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const [canSubmit, setCanSubmit] = useState(false);
  const [loading, setLoading] = useState(false);

  const validateUrl = () => {
    form
      .validateFields(["url"])
      .then(({ url }) => setCanSubmit(!!url))
      .catch(() => setCanSubmit(false));
  };

  const handleSubmit = async ({ url }) => {
    try {
      setLoading(true);
      await onProcessUrl(url);
    } catch (error) {
      form.setFields([{ name: "url", errors: [error.message] }]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form form={form} onValuesChange={validateUrl} onFinish={handleSubmit}>
      <Row gutter={[parseInt(theme.spacing.space_xs)]}>
        <Col flex="auto">
          <Form.Item name="url" rules={rules} extra={help}>
            <Input placeholder={placeholder} className={styles.urlInput} data-testid="url-input" />
          </Form.Item>
        </Col>
        <Col>
          <Button submit loading={loading} disabled={!canSubmit} type="secondary" data-testid="url-upload-button">
            {t("components.urlFileUpload.upload")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

InputUrlUpload.propTypes = {
  onProcessUrl: PropTypes.func.isRequired,
  rules: PropTypes.arrayOf(PropTypes.object),
  help: PropTypes.node,
  placeholder: PropTypes.string,
};

export default InputUrlUpload;
