import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Image } from "react-konva/es/ReactKonva";

const KonvaURLImage = ({ src, ...rest }) => {
  const [image, setImage] = useState(null);

  const loadImage = imageURL => {
    if (!imageURL) {
      setImage(null);
      return;
    }
    const htmlImage = new window.Image();
    htmlImage.src = imageURL;

    const handleImageLoad = () => {
      setImage(htmlImage);
      htmlImage.removeEventListener("load", handleImageLoad);
    };
    htmlImage.addEventListener("load", handleImageLoad);
  };

  useEffect(() => {
    loadImage(src);
  }, [src]);

  if (!image) {
    return null;
  }

  return <Image data-testid="konva-image" image={image} {...rest} />;
};

KonvaURLImage.propTypes = {
  src: PropTypes.string.isRequired,
};

export default KonvaURLImage;
