import { SCHEMA_INPUTS_FIELDS } from "~/util/ModelConstants";
import InputGroupModel from "./InputGroupModel";
import InputCollectionModel from "./InputCollectionModel";
import InputBooleanModel from "./InputBooleanModel";
import InputStringModel from "./InputStringModel";
import InputTextModel from "./InputTextModel";
import InputRichTextModel from "./InputRichTextModel";
import InputUrlModel from "./InputUrlModel";
import InputFloatModel from "./InputFloatModel";
import InputIntegerModel from "./InputIntegerModel";
import InputColorModel from "./InputColorModel";
import InputPointModel from "./InputPointModel";
import InputFrameModel from "./InputFrameModel";
import InputCtaModel from "./InputCtaModel";
import InputSpriteModel from "./InputSpriteModel";
import InputVideoModel from "./InputVideoModel";
import InputVideoUrlsModel from "./InputVideoUrlsModel";
import InputFontModel from "./InputFontModel";
import InputLottieModel from "./InputLottieModel";
import InputInvalidModel from "./InputInvalidModel";
import InputStripesModel from "./InputStripesModel";
import InputImageOverlayModel from "./InputImageOverlayModel";
import InputTabularModel from "./InputTabularModel";
import InputCollectionChildModel from "./InputCollectionChildModel";
import InputLottieOverlayModel from "./InputLottieOverlayModel";
import InputZipModel from "app/inputs/model/InputZipModel";
import InputMultipleImagesModel from "app/inputs/model/InputMultipleImagesModel";

const inputMap = {
  Group: { type: "group", model: InputGroupModel },
  Collection: { type: "collection", model: InputCollectionModel },
  CollectionChild: { type: "collectionChild", model: InputCollectionChildModel },
  Boolean: { type: "boolean", model: InputBooleanModel },
  String: { type: "string", model: InputStringModel },
  Text: { type: "text", model: InputTextModel },
  RichText: { type: "richText", model: InputRichTextModel },
  Url: { type: "url", model: InputUrlModel },
  Float: { type: "float", model: InputFloatModel },
  Integer: { type: "integer", model: InputIntegerModel },
  Color: { type: "color", model: InputColorModel },
  Point: { type: "point", model: InputPointModel },
  Frame: { type: "frame", model: InputFrameModel },
  Cta: { type: "cta", model: InputCtaModel },
  Stripes: { type: "stripes", model: InputStripesModel },
  Image: { type: "image", model: InputSpriteModel },
  MultipleImages: { type: "multipleImages", model: InputMultipleImagesModel },
  Video: { type: "video", model: InputVideoModel },
  VideoUrls: { type: "videoUrls", model: InputVideoUrlsModel },
  Font: { type: "font", model: InputFontModel },
  Lottie: { type: "lottie", model: InputLottieModel },
  ImageOverlay: { type: "imageOverlay", model: InputImageOverlayModel },
  Tabular: { type: "tabular", model: InputTabularModel },
  LottieOverlay: { type: "lottieOverlay", model: InputLottieOverlayModel },
  Zip: { type: "zip", model: InputZipModel },
};

export const InputTypes = {};
const inputDefinition = {};
// eslint-disable-next-line guard-for-in, no-restricted-syntax
for (const inputType in inputMap) {
  const entry = inputMap[inputType];
  InputTypes[inputType] = entry.type;
  inputDefinition[entry.type] = entry.model;
}

export function createChildCollection(id, name) {
  let ModelClass = inputDefinition[InputTypes.CollectionChild];
  return new ModelClass(id, name);
}

export function createModel(referenceInput, id) {
  const type = referenceInput[SCHEMA_INPUTS_FIELDS.Type];
  let ModelClass;

  if (type === undefined) {
    if (referenceInput[SCHEMA_INPUTS_FIELDS.Count] === undefined) {
      ModelClass = inputDefinition[InputTypes.Group];
    } else {
      ModelClass = inputDefinition[InputTypes.Collection];
    }
  } else {
    ModelClass = inputDefinition[referenceInput.type];
  }

  if (ModelClass === undefined) {
    console.error(`Cannot instantiate a model for the type '${type}'`);
    return new InputInvalidModel(referenceInput, id);
  }
  return new ModelClass(referenceInput, id);
}
