import React, { cloneElement, useEffect, useState } from "react";
import { AdUnitCanvas, AdUnitCanvasControls } from "components";
import { Button, Row } from "@ogury/design-system";
import RefreshLineIcon from "remixicon-react/RefreshLineIcon";
import GridLineIcon from "remixicon-react/GridLineIcon";
import PropTypes from "prop-types";

export default function InteractiveArea({
  inputData,
  withRotation = false,
  onChange,
  constraintsReferenceInput,
  children,
}) {
  const [canvasData, setCanvasData] = useState();
  const [gridEnabled, setGridEnabled] = useState(true);
  const [konvaElementCoordinatesAndSize, setKonvaElementCoordinatesAndSize] = useState();
  const ratio = inputData?.meta ? inputData.meta.width / inputData.meta.height : 1;

  useEffect(() => {
    if (!canvasData?.canvasDimensions || !canvasData.originalAdSize || !inputData) {
      return;
    }
    // First load after having uploaded a new lottie element, the size is 0 but now we have the canvas info "originalAdSize" so we can compute the correct dimensions
    if (inputData.value.size === 0) {
      const initialElementWidth = inputData.meta.width;
      onChange({
        ...inputData.value,
        size: (initialElementWidth * 100) / canvasData.originalAdSize.width,
      });
    }

    const { x, y, size, rotation } = inputData.value;
    setKonvaElementCoordinatesAndSize({
      ...fromOutputToLocalFormat(x, y, size),
      rotation,
    });
  }, [canvasData, inputData.value]);

  function fromCanvasPixelToPercentage(x, y, width, height) {
    const canvasWidth = canvasData?.canvasDimensions.width;
    const canvasHeight = canvasData?.canvasDimensions.height;

    const xPercentage = (x * 100) / canvasWidth;
    const yPercentage = (y * 100) / canvasHeight;

    const widthPercentage = (width * 100) / canvasWidth;
    const heightPercentage = (height * 100) / canvasHeight;

    return {
      x: xPercentage,
      y: yPercentage,
      width: widthPercentage,
      height: heightPercentage,
    };
  }

  function fromPercentageToCanvasPixels(x, y, width = 1, height = 1) {
    const canvasWidth = canvasData?.canvasDimensions.width;
    const canvasHeight = canvasData?.canvasDimensions.height;
    const xPixels = (x * canvasWidth) / 100;
    const yPixels = (y * canvasHeight) / 100;
    const widthPixels = (width * canvasWidth) / 100;
    const heightPixels = (height * canvasHeight) / 100;

    return {
      x: xPixels,
      y: yPixels,
      width: widthPixels,
      height: heightPixels,
    };
  }

  function fromOutputToLocalFormat(x, y, size) {
    const { x: xPercentage, y: yPercentage } = fromPercentageToCanvasPixels(x, y);
    const canvasWidth = canvasData?.canvasDimensions.width;

    const width = (size * canvasWidth) / 100;
    const height = width / ratio;

    return {
      x: xPercentage,
      y: yPercentage,
      width,
      height,
    };
  }

  function fromLocalToOutputFormat(x, y, width) {
    const { x: xPercentage, y: yPercentage, width: widthPercentage } = fromCanvasPixelToPercentage(x, y, width);
    return {
      x: xPercentage,
      y: yPercentage,
      size: widthPercentage,
    };
  }

  function handleOnCanvasChange(data) {
    const { canvasDimensions, originalAdSize } = data;
    setCanvasData({
      canvasDimensions,
      originalAdSize,
    });
  }

  function handleOnKonvaElementMovedOrResized(data) {
    const { x, y, width, rotation } = data;
    onChange({
      ...inputData.value,
      ...fromLocalToOutputFormat(x, y, width),
      rotation: rotation,
    });
  }

  function handleOnResetData() {
    const initialElementWidth = inputData.meta.width;
    onChange({
      ...inputData.value,
      x: 0,
      y: 0,
      size: (initialElementWidth * 100) / canvasData.originalAdSize.width,
      rotation: 0,
    });
  }

  const enrichedChildren = cloneElement(children, {
    withRotation,
    canvasData,
    ...konvaElementCoordinatesAndSize,
    onChange: handleOnKonvaElementMovedOrResized,
  });

  return (
    <>
      <AdUnitCanvas
        onCanvasChange={handleOnCanvasChange}
        constraintsReferenceInput={constraintsReferenceInput}
        gridEnabled={gridEnabled}
      >
        {konvaElementCoordinatesAndSize && enrichedChildren}
      </AdUnitCanvas>
      <Row align="end">
        <Button
          type={"tertiary"}
          onClick={handleOnResetData}
          iconPosition="iconOnly"
          size="small"
          icon={<RefreshLineIcon />}
        />
        <Button
          type={gridEnabled ? "secondary" : "tertiary"}
          onClick={() => setGridEnabled(!gridEnabled)}
          iconPosition="iconOnly"
          size="small"
          icon={<GridLineIcon />}
        />
      </Row>
      {konvaElementCoordinatesAndSize && canvasData?.canvasDimensions && canvasData?.originalAdSize && (
        <AdUnitCanvasControls
          withRotation={withRotation}
          canvasData={canvasData}
          konvaElementRatio={ratio}
          konvaElementCoordinatesAndSize={konvaElementCoordinatesAndSize}
          onChange={handleOnKonvaElementMovedOrResized}
        />
      )}
    </>
  );
}

InteractiveArea.propTypes = {
  onChange: PropTypes.func.isRequired,
  withRotation: PropTypes.bool,
  inputData: PropTypes.shape({
    value: PropTypes.object.isRequired,
    meta: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  constraintsReferenceInput: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};
