import styles from "./ExternalLink.module.scss";
import ExternalLinkFillIcon from "remixicon-react/ExternalLinkFillIcon";

function ExternalLink({ children, ...props }) {
  return (
    <a {...props} className={`${styles.link} ${props.className ?? ""}`} target="_blank" rel="noopener noreferrer">
      {children}
      <ExternalLinkFillIcon className={styles.icon} />
    </a>
  );
}

export default ExternalLink;
