import { createContext, useContext, useState } from "react";

const PsdStoreContext = createContext();

export function usePsdStore() {
  return useContext(PsdStoreContext);
}

function getStore() {
  const [psdStore, setPsdStore] = useState([]);
  return { psdStore, setPsdStore };
}

export function PsdStoreProvider({ children }) {
  const store = getStore();
  return <PsdStoreContext.Provider value={store}>{children}</PsdStoreContext.Provider>;
}
