import { RadioGroup, Select } from "components";
import React from "react";
import { SCHEMA_INPUTS_FIELDS } from "~/util/ModelConstants";
import { theme, Typography } from "@ogury/design-system";
import style from "./AcceptableValues.module.scss";

export default function AcceptableValues({ name, value, disabled, options, onChangeCallback, inputStyle }) {
  const isSelect =
    inputStyle !== undefined && inputStyle[SCHEMA_INPUTS_FIELDS.StyleLayout] === SCHEMA_INPUTS_FIELDS.StyleLayoutCombo;

  function renderOption(option) {
    const fullOption = options.find(opt => opt.value === option.value);

    if (fullOption?.comment) {
      return (
        <div className={style.optionWithCommentContainer}>
          <Typography.P2Regular>{fullOption.label}</Typography.P2Regular>
          <Typography.P3Regular style={{ color: theme.colors.content.subdued }}>
            {fullOption.comment}
          </Typography.P3Regular>
        </div>
      );
    }
    return option.label;
  }

  if (isSelect === true) {
    return (
      <Select
        optionRender={renderOption}
        disabled={disabled}
        name={name}
        options={options}
        value={value}
        onChange={onChangeCallback}
        width="40%"
      />
    );
  }
  const isHorizontal =
    inputStyle !== undefined &&
    inputStyle[SCHEMA_INPUTS_FIELDS.StyleOrientation] === SCHEMA_INPUTS_FIELDS.StyleOrientationHorizontal;
  return (
    <RadioGroup name={name} options={options} isHorizontal={isHorizontal} value={value} onChange={onChangeCallback} />
  );
}
