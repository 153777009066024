import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FileUploadMeta, InputWrapper, KonvaElement } from "components";
import FilePickerV2 from "components/FilePickerV2/FilePickerV2";
import { fromFileToJSON, META_SUFFIX } from "~/util/InputsComputer";
import { validateLottieFormat } from "~/util/Format";
import { Form, Typography } from "@ogury/design-system";
import Api from "~/util/Api";
import InteractiveArea from "../../../../components/InteractiveArea/InteractiveArea";
import PropTypes from "prop-types";
import style from "./InputLottieOverlayUi.module.scss";
import LottieFilesLogo from "assets/img/lottie-logo.svg";
import { LottieFilesAPIModal } from "app/inputs/ui/InputLottieOverlayUi/components";
import References from "~/util/References";
import { datadogRum } from "@datadog/browser-rum";
import DatadogEvents from "~/util/DatadogEvents";

const lottieFileExtension = ".json";
const lottieMimeType = "application/json";
// TODO create a factorized component for FilePickerV2 (like BinaryUI, maybe BinaryUiV2 ?), allowing multiple formats (tabular, image, video, lottie, font)

export default function InputLottieOverlayUi({ input }) {
  const [uploadedFile, setUploadedFile] = useState();
  const [t] = useTranslation();
  const [showLottieFilesAPIModal, setShowLottieFilesAPIModal] = useState(false);

  const inputData = useMemo(() => {
    if (input.getValue()) {
      const value = input.getValue();
      const meta = value?.["source" + META_SUFFIX];
      return {
        value,
        meta,
      };
    }
    return undefined;
  }, [input.getValue()]);

  function getUploadFileMeta(inputValue) {
    if (!inputValue) {
      return null;
    }
    const { source } = inputValue;
    const metaProperty = `source${META_SUFFIX}`;
    const sourceMeta = inputValue[metaProperty];
    const { fileName, width, height, bytes } = sourceMeta;
    return {
      icon: "default",
      status: "done",
      name: fileName,
      downloadName: fileName,
      downloadBlob: source,
      size: bytes,
      width,
      height,
    };
  }
  const parsedErrors = useMemo(() => {
    if (!uploadedFile) {
      return;
    }

    const { errors } = uploadedFile;
    if (!errors || errors.length === 0) {
      return;
    }

    return (
      <div>
        {t("components.tabularInput.errors.uploadFailed")}
        <ul>
          {errors.map((error, index) => (
            <li key={"lottie-overlay-file-picker-error-" + index}>{error.message}</li>
          ))}
        </ul>
        {t("components.tabularInput.errors.tryAgain")}
      </div>
    );
  }, [uploadedFile]);

  useEffect(() => {
    setUploadedFile(getUploadFileMeta(inputData?.value));
  }, [inputData?.value]);

  async function validateFile(file) {
    const errors = [];
    try {
      const lottieJSON = await fromFileToJSON(file);
      const isValidLottie = validateLottieFormat(lottieJSON);
      //JSON verification is fine, if it not raises an error, then we can continue.
      if (!isValidLottie) {
        errors.push(new Error(t("error.invalid.lottieFile")));
      }
    } catch (error) {
      errors.push(new Error(t("error.invalid.lottieFile")));
    }
    return errors;
  }

  async function processFile(file) {
    input.resetErrors();

    const fileErrors = await validateFile(file);

    if (fileErrors.length > 0) {
      setUploadedFile({
        percent: 100,
        status: "error",
        errors: fileErrors,
      });
      return;
    }
    setUploadedFile({ percent: 0, status: "processing" });

    const { url } = await Api.uploadTemporaryFile(lottieMimeType, file.name, file);
    const lottieJSON = await fromFileToJSON(file);

    setUploadedFile({
      percent: 100,
      status: "success",
    });

    setTimeout(() => {
      input.setValue({
        source: url,
        rotation: 0,
        size: 0,
        x: 0,
        y: 0,
        ["source" + META_SUFFIX]: {
          fileName: file.name,
          height: lottieJSON.h,
          width: lottieJSON.w,
          bytes: file.size,
        },
      });
      datadogRum.addAction(DatadogEvents.LOTTIE_OVERLAY_FILE_MANUALLY_UPLOADED);
    }, 300);
  }

  function handleFileRemoval() {
    setUploadedFile(null);
    input.clearValue();
  }

  function handleOnInteractiveAreaChange(values) {
    input.setValue(values);
  }

  async function handleOnDownloadedLottieFile(values) {
    input.setValue(values);
    setShowLottieFilesAPIModal(false);
  }

  return (
    <>
      <LottieFilesAPIModal
        open={showLottieFilesAPIModal}
        onClose={() => setShowLottieFilesAPIModal(false)}
        onSuccess={handleOnDownloadedLottieFile}
      />

      <InputWrapper input={input}>
        {!uploadedFile ? (
          <div className={style.pickerContainer}>
            <div className={style.lottieFilesContainer} onClick={() => setShowLottieFilesAPIModal(true)}>
              <Typography.P2Regular>Search and select with </Typography.P2Regular>
              <img height={24} alt="LottieFiles API" src={LottieFilesLogo} />
            </div>
            <Typography.P2Regular className={style.text}>{t("components.picker.or")}</Typography.P2Regular>
            <FilePickerV2
              hint={t("components.filePicker.uploadHintLotties")}
              beforeUpload={processFile}
              action={() => false}
              accept={lottieFileExtension}
              maxCount={1}
            />
          </div>
        ) : (
          <>
            <Form.Item validateStatus={uploadedFile.status} help={parsedErrors}>
              <FileUploadMeta fileMeta={uploadedFile} onRemove={handleFileRemoval} />
            </Form.Item>
            {inputData && (
              <InteractiveArea
                onChange={handleOnInteractiveAreaChange}
                constraintsReferenceInput={input.getConstraintsOnInputPathReferenceInstance()}
                inputData={inputData}
                withRotation
              >
                <KonvaElement
                  type={References.KONVA_ELEMENT_TYPE_LOTTIE}
                  enableFlip={false}
                  url={inputData.value.source}
                />
              </InteractiveArea>
            )}
          </>
        )}
      </InputWrapper>
    </>
  );
}

InputLottieOverlayUi.propTypes = {
  input: PropTypes.shape({
    getValue: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    getConstraintsOnInputPathReferenceInstance: PropTypes.func.isRequired,
    resetErrors: PropTypes.func.isRequired,
    clearValue: PropTypes.func.isRequired,
  }).isRequired,
};
