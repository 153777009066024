import { Modal } from "@ogury/design-system";
import { CreativeWeight } from "components";
import { useTranslation } from "react-i18next";

export default function CreativeWeightModal({ experienceId, onClose, onGotoBuilder = () => {} }) {
  const [t] = useTranslation();

  return (
    <Modal title={t("header.creativeWeightModalTitle")} open onCancel={onClose}>
      <CreativeWeight experienceId={experienceId} onGotoBuilder={onGotoBuilder} />
    </Modal>
  );
}
