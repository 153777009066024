import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { generateColors } from "~/util/Utils";
import style from "./DonutChart.module.scss";
import { useMemo, useState } from "react";
import { PopoverElement } from "components/CreativeWeight/components";
import { useTranslation } from "react-i18next";
import { Typography } from "@ogury/design-system";
import CodeSSlashFillIcon from "remixicon-react/CodeSSlashFillIcon";
import { Image } from "components";

ChartJS.register(ArcElement, Tooltip, Legend);

const ASSET_TYPE_IMAGE = "image";
const ASSET_TYPE_VIDEO = "video";
const ASSET_TYPE_OTHER = "other";

export default function DonutChart({ resources, totalWeight, onGotoBuilder }) {
  const colors = generateColors(resources.length); // Example usage
  const [t] = useTranslation();

  const [asset, setAsset] = useState();

  const data = useMemo(() => {
    return {
      labels: resources.map(resource => resource.title),
      datasets: [
        {
          networkWeightReadable: resources.map(resource => resource.networkWeightReadable),
          isImage: resources.map(resource => resource.isImage),
          isVideo: resources.map(resource => resource.isVideo),
          url: resources.map(resource => resource.url),
          filename: resources.map(resource => resource.title),
          data: resources.map(resource => (resource.networkWeightInBytes * 100) / totalWeight),
          backgroundColor: colors,
          borderWidth: 1,
        },
      ],
    };
  }, [resources]);

  function renderImage() {
    if (asset?.type === ASSET_TYPE_IMAGE) {
      return (
        <PopoverElement url={asset.url} filename={asset.filename} onGotoBuilder={onGotoBuilder}>
          <Image src={asset.url} withCheckboardBackground />
        </PopoverElement>
      );
    } else if (asset?.type === ASSET_TYPE_VIDEO) {
      return (
        <PopoverElement url={asset.url} filename={asset.filename} onGotoBuilder={onGotoBuilder}>
          <video autoPlay loop src={asset.url} />
        </PopoverElement>
      );
    } else if (asset?.type === ASSET_TYPE_OTHER) {
      return (
        <div className={style.otherContainer}>
          <CodeSSlashFillIcon />
          <Typography.P2Regular>{asset.filename}</Typography.P2Regular>
        </div>
      );
    }
    return (
      <div className={style.noImage}>
        <span>{t("components.creativeWeight.emptyImageInChart")}</span>
      </div>
    );
  }

  return (
    <div className={style.container}>
      <Doughnut
        options={{
          cutout: "70%",
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              position: "nearest",
              callbacks: {
                label(tooltipItem) {
                  const isImage = tooltipItem.dataset.isImage[tooltipItem.dataIndex];
                  const isVideo = tooltipItem.dataset.isVideo[tooltipItem.dataIndex];
                  const url = tooltipItem.dataset.url[tooltipItem.dataIndex];
                  const filename = tooltipItem.dataset.filename[tooltipItem.dataIndex];

                  const assetIsDifferent = asset?.filename !== filename;

                  if (assetIsDifferent) {
                    let assetType;
                    if (isImage) {
                      assetType = ASSET_TYPE_IMAGE;
                    } else if (isVideo) {
                      assetType = ASSET_TYPE_VIDEO;
                    } else {
                      assetType = ASSET_TYPE_OTHER;
                    }

                    setAsset({ type: assetType, filename, url });
                  }
                  return tooltipItem.dataset[tooltipItem.dataIndex];
                },
              },
            },
          },
        }}
        data={data}
      />

      <div className={style.imageContainer}>{renderImage()}</div>
    </div>
  );
}
