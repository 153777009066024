import Model from "./Model";
import InputImageOverlayUi from "../ui/InputImageOverlay/InputImageOverlayUi";
import {
  BINARY_SUFFIX,
  computeFileNameFromUrl,
  computeUiValueFromUrlOrBinaryString,
  fromBinaryStringValueToName,
  META_SUFFIX,
} from "~/util/InputsComputer";
import { loadImage } from "~/util/Image";
import InputBinaryModel from "app/inputs/model/InputBinaryModel";

const IMAGE_PATH = "image";

const COORDINATES_COMPUTATION = {
  TOP_LEFT: "topLeft",
  GRAVITY: "gravity",
};

const SIZE_COMPUTATION = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
};

const defaultState = {
  x: 50,
  y: 50,
  size: 100,
  coordinatesComputation: COORDINATES_COMPUTATION.TOP_LEFT,
  sizeComputation: SIZE_COMPUTATION.HORIZONTAL,
  "image-meta": {},
};

export default class InputImageOverlayModel extends InputBinaryModel {
  constructor(...args) {
    super(...args);
  }

  renderComponent() {
    return InputImageOverlayUi;
  }

  getConstraintsOnInputPathReferenceInstance() {
    return Model.getConstraintsOnInputPathReferenceInstance(this, true);
  }

  hasBinaryForm() {
    return true;
  }

  getFilename() {
    const value = this.getValue()?.image;
    if (value === undefined) {
      return undefined;
    }
    if (value.bin !== undefined) {
      return fromBinaryStringValueToName(value.bin);
    }
    return computeFileNameFromUrl(value, true);
  }

  getDefaultValue() {
    let defaultValue = super.getDefaultValue();
    // If all the default values have not been set in the template, then we replace the missing one with standard values.
    Object.keys(defaultState).forEach(key => {
      if (!defaultValue.hasOwnProperty(key)) {
        defaultValue[key] = defaultState[key];
      }
    });
    return super.getDefaultValue();
  }

  async computeMetas() {
    let value = this.getValue();
    if (value?.image != null) {
      value = value.image;
      const image = await loadImage(value);
      if (image === undefined) {
        return undefined;
      }
      // noinspection JSValidateTypes
      const { mimeType } = await computeUiValueFromUrlOrBinaryString(value);

      return {
        ratio: image.naturalWidth / image.naturalHeight,
        width: image.naturalWidth,
        height: image.naturalHeight,
        mimeType,
      };
    }
    return undefined;
  }

  async validateConstraints() {
    let value = this.getValue();
    if (!value) {
      return true;
    }

    let imageValue = value.image?.bin ? value.image.bin : value.image;
    this.getValidator().isDefined(imageValue, this.errors);
    if (this.errors.length > 0) {
      return false;
    }
    const additionalErrors = await this.computeConstraintsErrors();
    this.errors = this.errors.concat(additionalErrors);
    if (this.errors.length > 0) {
      console.warn("A constraint on the input '" + this.getId() + "' is violated");
    }
    return this.errors.length <= 0;
  }

  computeValue(value, image) {
    return {
      x: value.x,
      y: value.y,
      size: value.size,
      sizeComputation: value.sizeComputation,
      coordinatesComputation: value.coordinatesComputation,
      ...image,
    };
  }

  getFormatsConstraints() {
    return Model.getFormatsConstraints(this);
  }

  async computeImportValue(value) {
    if (value != null && value[IMAGE_PATH + BINARY_SUFFIX] != null) {
      const binaryValue = value[IMAGE_PATH + BINARY_SUFFIX];
      try {
        const image = (await this.convertBinaryToTemporaryUrl(binaryValue)).url;
        return this.computeValue(value, { image, ["image" + META_SUFFIX]: value["image" + META_SUFFIX] });
      } catch (error) {
        console.error("Can not convert the asset with ID " + this.getId() + " to URL. ", error);
        return this.computeValue(value, { image: { bin: binaryValue } });
      }
    }
    return value;
  }

  async export(assetsBaseUrl = "", resolveAssets = false) {
    let exportKey = this.getReferenceId();
    let exportObj = {};

    const value = this.getValue();

    const image = value?.image;
    if (image) {
      const binaryValue = await this.exportBinaryValue(assetsBaseUrl, resolveAssets, image);

      const isBinary = binaryValue != null;
      const valueKey = isBinary ? IMAGE_PATH + BINARY_SUFFIX : IMAGE_PATH;
      const valueData = isBinary ? binaryValue : image;

      const metas = await this.computeMetas();
      exportObj[exportKey] = this.computeValue(value, {
        [valueKey]: valueData,
        [IMAGE_PATH + META_SUFFIX]: metas,
      });
    }
    return exportObj;
  }

  canBeDetached() {
    return true;
  }
}
