import style from "./LeftItem.module.scss";
import React, { useEffect, useState } from "react";
import ArrowDropRightLineIcon from "remixicon-react/ArrowDropRightLineIcon";
import ArrowDropDownLineIcon from "remixicon-react/ArrowDropDownLineIcon";
import CopyOutlined from "remixicon-react/FileCopyLineIcon";
import DeleteOutlined from "remixicon-react/DeleteBinLineIcon";
import DownOutlined from "remixicon-react/ArrowDownSLineIcon";
import UpOutlined from "remixicon-react/ArrowUpSLineIcon";
import { Button, Icons, Tooltip } from "components";
import AddCircleLineIcon from "remixicon-react/AddCircleLineIcon";
import useStores from "../../../../../../hooks/useStores";
import { Switch, Badge, Dropdown, theme, Typography } from "@ogury/design-system";
import { useTranslation } from "react-i18next";
import { SCHEMA_INPUTS_FIELDS } from "~/util/ModelConstants";
import More2FillIcon from "remixicon-react/More2FillIcon";
import References from "../../../../../../util/References";

// treeNode is an object generated by the method getUiData() in Model.js
export default function LeftItem({ treeNode = {}, level = 0, hidden = true, active }) {
  const [hideChildren, setHideChildren] = useState(hidden);
  const [contextualMenuVisible, setContextualMenuVisible] = useState(false);
  const [t] = useTranslation();
  const {
    uiStore,
    addCollection,
    moveCollection,
    duplicateCollection,
    deleteCollection,
    getDeepNodeInstanceById,
    getTreeNodeById,
    activeTreeNodeId,
    setActiveTreeNodeId,
  } = useStores();

  const childItems = treeNode.inputs;
  const hasChildItems = childItems?.length >= 1 && treeNode.superType !== References.INPUT_SUPER_TYPE.COMPOSITE;
  const isCollection = treeNode.superType === References.INPUT_SUPER_TYPE.COLLECTION;
  const isCollectionChild = treeNode.superType === References.INPUT_SUPER_TYPE.COLLECTION_CHILD;
  const isSelected = active || treeNode.id === activeTreeNodeId;

  useEffect(() => {
    if (hidden === true) {
      // Forces hiding every children down the children tree
      setHideChildren(true);
    }
  }, [hidden]);

  useEffect(() => {
    if (activeTreeNodeId.startsWith(treeNode.id)) {
      setHideChildren(false);
    }
  }, [activeTreeNodeId]);

  function handleOnClickMoveCollection(event, { moveDown }) {
    event.stopPropagation();

    const movedInstance = moveCollection(treeNode.id, moveDown);
    if (activeTreeNodeId === movedInstance.getId()) {
      setActiveTreeNodeId(treeNode.id);
    }
    if (activeTreeNodeId === treeNode.id) {
      setActiveTreeNodeId(movedInstance.getId());
    }
    setHideChildren(true);
    setContextualMenuVisible(false);
  }

  function handleOnClickDuplicateCollection(event) {
    event.stopPropagation();
    const duplicatedCollectionNode = duplicateCollection(treeNode.id);
    const instance = duplicatedCollectionNode.instance;
    setActiveTreeNodeId(instance.getId());
    setHideChildren(true);
    setContextualMenuVisible(false);
  }

  function handleOnClickDeleteCollection(event) {
    event.stopPropagation();
    const instance = getDeepNodeInstanceById(treeNode.id);
    const parentInstance = getDeepNodeInstanceById(instance.getParentId());
    setActiveTreeNodeId(parentInstance.getId());
    deleteCollection(treeNode.id);
    setContextualMenuVisible(false);
  }

  function handleOnClickToggleOptional(checked, event) {
    event.stopPropagation();
    const instance = getDeepNodeInstanceById(treeNode.id);
    instance.setEnabled(checked);
  }

  function handleOnClickUpDownCarret(event) {
    event.stopPropagation();
    setHideChildren(!hideChildren);
  }

  function handleOnClickAddCollection(event) {
    event.stopPropagation();
    const childCollectionNodeInstance = addCollection(treeNode.id);
    setActiveTreeNodeId(childCollectionNodeInstance.getId());
    setHideChildren(false);
    setContextualMenuVisible(false);
  }

  function collectionChildMenu({
    collectionLength,
    collectionChildIndex,
    canDeleteCollectionChild,
    canDuplicateCollectionChild,
  }) {
    const canMoveUpCollection = collectionChildIndex !== 0;
    const canMoveDownCollection = collectionChildIndex < collectionLength - 1;

    return [
      {
        key: "1",
        icon: <CopyOutlined size={16} />,
        label: (
          <div
            onClick={event => {
              if (canDuplicateCollectionChild) {
                handleOnClickDuplicateCollection(event);
              }
            }}
          >
            {t("leftPanel.contextualMenu.button.duplicate")}
          </div>
        ),
      },
      { type: "divider" },
      {
        key: "2",
        icon: <UpOutlined size={16} />,
        label: (
          <div
            onClick={event => {
              if (canMoveUpCollection) {
                handleOnClickMoveCollection(event, { moveDown: false });
              }
            }}
          >
            {t("leftPanel.contextualMenu.button.moveUp")}
          </div>
        ),
        disabled: !canMoveUpCollection,
      },
      {
        key: "3",
        icon: <DownOutlined size={16} />,
        label: (
          <div
            onClick={event => {
              if (canMoveDownCollection) {
                handleOnClickMoveCollection(event, { moveDown: true });
              }
            }}
          >
            {t("leftPanel.contextualMenu.button.moveDown")}
          </div>
        ),
        disabled: !canMoveDownCollection,
      },
      { type: "divider" },
      {
        key: "4",
        icon: <DeleteOutlined size={16} />,
        label: (
          <div
            onClick={event => {
              if (canDeleteCollectionChild) {
                handleOnClickDeleteCollection(event);
              }
            }}
          >
            {t("leftPanel.contextualMenu.button.delete")}
          </div>
        ),
        disabled: !canDeleteCollectionChild,
      },
    ];
  }

  function onClickOnItem() {
    setActiveTreeNodeId(treeNode.id);
  }

  function renderUpDownCarret() {
    function computeCarretSize() {
      if (level > 0) {
        return 20;
      }
      return 24;
    }

    function computeCarretColor() {
      return isSelected === true ? theme.colors.secondary.dark : theme.colors.content.primary;
    }

    const carretOptions = {
      color: computeCarretColor(),
      size: computeCarretSize(),
    };

    if (treeNode.enabled) {
      return (
        <div className={style.iconCarretContainer}>
          <div className={style.iconCarretEventHandler} onClick={handleOnClickUpDownCarret} />
          {hideChildren ? <ArrowDropRightLineIcon {...carretOptions} /> : <ArrowDropDownLineIcon {...carretOptions} />}
        </div>
      );
    }
  }

  function renderOptional() {
    if (treeNode.optional) {
      return <Switch size="small" checked={treeNode.enabled} onChange={handleOnClickToggleOptional} />;
    }
  }

  function renderLeftLabel() {
    const style = {
      fontWeight: 400,
      fontSize: 14,
      color: isSelected === true ? theme.colors.secondary.dark : theme.colors.content.primary,
    };

    if (level === 0) {
      style.fontWeight = 500;
      style.fontSize = 16;
    }
    if (level > 1) {
      style.fontWeight = 400;
      style.fontSize = 12;
    }
    return <Typography.P2Regular style={style}>{treeNode.ui.name}</Typography.P2Regular>;
  }

  function renderValueLabel() {
    if (treeNode.showInLeftMenu) {
      return <Typography.P1Regular className={style.valueLabel}>{treeNode.valueLabel}</Typography.P1Regular>;
    }
  }

  function renderAddButton() {
    if (isCollection && treeNode.enabled) {
      const countMinimum = treeNode.count?.minimum;
      const countMaximum = treeNode.count?.maximum;

      if (countMinimum !== undefined && countMaximum !== undefined && countMinimum === countMaximum) {
        return <></>;
      }
      if (countMaximum !== undefined && treeNode.inputs?.length >= countMaximum) {
        return (
          <div style={{ width: 32 }}>
            <Tooltip content={t("leftPanel.collection.countMaximumReached")} />
          </div>
        );
      }
      if (treeNode.inputs) {
        return (
          <Button type="tertiary" size="small" onClick={handleOnClickAddCollection}>
            <AddCircleLineIcon />
          </Button>
        );
      }
    }
  }

  function renderContextualMenuButton() {
    if (isCollectionChild) {
      const instance = getDeepNodeInstanceById(treeNode.id);
      const parentCollectionId = instance?.getParentId();
      const parentCollectionInstance = getDeepNodeInstanceById(parentCollectionId);

      const countMinimum =
        parentCollectionInstance?.getReferenceInput()[SCHEMA_INPUTS_FIELDS.Count][SCHEMA_INPUTS_FIELDS.CountMinimum];
      const countMaximum =
        parentCollectionInstance?.getReferenceInput()[SCHEMA_INPUTS_FIELDS.Count][SCHEMA_INPUTS_FIELDS.CountMaximum];

      const parentCollectionUiStoreNode = getTreeNodeById(parentCollectionId);
      const collectionLength = parentCollectionUiStoreNode?.inputs?.length;

      if (countMinimum !== undefined && countMaximum !== undefined && countMinimum === countMaximum) {
        return <></>;
      }

      const canDeleteCollectionChild = collectionLength > countMinimum;
      const canDuplicateCollectionChild = countMaximum !== undefined && collectionLength < countMaximum;

      let collectionChildIndex = 0;

      parentCollectionUiStoreNode?.inputs?.map((collectionChildNode, index) => {
        if (collectionChildNode.id === treeNode.id) {
          collectionChildIndex = index;
        }
      });

      function onBlur({ relatedTarget }) {
        if (!relatedTarget?.className?.includes("ant-dropdown-menu-item")) {
          setContextualMenuVisible(false);
        }
      }

      return (
        <Dropdown
          open={contextualMenuVisible}
          menu={{
            items: collectionChildMenu({
              collectionLength,
              collectionChildIndex,
              canDeleteCollectionChild,
              canDuplicateCollectionChild,
            }),
            style: { width: 218 },
          }}
          onClick={() => setContextualMenuVisible(true)}
          onBlur={onBlur}
          trigger={["click"]}
        >
          <Button type="tertiary" size="small" onClick={event => event.stopPropagation()}>
            <More2FillIcon color={theme.colors.content.primary} />
          </Button>
        </Dropdown>
      );
    }
  }

  // TODO Performance issue : find a better way to display error badge on parents
  function renderErrors() {
    let showError = false;

    function iterateThroughStore(nodes) {
      for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].id.startsWith(treeNode.id) && nodes[i].errors?.length >= 1) {
          if (nodes[i].errorBadge) {
            showError = true;
            break;
          }
        }
        if (nodes[i].inputs) {
          iterateThroughStore(nodes[i].inputs);
        }
      }
    }

    iterateThroughStore(uiStore);

    return (
      showError && (
        <div className={style.errorBadgeContainer}>
          <Badge count={<Icons name="CloseCircleFilled" style={{ color: "red" }} />} />
        </div>
      )
    );
  }

  function renderChildren() {
    if (hasChildItems && treeNode.enabled) {
      return childItems.map(childItem => {
        if (childItem.display) {
          return (
            <LeftItem
              active={isSelected}
              key={"treeNode-" + childItem.id}
              hidden={hideChildren}
              level={level + 1}
              treeNode={childItem}
            />
          );
        }
      });
    }
  }

  return (
    <div>
      <div
        onClick={onClickOnItem}
        className={`${style.container} ${level > 0 && hidden === true ? style.hidden : ""} ${
          isSelected === true ? style.active : ""
        } ${level === 1 ? style.child1 : ""} ${level > 1 ? style.child2 : ""} `}
        data-testid="left-panel-nav-item"
      >
        <div className={style.left} style={{ marginLeft: level + "0" + "px" }}>
          {hasChildItems && renderUpDownCarret()}
          {renderLeftLabel()}
          {renderValueLabel()}
          {renderErrors()}
        </div>
        <div className={style.right}>
          {renderContextualMenuButton()}
          {renderAddButton()}
          {renderOptional()}
        </div>
      </div>
      <div className="child">{renderChildren()}</div>
    </div>
  );
}
