import React from "react";
import cloneDeep from "lodash.clonedeep";
import { RATIO_DIGITS_COUNT } from "~/util/ModelConstants";

export function toStringWithDigits(digits = -1, value, withoutLocale) {
  const number = Number(value);

  // check if value is falsy, excluding 0 and that conversion to number is valid
  if ((!value && value !== 0) || isNaN(value)) {
    return "";
  }

  return digits === -1
    ? withoutLocale === true
      ? number.toString()
      : number.toLocaleString()
    : withoutLocale === true
    ? number.toFixed(digits)
    : number.toLocaleString(undefined, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
      });
}

export function toStringWithInteger(number) {
  return number === undefined ? undefined : toStringWithDigits(0, number);
}

export function toStringWithRatio(number) {
  return number === undefined ? undefined : toStringWithDigits(RATIO_DIGITS_COUNT, number);
}

export function toStringWithBytes(number) {
  const numberOfBytesInAKilobytes = 1000;
  if (number === undefined) {
    return undefined;
  }
  if (number < numberOfBytesInAKilobytes) {
    return toStringWithDigits(0, number) + " b";
  } else if (number < numberOfBytesInAKilobytes * numberOfBytesInAKilobytes) {
    return toStringWithDigits(0, Math.round(number / numberOfBytesInAKilobytes)) + " kb";
  }
  return toStringWithDigits(0, Math.round(number / (numberOfBytesInAKilobytes * numberOfBytesInAKilobytes))) + " Mb";
}

export function toStringWithDuration(number) {
  return number === undefined ? undefined : toStringWithDigits(-1, number / 1000) + " s";
}

export function toStringWithDimensions(width, height) {
  return toStringWithInteger(width) + "x" + toStringWithInteger(height);
}

export function toStringWithRange(lowerBound, upperBound) {
  return lowerBound === upperBound
    ? lowerBound
    : "[" +
        lowerBound +
        ", " +
        (upperBound === undefined ? <React.Fragment>&infin;</React.Fragment> : upperBound) +
        "]";
}

export function toStringWithCollection(collection) {
  return collection.join(", ");
}

export function toStringWithLessThan(bound) {
  return <React.Fragment>&#8804; {bound}</React.Fragment>;
}

export function toStringWithGreaterThan(bound) {
  return <React.Fragment>&#8805; {bound}</React.Fragment>;
}

export function convertHTMLBreakLine(text) {
  return text.replaceAll(/\n/g, "<br/>");
}

export function convertTextBreakline(text) {
  return text.replaceAll(/<br\/>/g, "\n");
}

export function arrayMoveMutable(array, fromIndex, toIndex) {
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

    const [item] = array.splice(fromIndex, 1);
    array.splice(endIndex, 0, item);
  }
}

export function isObject(objValue) {
  return objValue && typeof objValue === "object" && objValue.constructor === Object;
}

export function isArray(arrayValue) {
  return arrayValue && Array.isArray(arrayValue);
}

/**
 * Taken from https://github.com/sindresorhus/array-move/blob/main/index.js
 * @param array
 * @param fromIndex
 * @param toIndex
 */
export function arrayMoveImmutable(array, fromIndex, toIndex) {
  const newArray = [...array];
  arrayMoveMutable(newArray, fromIndex, toIndex);
  return newArray;
}

/**
 * Deep copy function from "lodash" https://github.com/lodash/lodash
 * @param object
 * @returns {*}
 */
export function deepCopy(object) {
  return cloneDeep(object);
}
