import { ColorFactory, ColorPicker, Colors } from "@ogury/design-system";
import { InputWrapper } from "components";
import { useEffect, useState } from "react";

const InputColorUi = ({ input }) => {
  /**
   * @type {React.MutableRefObject<HTMLElement>}
   */
  const [value, setValue] = useState(input.getValue());
  const withAlpha = input.getAlphaConstraints();

  useEffect(() => {
    const inputValue = input.getValue();
    if (inputValue !== undefined) {
      setValue(inputValue);
    }
  }, [input.getValue()]);

  const handleChangeComplete = value => {
    const selectionAlpha = value.toHsb().a;
    if (selectionAlpha === 1 && withAlpha) {
      input.setValue(`${value.toHexString()}FF`); // antd does not append alpha string when opacity is 1
    } else {
      input.setValue(value.toHexString());
    }
  };

  return (
    <InputWrapper input={input}>
      <ColorPicker
        disabledAlpha={!withAlpha}
        allowClear
        value={value}
        width={200}
        onChangeComplete={handleChangeComplete}
      />
    </InputWrapper>
  );
};

export default InputColorUi;
